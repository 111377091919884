<footer [ngClass]="{'footer-rtl':cookiesService.get('lang')=='ar',
       'footer': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2023 © Rh B-link.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          Powered by B-link
        </div>
      </div>
    </div>
  </div>
</footer>
