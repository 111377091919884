import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {DiLovDetails} from '../../models/adm/DiLovDetails';
import {DiLovMaitres} from '../../models/adm/DiLovMaitres';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) {
  }


  printDocument(formData: FormGroup, url: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
    };
    return this.http.post<any>(url
      , formData.value, options);
  }
}
