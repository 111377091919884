import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {User} from '../../models/auth.models';
import {AuthenticationService} from '../auth.service';
import {EmployeeData} from '../../models/payloads/responces/EmployeeData';
import {FormGroup} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class UsRolesService {

  selectedEmployData = new EmployeeData();
  currentUser: User = new User();

  constructor(private http: HttpClient, private authService: AuthenticationService) {
  }

  findAllRoles(): Observable<any> {
    return this.http.get<any>(PAGES_URLS.ROLES);
  }

  public editRole(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.ROLES, formData.value);
  }
  public findMaxId() {
    return this.http.get<number>(PAGES_URLS.FIND_MAX_USERS_ROLES);
  }

  public saveRole(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.ROLES
      , formData.value);

  }


}
