<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="Calendar" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row" *ngIf="authenticationService.currentUserRoles().includes('ROLE_RH')">
    <div class="col-12">
      <p-toast></p-toast>
      <div class="card">
        <div class="card-body">

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-12">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                  <ng-template pTemplate="right">
                    <button pButton pRipple label="{{'ABS_REQUEST.LIST.NEW_REQUEST' |translate}}" icon="pi pi-plus"
                            class="p-button-success succes-color p-button-add p-mr-2"
                            (click)="showAddNewEvent()"></button>
                  </ng-template>
                  <ng-template pTemplate="left">
                    <div class="d-flex ">
                      <button type="button" pButton pRipple icon="pi pi-file-excel"
                              (click)="applicationService.exportExcel(dataEvents)"
                              class="p-button-success p-button-outlined mr-2" pTooltip="XLS"
                              tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="pi pi-file-pdf"
                              (click)="applicationService.exportPdf(cols,dataEvents,null)"
                              class="p-button-danger p-button-outlined mr-2" pTooltip="PDF"
                              tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                </p-toolbar>

                <p-table [columns]="cols" [value]="dataEvents" [responsive]="true" #dtE
                         selectionMode="single" [(selection)]="rhEmployeEvt" dataKey="rhEmployeEvtsPK"
                         [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                         [paginator]="true" [rows]="4"
                         [totalRecords]="totalRecords"
                         [showCurrentPageReport]="true"
                         [tableStyle]="{ 'min-width': '50rem' }"
                         currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                         [rowsPerPageOptions]="[5,10, 15, 50]">
                  <ng-template pTemplate="caption">
                    <div class="p-toolbar-group-left col-lg-5 col-sm-12">
                      <div class="app-search  d-lg-block" style="padding: 0 !important">
                        <div class="position-relative">
                          <input type="text" class="form-control" pInputText size="35"
                                 placeholder="{{'LEAVE_REQUEST.LIST.SEARCH' | translate}}"
                                 (input)="dtE.filterGlobal($event.target.value, 'contains')"
                                 style="width:auto;text-align: left !important">
                          <span class="ri-search-line"></span>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">
                        {{col.header | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                      <td *ngFor="let col of columns">
                        <span *ngIf="col.field ==='stEvents'">  {{rowData[col.field].evtDescLoc }} </span>
                        <span *ngIf="col.field ==='rhEmployes'">  {{rowData[col.field].empNomLoc }} </span>
                        <span
                          *ngIf="col.field ==='epeStartDate' || col.field ==='epeEndDate'">  {{rowData[col.field]| date:'longDate'}} </span>
                        <div class="badge font-size-12" *ngIf="col.field ==='epeStatus'"
                             ngClass="{{rowData[col.field] === 0 ? 'badge-soft-info' : rowData[col.field] === 1 ? 'badge-soft-success ' :'badge-soft-danger'}}"
                        >{{rowData[col.field] === 0 ? 'En Cours' : rowData[col.field] === 1 ? 'Accepté' : 'Rejeté'}}</div>

                        <!--ACTIONS TOOLS BARRE-->
                        <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">
                          <button pButton pRipple type="button" icon="pi pi-search"
                                  class="p-button-rounded p-button-text p-button-info"
                                  pTooltip="Afficher details"
                                  (click)="showDetailsEvent(rowData)"></button>

                          <button pButton pRipple type="button" icon="pi pi-pencil"
                                  class="p-button-rounded p-button-text p-button-warning  mr-2"
                                  pTooltip="modifier" id="one"
                                  (click)="showEditEvent(rowData , rowIndex)"></button>

                          <button pButton pRipple type="button" icon="pi pi-folder"
                                  class="p-button-rounded p-button-text p-button-success"
                                  pTooltip="Status"
                                  (click)="showStatusEmpEvent(rowData)"></button>

                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                      <td [attr.colspan]="columns.length">
                        No records found
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                      Au total il y a {{dataEvents ? dataEvents.length : 0 }} demandes.
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </div>

            <!-- STATUS DIALOG-->
            <p-dialog #pdBack [maximizable]="false"
                      [baseZIndex]="10000" [draggable]="false"
                      [(visible)]="statusDlg" [style]="{width: '50%'}"
                      header="{{'LEAVE_REQUEST.LIST.REQUEST_STATUS' |translate}}" [modal]="true"
                      styleClass="p-fluid" id="statusDlg">
              <p-card
                header="{{rhEmployeEvt.rhEmployes.rhEmployesPK.empNum}} : {{rhEmployeEvt.rhEmployes.empNomLoc}}"
                *ngIf="statusDlg"
                subheader="{{rhEmployeEvt.stEvents.evtDescLoc}}"
                styleClass="p-card-shadow">
                <p-table [value]="rhEmployeEvt.rhEmployeEvtsStatusList" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>

                      <th>{{'ABS_REQUEST.HEADERS.CREATE_BY' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.CREATE_DATE' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.STATUS' |translate}}</th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-status>
                    <tr>
                      <td>{{status.epsCreatedPar}}</td>
                      <td>{{status.epsCreationDate | date:'longDate'}}</td>
                      <td>
                        <div class="badge font-size-12"
                             ngClass="{{status.epsStatus === 0 ? 'badge-soft-info' : status.epsStatus === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{status.epsInformation}}</div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-card>
            </p-dialog>

            <p-confirmDialog [style]="{width: '450px'}" [baseZIndex]="999999"></p-confirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--ADD+UPDATE DIALOG-->
  <form [formGroup]="eventForm" (ngSubmit)="saveEmployeeEvent()">
    <p-dialog #pdBack
              id="modal-container"
              [baseZIndex]="10000"
              [(visible)]="eventDtDlg"
              [style]="{width: '80%'}"
              header="{{showAdd ?('EVENT.TEXT' |translate) :
                      showEdits ? ('LEAVE_REQUEST.LIST.REQUEST_DETAILS' |translate) :
                      ('LEAVE_REQUEST.LIST.REQUEST_DETAILS' |translate)}}" [modal]="true"
              [draggable]="false" [resizable]="false"
              styleClass="p-fluid" id="evtDlg">
      <app-loader *ngIf="blockedDocument"></app-loader>
      <ng-template pTemplate="content">
        <p-card class="pb-2">


          <div class="form-group">
            <div class="row col-sm-12 form-row mb-2 ">
              <div class="p-field col-12 col-lg-4">
                <label class="control-label" for="evet">{{'EVENT.FIELD.EVENT' |translate}}</label>
                <span class="text-danger ml-2 mr-2" *ngIf="showAdd || showEdits">*</span>
                <div class="field" *ngIf="showAdd">
                  <p-dropdown id="evet" [options]="listEvents" styleClass="col-lg-12"
                              (onChange)="onSelectEvent($event)"
                              optionLabel="evtDescLoc" placeholder="{{'EVENT.FIELD.CHOISE_EVENT' |translate}}"
                              [ngClass]="{'is-invalid': submitted  && deF.codeEvent.errors }"
                              [readonly]="showDetails">
                  </p-dropdown>
                  <div *ngIf="submitted && deF.codeEvent.errors" class="invalid-feedback">
                    <div *ngIf="deF.codeEvent.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                  </div>
                </div>
                <div class="field" *ngIf="showDetails || showEdits">
                  <input value="{{rhEmployeEvt.stEvents.evtDescLoc}}" class="form-control" readonly/>
                </div>
              </div>

              <div class="p-field col-12 col-lg-4">
                <label class="control-label">{{'EVENT.FIELD.START_DATE' |translate}} </label>
                <span class="text-danger ml-2 mr-2">*</span>
                <p-calendar [baseZIndex]="10001" appendTo="body" id="startDate" required
                            [showIcon]="true"
                            dateFormat="dd/mm/yy" formControlName="dateDebut"
                            name="epeEndDate" inputId="icon"
                            [showTime]="true"
                            styleClass="ui-fluid col-lg-12"
                            [disabled]="showDetails"
                            [ngClass]="{'is-invalid': submitted  && deF.dateDebut.errors }">

                </p-calendar>
                <div *ngIf="submitted && deF.dateDebut.errors" class="invalid-feedback">
                  <div *ngIf="deF.dateDebut.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                </div>
              </div>

              <div class="p-field col-12 col-lg-4">
                <label class="control-label">{{'EVENT.FIELD.END_DATE' |translate}} </label>
                <span class="text-danger ml-2 mr-2">*</span>
                <p-calendar [baseZIndex]="10001" appendTo="body" id="EndDate" required
                            [showIcon]="true" styleClass="form-control"
                            dateFormat="dd/mm/yy" formControlName="dateFin"
                            name="epeEndDate" [minDate]='deF.dateDebut.value' inputId="icon"
                            [showTime]="true"
                            styleClass="ui-fluid col-lg-12"
                            [disabled]="showDetails"
                            [ngClass]="{'is-invalid': submitted  && deF.dateFin.errors }">

                </p-calendar>
                <div *ngIf="submitted && deF.dateFin.errors" class="invalid-feedback">
                  <div *ngIf="deF.dateFin.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="eventFileFlag">
            <div class="row col-sm-12 form-row mb-2 ">
              <div class="p-field col-12 col-lg-12">
                <app-custom-file-upload
                  [uploadedFiles]="uploadedFiles"
                  (fileUploaded)="onFileUploaded($event)"
                  [ngClass]="{ 'is-invalid': deF.eventFiles.errors}"
                  (validateUpload)="onValidateUpload(uploadedFiles)">
                </app-custom-file-upload>
                <div *ngIf="submitted && deF.eventFiles.errors" class="invalid-feedback">
                  <div *ngIf="deF.eventFiles.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row col-sm-12 form-row mb-2 ">
              <div class="p-field col-12 col-lg-4" *ngIf="showAdd">
                <label>{{'EVENT.FIELD.EMPLOYEE' |translate}}</label>
                <p-multiSelect styleClass="col-lg-12 ui-fluid" [options]="listEmployes"
                               [(ngModel)]="selectedEmployee"
                               optionLabel="empNomLoc" defaultLabel="{{'EVENT.FIELD.CHOISE_EMPLOYEES' |translate}}"
                               [disabled]="listEmployes.length===0" formControlName="employeeList"
                               [ngClass]="{'is-invalid': submitted  && deF.employeeList.errors }"
                               [readonly]="showDetails">
                </p-multiSelect>
                <div *ngIf="submitted && deF.employeeList.errors" class="invalid-feedback">
                  <div *ngIf="deF.employeeList.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                </div>
              </div>
              <div class="p-field col-12 col-lg-4" *ngIf="showDetails">
                <label>{{'EVENT.FIELD.EMPLOYEE' |translate}}</label>
                <input id="employeeUs" type="text" pInputText [value]="rhEmployeEvt.rhEmployes.empNomLoc" readonly
                       name="employeeUs" class="form-control"/>
              </div>

              <div class="p-field col-12 col-lg-2">
                <label>{{'EVENT.FIELD.ACTIVE_FLAG' |translate}}</label>
                <p-toggleButton styleClass="form-control" formControlName="activeFlag" onIcon="pi pi-check"
                                onLabel="{{'EVENT.FIELD.ACTIVE' |translate}}"
                                offIcon="pi pi-times" offLabel="{{'EVENT.FIELD.INACTIVE' |translate}}"></p-toggleButton>
              </div>
            </div>
          </div>
        </p-card>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' |translate}}" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>

        <button pButton pRipple label="{{'EVENT.FIELD.SAVE' |translate}}" icon="pi pi-check"
                class="p-button-text  float-right" *ngIf="showAdd || showEdits"
                type="submit"></button>

      </ng-template>
    </p-dialog>
  </form>
</div>

