import {RhRoles} from './RhRoles';
import {RhUsersApp} from './RhUsersApp';

export class RhUsersAppRoles  {
    rhUsersAppRolesPK: RhUsersAppRolesPK;
    uurGrpId: number;
    uurUsrCode: string;
    rhRoles: RhRoles;
    // rhUsersApp: RhUsersApp;
}

export class RhUsersAppRolesPK {
    uurUsrId: number;
    uurRlaCode: string;
}
