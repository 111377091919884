import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {RhEmployes, RhEmployesPK} from '../../models/rh-ec/RhEmployes';
import {RhEmployeEvts, RhEmployeEvtsPK} from '../../models/rh-ec/RhEmployeEvts';
import {FormGroup} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private http: HttpClient) {
  }

  /*
  setup evenets methods
   */
  findAllStEvents(): Observable<any> {
    return this.http.get<any>(PAGES_URLS.EVENTS);
  }


  saveEvent(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.EVENTS
      , formData.value);
  }

  /*
  end setup events methods
   */
  findMaxEventCode(): Observable<any> {
    return this.http.get<any>(PAGES_URLS.MAX_CODE_EVENT);
  }


  findAllRhEmployeeEvents(): Observable<RhEmployeEvts[]> {
    return this.http.get<any>(PAGES_URLS.EMPLOYEE_EVENTS);
  }

  saveRhEmployeeEvent(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.EMPLOYEE_EVENTS
      , formData.value);
  }

  saveRhEmployeeEventByRh(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.SAVE_EVENT_BY_RH
      , formData.value);
  }

  public validateEvent(rhEmployeEvts: RhEmployeEvts) {
    return this.http.put<any>(PAGES_URLS.EMPLOYEE_EVENTS, rhEmployeEvts);
  }

  public delteEvent(pk: RhEmployeEvtsPK) {
    const requestBody = {
      epeGrpId: pk.epeGrpId,
      epeCmpId: pk.epeCmpId,
      epeEmpNum: pk.epeEmpNum,
      epeEmpType: pk.epeEmpType,
      epeEvtCode: pk.epeEvtCode,
      epeSerial: pk.epeSerial
    };
    return this.http.post(PAGES_URLS.DELETE_EMPLOYEE_EVENTS, {requestBody});
  }

  // findEvent(empNum: string , empType: number): Observable<any> {
  //   const params = new HttpParams()
  //     .set('empNum', empNum).set('empType', empType.toString());
  //   return this.http.get<any>(PAGES_URLS.FIND_EMPLOYES , {
  //     params
  //   });
  // }

}
