<app-loader *ngIf="loading"></app-loader>
<p-toast position="bottom-right"></p-toast>
<div class="container-fluid">
  <app-pagetitle title="{{'USERS.LIST.USER_DETAILS' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>
<div class="row">
  <div class="col-10 offset-1" *ngIf="!loading">
    <div class="card" style="box-shadow: none!important;">
      <div class="card-header" style="padding:0 !important ; height: 70px!important;">
        <div class="text-center">
          <img src="{{employeeProfilePicStream}}" class="profile-picture">
        </div>
      </div>
      <div class="card-body" style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);!important;">
        <i class="fa fa-arrow-left" style="font-size: 20px ; color: var(--primary) ; cursor: pointer"
           (click)="applicationService.goToLink('#/employees')"></i>
        <div class="row mt-5">
          <div class="d-flex flex-column col-12 mt-4">
            <div class="text-center">
              <h2 class="card-subtitle">{{employee.emp1stNameLoc}} {{employee.empLastNameLoc}}</h2>
              <span class="card-title">{{employeePoste}}</span>
            </div>
            <div class="d-flex justify-content-around text-center">
              <div class="col-lg-4 col-sm-12">
                <i class="mdi mdi-facebook social-media-icons text-primary mr-3"></i>
                <i class="mdi mdi-linkedin social-media-icons text-primary mr-3"></i>
                <i class="mdi mdi-gmail social-media-icons text-primary"></i>
              </div>
            </div>
          </div>
          <div class="row mt-4 col-12">
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Nom et Prénom :</label>
              <input class="form-control" value="{{employee.emp1stNameLoc}} {{employee.empLastNameLoc}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Email :</label>
              <input class="form-control" value="{{employee.empEmail}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Genre :</label>
              <input class="form-control"
                     value="{{applicationService.filterListDlvById(listGenders,employee.empGender)}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Date De Naissance :</label>
              <input class="form-control" value="{{employee.empBirthDate | date:'fullDate'}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Lieu de Naissance :</label>
              <input class="form-control" value="{{employee.empBirthPlace}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Age :</label>
              <input class="form-control" value="{{applicationService.calculateYears(employee.empBirthDate)}} ans"
                     readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Date début Contrat :</label>
              <input class="form-control" value="{{employee.empTrainingStartDate |date:'fullDate'}}" readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Date fin Contrat :</label>
              <input class="form-control"
                     value="{{employee.empTrainingEndDate===null ? '/' :employee.empTrainingEndDate |date:'fullDate'}}"
                     readonly>
            </div>
            <div class="col-12 col-lg-4 form-group">
              <label class="control-label">Type Contrat :</label>
              <input class="form-control"
                     value="{{applicationService.filterListDlvById(listContractsTypes,employee.empContractType)}}"
                     readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
