import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {User} from '../../core/models/auth.models';
import {ApplicationService} from '../../core/services/application.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {DemandeCongesService} from '../../core/services/rh-ec/demandeCongesService';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {RhRoles} from '../../core/models/security/RhRoles';
import {UsRolesService} from '../../core/services/rh-ec/usRolesService';
import {UtilConstants} from '../../core/helpers/util-constants';
import {RhUsersApp} from '../../core/models/security/RhUsersApp';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  cookieValue;

  currentUser: User;

  // Form Controll attributs
  rolesForm: FormGroup;
  editUserAppForm: FormGroup;
  submitted = false;
  error = '';


  // data Attributes
  // Data
  data: RhRoles[];
  roles: RhRoles[];
  totalRecords: number;
  selectedRole = new RhRoles();
  rowIndex: any;

  // Dialog Values
  rolesDtDlg = false;
  rolesDlg = false;
  editMode = false;

  // Lazy attributes
  cols: any[];
  exportColumns: any[];
  loading = true;


  breadCrumbItems: Array<{}>;


  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, public cookiesService: CookieService,
              public employesService: EmployesService, public dilovService: DilovService, private messageService: MessageService,
              private confirmationService: ConfirmationService, public authService: AuthenticationService, private router: Router,
              public usrolesService: UsRolesService, private cdr: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.cookieValue = this.cookiesService.get('lang');
    this.currentUser = this.authService.currentUser();
    this.breadCrumbItems = [{label: 'Dashbord'}, {label: 'Roles', active: true}];
    this._fetchData();

    /**
     * Template cols
     */
    this.cols = [
      // {field: 'img', header: '#'},
      {
        field: 'rlaLabel',
        header: 'ROLE.HEADERS.ROLE_LABEL',
        width: '18%'

      },
      {
        field: 'rlaCode',
        header: 'ROLE.HEADERS.CODE_ROLE',
        width: '25%'
      },
      {
        field: 'actions',
        header: 'ROLE.HEADERS.ACTIONS',
        width: '8%',
        display: this.authService.currentUserRoles().includes(UtilConstants.DEVLOPPER_ROLE) ? 'block ! important' : 'none ! important'
      }

    ];

    /**
     * init RolesForm  form groupe
     */
    this.rolesForm = this.formBuilder.group({
      rlaCode: ['', Validators.required],
      rlaLabel: ['', Validators.required]
    });

  }


  // convenience getter for easy access to form fields
  get urF() {
    return this.rolesForm.controls;
  }

  resetForm(form: FormGroup) {
    form.reset();
  }


  _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.usrolesService.findAllRoles().subscribe(
        data => {
          this.data = data.data[0];
          this.roles = data.data[0];
          this.totalRecords = this.roles.length;
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/account/login']);
          }
        },
      );
    }, 1000);

  }

  onKeyLabel(event) {
    this.rolesForm.patchValue({
      rlaCode: this.applicationService.convertToRoleName(event.target.value)
    });
  }

  /**
   *  new User App
   */
  showAddNewRole() {
    this.rolesDlg = true;

  }

  hideDialog() {
    this.rolesDlg = false;
  }

  saveRole() {
    // stop here if form is invalid
    console.log(this.rolesForm.valid);
    console.log(this.rolesForm.value);
    this.cdr.detectChanges();
    this.submitted = true;
    if (this.rolesForm.invalid) {
      return;
    } else {
      if (this.editMode) {
        this.editEntity();
      } else {
        this.addEntity();
      }
    }

  }

  /**
   *edit User App
   */
  showEditRole(rowdata: RhRoles, rowIndex: any) {
    this.rowIndex = rowIndex;
    this.selectedRole = new RhRoles();
    this.selectedRole = rowdata;
    this.editMode = true;

    /**
     * init edit form groupe
     */
    this.rolesForm.patchValue({
      rlaCode: this.selectedRole.rlaCode,
      rlaLabel: this.selectedRole.rlaCode,
    });
    this.rolesDlg = true;

  }

  /**
   * entity methods
   */
  addEntity() {

    this.usrolesService.saveRole(this.rolesForm).subscribe(data => {
      if (data.data != null) {
        this.selectedRole = (data.data[0] as RhRoles);
        this.roles.push(this.selectedRole);
        this.roles = [...this.roles];
        this.rolesDlg = false;
        this.resetForm(this.rolesForm);
        this.applicationService.showSuccefulSaveDialog('Role Ajouté Avec Succée.', 'success');
      }
    }, error => {
      console.log(error);
    });
  }


  editEntity() {

    this.usrolesService.editRole(this.rolesForm).subscribe(data => {
      this.selectedRole = (data.data[0] as RhRoles);
      this.roles.splice(this.rowIndex, 1);
      this.roles.splice(this.rowIndex, 0, this.selectedRole);
      this.roles = [...this.roles];
      this.rolesDlg = false;
      this.resetForm(this.rolesForm);
      this.applicationService.showSuccefulSaveDialog('Le Role  a été modifier avec succéee', 'success');
    }, error => {
      console.log('eroor===>' + error);
    });
  }

  public readonly UtilConstants = UtilConstants;
}
