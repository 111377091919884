// @ts-ignore

import {Injectable} from '@angular/core';

import {getFirebaseBackend} from '../../authUtils';

import {User} from '../models/auth.models';
import {HttpClient} from '@angular/common/http';
import {PAGES_URLS} from '../../pages/pages.urls';
import {CookieService} from 'ngx-cookie-service';
import {RhDemandeAbs} from '../models/rh-ec/RhDemandeAbs';
import {FormGroup} from '@angular/forms';
import {EncryptionAes} from '../helpers/EncryptionAes';
import {PathName} from '../helpers/path-name';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({providedIn: 'root'})

export class AuthenticationService {

  user: User;
  private jwtToken = null;

  constructor(private http: HttpClient, public cookie: CookieService) {
  }

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return this.getAuthenticatedUser();
  }

  public currentUserToken(): any {
    return sessionStorage.getItem(PathName.TOKEN);
  }

  public currentUserRolesStr(): string[] {
    return this.currentUser().roles;
  }

  public currentUserRoles(): any {
    return this.currentUser().roles;
  }


  login(email: string, password: string): any {
    const user = new User();
    user.username = email;
    user.password = password;
    return this.http.post<User>(PAGES_URLS.LOGIN
      , user);
  }


  register(email: string, password: string) {
    return getFirebaseBackend().registerUser(email, password).then((response: any) => {
      const user = response;
      return user;
    });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend().forgetPassword(email).then((response: any) => {
      const message = response.data;
      return message;
    });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    return this.http.post(PAGES_URLS.LOGOUT, null);
  }


  // setLoggeedInUser = (data) => {
  //   sessionStorage.setItem('RH_TOKEN', data.data);
  // };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    return (JSON.parse(sessionStorage.getItem(PathName.USER_CURRENT)) as User);
  };

  public saveToken(jwt: string) {
    console.log('¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤');
    if (jwt !== null) {
      this.jwtToken = null;
      this.jwtToken = jwt;
      if (sessionStorage.getItem(PathName.TOKEN)) {
        sessionStorage.removeItem(PathName.TOKEN);
      }
      this.user = new User();
      sessionStorage.setItem(PathName.TOKEN, jwt);
      const jwtHelper = new JwtHelperService();
      console.log(this.jwtToken);
      this.user = JSON.parse(jwtHelper.decodeToken(this.jwtToken).sub) as User;
      this.user.roles = jwtHelper.decodeToken(this.jwtToken).roles;
      sessionStorage.setItem(PathName.USER_CURRENT, JSON.stringify(this.user));
    }


  }

}
