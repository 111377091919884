import {RhDemandeAbs} from './RhDemandeAbs';
import {RhExperiences} from './RhExperiences';
import {RhFormations} from './RhFormations';
import {RhDocuments} from './RhDocuments';

export class RhEmployes {
  empNomLoc: string;
  empNomFrn: string;
  empStatus: number;
  empContractType: string;
  empEmploymentDate: Date;
  empTrainingStartDate: Date;
  empTrainingEndDate: Date;
  empBirthDate: Date;
  empGender: string;
  empBloodType: string;
  empNationalityId: string;
  empMotherNomLoc: string;
  empMotherNomFrn: string;
  empSocialSecurityNum: string;
  empTaxNum: string;
  empSocialSecurityFlag: number;
  empTaxFlag: any;
  empInsuranceFlag: number;
  empSmokingFlag: number;
  empNationalNum: string;
  empMartialStatus: string;
  empAdresseLoc: string;
  empAdresseFrn: string;
  empUrlAdresse: string;
  empFax: string;
  empTel: string;
  empMobileNo: string;
  empBnkCompte: string;
  empBnkCode: string;
  empBnkFilId: string;
  empAnnualLeave: number;
  empSickLeave: number;
  emp1stNameLoc: string;
  emp1stNameFrn: string;
  emp2ndNameLoc: string;
  emp2ndNameFrn: string;
  empLastNameLoc: string;
  empLastNameFrn: string;
  empPobox: string;
  empCountryId: string;
  empCityId: string;
  empBlock: string;
  empArea: string;
  empBuilding: string;
  empState: string;
  empFlatHouseVilla: string;
  empEmail: string;
  empPostalCode: string;
  empAdresse: string;
  empApprFlag: number;
  empCreerPar: string;
  empCreationDate: Date;
  empModifierPar: string;
  empModificationDate: Date;
  empBirthPlace: string;
  empAbtFlag: number;
  empAbtCode: string;
  empAbtDateFin: Date;
  empTrainingEndMotif: string;
  empContratType: string;
  empContratSalaire: number;
  empContratPeriodeEssai: number;
  rhDemandeAbsList: RhDemandeAbs[];
  rhEmployesPK: RhEmployesPK;
  rhExperiencesList: RhExperiences[];
  rhFormationsList: RhFormations[];
  rhDocumentsList: RhDocuments[];
}

export class RhEmployesPK {
  empGrpId: number;
  empCmpId: number;
  empType: number;
  empNum: string;
}
