import {Icon} from './setup-events.model';


export const fontAwsomeIconsList: Icon[] = [
  {value: 'fa-500px', label: '500px'},
  {value: 'fa-adjust', label: 'Adjust'},
  {value: 'fa-adn', label: 'Adn'},
  {value: 'fa-align-center', label: 'Align center'},
  {value: 'fa-align-justify', label: 'Align justify'},
  {value: 'fa-align-left', label: 'Align left'},
  {value: 'fa-align-right', label: 'Align right'},
  {value: 'fa-amazon', label: 'Amazon'},
  {value: 'fa-ambulance', label: 'Ambulance'},
  {value: 'fa-anchor', label: 'Anchor'},
  {value: 'fa-android', label: 'Android'},
  {value: 'fa-angellist', label: 'Angellist'},
  {value: 'fa-angle-double-down', label: 'Angle double down'},
  {value: 'fa-angle-double-left', label: 'Angle double left'},
  {value: 'fa-angle-double-right', label: 'Angle double right'},
  {value: 'fa-angle-double-up', label: 'Angle double up'},
  {value: 'fa-angle-down', label: 'Angle down'},
  {value: 'fa-angle-left', label: 'Angle left'},
  {value: 'fa-angle-right', label: 'Angle right'},
  {value: 'fa-angle-up', label: 'Angle up'},
  {value: 'fa-apple', label: 'Apple'},
  {value: 'fa-archive', label: 'Archive'},
  {value: 'fa-area-chart', label: 'Area chart'},
  {value: 'fa-arrow-circle-down', label: 'Arrow circle down'},
  {value: 'fa-arrow-circle-left', label: 'Arrow circle left'},
  {value: 'fa-arrow-circle-o-down', label: 'Arrow circle o down'},
  {value: 'fa-arrow-circle-o-left', label: 'Arrow circle o left'},
  {value: 'fa-arrow-circle-o-right', label: 'Arrow circle o right'},
  {value: 'fa-arrow-circle-o-up', label: 'Arrow circle o up'},
  {value: 'fa-arrow-circle-right', label: 'Arrow circle right'},
  {value: 'fa-arrow-circle-up', label: 'Arrow circle up'},
  {value: 'fa-arrow-down', label: 'Arrow down'},
  {value: 'fa-arrow-left', label: 'Arrow left'},
  {value: 'fa-arrow-right', label: 'Arrow right'},
  {value: 'fa-arrow-up', label: 'Arrow up'},
  {value: 'fa-arrows', label: 'Arrows'},
  {value: 'fa-arrows-alt', label: 'Arrows alt'},
  {value: 'fa-arrows-h', label: 'Arrows h'},
  {value: 'fa-arrows-v', label: 'Arrows v'},
  {value: 'fa-asterisk', label: 'Asterisk'},
  {value: 'fa-at', label: 'At'},
  {value: 'fa-backward', label: 'Backward'},
  {value: 'fa-balance-scale', label: 'Balance scale'},
  {value: 'fa-ban', label: 'Ban'},
  {value: 'fa-bar-chart', label: 'Bar chart'},
  {value: 'fa-barcode', label: 'Barcode'},
  {value: 'fa-bars', label: 'Bars'},
  {value: 'fa-battery-empty', label: 'Battery empty'},
  {value: 'fa-battery-full', label: 'Battery full'},
  {value: 'fa-battery-half', label: 'Battery half'},
  {value: 'fa-battery-quarter', label: 'Battery quarter'},
  {value: 'fa-battery-three-quarters', label: 'Battery three quarters'},
  {value: 'fa-bed', label: 'Bed'},
  {value: 'fa-beer', label: 'Beer'},
  {value: 'fa-behance', label: 'Behance'},
  {value: 'fa-behance-square', label: 'Behance square'},
  {value: 'fa-bell', label: 'Bell'},
  {value: 'fa-bell-o', label: 'Bell o'},
  {value: 'fa-bell-slash', label: 'Bell slash'},
  {value: 'fa-bell-slash-o', label: 'Bell slash o'},
  {value: 'fa-bicycle', label: 'Bicycle'},
  {value: 'fa-binoculars', label: 'Binoculars'},
  {value: 'fa-birthday-cake', label: 'Birthday cake'},
  {value: 'fa-bitbucket', label: 'Bitbucket'},
  {value: 'fa-bitbucket-square', label: 'Bitbucket square'},
  {value: 'fa-black-tie', label: 'Black tie'},
  {value: 'fa-bold', label: 'Bold'},
  {value: 'fa-bolt', label: 'Bolt'},
  {value: 'fa-bomb', label: 'Bomb'},
  {value: 'fa-book', label: 'Book'},
  {value: 'fa-bookmark', label: 'Bookmark'},
  {value: 'fa-bookmark-o', label: 'Bookmark o'},
  {value: 'fa-briefcase', label: 'Briefcase'},
  {value: 'fa-btc', label: 'Btc'},
  {value: 'fa-bug', label: 'Bug'},
  {value: 'fa-building', label: 'Building'},
  {value: 'fa-building-o', label: 'Building o'},
  {value: 'fa-bullhorn', label: 'Bullhorn'},
  {value: 'fa-bullseye', label: 'Bullseye'},
  {value: 'fa-bus', label: 'Bus'},
  {value: 'fa-buysellads', label: 'Buysellads'},
  {value: 'fa-calculator', label: 'Calculator'},
  {value: 'fa-calendar', label: 'Calendar'},
  {value: 'fa-calendar-check-o', label: 'Calendar check o'},
  {value: 'fa-calendar-minus-o', label: 'Calendar minus o'},
  {value: 'fa-calendar-o', label: 'Calendar o'},
  {value: 'fa-calendar-plus-o', label: 'Calendar plus o'},
  {value: 'fa-calendar-times-o', label: 'Calendar times o'},
  {value: 'fa-camera', label: 'Camera'},
  {value: 'fa-camera-retro', label: 'Camera retro'},
  {value: 'fa-car', label: 'Car'},
  {value: 'fa-caret-down', label: 'Caret down'},
  {value: 'fa-caret-left', label: 'Caret left'},
  {value: 'fa-caret-right', label: 'Caret right'},
  {value: 'fa-caret-square-o-down', label: 'Caret square o down'},
  {value: 'fa-caret-square-o-left', label: 'Caret square o left'},
  {value: 'fa-caret-square-o-right', label: 'Caret square o right'},
  {value: 'fa-caret-square-o-up', label: 'Caret square o up'},
  {value: 'fa-caret-up', label: 'Caret up'},
  {value: 'fa-cart-arrow-down', label: 'Cart arrow down'},
  {value: 'fa-cart-plus', label: 'Cart plus'},
  {value: 'fa-cc', label: 'Cc'},
  {value: 'fa-cc-amex', label: 'Cc amex'},
  {value: 'fa-cc-diners-club', label: 'Cc diners club'},
  {value: 'fa-cc-discover', label: 'Cc discover'},
  {value: 'fa-cc-jcb', label: 'Cc jcb'},
  {value: 'fa-cc-mastercard', label: 'Cc mastercard'},
  {value: 'fa-cc-paypal', label: 'Cc paypal'},
  {value: 'fa-cc-stripe', label: 'Cc stripe'},
  {value: 'fa-cc-visa', label: 'Cc visa'},
  {value: 'fa-certificate', label: 'Certificate'},
  {value: 'fa-chain-broken', label: 'Chain broken'},
  {value: 'fa-check', label: 'Check'},
  {value: 'fa-check-circle', label: 'Check circle'},
  {value: 'fa-check-circle-o', label: 'Check circle o'},
  {value: 'fa-check-square', label: 'Check square'},
  {value: 'fa-check-square-o', label: 'Check square o'},
  {value: 'fa-chevron-circle-down', label: 'Chevron circle down'},
  {value: 'fa-chevron-circle-left', label: 'Chevron circle left'},
  {value: 'fa-chevron-circle-right', label: 'Chevron circle right'},
  {value: 'fa-chevron-circle-up', label: 'Chevron circle up'},
  {value: 'fa-chevron-down', label: 'Chevron down'},
  {value: 'fa-chevron-left', label: 'Chevron left'},
  {value: 'fa-chevron-right', label: 'Chevron right'},
  {value: 'fa-chevron-up', label: 'Chevron up'},
  {value: 'fa-child', label: 'Child'},
  {value: 'fa-chrome', label: 'Chrome'},
  {value: 'fa-circle', label: 'Circle'},
  {value: 'fa-circle-o', label: 'Circle o'},
  {value: 'fa-circle-o-notch', label: 'Circle o notch'},
  {value: 'fa-circle-thin', label: 'Circle thin'},
  {value: 'fa-clipboard', label: 'Clipboard'},
  {value: 'fa-clock-o', label: 'Clock o'},
  {value: 'fa-clone', label: 'Clone'},
  {value: 'fa-cloud', label: 'Cloud'},
  {value: 'fa-cloud-download', label: 'Cloud download'},
  {value: 'fa-cloud-upload', label: 'Cloud upload'},
  {value: 'fa-code', label: 'Code'},
  {value: 'fa-code-fork', label: 'Code fork'},
  {value: 'fa-codepen', label: 'Codepen'},
  {value: 'fa-coffee', label: 'Coffee'},
  {value: 'fa-cog', label: 'Cog'},
  {value: 'fa-cogs', label: 'Cogs'},
  {value: 'fa-columns', label: 'Columns'},
  {value: 'fa-comment', label: 'Comment'},
  {value: 'fa-comment-o', label: 'Comment o'},
  {value: 'fa-commenting', label: 'Commenting'},
  {value: 'fa-commenting-o', label: 'Commenting o'},
  {value: 'fa-comments', label: 'Comments'},
  {value: 'fa-comments-o', label: 'Comments o'},
  {value: 'fa-compass', label: 'Compass'},
  {value: 'fa-compress', label: 'Compress'},
  {value: 'fa-connectdevelop', label: 'Connectdevelop'},
  {value: 'fa-contao', label: 'Contao'},
  {value: 'fa-copyright', label: 'Copyright'},
  {value: 'fa-creative-commons', label: 'Creative commons'},
  {value: 'fa-credit-card', label: 'Credit card'},
  {value: 'fa-crop', label: 'Crop'},
  {value: 'fa-crosshairs', label: 'Crosshairs'},
  {value: 'fa-css3', label: 'Css3'},
  {value: 'fa-cube', label: 'Cube'},
  {value: 'fa-cubes', label: 'Cubes'},
  {value: 'fa-cutlery', label: 'Cutlery'},
  {value: 'fa-dashcube', label: 'Dashcube'},
  {value: 'fa-database', label: 'Database'},
  {value: 'fa-delicious', label: 'Delicious'},
  {value: 'fa-desktop', label: 'Desktop'},
  {value: 'fa-deviantart', label: 'Deviantart'},
  {value: 'fa-diamond', label: 'Diamond'},
  {value: 'fa-digg', label: 'Digg'},
  {value: 'fa-dot-circle-o', label: 'Dot circle o'},
  {value: 'fa-download', label: 'Download'},
  {value: 'fa-dribbble', label: 'Dribbble'},
  {value: 'fa-dropbox', label: 'Dropbox'},
  {value: 'fa-drupal', label: 'Drupal'},
  {value: 'fa-eject', label: 'Eject'},
  {value: 'fa-ellipsis-h', label: 'Ellipsis h'},
  {value: 'fa-ellipsis-v', label: 'Ellipsis v'},
  {value: 'fa-empire', label: 'Empire'},
  {value: 'fa-envelope', label: 'Envelope'},
  {value: 'fa-envelope-o', label: 'Envelope o'},
  {value: 'fa-envelope-square', label: 'Envelope square'},
  {value: 'fa-eraser', label: 'Eraser'},
  {value: 'fa-eur', label: 'Eur'},
  {value: 'fa-exchange', label: 'Exchange'},
  {value: 'fa-exclamation', label: 'Exclamation'},
  {value: 'fa-exclamation-circle', label: 'Exclamation circle'},
  {value: 'fa-exclamation-triangle', label: 'Exclamation triangle'},
  {value: 'fa-expand', label: 'Expand'},
  {value: 'fa-expeditedssl', label: 'Expeditedssl'},
  {value: 'fa-external-link', label: 'External link'},
  {value: 'fa-external-link-square', label: 'External link square'},
  {value: 'fa-eye', label: 'Eye'},
  {value: 'fa-eye-slash', label: 'Eye slash'},
  {value: 'fa-eyedropper', label: 'Eyedropper'},
  {value: 'fa-facebook', label: 'Facebook'},
  {value: 'fa-facebook-official', label: 'Facebook official'},
  {value: 'fa-facebook-square', label: 'Facebook square'},
  {value: 'fa-fast-backward', label: 'Fast backward'},
  {value: 'fa-fast-forward', label: 'Fast forward'},
  {value: 'fa-fax', label: 'Fax'},
  {value: 'fa-female', label: 'Female'},
  {value: 'fa-fighter-jet', label: 'Fighter jet'},
  {value: 'fa-file', label: 'File'},
  {value: 'fa-file-archive-o', label: 'File archive o'},
  {value: 'fa-file-audio-o', label: 'File audio o'},
  {value: 'fa-file-code-o', label: 'File code o'},
  {value: 'fa-file-excel-o', label: 'File excel o'},
  {value: 'fa-file-image-o', label: 'File image o'},
  {value: 'fa-file-o', label: 'File o'},
  {value: 'fa-file-pdf-o', label: 'File pdf o'},
  {value: 'fa-file-powerpoint-o', label: 'File powerpoint o'},
  {value: 'fa-file-text', label: 'File text'},
  {value: 'fa-file-text-o', label: 'File text o'},
  {value: 'fa-file-video-o', label: 'File video o'},
  {value: 'fa-file-word-o', label: 'File word o'},
  {value: 'fa-files-o', label: 'Files o'},
  {value: 'fa-film', label: 'Film'},
  {value: 'fa-filter', label: 'Filter'},
  {value: 'fa-fire', label: 'Fire'},
  {value: 'fa-fire-extinguisher', label: 'Fire extinguisher'},
  {value: 'fa-firefox', label: 'Firefox'},
  {value: 'fa-flag', label: 'Flag'},
  {value: 'fa-flag-checkered', label: 'Flag checkered'},
  {value: 'fa-flag-o', label: 'Flag o'},
  {value: 'fa-flask', label: 'Flask'},
  {value: 'fa-flickr', label: 'Flickr'},
  {value: 'fa-floppy-o', label: 'Floppy o'},
  {value: 'fa-folder', label: 'Folder'},
  {value: 'fa-folder-o', label: 'Folder o'},
  {value: 'fa-folder-open', label: 'Folder open'},
  {value: 'fa-folder-open-o', label: 'Folder open o'},
  {value: 'fa-font', label: 'Font'},
  {value: 'fa-fonticons', label: 'Fonticons'},
  {value: 'fa-forumbee', label: 'Forumbee'},
  {value: 'fa-forward', label: 'Forward'},
  {value: 'fa-foursquare', label: 'Foursquare'},
  {value: 'fa-frown-o', label: 'Frown o'},
  {value: 'fa-futbol-o', label: 'Futbol o'},
  {value: 'fa-gamepad', label: 'Gamepad'},
  {value: 'fa-gavel', label: 'Gavel'},
  {value: 'fa-gbp', label: 'Gbp'},
  {value: 'fa-genderless', label: 'Genderless'},
  {value: 'fa-get-pocket', label: 'Get pocket'},
  {value: 'fa-gg', label: 'Gg'},
  {value: 'fa-gg-circle', label: 'Gg circle'},
  {value: 'fa-gift', label: 'Gift'},
  {value: 'fa-git', label: 'Git'},
  {value: 'fa-git-square', label: 'Git square'},
  {value: 'fa-github', label: 'Github'},
  {value: 'fa-github-alt', label: 'Github alt'},
  {value: 'fa-github-square', label: 'Github square'},
  {value: 'fa-glass', label: 'Glass'},
  {value: 'fa-globe', label: 'Globe'},
  {value: 'fa-google', label: 'Google'},
  {value: 'fa-google-plus', label: 'Google plus'},
  {value: 'fa-google-plus-square', label: 'Google plus square'},
  {value: 'fa-google-wallet', label: 'Google wallet'},
  {value: 'fa-graduation-cap', label: 'Graduation cap'},
  {value: 'fa-gratipay', label: 'Gratipay'},
  {value: 'fa-h-square', label: 'H square'},
  {value: 'fa-hacker-news', label: 'Hacker news'},
  {value: 'fa-hand-lizard-o', label: 'Hand lizard o'},
  {value: 'fa-hand-o-down', label: 'Hand o down'},
  {value: 'fa-hand-o-left', label: 'Hand o left'},
  {value: 'fa-hand-o-right', label: 'Hand o right'},
  {value: 'fa-hand-o-up', label: 'Hand o up'},
  {value: 'fa-hand-paper-o', label: 'Hand paper o'},
  {value: 'fa-hand-peace-o', label: 'Hand peace o'},
  {value: 'fa-hand-pointer-o', label: 'Hand pointer o'},
  {value: 'fa-hand-rock-o', label: 'Hand rock o'},
  {value: 'fa-hand-scissors-o', label: 'Hand scissors o'},
  {value: 'fa-hand-spock-o', label: 'Hand spock o'},
  {value: 'fa-hdd-o', label: 'Hdd o'},
  {value: 'fa-header', label: 'Header'},
  {value: 'fa-headphones', label: 'Headphones'},
  {value: 'fa-heart', label: 'Heart'},
  {value: 'fa-heart-o', label: 'Heart o'},
  {value: 'fa-heartbeat', label: 'Heartbeat'},
  {value: 'fa-history', label: 'History'},
  {value: 'fa-home', label: 'Home'},
  {value: 'fa-hospital-o', label: 'Hospital o'},
  {value: 'fa-hourglass', label: 'Hourglass'},
  {value: 'fa-hourglass-end', label: 'Hourglass end'},
  {value: 'fa-hourglass-half', label: 'Hourglass half'},
  {value: 'fa-hourglass-o', label: 'Hourglass o'},
  {value: 'fa-hourglass-start', label: 'Hourglass start'},
  {value: 'fa-houzz', label: 'Houzz'},
  {value: 'fa-html5', label: 'Html5'},
  {value: 'fa-i-cursor', label: 'I cursor'},
  {value: 'fa-ils', label: 'Ils'},
  {value: 'fa-inbox', label: 'Inbox'},
  {value: 'fa-indent', label: 'Indent'},
  {value: 'fa-industry', label: 'Industry'},
  {value: 'fa-info', label: 'Info'},
  {value: 'fa-info-circle', label: 'Info circle'},
  {value: 'fa-inr', label: 'Inr'},
  {value: 'fa-instagram', label: 'Instagram'},
  {value: 'fa-internet-explorer', label: 'Internet explorer'},
  {value: 'fa-ioxhost', label: 'Ioxhost'},
  {value: 'fa-italic', label: 'Italic'},
  {value: 'fa-joomla', label: 'Joomla'},
  {value: 'fa-jpy', label: 'Jpy'},
  {value: 'fa-jsfiddle', label: 'Jsfiddle'},
  {value: 'fa-key', label: 'Key'},
  {value: 'fa-keyboard-o', label: 'Keyboard o'},
  {value: 'fa-krw', label: 'Krw'},
  {value: 'fa-language', label: 'Language'},
  {value: 'fa-laptop', label: 'Laptop'},
  {value: 'fa-lastfm', label: 'Lastfm'},
  {value: 'fa-lastfm-square', label: 'Lastfm square'},
  {value: 'fa-leaf', label: 'Leaf'},
  {value: 'fa-leanpub', label: 'Leanpub'},
  {value: 'fa-lemon-o', label: 'Lemon o'},
  {value: 'fa-level-down', label: 'Level down'},
  {value: 'fa-level-up', label: 'Level up'},
  {value: 'fa-life-ring', label: 'Life ring'},
  {value: 'fa-lightbulb-o', label: 'Lightbulb o'},
  {value: 'fa-line-chart', label: 'Line chart'},
  {value: 'fa-link', label: 'Link'},
  {value: 'fa-linkedin', label: 'Linkedin'},
  {value: 'fa-linkedin-square', label: 'Linkedin square'},
  {value: 'fa-linux', label: 'Linux'},
  {value: 'fa-list', label: 'List'},
  {value: 'fa-list-alt', label: 'List alt'},
  {value: 'fa-list-ol', label: 'List ol'},
  {value: 'fa-list-ul', label: 'List ul'},
  {value: 'fa-location-arrow', label: 'Location arrow'},
  {value: 'fa-lock', label: 'Lock'},
  {value: 'fa-long-arrow-down', label: 'Long arrow down'},
  {value: 'fa-long-arrow-left', label: 'Long arrow left'},
  {value: 'fa-long-arrow-right', label: 'Long arrow right'},
  {value: 'fa-long-arrow-up', label: 'Long arrow up'},
  {value: 'fa-magic', label: 'Magic'},
  {value: 'fa-magnet', label: 'Magnet'},
  {value: 'fa-male', label: 'Male'},
  {value: 'fa-map', label: 'Map'},
  {value: 'fa-map-marker', label: 'Map marker'},
  {value: 'fa-map-o', label: 'Map o'},
  {value: 'fa-map-pin', label: 'Map pin'},
  {value: 'fa-map-signs', label: 'Map signs'},
  {value: 'fa-mars', label: 'Mars'},
  {value: 'fa-mars-double', label: 'Mars double'},
  {value: 'fa-mars-stroke', label: 'Mars stroke'},
  {value: 'fa-mars-stroke-h', label: 'Mars stroke h'},
  {value: 'fa-mars-stroke-v', label: 'Mars stroke v'},
  {value: 'fa-maxcdn', label: 'Maxcdn'},
  {value: 'fa-meanpath', label: 'Meanpath'},
  {value: 'fa-medium', label: 'Medium'},
  {value: 'fa-medkit', label: 'Medkit'},
  {value: 'fa-meh-o', label: 'Meh o'},
  {value: 'fa-mercury', label: 'Mercury'},
  {value: 'fa-microphone', label: 'Microphone'},
  {value: 'fa-microphone-slash', label: 'Microphone slash'},
  {value: 'fa-minus', label: 'Minus'},
  {value: 'fa-minus-circle', label: 'Minus circle'},
  {value: 'fa-minus-square', label: 'Minus square'},
  {value: 'fa-minus-square-o', label: 'Minus square o'},
  {value: 'fa-mobile', label: 'Mobile'},
  {value: 'fa-money', label: 'Money'},
  {value: 'fa-moon-o', label: 'Moon o'},
  {value: 'fa-motorcycle', label: 'Motorcycle'},
  {value: 'fa-mouse-pointer', label: 'Mouse pointer'},
  {value: 'fa-music', label: 'Music'},
  {value: 'fa-neuter', label: 'Neuter'},
  {value: 'fa-newspaper-o', label: 'Newspaper o'},
  {value: 'fa-object-group', label: 'Object group'},
  {value: 'fa-object-ungroup', label: 'Object ungroup'},
  {value: 'fa-odnoklassniki', label: 'Odnoklassniki'},
  {value: 'fa-odnoklassniki-square', label: 'Odnoklassniki square'},
  {value: 'fa-opencart', label: 'Opencart'},
  {value: 'fa-openid', label: 'Openid'},
  {value: 'fa-opera', label: 'Opera'},
  {value: 'fa-optin-monster', label: 'Optin monster'},
  {value: 'fa-outdent', label: 'Outdent'},
  {value: 'fa-pagelines', label: 'Pagelines'},
  {value: 'fa-paint-brush', label: 'Paint brush'},
  {value: 'fa-paper-plane', label: 'Paper plane'},
  {value: 'fa-paper-plane-o', label: 'Paper plane o'},
  {value: 'fa-paperclip', label: 'Paperclip'},
  {value: 'fa-paragraph', label: 'Paragraph'},
  {value: 'fa-pause', label: 'Pause'},
  {value: 'fa-paw', label: 'Paw'},
  {value: 'fa-paypal', label: 'Paypal'},
  {value: 'fa-pencil', label: 'Pencil'},
  {value: 'fa-pencil-square', label: 'Pencil square'},
  {value: 'fa-pencil-square-o', label: 'Pencil square o'},
  {value: 'fa-phone', label: 'Phone'},
  {value: 'fa-phone-square', label: 'Phone square'},
  {value: 'fa-picture-o', label: 'Picture o'},
  {value: 'fa-pie-chart', label: 'Pie chart'},
  {value: 'fa-pied-piper', label: 'Pied piper'},
  {value: 'fa-pied-piper-alt', label: 'Pied piper alt'},
  {value: 'fa-pinterest', label: 'Pinterest'},
  {value: 'fa-pinterest-p', label: 'Pinterest p'},
  {value: 'fa-pinterest-square', label: 'Pinterest square'},
  {value: 'fa-plane', label: 'Plane'},
  {value: 'fa-play', label: 'Play'},
  {value: 'fa-play-circle', label: 'Play circle'},
  {value: 'fa-play-circle-o', label: 'Play circle o'},
  {value: 'fa-plug', label: 'Plug'},
  {value: 'fa-plus', label: 'Plus'},
  {value: 'fa-plus-circle', label: 'Plus circle'},
  {value: 'fa-plus-square', label: 'Plus square'},
  {value: 'fa-plus-square-o', label: 'Plus square o'},
  {value: 'fa-power-off', label: 'Power off'},
  {value: 'fa-print', label: 'Print'},
  {value: 'fa-puzzle-piece', label: 'Puzzle piece'},
  {value: 'fa-qq', label: 'Qq'},
  {value: 'fa-qrcode', label: 'Qrcode'},
  {value: 'fa-question', label: 'Question'},
  {value: 'fa-question-circle', label: 'Question circle'},
  {value: 'fa-quote-left', label: 'Quote left'},
  {value: 'fa-quote-right', label: 'Quote right'},
  {value: 'fa-random', label: 'Random'},
  {value: 'fa-rebel', label: 'Rebel'},
  {value: 'fa-recycle', label: 'Recycle'},
  {value: 'fa-reddit', label: 'Reddit'},
  {value: 'fa-reddit-square', label: 'Reddit square'},
  {value: 'fa-refresh', label: 'Refresh'},
  {value: 'fa-registered', label: 'Registered'},
  {value: 'fa-renren', label: 'Renren'},
  {value: 'fa-repeat', label: 'Repeat'},
  {value: 'fa-reply', label: 'Reply'},
  {value: 'fa-reply-all', label: 'Reply all'},
  {value: 'fa-retweet', label: 'Retweet'},
  {value: 'fa-road', label: 'Road'},
  {value: 'fa-rocket', label: 'Rocket'},
  {value: 'fa-rss', label: 'Rss'},
  {value: 'fa-rss-square', label: 'Rss square'},
  {value: 'fa-rub', label: 'Rub'},
  {value: 'fa-safari', label: 'Safari'},
  {value: 'fa-scissors', label: 'Scissors'},
  {value: 'fa-search', label: 'Search'},
  {value: 'fa-search-minus', label: 'Search minus'},
  {value: 'fa-search-plus', label: 'Search plus'},
  {value: 'fa-sellsy', label: 'Sellsy'},
  {value: 'fa-server', label: 'Server'},
  {value: 'fa-share', label: 'Share'},
  {value: 'fa-share-alt', label: 'Share alt'},
  {value: 'fa-share-alt-square', label: 'Share alt square'},
  {value: 'fa-share-square', label: 'Share square'},
  {value: 'fa-share-square-o', label: 'Share square o'},
  {value: 'fa-shield', label: 'Shield'},
  {value: 'fa-ship', label: 'Ship'},
  {value: 'fa-shirtsinbulk', label: 'Shirtsinbulk'},
  {value: 'fa-shopping-cart', label: 'Shopping cart'},
  {value: 'fa-sign-in', label: 'Sign in'},
  {value: 'fa-sign-out', label: 'Sign out'},
  {value: 'fa-signal', label: 'Signal'},
  {value: 'fa-simplybuilt', label: 'Simplybuilt'},
  {value: 'fa-sitemap', label: 'Sitemap'},
  {value: 'fa-skyatlas', label: 'Skyatlas'},
  {value: 'fa-skype', label: 'Skype'},
  {value: 'fa-slack', label: 'Slack'},
  {value: 'fa-sliders', label: 'Sliders'},
  {value: 'fa-slideshare', label: 'Slideshare'},
  {value: 'fa-smile-o', label: 'Smile o'},
  {value: 'fa-sort', label: 'Sort'},
  {value: 'fa-sort-alpha-asc', label: 'Sort alpha asc'},
  {value: 'fa-sort-alpha-desc', label: 'Sort alpha desc'},
  {value: 'fa-sort-amount-asc', label: 'Sort amount asc'},
  {value: 'fa-sort-amount-desc', label: 'Sort amount desc'},
  {value: 'fa-sort-asc', label: 'Sort asc'},
  {value: 'fa-sort-desc', label: 'Sort desc'},
  {value: 'fa-sort-numeric-asc', label: 'Sort numeric asc'},
  {value: 'fa-sort-numeric-desc', label: 'Sort numeric desc'},
  {value: 'fa-soundcloud', label: 'Soundcloud'},
  {value: 'fa-space-shuttle', label: 'Space shuttle'},
  {value: 'fa-spinner', label: 'Spinner'},
  {value: 'fa-spoon', label: 'Spoon'},
  {value: 'fa-spotify', label: 'Spotify'},
  {value: 'fa-square', label: 'Square'},
  {value: 'fa-square-o', label: 'Square o'},
  {value: 'fa-stack-exchange', label: 'Stack exchange'},
  {value: 'fa-stack-overflow', label: 'Stack overflow'},
  {value: 'fa-star', label: 'Star'},
  {value: 'fa-star-half', label: 'Star half'},
  {value: 'fa-star-half-o', label: 'Star half o'},
  {value: 'fa-star-o', label: 'Star o'},
  {value: 'fa-steam', label: 'Steam'},
  {value: 'fa-steam-square', label: 'Steam square'},
  {value: 'fa-step-backward', label: 'Step backward'},
  {value: 'fa-step-forward', label: 'Step forward'},
  {value: 'fa-stethoscope', label: 'Stethoscope'},
  {value: 'fa-sticky-note', label: 'Sticky note'},
  {value: 'fa-sticky-note-o', label: 'Sticky note o'},
  {value: 'fa-stop', label: 'Stop'},
  {value: 'fa-street-view', label: 'Street view'},
  {value: 'fa-strikethrough', label: 'Strikethrough'},
  {value: 'fa-stumbleupon', label: 'Stumbleupon'},
  {value: 'fa-stumbleupon-circle', label: 'Stumbleupon circle'},
  {value: 'fa-subscript', label: 'Subscript'},
  {value: 'fa-subway', label: 'Subway'},
  {value: 'fa-suitcase', label: 'Suitcase'},
  {value: 'fa-sun-o', label: 'Sun o'},
  {value: 'fa-superscript', label: 'Superscript'},
  {value: 'fa-table', label: 'Table'},
  {value: 'fa-tablet', label: 'Tablet'},
  {value: 'fa-tachometer', label: 'Tachometer'},
  {value: 'fa-tag', label: 'Tag'},
  {value: 'fa-tags', label: 'Tags'},
  {value: 'fa-tasks', label: 'Tasks'},
  {value: 'fa-taxi', label: 'Taxi'},
  {value: 'fa-television', label: 'Television'},
  {value: 'fa-tencent-weibo', label: 'Tencent weibo'},
  {value: 'fa-terminal', label: 'Terminal'},
  {value: 'fa-text-height', label: 'Text height'},
  {value: 'fa-text-width', label: 'Text width'},
  {value: 'fa-th', label: 'Th'},
  {value: 'fa-th-large', label: 'Th large'},
  {value: 'fa-th-list', label: 'Th list'},
  {value: 'fa-thumb-tack', label: 'Thumb tack'},
  {value: 'fa-thumbs-down', label: 'Thumbs down'},
  {value: 'fa-thumbs-o-down', label: 'Thumbs o down'},
  {value: 'fa-thumbs-o-up', label: 'Thumbs o up'},
  {value: 'fa-thumbs-up', label: 'Thumbs up'},
  {value: 'fa-ticket', label: 'Ticket'},
  {value: 'fa-times', label: 'Times'},
  {value: 'fa-times-circle', label: 'Times circle'},
  {value: 'fa-times-circle-o', label: 'Times circle o'},
  {value: 'fa-tint', label: 'Tint'},
  {value: 'fa-toggle-off', label: 'Toggle off'},
  {value: 'fa-toggle-on', label: 'Toggle on'},
  {value: 'fa-trademark', label: 'Trademark'},
  {value: 'fa-train', label: 'Train'},
  {value: 'fa-transgender', label: 'Transgender'},
  {value: 'fa-transgender-alt', label: 'Transgender alt'},
  {value: 'fa-trash', label: 'Trash'},
  {value: 'fa-trash-o', label: 'Trash o'},
  {value: 'fa-tree', label: 'Tree'},
  {value: 'fa-trello', label: 'Trello'},
  {value: 'fa-tripadvisor', label: 'Tripadvisor'},
  {value: 'fa-trophy', label: 'Trophy'},
  {value: 'fa-truck', label: 'Truck'},
  {value: 'fa-try', label: 'Try'},
  {value: 'fa-tty', label: 'Tty'},
  {value: 'fa-tumblr', label: 'Tumblr'},
  {value: 'fa-tumblr-square', label: 'Tumblr square'},
  {value: 'fa-twitch', label: 'Twitch'},
  {value: 'fa-twitter', label: 'Twitter'},
  {value: 'fa-twitter-square', label: 'Twitter square'},
  {value: 'fa-umbrella', label: 'Umbrella'},
  {value: 'fa-underline', label: 'Underline'},
  {value: 'fa-undo', label: 'Undo'},
  {value: 'fa-university', label: 'University'},
  {value: 'fa-unlock', label: 'Unlock'},
  {value: 'fa-unlock-alt', label: 'Unlock alt'},
  {value: 'fa-upload', label: 'Upload'},
  {value: 'fa-usd', label: 'Usd'},
  {value: 'fa-user', label: 'User'},
  {value: 'fa-user-md', label: 'User md'},
  {value: 'fa-user-plus', label: 'User plus'},
  {value: 'fa-user-secret', label: 'User secret'},
  {value: 'fa-user-times', label: 'User times'},
  {value: 'fa-users', label: 'Users'},
  {value: 'fa-venus', label: 'Venus'},
  {value: 'fa-venus-double', label: 'Venus double'},
  {value: 'fa-venus-mars', label: 'Venus mars'},
  {value: 'fa-viacoin', label: 'Viacoin'},
  {value: 'fa-video-camera', label: 'Video camera'},
  {value: 'fa-vimeo', label: 'Vimeo'},
  {value: 'fa-vimeo-square', label: 'Vimeo square'},
  {value: 'fa-vine', label: 'Vine'},
  {value: 'fa-vk', label: 'Vk'},
  {value: 'fa-volume-down', label: 'Volume down'},
  {value: 'fa-volume-off', label: 'Volume off'},
  {value: 'fa-volume-up', label: 'Volume up'},
  {value: 'fa-weibo', label: 'Weibo'},
  {value: 'fa-weixin', label: 'Weixin'},
  {value: 'fa-whatsapp', label: 'Whatsapp'},
  {value: 'fa-wheelchair', label: 'Wheelchair'},
  {value: 'fa-wifi', label: 'Wifi'},
  {value: 'fa-wikipedia-w', label: 'Wikipedia w'},
  {value: 'fa-windows', label: 'Windows'},
  {value: 'fa-wordpress', label: 'Wordpress'},
  {value: 'fa-wrench', label: 'Wrench'},
  {value: 'fa-xing', label: 'Xing'},
  {value: 'fa-xing-square', label: 'Xing square'},
  {value: 'fa-y-combinator', label: 'Y combinator'},
  {value: 'fa-yahoo', label: 'Yahoo'},
  {value: 'fa-yelp', label: 'Yelp'},
  {value: 'fa-youtube', label: 'Youtube'},
  {value: 'fa-youtube-play', label: 'Youtube play'},
  {value: 'fa-youtube-square', label: 'Youtube square'}
];

export const remixIconsList: Icon[] = [
  {value: 'ri-arrow-left-up-line', label: 'arrow-left-up'},

  {value: 'ri-arrow-left-up-line', label: 'arrow-left-up'},

  {value: 'ri-arrow-up-line', label: 'arrow-up'},

  {value: 'ri-arrow-up-line', label: 'arrow-up'},

  {value: 'ri-arrow-right-up-line', label: 'arrow-right-up'},

  {value: 'ri-arrow-right-up-line', label: 'arrow-right-up'},

  {value: 'ri-arrow-right-line', label: 'arrow-right'},

  {value: 'ri-arrow-right-line', label: 'arrow-right'},

  {value: 'ri-arrow-right-down-line', label: 'arrow-right-down'},

  {value: 'ri-arrow-right-down-line', label: 'arrow-right-down'},

  {value: 'ri-arrow-down-line', label: 'arrow-down'},

  {value: 'ri-arrow-down-line', label: 'arrow-down'},

  {value: 'ri-arrow-left-down-line', label: 'arrow-left-down'},

  {value: 'ri-arrow-left-down-line', label: 'arrow-left-down'},

  {value: 'ri-arrow-left-line', label: 'arrow-left'},

  {value: 'ri-arrow-left-line', label: 'arrow-left'},

  {value: 'ri-corner-right-up-line', label: 'corner-right-up'},

  {value: 'ri-corner-right-up-line', label: 'corner-right-up'},

  {value: 'ri-corner-up-left-line', label: 'corner-up-left'},

  {value: 'ri-corner-up-left-line', label: 'corner-up-left'},

  {value: 'ri-corner-up-right-line', label: 'corner-up-right'},

  {value: 'ri-corner-up-right-line', label: 'corner-up-right'},

  {value: 'ri-corner-right-down-line', label: 'corner-right-down'},

  {value: 'ri-corner-right-down-line', label: 'corner-right-down'},

  {value: 'ri-corner-down-left-line', label: 'corner-down-left'},

  {value: 'ri-corner-down-left-line', label: 'corner-down-left'},

  {value: 'ri-corner-left-up-line', label: 'corner-left-up'},

  {value: 'ri-corner-left-up-line', label: 'corner-left-up'},

  {value: 'ri-corner-left-down-line', label: 'corner-left-down'},

  {value: 'ri-corner-left-down-line', label: 'corner-left-down'},

  {value: 'ri-corner-down-right-line', label: 'corner-down-right'},

  {value: 'ri-corner-down-right-line', label: 'corner-down-right'},

  {value: 'ri-arrow-up-circle-line', label: 'arrow-up-circle'},

  {value: 'ri-arrow-up-circle-line', label: 'arrow-up-circle'},

  {value: 'ri-arrow-right-circle-line', label: 'arrow-right-circle'},

  {value: 'ri-arrow-right-circle-line', label: 'arrow-right-circle'},

  {value: 'ri-arrow-down-circle-line', label: 'arrow-down-circle'},

  {value: 'ri-arrow-down-circle-line', label: 'arrow-down-circle'},

  {value: 'ri-arrow-left-circle-line', label: 'arrow-left-circle'},

  {value: 'ri-arrow-left-circle-line', label: 'arrow-left-circle'},

  {value: 'ri-arrow-up-s-line', label: 'arrow-up-s'},

  {value: 'ri-arrow-up-s-line', label: 'arrow-up-s'},

  {value: 'ri-arrow-right-s-line', label: 'arrow-right-s'},

  {value: 'ri-arrow-right-s-line', label: 'arrow-right-s'},

  {value: 'ri-arrow-down-s-line', label: 'arrow-down-s'},

  {value: 'ri-arrow-down-s-line', label: 'arrow-down-s'},

  {value: 'ri-arrow-left-s-line', label: 'arrow-left-s'},

  {value: 'ri-arrow-left-s-line', label: 'arrow-left-s'},

  {value: 'ri-skip-up-line', label: 'skip-up'},

  {value: 'ri-skip-up-line', label: 'skip-up'},

  {value: 'ri-skip-right-line', label: 'skip-right'},

  {value: 'ri-skip-right-line', label: 'skip-right'},

  {value: 'ri-skip-down-line', label: 'skip-down'},

  {value: 'ri-skip-down-line', label: 'skip-down'},

  {value: 'ri-skip-left-line', label: 'skip-left'},

  {value: 'ri-skip-left-line', label: 'skip-left'},

  {value: 'ri-arrow-left-right-line', label: 'arrow-left-right'},

  {value: 'ri-arrow-left-right-line', label: 'arrow-left-right'},

  {value: 'ri-arrow-up-down-line', label: 'arrow-up-down'},

  {value: 'ri-arrow-up-down-line', label: 'arrow-up-down'},

  {value: 'ri-arrow-drop-up-line', label: 'arrow-drop-up'},

  {value: 'ri-arrow-drop-up-line', label: 'arrow-drop-up'},

  {value: 'ri-arrow-drop-right-line', label: 'arrow-drop-right'},

  {value: 'ri-arrow-drop-right-line', label: 'arrow-drop-right'},

  {value: 'ri-arrow-drop-down-line', label: 'arrow-drop-down'},

  {value: 'ri-arrow-drop-down-line', label: 'arrow-drop-down'},

  {value: 'ri-arrow-drop-left-line', label: 'arrow-drop-left'},

  {value: 'ri-arrow-drop-left-line', label: 'arrow-drop-left'},

  {value: 'ri-arrow-up-double-line', label: 'arrow-up-double'},

  {value: 'ri-arrow-up-double-line', label: 'arrow-up-double'},

  {value: 'ri-arrow-right-double-line', label: 'arrow-right-double'},

  {value: 'ri-arrow-right-double-line', label: 'arrow-right-double'},

  {value: 'ri-arrow-down-double-line', label: 'arrow-down-double'},

  {value: 'ri-arrow-down-double-line', label: 'arrow-down-double'},

  {value: 'ri-arrow-left-double-line', label: 'arrow-left-double'},

  {value: 'ri-arrow-left-double-line', label: 'arrow-left-double'},

  {value: 'ri-corner-up-left-double-line', label: 'corner-up-left-double'},

  {value: 'ri-corner-up-left-double-line', label: 'corner-up-left-double'},

  {value: 'ri-corner-up-right-double-line', label: 'corner-up-right-double'},

  {value: 'ri-corner-up-right-double-line', label: 'corner-up-right-double'},

  {value: 'ri-expand-left-line', label: 'expand-left'},

  {value: 'ri-expand-left-line', label: 'expand-left'},

  {value: 'ri-expand-right-line', label: 'expand-right'},

  {value: 'ri-expand-right-line', label: 'expand-right'},

  {value: 'ri-contract-left-line', label: 'contract-left'},

  {value: 'ri-contract-left-line', label: 'contract-left'},

  {value: 'ri-contract-right-line', label: 'contract-right'},

  {value: 'ri-contract-right-line', label: 'contract-right'},

  {value: 'ri-expand-up-down-line', label: 'expand-up-down'},

  {value: 'ri-expand-up-down-line', label: 'expand-up-down'},

  {value: 'ri-expand-left-right-line', label: 'expand-left-right'},

  {value: 'ri-expand-left-right-line', label: 'expand-left-right'},

  {value: 'ri-contract-up-down-line', label: 'contract-up-down'},

  {value: 'ri-contract-up-down-line', label: 'contract-up-down'},

  {value: 'ri-contract-left-right-line', label: 'contract-left-right'},

  {value: 'ri-contract-left-right-line', label: 'contract-left-right'},

  {value: 'ri-drag-move-2-line', label: 'drag-move-2'},

  {value: 'ri-drag-move-2-line', label: 'drag-move-2'},

  {value: 'ri-drag-move-line', label: 'drag-move'},

  {value: 'ri-drag-move-line', label: 'drag-move'},

  {value: 'ri-arrow-go-back-line', label: 'arrow-go-back'},

  {value: 'ri-arrow-go-back-line', label: 'arrow-go-back'},

  {value: 'ri-arrow-go-forward-line', label: 'arrow-go-forward'},

  {value: 'ri-arrow-go-forward-line', label: 'arrow-go-forward'},

  {value: 'ri-arrow-turn-forward-line', label: 'arrow-turn-forward'},

  {value: 'ri-arrow-turn-forward-line', label: 'arrow-turn-forward'},

  {value: 'ri-arrow-turn-back-line', label: 'arrow-turn-back'},

  {value: 'ri-arrow-turn-back-line', label: 'arrow-turn-back'},

  {value: 'ri-Arrows108-line', label: 'Arrows108'},

  {value: 'ri-home-line', label: 'home'},

  {value: 'ri-home-line', label: 'home'},

  {value: 'ri-home-2-line', label: 'home-2'},

  {value: 'ri-home-2-line', label: 'home-2'},

  {value: 'ri-home-3-line', label: 'home-3'},

  {value: 'ri-home-3-line', label: 'home-3'},

  {value: 'ri-home-4-line', label: 'home-4'},

  {value: 'ri-home-4-line', label: 'home-4'},

  {value: 'ri-home-5-line', label: 'home-5'},

  {value: 'ri-home-5-line', label: 'home-5'},

  {value: 'ri-home-6-line', label: 'home-6'},

  {value: 'ri-home-6-line', label: 'home-6'},

  {value: 'ri-home-7-line', label: 'home-7'},

  {value: 'ri-home-7-line', label: 'home-7'},

  {value: 'ri-home-8-line', label: 'home-8'},

  {value: 'ri-home-8-line', label: 'home-8'},

  {value: 'ri-home-gear-line', label: 'home-gear'},

  {value: 'ri-home-gear-line', label: 'home-gear'},

  {value: 'ri-home-wifi-line', label: 'home-wifi'},

  {value: 'ri-home-wifi-line', label: 'home-wifi'},

  {value: 'ri-home-smile-line', label: 'home-smile'},

  {value: 'ri-home-smile-line', label: 'home-smile'},

  {value: 'ri-home-smile-2-line', label: 'home-smile-2'},

  {value: 'ri-home-smile-2-line', label: 'home-smile-2'},

  {value: 'ri-home-heart-line', label: 'home-heart'},

  {value: 'ri-home-heart-line', label: 'home-heart'},

  {value: 'ri-home-office-line', label: 'home-office'},

  {value: 'ri-home-office-line', label: 'home-office'},

  {value: 'ri-building-line', label: 'building'},

  {value: 'ri-building-line', label: 'building'},

  {value: 'ri-building-2-line', label: 'building-2'},

  {value: 'ri-building-2-line', label: 'building-2'},

  {value: 'ri-building-3-line', label: 'building-3'},

  {value: 'ri-building-3-line', label: 'building-3'},

  {value: 'ri-building-4-line', label: 'building-4'},

  {value: 'ri-building-4-line', label: 'building-4'},

  {value: 'ri-hotel-line', label: 'hotel'},

  {value: 'ri-hotel-line', label: 'hotel'},

  {value: 'ri-community-line', label: 'community'},

  {value: 'ri-community-line', label: 'community'},

  {value: 'ri-school-line', label: 'school'},

  {value: 'ri-school-line', label: 'school'},

  {value: 'ri-government-line', label: 'government'},

  {value: 'ri-government-line', label: 'government'},

  {value: 'ri-bank-line', label: 'bank'},

  {value: 'ri-bank-line', label: 'bank'},

  {value: 'ri-store-line', label: 'store'},

  {value: 'ri-store-line', label: 'store'},

  {value: 'ri-store-2-line', label: 'store-2'},

  {value: 'ri-store-2-line', label: 'store-2'},

  {value: 'ri-store-3-line', label: 'store-3'},

  {value: 'ri-store-3-line', label: 'store-3'},

  {value: 'ri-hospital-line', label: 'hospital'},

  {value: 'ri-hospital-line', label: 'hospital'},

  {value: 'ri-ancient-gate-line', label: 'ancient-gate'},

  {value: 'ri-ancient-gate-line', label: 'ancient-gate'},

  {value: 'ri-ancient-pavilion-line', label: 'ancient-pavilion'},

  {value: 'ri-ancient-pavilion-line', label: 'ancient-pavilion'},

  {value: 'ri-tent-line', label: 'tent'},

  {value: 'ri-tent-line', label: 'tent'},

  {value: 'ri-Buildings60-line', label: 'Buildings60'},

  {value: 'ri-mail-line', label: 'mail'},

  {value: 'ri-mail-line', label: 'mail'},

  {value: 'ri-mail-open-line', label: 'mail-open'},

  {value: 'ri-mail-open-line', label: 'mail-open'},

  {value: 'ri-mail-send-line', label: 'mail-send'},

  {value: 'ri-mail-send-line', label: 'mail-send'},

  {value: 'ri-mail-unread-line', label: 'mail-unread'},

  {value: 'ri-mail-unread-line', label: 'mail-unread'},

  {value: 'ri-mail-add-line', label: 'mail-add'},

  {value: 'ri-mail-add-line', label: 'mail-add'},

  {value: 'ri-mail-check-line', label: 'mail-check'},

  {value: 'ri-mail-check-line', label: 'mail-check'},

  {value: 'ri-mail-close-line', label: 'mail-close'},

  {value: 'ri-mail-close-line', label: 'mail-close'},

  {value: 'ri-mail-download-line', label: 'mail-download'},

  {value: 'ri-mail-download-line', label: 'mail-download'},

  {value: 'ri-mail-forbid-line', label: 'mail-forbid'},

  {value: 'ri-mail-forbid-line', label: 'mail-forbid'},

  {value: 'ri-mail-lock-line', label: 'mail-lock'},

  {value: 'ri-mail-lock-line', label: 'mail-lock'},

  {value: 'ri-mail-settings-line', label: 'mail-settings'},

  {value: 'ri-mail-settings-line', label: 'mail-settings'},

  {value: 'ri-mail-star-line', label: 'mail-star'},

  {value: 'ri-mail-star-line', label: 'mail-star'},

  {value: 'ri-mail-volume-line', label: 'mail-volume'},

  {value: 'ri-mail-volume-line', label: 'mail-volume'},

  {value: 'ri-inbox-line', label: 'inbox'},

  {value: 'ri-inbox-line', label: 'inbox'},

  {value: 'ri-inbox-2-line', label: 'inbox-2'},

  {value: 'ri-inbox-2-line', label: 'inbox-2'},

  {value: 'ri-inbox-archive-line', label: 'inbox-archive'},

  {value: 'ri-inbox-archive-line', label: 'inbox-archive'},

  {value: 'ri-inbox-unarchive-line', label: 'inbox-unarchive'},

  {value: 'ri-inbox-unarchive-line', label: 'inbox-unarchive'},

  {value: 'ri-cloud-line', label: 'cloud'},

  {value: 'ri-cloud-line', label: 'cloud'},

  {value: 'ri-cloud-off-line', label: 'cloud-off'},

  {value: 'ri-cloud-off-line', label: 'cloud-off'},

  {value: 'ri-attachment-line', label: 'attachment'},

  {value: 'ri-attachment-line', label: 'attachment'},

  {value: 'ri-profile-line', label: 'profile'},

  {value: 'ri-profile-line', label: 'profile'},

  {value: 'ri-archive-line', label: 'archive'},

  {value: 'ri-archive-line', label: 'archive'},

  {value: 'ri-archive-2-line', label: 'archive-2'},

  {value: 'ri-archive-2-line', label: 'archive-2'},

  {value: 'ri-archive-drawer-line', label: 'archive-drawer'},

  {value: 'ri-archive-drawer-line', label: 'archive-drawer'},

  {value: 'ri-at-line', label: 'at'},

  {value: 'ri-at-line', label: 'at'},

  {value: 'ri-award-line', label: 'award'},

  {value: 'ri-award-line', label: 'award'},

  {value: 'ri-medal-line', label: 'medal'},

  {value: 'ri-medal-line', label: 'medal'},

  {value: 'ri-medal-2-line', label: 'medal-2'},

  {value: 'ri-medal-2-line', label: 'medal-2'},

  {value: 'ri-verified-badge-line', label: 'verified-badge'},

  {value: 'ri-verified-badge-line', label: 'verified-badge'},

  {value: 'ri-bar-chart-line', label: 'bar-chart'},

  {value: 'ri-bar-chart-line', label: 'bar-chart'},

  {value: 'ri-bar-chart-horizontal-line', label: 'bar-chart-horizontal'},

  {value: 'ri-bar-chart-horizontal-line', label: 'bar-chart-horizontal'},

  {value: 'ri-bar-chart-2-line', label: 'bar-chart-2'},

  {value: 'ri-bar-chart-2-line', label: 'bar-chart-2'},

  {value: 'ri-bar-chart-box-line', label: 'bar-chart-box'},

  {value: 'ri-bar-chart-box-line', label: 'bar-chart-box'},

  {value: 'ri-bar-chart-grouped-line', label: 'bar-chart-grouped'},

  {value: 'ri-bar-chart-grouped-line', label: 'bar-chart-grouped'},

  {value: 'ri-bubble-chart-line', label: 'bubble-chart'},

  {value: 'ri-bubble-chart-line', label: 'bubble-chart'},

  {value: 'ri-pie-chart-line', label: 'pie-chart'},

  {value: 'ri-pie-chart-line', label: 'pie-chart'},

  {value: 'ri-pie-chart-2-line', label: 'pie-chart-2'},

  {value: 'ri-pie-chart-2-line', label: 'pie-chart-2'},

  {value: 'ri-pie-chart-box-line', label: 'pie-chart-box'},

  {value: 'ri-pie-chart-box-line', label: 'pie-chart-box'},

  {value: 'ri-donut-chart-line', label: 'donut-chart'},

  {value: 'ri-donut-chart-line', label: 'donut-chart'},

  {value: 'ri-line-chart-line', label: 'line-chart'},

  {value: 'ri-line-chart-line', label: 'line-chart'},

  {value: 'ri-bookmark-line', label: 'bookmark'},

  {value: 'ri-bookmark-line', label: 'bookmark'},

  {value: 'ri-bookmark-2-line', label: 'bookmark-2'},

  {value: 'ri-bookmark-2-line', label: 'bookmark-2'},

  {value: 'ri-bookmark-3-line', label: 'bookmark-3'},

  {value: 'ri-bookmark-3-line', label: 'bookmark-3'},

  {value: 'ri-briefcase-line', label: 'briefcase'},

  {value: 'ri-briefcase-line', label: 'briefcase'},

  {value: 'ri-briefcase-2-line', label: 'briefcase-2'},

  {value: 'ri-briefcase-2-line', label: 'briefcase-2'},

  {value: 'ri-briefcase-3-line', label: 'briefcase-3'},

  {value: 'ri-briefcase-3-line', label: 'briefcase-3'},

  {value: 'ri-briefcase-4-line', label: 'briefcase-4'},

  {value: 'ri-briefcase-4-line', label: 'briefcase-4'},

  {value: 'ri-briefcase-5-line', label: 'briefcase-5'},

  {value: 'ri-briefcase-5-line', label: 'briefcase-5'},

  {value: 'ri-calculator-line', label: 'calculator'},

  {value: 'ri-calculator-line', label: 'calculator'},

  {value: 'ri-calendar-line', label: 'calendar'},

  {value: 'ri-calendar-line', label: 'calendar'},

  {value: 'ri-calendar-2-line', label: 'calendar-2'},

  {value: 'ri-calendar-2-line', label: 'calendar-2'},

  {value: 'ri-calendar-event-line', label: 'calendar-event'},

  {value: 'ri-calendar-event-line', label: 'calendar-event'},

  {value: 'ri-calendar-todo-line', label: 'calendar-todo'},

  {value: 'ri-calendar-todo-line', label: 'calendar-todo'},

  {value: 'ri-calendar-check-line', label: 'calendar-check'},

  {value: 'ri-calendar-check-line', label: 'calendar-check'},

  {value: 'ri-calendar-close-line', label: 'calendar-close'},

  {value: 'ri-calendar-close-line', label: 'calendar-close'},

  {value: 'ri-customer-service-line', label: 'customer-service'},

  {value: 'ri-customer-service-line', label: 'customer-service'},

  {value: 'ri-customer-service-2-line', label: 'customer-service-2'},

  {value: 'ri-customer-service-2-line', label: 'customer-service-2'},

  {value: 'ri-flag-line', label: 'flag'},

  {value: 'ri-flag-line', label: 'flag'},

  {value: 'ri-flag-2-line', label: 'flag-2'},

  {value: 'ri-flag-2-line', label: 'flag-2'},

  {value: 'ri-global-line', label: 'global'},

  {value: 'ri-global-line', label: 'global'},

  {value: 'ri-honour-line', label: 'honour'},

  {value: 'ri-honour-line', label: 'honour'},

  {value: 'ri-links-line', label: 'links'},

  {value: 'ri-links-line', label: 'links'},

  {value: 'ri-printer-line', label: 'printer'},

  {value: 'ri-printer-line', label: 'printer'},

  {value: 'ri-printer-cloud-line', label: 'printer-cloud'},

  {value: 'ri-printer-cloud-line', label: 'printer-cloud'},

  {value: 'ri-record-mail-line', label: 'record-mail'},

  {value: 'ri-record-mail-line', label: 'record-mail'},

  {value: 'ri-reply-line', label: 'reply'},

  {value: 'ri-reply-line', label: 'reply'},

  {value: 'ri-reply-all-line', label: 'reply-all'},

  {value: 'ri-reply-all-line', label: 'reply-all'},

  {value: 'ri-send-plane-line', label: 'send-plane'},

  {value: 'ri-send-plane-line', label: 'send-plane'},

  {value: 'ri-send-plane-2-line', label: 'send-plane-2'},

  {value: 'ri-send-plane-2-line', label: 'send-plane-2'},

  {value: 'ri-projector-line', label: 'projector'},

  {value: 'ri-projector-line', label: 'projector'},

  {value: 'ri-projector-2-line', label: 'projector-2'},

  {value: 'ri-projector-2-line', label: 'projector-2'},

  {value: 'ri-slideshow-line', label: 'slideshow'},

  {value: 'ri-slideshow-line', label: 'slideshow'},

  {value: 'ri-slideshow-2-line', label: 'slideshow-2'},

  {value: 'ri-slideshow-2-line', label: 'slideshow-2'},

  {value: 'ri-slideshow-3-line', label: 'slideshow-3'},

  {value: 'ri-slideshow-3-line', label: 'slideshow-3'},

  {value: 'ri-slideshow-4-line', label: 'slideshow-4'},

  {value: 'ri-slideshow-4-line', label: 'slideshow-4'},

  {value: 'ri-presentation-line', label: 'presentation'},

  {value: 'ri-presentation-line', label: 'presentation'},

  {value: 'ri-window-line', label: 'window'},

  {value: 'ri-window-line', label: 'window'},

  {value: 'ri-window-2-line', label: 'window-2'},

  {value: 'ri-window-2-line', label: 'window-2'},

  {value: 'ri-stack-line', label: 'stack'},

  {value: 'ri-stack-line', label: 'stack'},

  {value: 'ri-shake-hands-line', label: 'shake-hands'},

  {value: 'ri-shake-hands-line', label: 'shake-hands'},

  {value: 'ri-service-line', label: 'service'},

  {value: 'ri-service-line', label: 'service'},

  {value: 'ri-registered-line', label: 'registered'},

  {value: 'ri-registered-line', label: 'registered'},

  {value: 'ri-trademark-line', label: 'trademark'},

  {value: 'ri-trademark-line', label: 'trademark'},

  {value: 'ri-advertisement-line', label: 'advertisement'},

  {value: 'ri-advertisement-line', label: 'advertisement'},

  {value: 'ri-copyleft-line', label: 'copyleft'},

  {value: 'ri-copyleft-line', label: 'copyleft'},

  {value: 'ri-copyright-line', label: 'copyright'},

  {value: 'ri-copyright-line', label: 'copyright'},

  {value: 'ri-creative-commons-line', label: 'creative-commons'},

  {value: 'ri-creative-commons-line', label: 'creative-commons'},

  {value: 'ri-creative-commons-by-line', label: 'creative-commons-by'},

  {value: 'ri-creative-commons-by-line', label: 'creative-commons-by'},

  {value: 'ri-creative-commons-nc-line', label: 'creative-commons-nc'},

  {value: 'ri-creative-commons-nc-line', label: 'creative-commons-nc'},

  {value: 'ri-creative-commons-nd-line', label: 'creative-commons-nd'},

  {value: 'ri-creative-commons-nd-line', label: 'creative-commons-nd'},

  {value: 'ri-creative-commons-sa-line', label: 'creative-commons-sa'},

  {value: 'ri-creative-commons-sa-line', label: 'creative-commons-sa'},

  {value: 'ri-creative-commons-zero-line', label: 'creative-commons-zero'},

  {value: 'ri-creative-commons-zero-line', label: 'creative-commons-zero'},

  {value: 'ri-megaphone-line', label: 'megaphone'},

  {value: 'ri-megaphone-line', label: 'megaphone'},

  {value: 'ri-pass-valid-line', label: 'pass-valid'},

  {value: 'ri-pass-valid-line', label: 'pass-valid'},

  {value: 'ri-pass-pending-line', label: 'pass-pending'},

  {value: 'ri-pass-pending-line', label: 'pass-pending'},

  {value: 'ri-pass-expired-line', label: 'pass-expired'},

  {value: 'ri-pass-expired-line', label: 'pass-expired'},

  {value: 'ri-seo-line', label: 'seo'},

  {value: 'ri-seo-line', label: 'seo'},

  {value: 'ri-Business194-line', label: 'Business194'},

  {value: 'ri-chat-1-line', label: 'chat-1'},

  {value: 'ri-chat-1-line', label: 'chat-1'},

  {value: 'ri-chat-2-line', label: 'chat-2'},

  {value: 'ri-chat-2-line', label: 'chat-2'},

  {value: 'ri-chat-3-line', label: 'chat-3'},

  {value: 'ri-chat-3-line', label: 'chat-3'},

  {value: 'ri-chat-4-line', label: 'chat-4'},

  {value: 'ri-chat-4-line', label: 'chat-4'},

  {value: 'ri-message-line', label: 'message'},

  {value: 'ri-message-line', label: 'message'},

  {value: 'ri-message-2-line', label: 'message-2'},

  {value: 'ri-message-2-line', label: 'message-2'},

  {value: 'ri-message-3-line', label: 'message-3'},

  {value: 'ri-message-3-line', label: 'message-3'},

  {value: 'ri-chat-check-line', label: 'chat-check'},

  {value: 'ri-chat-check-line', label: 'chat-check'},

  {value: 'ri-chat-delete-line', label: 'chat-delete'},

  {value: 'ri-chat-delete-line', label: 'chat-delete'},

  {value: 'ri-chat-forward-line', label: 'chat-forward'},

  {value: 'ri-chat-forward-line', label: 'chat-forward'},

  {value: 'ri-chat-upload-line', label: 'chat-upload'},

  {value: 'ri-chat-upload-line', label: 'chat-upload'},

  {value: 'ri-chat-download-line', label: 'chat-download'},

  {value: 'ri-chat-download-line', label: 'chat-download'},

  {value: 'ri-chat-new-line', label: 'chat-new'},

  {value: 'ri-chat-new-line', label: 'chat-new'},

  {value: 'ri-chat-settings-line', label: 'chat-settings'},

  {value: 'ri-chat-settings-line', label: 'chat-settings'},

  {value: 'ri-chat-smile-line', label: 'chat-smile'},

  {value: 'ri-chat-smile-line', label: 'chat-smile'},

  {value: 'ri-chat-smile-2-line', label: 'chat-smile-2'},

  {value: 'ri-chat-smile-2-line', label: 'chat-smile-2'},

  {value: 'ri-chat-smile-3-line', label: 'chat-smile-3'},

  {value: 'ri-chat-smile-3-line', label: 'chat-smile-3'},

  {value: 'ri-chat-heart-line', label: 'chat-heart'},

  {value: 'ri-chat-heart-line', label: 'chat-heart'},

  {value: 'ri-chat-off-line', label: 'chat-off'},

  {value: 'ri-chat-off-line', label: 'chat-off'},

  {value: 'ri-feedback-line', label: 'feedback'},

  {value: 'ri-feedback-line', label: 'feedback'},

  {value: 'ri-discuss-line', label: 'discuss'},

  {value: 'ri-discuss-line', label: 'discuss'},

  {value: 'ri-question-answer-line', label: 'question-answer'},

  {value: 'ri-question-answer-line', label: 'question-answer'},

  {value: 'ri-questionnaire-line', label: 'questionnaire'},

  {value: 'ri-questionnaire-line', label: 'questionnaire'},

  {value: 'ri-video-chat-line', label: 'video-chat'},

  {value: 'ri-video-chat-line', label: 'video-chat'},

  {value: 'ri-chat-voice-line', label: 'chat-voice'},

  {value: 'ri-chat-voice-line', label: 'chat-voice'},

  {value: 'ri-chat-quote-line', label: 'chat-quote'},

  {value: 'ri-chat-quote-line', label: 'chat-quote'},

  {value: 'ri-chat-follow-up-line', label: 'chat-follow-up'},

  {value: 'ri-chat-follow-up-line', label: 'chat-follow-up'},

  {value: 'ri-chat-poll-line', label: 'chat-poll'},

  {value: 'ri-chat-poll-line', label: 'chat-poll'},

  {value: 'ri-chat-history-line', label: 'chat-history'},

  {value: 'ri-chat-history-line', label: 'chat-history'},

  {value: 'ri-chat-private-line', label: 'chat-private'},

  {value: 'ri-chat-private-line', label: 'chat-private'},

  {value: 'ri-speak-line', label: 'speak'},

  {value: 'ri-speak-line', label: 'speak'},

  {value: 'ri-emoji-sticker-line', label: 'emoji-sticker'},

  {value: 'ri-emoji-sticker-line', label: 'emoji-sticker'},

  {value: 'ri-Communication64-line', label: 'Communication64'},

  {value: 'ri-pencil-line', label: 'pencil'},

  {value: 'ri-pencil-line', label: 'pencil'},

  {value: 'ri-edit-line', label: 'edit'},

  {value: 'ri-edit-line', label: 'edit'},

  {value: 'ri-edit-2-line', label: 'edit-2'},

  {value: 'ri-edit-2-line', label: 'edit-2'},

  {value: 'ri-ball-pen-line', label: 'ball-pen'},

  {value: 'ri-ball-pen-line', label: 'ball-pen'},

  {value: 'ri-quill-pen-line', label: 'quill-pen'},

  {value: 'ri-quill-pen-line', label: 'quill-pen'},

  {value: 'ri-pen-nib-line', label: 'pen-nib'},

  {value: 'ri-pen-nib-line', label: 'pen-nib'},

  {value: 'ri-ink-bottle-line', label: 'ink-bottle'},

  {value: 'ri-ink-bottle-line', label: 'ink-bottle'},

  {value: 'ri-mark-pen-line', label: 'mark-pen'},

  {value: 'ri-mark-pen-line', label: 'mark-pen'},

  {value: 'ri-markup-line', label: 'markup'},

  {value: 'ri-markup-line', label: 'markup'},

  {value: 'ri-edit-box-line', label: 'edit-box'},

  {value: 'ri-edit-box-line', label: 'edit-box'},

  {value: 'ri-edit-circle-line', label: 'edit-circle'},

  {value: 'ri-edit-circle-line', label: 'edit-circle'},

  {value: 'ri-sip-line', label: 'sip'},

  {value: 'ri-sip-line', label: 'sip'},

  {value: 'ri-brush-line', label: 'brush'},

  {value: 'ri-brush-line', label: 'brush'},

  {value: 'ri-brush-2-line', label: 'brush-2'},

  {value: 'ri-brush-2-line', label: 'brush-2'},

  {value: 'ri-brush-3-line', label: 'brush-3'},

  {value: 'ri-brush-3-line', label: 'brush-3'},

  {value: 'ri-brush-4-line', label: 'brush-4'},

  {value: 'ri-brush-4-line', label: 'brush-4'},

  {value: 'ri-paint-brush-line', label: 'paint-brush'},

  {value: 'ri-paint-brush-line', label: 'paint-brush'},

  {value: 'ri-contrast-line', label: 'contrast'},

  {value: 'ri-contrast-line', label: 'contrast'},

  {value: 'ri-contrast-2-line', label: 'contrast-2'},

  {value: 'ri-contrast-2-line', label: 'contrast-2'},

  {value: 'ri-drop-line', label: 'drop'},

  {value: 'ri-drop-line', label: 'drop'},

  {value: 'ri-blur-off-line', label: 'blur-off'},

  {value: 'ri-blur-off-line', label: 'blur-off'},

  {value: 'ri-contrast-drop-line', label: 'contrast-drop'},

  {value: 'ri-contrast-drop-line', label: 'contrast-drop'},

  {value: 'ri-contrast-drop-2-line', label: 'contrast-drop-2'},

  {value: 'ri-contrast-drop-2-line', label: 'contrast-drop-2'},

  {value: 'ri-compasses-line', label: 'compasses'},

  {value: 'ri-compasses-line', label: 'compasses'},

  {value: 'ri-compasses-2-line', label: 'compasses-2'},

  {value: 'ri-compasses-2-line', label: 'compasses-2'},

  {value: 'ri-scissors-line', label: 'scissors'},

  {value: 'ri-scissors-line', label: 'scissors'},

  {value: 'ri-scissors-cut-line', label: 'scissors-cut'},

  {value: 'ri-scissors-cut-line', label: 'scissors-cut'},

  {value: 'ri-scissors-2-line', label: 'scissors-2'},

  {value: 'ri-scissors-2-line', label: 'scissors-2'},

  {value: 'ri-slice-line', label: 'slice'},

  {value: 'ri-slice-line', label: 'slice'},

  {value: 'ri-eraser-line', label: 'eraser'},

  {value: 'ri-eraser-line', label: 'eraser'},

  {value: 'ri-ruler-line', label: 'ruler'},

  {value: 'ri-ruler-line', label: 'ruler'},

  {value: 'ri-ruler-2-line', label: 'ruler-2'},

  {value: 'ri-ruler-2-line', label: 'ruler-2'},

  {value: 'ri-pencil-ruler-line', label: 'pencil-ruler'},

  {value: 'ri-pencil-ruler-line', label: 'pencil-ruler'},

  {value: 'ri-pencil-ruler-2-line', label: 'pencil-ruler-2'},

  {value: 'ri-pencil-ruler-2-line', label: 'pencil-ruler-2'},

  {value: 'ri-t-box-line', label: 't-box'},

  {value: 'ri-t-box-line', label: 't-box'},

  {value: 'ri-input-method-line', label: 'input-method'},

  {value: 'ri-input-method-line', label: 'input-method'},

  {value: 'ri-artboard-line', label: 'artboard'},

  {value: 'ri-artboard-line', label: 'artboard'},

  {value: 'ri-artboard-2-line', label: 'artboard-2'},

  {value: 'ri-artboard-2-line', label: 'artboard-2'},

  {value: 'ri-crop-line', label: 'crop'},

  {value: 'ri-crop-line', label: 'crop'},

  {value: 'ri-crop-2-line', label: 'crop-2'},

  {value: 'ri-crop-2-line', label: 'crop-2'},

  {value: 'ri-screenshot-line', label: 'screenshot'},

  {value: 'ri-screenshot-line', label: 'screenshot'},

  {value: 'ri-screenshot-2-line', label: 'screenshot-2'},

  {value: 'ri-screenshot-2-line', label: 'screenshot-2'},

  {value: 'ri-focus-line', label: 'focus'},

  {value: 'ri-focus-line', label: 'focus'},

  {value: 'ri-focus-2-line', label: 'focus-2'},

  {value: 'ri-focus-2-line', label: 'focus-2'},

  {value: 'ri-focus-3-line', label: 'focus-3'},

  {value: 'ri-focus-3-line', label: 'focus-3'},

  {value: 'ri-crosshair-line', label: 'crosshair'},

  {value: 'ri-crosshair-line', label: 'crosshair'},

  {value: 'ri-crosshair-2-line', label: 'crosshair-2'},

  {value: 'ri-crosshair-2-line', label: 'crosshair-2'},

  {value: 'ri-paint-line', label: 'paint'},

  {value: 'ri-paint-line', label: 'paint'},

  {value: 'ri-palette-line', label: 'palette'},

  {value: 'ri-palette-line', label: 'palette'},

  {value: 'ri-pantone-line', label: 'pantone'},

  {value: 'ri-pantone-line', label: 'pantone'},

  {value: 'ri-shape-line', label: 'shape'},

  {value: 'ri-shape-line', label: 'shape'},

  {value: 'ri-shape-2-line', label: 'shape-2'},

  {value: 'ri-shape-2-line', label: 'shape-2'},

  {value: 'ri-magic-line', label: 'magic'},

  {value: 'ri-magic-line', label: 'magic'},

  {value: 'ri-anticlockwise-line', label: 'anticlockwise'},

  {value: 'ri-anticlockwise-line', label: 'anticlockwise'},

  {value: 'ri-anticlockwise-2-line', label: 'anticlockwise-2'},

  {value: 'ri-anticlockwise-2-line', label: 'anticlockwise-2'},

  {value: 'ri-clockwise-line', label: 'clockwise'},

  {value: 'ri-clockwise-line', label: 'clockwise'},

  {value: 'ri-clockwise-2-line', label: 'clockwise-2'},

  {value: 'ri-clockwise-2-line', label: 'clockwise-2'},

  {value: 'ri-hammer-line', label: 'hammer'},

  {value: 'ri-hammer-line', label: 'hammer'},

  {value: 'ri-tools-line', label: 'tools'},

  {value: 'ri-tools-line', label: 'tools'},

  {value: 'ri-drag-drop-line', label: 'drag-drop'},

  {value: 'ri-drag-drop-line', label: 'drag-drop'},

  {value: 'ri-table-line', label: 'table'},

  {value: 'ri-table-line', label: 'table'},

  {value: 'ri-table-alt-line', label: 'table-alt'},

  {value: 'ri-table-alt-line', label: 'table-alt'},

  {value: 'ri-layout-line', label: 'layout'},

  {value: 'ri-layout-line', label: 'layout'},

  {value: 'ri-layout-2-line', label: 'layout-2'},

  {value: 'ri-layout-2-line', label: 'layout-2'},

  {value: 'ri-layout-3-line', label: 'layout-3'},

  {value: 'ri-layout-3-line', label: 'layout-3'},

  {value: 'ri-layout-4-line', label: 'layout-4'},

  {value: 'ri-layout-4-line', label: 'layout-4'},

  {value: 'ri-layout-5-line', label: 'layout-5'},

  {value: 'ri-layout-5-line', label: 'layout-5'},

  {value: 'ri-layout-6-line', label: 'layout-6'},

  {value: 'ri-layout-6-line', label: 'layout-6'},

  {value: 'ri-layout-column-line', label: 'layout-column'},

  {value: 'ri-layout-column-line', label: 'layout-column'},

  {value: 'ri-layout-row-line', label: 'layout-row'},

  {value: 'ri-layout-row-line', label: 'layout-row'},

  {value: 'ri-layout-top-line', label: 'layout-top'},

  {value: 'ri-layout-top-line', label: 'layout-top'},

  {value: 'ri-layout-right-line', label: 'layout-right'},

  {value: 'ri-layout-right-line', label: 'layout-right'},

  {value: 'ri-layout-bottom-line', label: 'layout-bottom'},

  {value: 'ri-layout-bottom-line', label: 'layout-bottom'},

  {value: 'ri-layout-left-line', label: 'layout-left'},

  {value: 'ri-layout-left-line', label: 'layout-left'},

  {value: 'ri-layout-top-2-line', label: 'layout-top-2'},

  {value: 'ri-layout-top-2-line', label: 'layout-top-2'},

  {value: 'ri-layout-right-2-line', label: 'layout-right-2'},

  {value: 'ri-layout-right-2-line', label: 'layout-right-2'},

  {value: 'ri-layout-bottom-2-line', label: 'layout-bottom-2'},

  {value: 'ri-layout-bottom-2-line', label: 'layout-bottom-2'},

  {value: 'ri-layout-left-2-line', label: 'layout-left-2'},

  {value: 'ri-layout-left-2-line', label: 'layout-left-2'},

  {value: 'ri-layout-grid-line', label: 'layout-grid'},

  {value: 'ri-layout-grid-line', label: 'layout-grid'},

  {value: 'ri-layout-masonry-line', label: 'layout-masonry'},

  {value: 'ri-layout-masonry-line', label: 'layout-masonry'},

  {value: 'ri-collage-line', label: 'collage'},

  {value: 'ri-collage-line', label: 'collage'},

  {value: 'ri-grid-line', label: 'grid'},

  {value: 'ri-grid-line', label: 'grid'},

  {value: 'ri-circle-line', label: 'circle'},

  {value: 'ri-circle-line', label: 'circle'},

  {value: 'ri-triangle-line', label: 'triangle'},

  {value: 'ri-triangle-line', label: 'triangle'},

  {value: 'ri-pentagon-line', label: 'pentagon'},

  {value: 'ri-pentagon-line', label: 'pentagon'},

  {value: 'ri-hexagon-line', label: 'hexagon'},

  {value: 'ri-hexagon-line', label: 'hexagon'},

  {value: 'ri-octagon-line', label: 'octagon'},

  {value: 'ri-octagon-line', label: 'octagon'},

  {value: 'ri-square-line', label: 'square'},

  {value: 'ri-square-line', label: 'square'},

  {value: 'ri-rectangle-line', label: 'rectangle'},

  {value: 'ri-rectangle-line', label: 'rectangle'},

  {value: 'ri-shapes-line', label: 'shapes'},

  {value: 'ri-shapes-line', label: 'shapes'},

  {value: 'ri-Design180-line', label: 'Design180'},

  {value: 'ri-bug-line', label: 'bug'},

  {value: 'ri-bug-line', label: 'bug'},

  {value: 'ri-bug-2-line', label: 'bug-2'},

  {value: 'ri-bug-2-line', label: 'bug-2'},

  {value: 'ri-code-line', label: 'code'},

  {value: 'ri-code-line', label: 'code'},

  {value: 'ri-code-s-line', label: 'code-s'},

  {value: 'ri-code-s-line', label: 'code-s'},

  {value: 'ri-code-s-slash-line', label: 'code-s-slash'},

  {value: 'ri-code-s-slash-line', label: 'code-s-slash'},

  {value: 'ri-code-box-line', label: 'code-box'},

  {value: 'ri-code-box-line', label: 'code-box'},

  {value: 'ri-terminal-box-line', label: 'terminal-box'},

  {value: 'ri-terminal-box-line', label: 'terminal-box'},

  {value: 'ri-terminal-line', label: 'terminal'},

  {value: 'ri-terminal-line', label: 'terminal'},

  {value: 'ri-terminal-window-line', label: 'terminal-window'},

  {value: 'ri-terminal-window-line', label: 'terminal-window'},

  {value: 'ri-parentheses-line', label: 'parentheses'},

  {value: 'ri-parentheses-line', label: 'parentheses'},

  {value: 'ri-brackets-line', label: 'brackets'},

  {value: 'ri-brackets-line', label: 'brackets'},

  {value: 'ri-braces-line', label: 'braces'},

  {value: 'ri-braces-line', label: 'braces'},

  {value: 'ri-command-line', label: 'command'},

  {value: 'ri-command-line', label: 'command'},

  {value: 'ri-cursor-line', label: 'cursor'},

  {value: 'ri-cursor-line', label: 'cursor'},

  {value: 'ri-git-commit-line', label: 'git-commit'},

  {value: 'ri-git-commit-line', label: 'git-commit'},

  {value: 'ri-git-pull-request-line', label: 'git-pull-request'},

  {value: 'ri-git-pull-request-line', label: 'git-pull-request'},

  {value: 'ri-git-merge-line', label: 'git-merge'},

  {value: 'ri-git-merge-line', label: 'git-merge'},

  {value: 'ri-git-branch-line', label: 'git-branch'},

  {value: 'ri-git-branch-line', label: 'git-branch'},

  {value: 'ri-git-close-pull-request-line', label: 'git-close-pull-request'},

  {value: 'ri-git-close-pull-request-line', label: 'git-close-pull-request'},

  {value: 'ri-git-repository-line', label: 'git-repository'},

  {value: 'ri-git-repository-line', label: 'git-repository'},

  {value: 'ri-git-repository-commits-line', label: 'git-repository-commits'},

  {value: 'ri-git-repository-commits-line', label: 'git-repository-commits'},

  {value: 'ri-git-repository-private-line', label: 'git-repository-private'},

  {value: 'ri-git-repository-private-line', label: 'git-repository-private'},

  {value: 'ri-html5-line', label: 'html5'},

  {value: 'ri-html5-line', label: 'html5'},

  {value: 'ri-css3-line', label: 'css3'},

  {value: 'ri-css3-line', label: 'css3'},

  {value: 'ri-javascript-line', label: 'javascript'},

  {value: 'ri-javascript-line', label: 'javascript'},

  {value: 'ri-Development50-line', label: 'Development50'},

  {value: 'ri-tv-line', label: 'tv'},

  {value: 'ri-tv-line', label: 'tv'},

  {value: 'ri-tv-2-line', label: 'tv-2'},

  {value: 'ri-tv-2-line', label: 'tv-2'},

  {value: 'ri-computer-line', label: 'computer'},

  {value: 'ri-computer-line', label: 'computer'},

  {value: 'ri-mac-line', label: 'mac'},

  {value: 'ri-mac-line', label: 'mac'},

  {value: 'ri-macbook-line', label: 'macbook'},

  {value: 'ri-macbook-line', label: 'macbook'},

  {value: 'ri-cellphone-line', label: 'cellphone'},

  {value: 'ri-cellphone-line', label: 'cellphone'},

  {value: 'ri-smartphone-line', label: 'smartphone'},

  {value: 'ri-smartphone-line', label: 'smartphone'},

  {value: 'ri-tablet-line', label: 'tablet'},

  {value: 'ri-tablet-line', label: 'tablet'},

  {value: 'ri-device-line', label: 'device'},

  {value: 'ri-device-line', label: 'device'},

  {value: 'ri-phone-line', label: 'phone'},

  {value: 'ri-phone-line', label: 'phone'},

  {value: 'ri-instance-line', label: 'instance'},

  {value: 'ri-instance-line', label: 'instance'},

  {value: 'ri-database-line', label: 'database'},

  {value: 'ri-database-line', label: 'database'},

  {value: 'ri-database-2-line', label: 'database-2'},

  {value: 'ri-database-2-line', label: 'database-2'},

  {value: 'ri-server-line', label: 'server'},

  {value: 'ri-server-line', label: 'server'},

  {value: 'ri-hard-drive-line', label: 'hard-drive'},

  {value: 'ri-hard-drive-line', label: 'hard-drive'},

  {value: 'ri-hard-drive-2-line', label: 'hard-drive-2'},

  {value: 'ri-hard-drive-2-line', label: 'hard-drive-2'},

  {value: 'ri-hard-drive-3-line', label: 'hard-drive-3'},

  {value: 'ri-hard-drive-3-line', label: 'hard-drive-3'},

  {value: 'ri-install-line', label: 'install'},

  {value: 'ri-install-line', label: 'install'},

  {value: 'ri-uninstall-line', label: 'uninstall'},

  {value: 'ri-uninstall-line', label: 'uninstall'},

  {value: 'ri-save-line', label: 'save'},

  {value: 'ri-save-line', label: 'save'},

  {value: 'ri-save-2-line', label: 'save-2'},

  {value: 'ri-save-2-line', label: 'save-2'},

  {value: 'ri-save-3-line', label: 'save-3'},

  {value: 'ri-save-3-line', label: 'save-3'},

  {value: 'ri-sd-card-line', label: 'sd-card'},

  {value: 'ri-sd-card-line', label: 'sd-card'},

  {value: 'ri-sd-card-mini-line', label: 'sd-card-mini'},

  {value: 'ri-sd-card-mini-line', label: 'sd-card-mini'},

  {value: 'ri-sim-card-line', label: 'sim-card'},

  {value: 'ri-sim-card-line', label: 'sim-card'},

  {value: 'ri-sim-card-2-line', label: 'sim-card-2'},

  {value: 'ri-sim-card-2-line', label: 'sim-card-2'},

  {value: 'ri-dual-sim-1-line', label: 'dual-sim-1'},

  {value: 'ri-dual-sim-1-line', label: 'dual-sim-1'},

  {value: 'ri-dual-sim-2-line', label: 'dual-sim-2'},

  {value: 'ri-dual-sim-2-line', label: 'dual-sim-2'},

  {value: 'ri-u-disk-line', label: 'u-disk'},

  {value: 'ri-u-disk-line', label: 'u-disk'},

  {value: 'ri-battery-line', label: 'battery'},

  {value: 'ri-battery-line', label: 'battery'},

  {value: 'ri-battery-charge-line', label: 'battery-charge'},

  {value: 'ri-battery-charge-line', label: 'battery-charge'},

  {value: 'ri-battery-low-line', label: 'battery-low'},

  {value: 'ri-battery-low-line', label: 'battery-low'},

  {value: 'ri-battery-2-line', label: 'battery-2'},

  {value: 'ri-battery-2-line', label: 'battery-2'},

  {value: 'ri-battery-2-charge-line', label: 'battery-2-charge'},

  {value: 'ri-battery-2-charge-line', label: 'battery-2-charge'},

  {value: 'ri-battery-saver-line', label: 'battery-saver'},

  {value: 'ri-battery-saver-line', label: 'battery-saver'},

  {value: 'ri-battery-share-line', label: 'battery-share'},

  {value: 'ri-battery-share-line', label: 'battery-share'},

  {value: 'ri-cast-line', label: 'cast'},

  {value: 'ri-cast-line', label: 'cast'},

  {value: 'ri-airplay-line', label: 'airplay'},

  {value: 'ri-airplay-line', label: 'airplay'},

  {value: 'ri-cpu-line', label: 'cpu'},

  {value: 'ri-cpu-line', label: 'cpu'},

  {value: 'ri-gradienter-line', label: 'gradienter'},

  {value: 'ri-gradienter-line', label: 'gradienter'},

  {value: 'ri-keyboard-line', label: 'keyboard'},

  {value: 'ri-keyboard-line', label: 'keyboard'},

  {value: 'ri-keyboard-box-line', label: 'keyboard-box'},

  {value: 'ri-keyboard-box-line', label: 'keyboard-box'},

  {value: 'ri-mouse-line', label: 'mouse'},

  {value: 'ri-mouse-line', label: 'mouse'},

  {value: 'ri-sensor-line', label: 'sensor'},

  {value: 'ri-sensor-line', label: 'sensor'},

  {value: 'ri-router-line', label: 'router'},

  {value: 'ri-router-line', label: 'router'},

  {value: 'ri-radar-line', label: 'radar'},

  {value: 'ri-radar-line', label: 'radar'},

  {value: 'ri-gamepad-line', label: 'gamepad'},

  {value: 'ri-gamepad-line', label: 'gamepad'},

  {value: 'ri-remote-control-line', label: 'remote-control'},

  {value: 'ri-remote-control-line', label: 'remote-control'},

  {value: 'ri-remote-control-2-line', label: 'remote-control-2'},

  {value: 'ri-remote-control-2-line', label: 'remote-control-2'},

  {value: 'ri-device-recover-line', label: 'device-recover'},

  {value: 'ri-device-recover-line', label: 'device-recover'},

  {value: 'ri-hotspot-line', label: 'hotspot'},

  {value: 'ri-hotspot-line', label: 'hotspot'},

  {value: 'ri-phone-find-line', label: 'phone-find'},

  {value: 'ri-phone-find-line', label: 'phone-find'},

  {value: 'ri-phone-lock-line', label: 'phone-lock'},

  {value: 'ri-phone-lock-line', label: 'phone-lock'},

  {value: 'ri-rotate-lock-line', label: 'rotate-lock'},

  {value: 'ri-rotate-lock-line', label: 'rotate-lock'},

  {value: 'ri-restart-line', label: 'restart'},

  {value: 'ri-restart-line', label: 'restart'},

  {value: 'ri-shut-down-line', label: 'shut-down'},

  {value: 'ri-shut-down-line', label: 'shut-down'},

  {value: 'ri-fingerprint-line', label: 'fingerprint'},

  {value: 'ri-fingerprint-line', label: 'fingerprint'},

  {value: 'ri-fingerprint-2-line', label: 'fingerprint-2'},

  {value: 'ri-fingerprint-2-line', label: 'fingerprint-2'},

  {value: 'ri-barcode-line', label: 'barcode'},

  {value: 'ri-barcode-line', label: 'barcode'},

  {value: 'ri-barcode-box-line', label: 'barcode-box'},

  {value: 'ri-barcode-box-line', label: 'barcode-box'},

  {value: 'ri-qr-code-line', label: 'qr-code'},

  {value: 'ri-qr-code-line', label: 'qr-code'},

  {value: 'ri-qr-scan-line', label: 'qr-scan'},

  {value: 'ri-qr-scan-line', label: 'qr-scan'},

  {value: 'ri-qr-scan-2-line', label: 'qr-scan-2'},

  {value: 'ri-qr-scan-2-line', label: 'qr-scan-2'},

  {value: 'ri-scan-line', label: 'scan'},

  {value: 'ri-scan-line', label: 'scan'},

  {value: 'ri-scan-2-line', label: 'scan-2'},

  {value: 'ri-scan-2-line', label: 'scan-2'},

  {value: 'ri-rss-line', label: 'rss'},

  {value: 'ri-rss-line', label: 'rss'},

  {value: 'ri-gps-line', label: 'gps'},

  {value: 'ri-gps-line', label: 'gps'},

  {value: 'ri-base-station-line', label: 'base-station'},

  {value: 'ri-base-station-line', label: 'base-station'},

  {value: 'ri-bluetooth-line', label: 'bluetooth'},

  {value: 'ri-bluetooth-line', label: 'bluetooth'},

  {value: 'ri-bluetooth-connect-line', label: 'bluetooth-connect'},

  {value: 'ri-bluetooth-connect-line', label: 'bluetooth-connect'},

  {value: 'ri-wifi-line', label: 'wifi'},

  {value: 'ri-wifi-line', label: 'wifi'},

  {value: 'ri-wifi-off-line', label: 'wifi-off'},

  {value: 'ri-wifi-off-line', label: 'wifi-off'},

  {value: 'ri-signal-wifi-line', label: 'signal-wifi'},

  {value: 'ri-signal-wifi-line', label: 'signal-wifi'},

  {value: 'ri-signal-wifi-1-line', label: 'signal-wifi-1'},

  {value: 'ri-signal-wifi-1-line', label: 'signal-wifi-1'},

  {value: 'ri-signal-wifi-2-line', label: 'signal-wifi-2'},

  {value: 'ri-signal-wifi-2-line', label: 'signal-wifi-2'},

  {value: 'ri-signal-wifi-3-line', label: 'signal-wifi-3'},

  {value: 'ri-signal-wifi-3-line', label: 'signal-wifi-3'},

  {value: 'ri-signal-wifi-error-line', label: 'signal-wifi-error'},

  {value: 'ri-signal-wifi-error-line', label: 'signal-wifi-error'},

  {value: 'ri-signal-wifi-off-line', label: 'signal-wifi-off'},

  {value: 'ri-signal-wifi-off-line', label: 'signal-wifi-off'},

  {value: 'ri-wireless-charging-line', label: 'wireless-charging'},

  {value: 'ri-wireless-charging-line', label: 'wireless-charging'},

  {value: 'ri-dashboard-2-line', label: 'dashboard-2'},

  {value: 'ri-dashboard-2-line', label: 'dashboard-2'},

  {value: 'ri-dashboard-3-line', label: 'dashboard-3'},

  {value: 'ri-dashboard-3-line', label: 'dashboard-3'},

  {value: 'ri-usb-line', label: 'usb'},

  {value: 'ri-usb-line', label: 'usb'},

  {value: 'ri-rfid-line', label: 'rfid'},

  {value: 'ri-rfid-line', label: 'rfid'},

  {value: 'ri-Device166-line', label: 'Device166'},

  {value: 'ri-file-line', label: 'file'},

  {value: 'ri-file-line', label: 'file'},

  {value: 'ri-file-2-line', label: 'file-2'},

  {value: 'ri-file-2-line', label: 'file-2'},

  {value: 'ri-file-3-line', label: 'file-3'},

  {value: 'ri-file-3-line', label: 'file-3'},

  {value: 'ri-file-4-line', label: 'file-4'},

  {value: 'ri-file-4-line', label: 'file-4'},

  {value: 'ri-sticky-note-line', label: 'sticky-note'},

  {value: 'ri-sticky-note-line', label: 'sticky-note'},

  {value: 'ri-sticky-note-2-line', label: 'sticky-note-2'},

  {value: 'ri-sticky-note-2-line', label: 'sticky-note-2'},

  {value: 'ri-file-edit-line', label: 'file-edit'},

  {value: 'ri-file-edit-line', label: 'file-edit'},

  {value: 'ri-draft-line', label: 'draft'},

  {value: 'ri-draft-line', label: 'draft'},

  {value: 'ri-file-paper-line', label: 'file-paper'},

  {value: 'ri-file-paper-line', label: 'file-paper'},

  {value: 'ri-file-paper-2-line', label: 'file-paper-2'},

  {value: 'ri-file-paper-2-line', label: 'file-paper-2'},

  {value: 'ri-file-text-line', label: 'file-text'},

  {value: 'ri-file-text-line', label: 'file-text'},

  {value: 'ri-file-list-line', label: 'file-list'},

  {value: 'ri-file-list-line', label: 'file-list'},

  {value: 'ri-file-list-2-line', label: 'file-list-2'},

  {value: 'ri-file-list-2-line', label: 'file-list-2'},

  {value: 'ri-file-list-3-line', label: 'file-list-3'},

  {value: 'ri-file-list-3-line', label: 'file-list-3'},

  {value: 'ri-bill-line', label: 'bill'},

  {value: 'ri-bill-line', label: 'bill'},

  {value: 'ri-file-copy-line', label: 'file-copy'},

  {value: 'ri-file-copy-line', label: 'file-copy'},

  {value: 'ri-file-copy-2-line', label: 'file-copy-2'},

  {value: 'ri-file-copy-2-line', label: 'file-copy-2'},

  {value: 'ri-clipboard-line', label: 'clipboard'},

  {value: 'ri-clipboard-line', label: 'clipboard'},

  {value: 'ri-survey-line', label: 'survey'},

  {value: 'ri-survey-line', label: 'survey'},

  {value: 'ri-article-line', label: 'article'},

  {value: 'ri-article-line', label: 'article'},

  {value: 'ri-newspaper-line', label: 'newspaper'},

  {value: 'ri-newspaper-line', label: 'newspaper'},

  {value: 'ri-file-zip-line', label: 'file-zip'},

  {value: 'ri-file-zip-line', label: 'file-zip'},

  {value: 'ri-file-mark-line', label: 'file-mark'},

  {value: 'ri-file-mark-line', label: 'file-mark'},

  {value: 'ri-task-line', label: 'task'},

  {value: 'ri-task-line', label: 'task'},

  {value: 'ri-todo-line', label: 'todo'},

  {value: 'ri-todo-line', label: 'todo'},

  {value: 'ri-book-line', label: 'book'},

  {value: 'ri-book-line', label: 'book'},

  {value: 'ri-book-mark-line', label: 'book-mark'},

  {value: 'ri-book-mark-line', label: 'book-mark'},

  {value: 'ri-book-2-line', label: 'book-2'},

  {value: 'ri-book-2-line', label: 'book-2'},

  {value: 'ri-book-3-line', label: 'book-3'},

  {value: 'ri-book-3-line', label: 'book-3'},

  {value: 'ri-book-open-line', label: 'book-open'},

  {value: 'ri-book-open-line', label: 'book-open'},

  {value: 'ri-book-read-line', label: 'book-read'},

  {value: 'ri-book-read-line', label: 'book-read'},

  {value: 'ri-contacts-book-line', label: 'contacts-book'},

  {value: 'ri-contacts-book-line', label: 'contacts-book'},

  {value: 'ri-contacts-book-2-line', label: 'contacts-book-2'},

  {value: 'ri-contacts-book-2-line', label: 'contacts-book-2'},

  {value: 'ri-contacts-book-upload-line', label: 'contacts-book-upload'},

  {value: 'ri-contacts-book-upload-line', label: 'contacts-book-upload'},

  {value: 'ri-booklet-line', label: 'booklet'},

  {value: 'ri-booklet-line', label: 'booklet'},

  {value: 'ri-file-code-line', label: 'file-code'},

  {value: 'ri-file-code-line', label: 'file-code'},

  {value: 'ri-file-pdf-line', label: 'file-pdf'},

  {value: 'ri-file-pdf-line', label: 'file-pdf'},

  {value: 'ri-file-pdf-2-line', label: 'file-pdf-2'},

  {value: 'ri-file-pdf-2-line', label: 'file-pdf-2'},

  {value: 'ri-file-word-line', label: 'file-word'},

  {value: 'ri-file-word-line', label: 'file-word'},

  {value: 'ri-file-ppt-line', label: 'file-ppt'},

  {value: 'ri-file-ppt-line', label: 'file-ppt'},

  {value: 'ri-file-excel-line', label: 'file-excel'},

  {value: 'ri-file-excel-line', label: 'file-excel'},

  {value: 'ri-file-word-2-line', label: 'file-word-2'},

  {value: 'ri-file-word-2-line', label: 'file-word-2'},

  {value: 'ri-file-ppt-2-line', label: 'file-ppt-2'},

  {value: 'ri-file-ppt-2-line', label: 'file-ppt-2'},

  {value: 'ri-file-excel-2-line', label: 'file-excel-2'},

  {value: 'ri-file-excel-2-line', label: 'file-excel-2'},

  {value: 'ri-file-hwp-line', label: 'file-hwp'},

  {value: 'ri-file-hwp-line', label: 'file-hwp'},

  {value: 'ri-keynote-line', label: 'keynote'},

  {value: 'ri-keynote-line', label: 'keynote'},

  {value: 'ri-numbers-line', label: 'numbers'},

  {value: 'ri-numbers-line', label: 'numbers'},

  {value: 'ri-pages-line', label: 'pages'},

  {value: 'ri-pages-line', label: 'pages'},

  {value: 'ri-file-search-line', label: 'file-search'},

  {value: 'ri-file-search-line', label: 'file-search'},

  {value: 'ri-file-add-line', label: 'file-add'},

  {value: 'ri-file-add-line', label: 'file-add'},

  {value: 'ri-file-reduce-line', label: 'file-reduce'},

  {value: 'ri-file-reduce-line', label: 'file-reduce'},

  {value: 'ri-file-settings-line', label: 'file-settings'},

  {value: 'ri-file-settings-line', label: 'file-settings'},

  {value: 'ri-file-upload-line', label: 'file-upload'},

  {value: 'ri-file-upload-line', label: 'file-upload'},

  {value: 'ri-file-transfer-line', label: 'file-transfer'},

  {value: 'ri-file-transfer-line', label: 'file-transfer'},

  {value: 'ri-file-download-line', label: 'file-download'},

  {value: 'ri-file-download-line', label: 'file-download'},

  {value: 'ri-file-lock-line', label: 'file-lock'},

  {value: 'ri-file-lock-line', label: 'file-lock'},

  {value: 'ri-file-chart-line', label: 'file-chart'},

  {value: 'ri-file-chart-line', label: 'file-chart'},

  {value: 'ri-file-chart-2-line', label: 'file-chart-2'},

  {value: 'ri-file-chart-2-line', label: 'file-chart-2'},

  {value: 'ri-file-image-line', label: 'file-image'},

  {value: 'ri-file-image-line', label: 'file-image'},

  {value: 'ri-file-music-line', label: 'file-music'},

  {value: 'ri-file-music-line', label: 'file-music'},

  {value: 'ri-file-video-line', label: 'file-video'},

  {value: 'ri-file-video-line', label: 'file-video'},

  {value: 'ri-file-gif-line', label: 'file-gif'},

  {value: 'ri-file-gif-line', label: 'file-gif'},

  {value: 'ri-file-forbid-line', label: 'file-forbid'},

  {value: 'ri-file-forbid-line', label: 'file-forbid'},

  {value: 'ri-file-close-line', label: 'file-close'},

  {value: 'ri-file-close-line', label: 'file-close'},

  {value: 'ri-file-info-line', label: 'file-info'},

  {value: 'ri-file-info-line', label: 'file-info'},

  {value: 'ri-file-warning-line', label: 'file-warning'},

  {value: 'ri-file-warning-line', label: 'file-warning'},

  {value: 'ri-file-unknow-line', label: 'file-unknow'},

  {value: 'ri-file-unknow-line', label: 'file-unknow'},

  {value: 'ri-file-user-line', label: 'file-user'},

  {value: 'ri-file-user-line', label: 'file-user'},

  {value: 'ri-file-shield-line', label: 'file-shield'},

  {value: 'ri-file-shield-line', label: 'file-shield'},

  {value: 'ri-file-shield-2-line', label: 'file-shield-2'},

  {value: 'ri-file-shield-2-line', label: 'file-shield-2'},

  {value: 'ri-file-damage-line', label: 'file-damage'},

  {value: 'ri-file-damage-line', label: 'file-damage'},

  {value: 'ri-file-history-line', label: 'file-history'},

  {value: 'ri-file-history-line', label: 'file-history'},

  {value: 'ri-file-shred-line', label: 'file-shred'},

  {value: 'ri-file-shred-line', label: 'file-shred'},

  {value: 'ri-file-cloud-line', label: 'file-cloud'},

  {value: 'ri-file-cloud-line', label: 'file-cloud'},

  {value: 'ri-folder-line', label: 'folder'},

  {value: 'ri-folder-line', label: 'folder'},

  {value: 'ri-folder-2-line', label: 'folder-2'},

  {value: 'ri-folder-2-line', label: 'folder-2'},

  {value: 'ri-folder-3-line', label: 'folder-3'},

  {value: 'ri-folder-3-line', label: 'folder-3'},

  {value: 'ri-folder-4-line', label: 'folder-4'},

  {value: 'ri-folder-4-line', label: 'folder-4'},

  {value: 'ri-folder-5-line', label: 'folder-5'},

  {value: 'ri-folder-5-line', label: 'folder-5'},

  {value: 'ri-folders-line', label: 'folders'},

  {value: 'ri-folders-line', label: 'folders'},

  {value: 'ri-folder-add-line', label: 'folder-add'},

  {value: 'ri-folder-add-line', label: 'folder-add'},

  {value: 'ri-folder-reduce-line', label: 'folder-reduce'},

  {value: 'ri-folder-reduce-line', label: 'folder-reduce'},

  {value: 'ri-folder-settings-line', label: 'folder-settings'},

  {value: 'ri-folder-settings-line', label: 'folder-settings'},

  {value: 'ri-folder-upload-line', label: 'folder-upload'},

  {value: 'ri-folder-upload-line', label: 'folder-upload'},

  {value: 'ri-folder-transfer-line', label: 'folder-transfer'},

  {value: 'ri-folder-transfer-line', label: 'folder-transfer'},

  {value: 'ri-folder-download-line', label: 'folder-download'},

  {value: 'ri-folder-download-line', label: 'folder-download'},

  {value: 'ri-folder-lock-line', label: 'folder-lock'},

  {value: 'ri-folder-lock-line', label: 'folder-lock'},

  {value: 'ri-folder-chart-line', label: 'folder-chart'},

  {value: 'ri-folder-chart-line', label: 'folder-chart'},

  {value: 'ri-folder-chart-2-line', label: 'folder-chart-2'},

  {value: 'ri-folder-chart-2-line', label: 'folder-chart-2'},

  {value: 'ri-folder-music-line', label: 'folder-music'},

  {value: 'ri-folder-music-line', label: 'folder-music'},

  {value: 'ri-folder-image-line', label: 'folder-image'},

  {value: 'ri-folder-image-line', label: 'folder-image'},

  {value: 'ri-folder-video-line', label: 'folder-video'},

  {value: 'ri-folder-video-line', label: 'folder-video'},

  {value: 'ri-folder-forbid-line', label: 'folder-forbid'},

  {value: 'ri-folder-forbid-line', label: 'folder-forbid'},

  {value: 'ri-folder-info-line', label: 'folder-info'},

  {value: 'ri-folder-info-line', label: 'folder-info'},

  {value: 'ri-folder-warning-line', label: 'folder-warning'},

  {value: 'ri-folder-warning-line', label: 'folder-warning'},

  {value: 'ri-folder-unknow-line', label: 'folder-unknow'},

  {value: 'ri-folder-unknow-line', label: 'folder-unknow'},

  {value: 'ri-folder-user-line', label: 'folder-user'},

  {value: 'ri-folder-user-line', label: 'folder-user'},

  {value: 'ri-folder-shield-line', label: 'folder-shield'},

  {value: 'ri-folder-shield-line', label: 'folder-shield'},

  {value: 'ri-folder-shield-2-line', label: 'folder-shield-2'},

  {value: 'ri-folder-shield-2-line', label: 'folder-shield-2'},

  {value: 'ri-folder-shared-line', label: 'folder-shared'},

  {value: 'ri-folder-shared-line', label: 'folder-shared'},

  {value: 'ri-folder-received-line', label: 'folder-received'},

  {value: 'ri-folder-received-line', label: 'folder-received'},

  {value: 'ri-folder-open-line', label: 'folder-open'},

  {value: 'ri-folder-open-line', label: 'folder-open'},

  {value: 'ri-folder-keyhole-line', label: 'folder-keyhole'},

  {value: 'ri-folder-keyhole-line', label: 'folder-keyhole'},

  {value: 'ri-folder-zip-line', label: 'folder-zip'},

  {value: 'ri-folder-zip-line', label: 'folder-zip'},

  {value: 'ri-folder-history-line', label: 'folder-history'},

  {value: 'ri-folder-history-line', label: 'folder-history'},

  {value: 'ri-markdown-line', label: 'markdown'},

  {value: 'ri-markdown-line', label: 'markdown'},

  {value: 'ri-Document212-line', label: 'Document212'},

  {value: 'ri-bold-line', label: 'bold'},

  {value: 'ri-italic-line', label: 'italic'},

  {value: 'ri-heading-line', label: 'heading'},

  {value: 'ri-text-line', label: 'text'},

  {value: 'ri-font-family-line', label: 'font-family'},

  {value: 'ri-font-sans-serif-line', label: 'font-sans-serif'},

  {value: 'ri-font-sans-line', label: 'font-sans'},

  {value: 'ri-font-mono-line', label: 'font-mono'},

  {value: 'ri-font-color-line', label: 'font-color'},

  {value: 'ri-font-size-line', label: 'font-size'},

  {value: 'ri-font-size-2-line', label: 'font-size-2'},

  {value: 'ri-underline-line', label: 'underline'},

  {value: 'ri-overline-line', label: 'overline'},

  {value: 'ri-emphasis-line', label: 'emphasis'},

  {value: 'ri-emphasis-cn-line', label: 'emphasis-cn'},

  {value: 'ri-strikethrough-line', label: 'strikethrough'},

  {value: 'ri-strikethrough-2-line', label: 'strikethrough-2'},

  {value: 'ri-format-clear-line', label: 'format-clear'},

  {value: 'ri-align-left-line', label: 'align-left'},

  {value: 'ri-align-center-line', label: 'align-center'},

  {value: 'ri-align-right-line', label: 'align-right'},

  {value: 'ri-align-justify-line', label: 'align-justify'},

  {value: 'ri-align-top-line', label: 'align-top'},

  {value: 'ri-align-vertically-line', label: 'align-vertically'},

  {value: 'ri-align-bottom-line', label: 'align-bottom'},

  {value: 'ri-list-check-line', label: 'list-check'},

  {value: 'ri-list-check-2-line', label: 'list-check-2'},

  {value: 'ri-list-check-3-line', label: 'list-check-3'},

  {value: 'ri-list-ordered-line', label: 'list-ordered'},

  {value: 'ri-list-ordered-2-line', label: 'list-ordered-2'},

  {value: 'ri-list-unordered-line', label: 'list-unordered'},

  {value: 'ri-list-radio-line', label: 'list-radio'},

  {value: 'ri-list-indefinite-line', label: 'list-indefinite'},

  {value: 'ri-indent-decrease-line', label: 'indent-decrease'},

  {value: 'ri-indent-increase-line', label: 'indent-increase'},

  {value: 'ri-line-height-line', label: 'line-height'},

  {value: 'ri-text-spacing-line', label: 'text-spacing'},

  {value: 'ri-text-wrap-line', label: 'text-wrap'},

  {value: 'ri-attachment-2-line', label: 'attachment-2'},

  {value: 'ri-link-line', label: 'link'},

  {value: 'ri-link-unlink-line', label: 'link-unlink'},

  {value: 'ri-link-m-line', label: 'link-m'},

  {value: 'ri-link-unlink-m-line', label: 'link-unlink-m'},

  {value: 'ri-separator-line', label: 'separator'},

  {value: 'ri-space-line', label: 'space'},

  {value: 'ri-page-separator-line', label: 'page-separator'},

  {value: 'ri-code-view-line', label: 'code-view'},

  {value: 'ri-quote-text-line', label: 'quote-text'},

  {value: 'ri-double-quotes-l-line', label: 'double-quotes-l'},

  {value: 'ri-double-quotes-r-line', label: 'double-quotes-r'},

  {value: 'ri-single-quotes-l-line', label: 'single-quotes-l'},

  {value: 'ri-single-quotes-r-line', label: 'single-quotes-r'},

  {value: 'ri-table-2-line', label: 'table-2'},

  {value: 'ri-subscript-line', label: 'subscript'},

  {value: 'ri-subscript-2-line', label: 'subscript-2'},

  {value: 'ri-superscript-line', label: 'superscript'},

  {value: 'ri-superscript-2-line', label: 'superscript-2'},

  {value: 'ri-paragraph-line', label: 'paragraph'},

  {value: 'ri-text-direction-l-line', label: 'text-direction-l'},

  {value: 'ri-text-direction-r-line', label: 'text-direction-r'},

  {value: 'ri-functions-line', label: 'functions'},

  {value: 'ri-omega-line', label: 'omega'},

  {value: 'ri-hashtag-line', label: 'hashtag'},

  {value: 'ri-asterisk-line', label: 'asterisk'},

  {value: 'ri-question-mark-line', label: 'question-mark'},

  {value: 'ri-translate-line', label: 'translate'},

  {value: 'ri-translate-2-line', label: 'translate-2'},

  {value: 'ri-a-b-line', label: 'a-b'},

  {value: 'ri-english-input-line', label: 'english-input'},

  {value: 'ri-pinyin-input-line', label: 'pinyin-input'},

  {value: 'ri-wubi-input-line', label: 'wubi-input'},

  {value: 'ri-input-cursor-move-line', label: 'input-cursor-move'},

  {value: 'ri-number-1-line', label: 'number-1'},

  {value: 'ri-number-2-line', label: 'number-2'},

  {value: 'ri-number-3-line', label: 'number-3'},

  {value: 'ri-number-4-line', label: 'number-4'},

  {value: 'ri-number-5-line', label: 'number-5'},

  {value: 'ri-number-6-line', label: 'number-6'},

  {value: 'ri-number-7-line', label: 'number-7'},

  {value: 'ri-number-8-line', label: 'number-8'},

  {value: 'ri-number-9-line', label: 'number-9'},

  {value: 'ri-number-0-line', label: 'number-0'},

  {value: 'ri-sort-asc-line', label: 'sort-asc'},

  {value: 'ri-sort-desc-line', label: 'sort-desc'},

  {value: 'ri-bring-forward-line', label: 'bring-forward'},

  {value: 'ri-send-backward-line', label: 'send-backward'},

  {value: 'ri-bring-to-front-line', label: 'bring-to-front'},

  {value: 'ri-send-to-back-line', label: 'send-to-back'},

  {value: 'ri-h-1-line', label: 'h-1'},

  {value: 'ri-h-2-line', label: 'h-2'},

  {value: 'ri-h-3-line', label: 'h-3'},

  {value: 'ri-h-4-line', label: 'h-4'},

  {value: 'ri-h-5-line', label: 'h-5'},

  {value: 'ri-h-6-line', label: 'h-6'},

  {value: 'ri-insert-column-left-line', label: 'insert-column-left'},

  {value: 'ri-insert-column-right-line', label: 'insert-column-right'},

  {value: 'ri-insert-row-top-line', label: 'insert-row-top'},

  {value: 'ri-insert-row-bottom-line', label: 'insert-row-bottom'},

  {value: 'ri-delete-column-line', label: 'delete-column'},

  {value: 'ri-delete-row-line', label: 'delete-row'},

  {value: 'ri-merge-cells-horizontal-line', label: 'merge-cells-horizontal'},

  {value: 'ri-merge-cells-vertical-line', label: 'merge-cells-vertical'},

  {value: 'ri-split-cells-horizontal-line', label: 'split-cells-horizontal'},

  {value: 'ri-split-cells-vertical-line', label: 'split-cells-vertical'},

  {value: 'ri-flow-chart-line', label: 'flow-chart'},

  {value: 'ri-mind-map-line', label: 'mind-map'},

  {value: 'ri-node-tree-line', label: 'node-tree'},

  {value: 'ri-organization-chart-line', label: 'organization-chart'},

  {value: 'ri-rounded-corner-line', label: 'rounded-corner'},

  {value: 'ri-sketching-line', label: 'sketching'},

  {value: 'ri-draggable-line', label: 'draggable'},

  {value: 'ri-slash-commands-line', label: 'slash-commands'},

  {value: 'ri-slash-commands-2-line', label: 'slash-commands-2'},

  {value: 'ri-ai-generate-line', label: 'ai-generate'},

  {value: 'ri-dropdown-list-line', label: 'dropdown-list'},

  {value: 'ri-Editor115-line', label: 'Editor115'},

  {value: 'ri-wallet-line', label: 'wallet'},

  {value: 'ri-wallet-line', label: 'wallet'},

  {value: 'ri-wallet-2-line', label: 'wallet-2'},

  {value: 'ri-wallet-2-line', label: 'wallet-2'},

  {value: 'ri-wallet-3-line', label: 'wallet-3'},

  {value: 'ri-wallet-3-line', label: 'wallet-3'},

  {value: 'ri-bank-card-line', label: 'bank-card'},

  {value: 'ri-bank-card-line', label: 'bank-card'},

  {value: 'ri-bank-card-2-line', label: 'bank-card-2'},

  {value: 'ri-bank-card-2-line', label: 'bank-card-2'},

  {value: 'ri-secure-payment-line', label: 'secure-payment'},

  {value: 'ri-secure-payment-line', label: 'secure-payment'},

  {value: 'ri-refund-line', label: 'refund'},

  {value: 'ri-refund-line', label: 'refund'},

  {value: 'ri-refund-2-line', label: 'refund-2'},

  {value: 'ri-refund-2-line', label: 'refund-2'},

  {value: 'ri-safe-line', label: 'safe'},

  {value: 'ri-safe-line', label: 'safe'},

  {value: 'ri-safe-2-line', label: 'safe-2'},

  {value: 'ri-safe-2-line', label: 'safe-2'},

  {value: 'ri-price-tag-line', label: 'price-tag'},

  {value: 'ri-price-tag-line', label: 'price-tag'},

  {value: 'ri-price-tag-2-line', label: 'price-tag-2'},

  {value: 'ri-price-tag-2-line', label: 'price-tag-2'},

  {value: 'ri-price-tag-3-line', label: 'price-tag-3'},

  {value: 'ri-price-tag-3-line', label: 'price-tag-3'},

  {value: 'ri-ticket-line', label: 'ticket'},

  {value: 'ri-ticket-line', label: 'ticket'},

  {value: 'ri-ticket-2-line', label: 'ticket-2'},

  {value: 'ri-ticket-2-line', label: 'ticket-2'},

  {value: 'ri-coupon-line', label: 'coupon'},

  {value: 'ri-coupon-line', label: 'coupon'},

  {value: 'ri-coupon-2-line', label: 'coupon-2'},

  {value: 'ri-coupon-2-line', label: 'coupon-2'},

  {value: 'ri-coupon-3-line', label: 'coupon-3'},

  {value: 'ri-coupon-3-line', label: 'coupon-3'},

  {value: 'ri-coupon-4-line', label: 'coupon-4'},

  {value: 'ri-coupon-4-line', label: 'coupon-4'},

  {value: 'ri-coupon-5-line', label: 'coupon-5'},

  {value: 'ri-coupon-5-line', label: 'coupon-5'},

  {value: 'ri-shopping-bag-line', label: 'shopping-bag'},

  {value: 'ri-shopping-bag-line', label: 'shopping-bag'},

  {value: 'ri-shopping-bag-2-line', label: 'shopping-bag-2'},

  {value: 'ri-shopping-bag-2-line', label: 'shopping-bag-2'},

  {value: 'ri-shopping-bag-3-line', label: 'shopping-bag-3'},

  {value: 'ri-shopping-bag-3-line', label: 'shopping-bag-3'},

  {value: 'ri-shopping-basket-line', label: 'shopping-basket'},

  {value: 'ri-shopping-basket-line', label: 'shopping-basket'},

  {value: 'ri-shopping-basket-2-line', label: 'shopping-basket-2'},

  {value: 'ri-shopping-basket-2-line', label: 'shopping-basket-2'},

  {value: 'ri-shopping-cart-line', label: 'shopping-cart'},

  {value: 'ri-shopping-cart-line', label: 'shopping-cart'},

  {value: 'ri-shopping-cart-2-line', label: 'shopping-cart-2'},

  {value: 'ri-shopping-cart-2-line', label: 'shopping-cart-2'},

  {value: 'ri-vip-line', label: 'vip'},

  {value: 'ri-vip-line', label: 'vip'},

  {value: 'ri-vip-crown-line', label: 'vip-crown'},

  {value: 'ri-vip-crown-line', label: 'vip-crown'},

  {value: 'ri-vip-crown-2-line', label: 'vip-crown-2'},

  {value: 'ri-vip-crown-2-line', label: 'vip-crown-2'},

  {value: 'ri-vip-diamond-line', label: 'vip-diamond'},

  {value: 'ri-vip-diamond-line', label: 'vip-diamond'},

  {value: 'ri-trophy-line', label: 'trophy'},

  {value: 'ri-trophy-line', label: 'trophy'},

  {value: 'ri-exchange-line', label: 'exchange'},

  {value: 'ri-exchange-line', label: 'exchange'},

  {value: 'ri-exchange-box-line', label: 'exchange-box'},

  {value: 'ri-exchange-box-line', label: 'exchange-box'},

  {value: 'ri-swap-line', label: 'swap'},

  {value: 'ri-swap-line', label: 'swap'},

  {value: 'ri-swap-box-line', label: 'swap-box'},

  {value: 'ri-swap-box-line', label: 'swap-box'},

  {value: 'ri-exchange-dollar-line', label: 'exchange-dollar'},

  {value: 'ri-exchange-dollar-line', label: 'exchange-dollar'},

  {value: 'ri-exchange-cny-line', label: 'exchange-cny'},

  {value: 'ri-exchange-cny-line', label: 'exchange-cny'},

  {value: 'ri-exchange-funds-line', label: 'exchange-funds'},

  {value: 'ri-exchange-funds-line', label: 'exchange-funds'},

  {value: 'ri-increase-decrease-line', label: 'increase-decrease'},

  {value: 'ri-increase-decrease-line', label: 'increase-decrease'},

  {value: 'ri-percent-line', label: 'percent'},

  {value: 'ri-percent-line', label: 'percent'},

  {value: 'ri-copper-coin-line', label: 'copper-coin'},

  {value: 'ri-copper-coin-line', label: 'copper-coin'},

  {value: 'ri-copper-diamond-line', label: 'copper-diamond'},

  {value: 'ri-copper-diamond-line', label: 'copper-diamond'},

  {value: 'ri-money-cny-box-line', label: 'money-cny-box'},

  {value: 'ri-money-cny-box-line', label: 'money-cny-box'},

  {value: 'ri-money-cny-circle-line', label: 'money-cny-circle'},

  {value: 'ri-money-cny-circle-line', label: 'money-cny-circle'},

  {value: 'ri-money-dollar-box-line', label: 'money-dollar-box'},

  {value: 'ri-money-dollar-box-line', label: 'money-dollar-box'},

  {value: 'ri-money-dollar-circle-line', label: 'money-dollar-circle'},

  {value: 'ri-money-dollar-circle-line', label: 'money-dollar-circle'},

  {value: 'ri-money-euro-box-line', label: 'money-euro-box'},

  {value: 'ri-money-euro-box-line', label: 'money-euro-box'},

  {value: 'ri-money-euro-circle-line', label: 'money-euro-circle'},

  {value: 'ri-money-euro-circle-line', label: 'money-euro-circle'},

  {value: 'ri-money-pound-box-line', label: 'money-pound-box'},

  {value: 'ri-money-pound-box-line', label: 'money-pound-box'},

  {value: 'ri-money-pound-circle-line', label: 'money-pound-circle'},

  {value: 'ri-money-pound-circle-line', label: 'money-pound-circle'},

  {value: 'ri-bit-coin-line', label: 'bit-coin'},

  {value: 'ri-bit-coin-line', label: 'bit-coin'},

  {value: 'ri-coin-line', label: 'coin'},

  {value: 'ri-coin-line', label: 'coin'},

  {value: 'ri-cash-line', label: 'cash'},

  {value: 'ri-cash-line', label: 'cash'},

  {value: 'ri-coins-line', label: 'coins'},

  {value: 'ri-coins-line', label: 'coins'},

  {value: 'ri-currency-line', label: 'currency'},

  {value: 'ri-currency-line', label: 'currency'},

  {value: 'ri-funds-line', label: 'funds'},

  {value: 'ri-funds-line', label: 'funds'},

  {value: 'ri-funds-box-line', label: 'funds-box'},

  {value: 'ri-funds-box-line', label: 'funds-box'},

  {value: 'ri-red-packet-line', label: 'red-packet'},

  {value: 'ri-red-packet-line', label: 'red-packet'},

  {value: 'ri-water-flash-line', label: 'water-flash'},

  {value: 'ri-water-flash-line', label: 'water-flash'},

  {value: 'ri-stock-line', label: 'stock'},

  {value: 'ri-stock-line', label: 'stock'},

  {value: 'ri-auction-line', label: 'auction'},

  {value: 'ri-auction-line', label: 'auction'},

  {value: 'ri-gift-line', label: 'gift'},

  {value: 'ri-gift-line', label: 'gift'},

  {value: 'ri-gift-2-line', label: 'gift-2'},

  {value: 'ri-gift-2-line', label: 'gift-2'},

  {value: 'ri-hand-coin-line', label: 'hand-coin'},

  {value: 'ri-hand-coin-line', label: 'hand-coin'},

  {value: 'ri-hand-heart-line', label: 'hand-heart'},

  {value: 'ri-hand-heart-line', label: 'hand-heart'},

  {value: 'ri-24-hours-line', label: '24-hours'},

  {value: 'ri-24-hours-line', label: '24-hours'},

  {value: 'ri-nft-line', label: 'nft'},

  {value: 'ri-nft-line', label: 'nft'},

  {value: 'ri-p2p-line', label: 'p2p'},

  {value: 'ri-p2p-line', label: 'p2p'},

  {value: 'ri-token-swap-line', label: 'token-swap'},

  {value: 'ri-token-swap-line', label: 'token-swap'},

  {value: 'ri-Finance140-line', label: 'Finance140'},

  {value: 'ri-heart-line', label: 'heart'},

  {value: 'ri-heart-line', label: 'heart'},

  {value: 'ri-heart-2-line', label: 'heart-2'},

  {value: 'ri-heart-2-line', label: 'heart-2'},

  {value: 'ri-heart-3-line', label: 'heart-3'},

  {value: 'ri-heart-3-line', label: 'heart-3'},

  {value: 'ri-heart-add-line', label: 'heart-add'},

  {value: 'ri-heart-add-line', label: 'heart-add'},

  {value: 'ri-dislike-line', label: 'dislike'},

  {value: 'ri-dislike-line', label: 'dislike'},

  {value: 'ri-hearts-line', label: 'hearts'},

  {value: 'ri-hearts-line', label: 'hearts'},

  {value: 'ri-heart-pulse-line', label: 'heart-pulse'},

  {value: 'ri-heart-pulse-line', label: 'heart-pulse'},

  {value: 'ri-pulse-line', label: 'pulse'},

  {value: 'ri-pulse-line', label: 'pulse'},

  {value: 'ri-empathize-line', label: 'empathize'},

  {value: 'ri-empathize-line', label: 'empathize'},

  {value: 'ri-nurse-line', label: 'nurse'},

  {value: 'ri-nurse-line', label: 'nurse'},

  {value: 'ri-dossier-line', label: 'dossier'},

  {value: 'ri-dossier-line', label: 'dossier'},

  {value: 'ri-health-book-line', label: 'health-book'},

  {value: 'ri-health-book-line', label: 'health-book'},

  {value: 'ri-first-aid-kit-line', label: 'first-aid-kit'},

  {value: 'ri-first-aid-kit-line', label: 'first-aid-kit'},

  {value: 'ri-capsule-line', label: 'capsule'},

  {value: 'ri-capsule-line', label: 'capsule'},

  {value: 'ri-medicine-bottle-line', label: 'medicine-bottle'},

  {value: 'ri-medicine-bottle-line', label: 'medicine-bottle'},

  {value: 'ri-flask-line', label: 'flask'},

  {value: 'ri-flask-line', label: 'flask'},

  {value: 'ri-test-tube-line', label: 'test-tube'},

  {value: 'ri-test-tube-line', label: 'test-tube'},

  {value: 'ri-microscope-line', label: 'microscope'},

  {value: 'ri-microscope-line', label: 'microscope'},

  {value: 'ri-hand-sanitizer-line', label: 'hand-sanitizer'},

  {value: 'ri-hand-sanitizer-line', label: 'hand-sanitizer'},

  {value: 'ri-mental-health-line', label: 'mental-health'},

  {value: 'ri-mental-health-line', label: 'mental-health'},

  {value: 'ri-psychotherapy-line', label: 'psychotherapy'},

  {value: 'ri-psychotherapy-line', label: 'psychotherapy'},

  {value: 'ri-stethoscope-line', label: 'stethoscope'},

  {value: 'ri-stethoscope-line', label: 'stethoscope'},

  {value: 'ri-syringe-line', label: 'syringe'},

  {value: 'ri-syringe-line', label: 'syringe'},

  {value: 'ri-thermometer-line', label: 'thermometer'},

  {value: 'ri-thermometer-line', label: 'thermometer'},

  {value: 'ri-infrared-thermometer-line', label: 'infrared-thermometer'},

  {value: 'ri-infrared-thermometer-line', label: 'infrared-thermometer'},

  {value: 'ri-surgical-mask-line', label: 'surgical-mask'},

  {value: 'ri-surgical-mask-line', label: 'surgical-mask'},

  {value: 'ri-virus-line', label: 'virus'},

  {value: 'ri-virus-line', label: 'virus'},

  {value: 'ri-lungs-line', label: 'lungs'},

  {value: 'ri-lungs-line', label: 'lungs'},

  {value: 'ri-rest-time-line', label: 'rest-time'},

  {value: 'ri-rest-time-line', label: 'rest-time'},

  {value: 'ri-zzz-line', label: 'zzz'},

  {value: 'ri-zzz-line', label: 'zzz'},

  {value: 'ri-brain-line', label: 'brain'},

  {value: 'ri-brain-line', label: 'brain'},

  {value: 'ri-Health & Medical62-line', label: 'Health & Medical62'},

  {value: 'ri-alipay-line', label: 'alipay'},

  {value: 'ri-alipay-line', label: 'alipay'},

  {value: 'ri-amazon-line', label: 'amazon'},

  {value: 'ri-amazon-line', label: 'amazon'},

  {value: 'ri-android-line', label: 'android'},

  {value: 'ri-android-line', label: 'android'},

  {value: 'ri-angularjs-line', label: 'angularjs'},

  {value: 'ri-angularjs-line', label: 'angularjs'},

  {value: 'ri-app-store-line', label: 'app-store'},

  {value: 'ri-app-store-line', label: 'app-store'},

  {value: 'ri-apple-line', label: 'apple'},

  {value: 'ri-apple-line', label: 'apple'},

  {value: 'ri-baidu-line', label: 'baidu'},

  {value: 'ri-baidu-line', label: 'baidu'},

  {value: 'ri-bard-line', label: 'bard'},

  {value: 'ri-bard-line', label: 'bard'},

  {value: 'ri-behance-line', label: 'behance'},

  {value: 'ri-behance-line', label: 'behance'},

  {value: 'ri-bilibili-line', label: 'bilibili'},

  {value: 'ri-bilibili-line', label: 'bilibili'},

  {value: 'ri-blender-line', label: 'blender'},

  {value: 'ri-blender-line', label: 'blender'},

  {value: 'ri-bootstrap-line', label: 'bootstrap'},

  {value: 'ri-bootstrap-line', label: 'bootstrap'},

  {value: 'ri-centos-line', label: 'centos'},

  {value: 'ri-centos-line', label: 'centos'},

  {value: 'ri-chrome-line', label: 'chrome'},

  {value: 'ri-chrome-line', label: 'chrome'},

  {value: 'ri-codepen-line', label: 'codepen'},

  {value: 'ri-codepen-line', label: 'codepen'},

  {value: 'ri-copilot-line', label: 'copilot'},

  {value: 'ri-copilot-line', label: 'copilot'},

  {value: 'ri-coreos-line', label: 'coreos'},

  {value: 'ri-coreos-line', label: 'coreos'},

  {value: 'ri-dingding-line', label: 'dingding'},

  {value: 'ri-dingding-line', label: 'dingding'},

  {value: 'ri-discord-line', label: 'discord'},

  {value: 'ri-discord-line', label: 'discord'},

  {value: 'ri-disqus-line', label: 'disqus'},

  {value: 'ri-disqus-line', label: 'disqus'},

  {value: 'ri-douban-line', label: 'douban'},

  {value: 'ri-douban-line', label: 'douban'},

  {value: 'ri-dribbble-line', label: 'dribbble'},

  {value: 'ri-dribbble-line', label: 'dribbble'},

  {value: 'ri-drive-line', label: 'drive'},

  {value: 'ri-drive-line', label: 'drive'},

  {value: 'ri-dropbox-line', label: 'dropbox'},

  {value: 'ri-dropbox-line', label: 'dropbox'},

  {value: 'ri-edge-line', label: 'edge'},

  {value: 'ri-edge-line', label: 'edge'},

  {value: 'ri-edge-new-line', label: 'edge-new'},

  {value: 'ri-edge-new-line', label: 'edge-new'},

  {value: 'ri-evernote-line', label: 'evernote'},

  {value: 'ri-evernote-line', label: 'evernote'},

  {value: 'ri-facebook-line', label: 'facebook'},

  {value: 'ri-facebook-line', label: 'facebook'},

  {value: 'ri-facebook-circle-line', label: 'facebook-circle'},

  {value: 'ri-facebook-circle-line', label: 'facebook-circle'},

  {value: 'ri-facebook-box-line', label: 'facebook-box'},

  {value: 'ri-facebook-box-line', label: 'facebook-box'},

  {value: 'ri-finder-line', label: 'finder'},

  {value: 'ri-finder-line', label: 'finder'},

  {value: 'ri-firefox-line', label: 'firefox'},

  {value: 'ri-firefox-line', label: 'firefox'},

  {value: 'ri-flickr-line', label: 'flickr'},

  {value: 'ri-flickr-line', label: 'flickr'},

  {value: 'ri-flutter-line', label: 'flutter'},

  {value: 'ri-flutter-line', label: 'flutter'},

  {value: 'ri-gatsby-line', label: 'gatsby'},

  {value: 'ri-gatsby-line', label: 'gatsby'},

  {value: 'ri-github-line', label: 'github'},

  {value: 'ri-github-line', label: 'github'},

  {value: 'ri-gitlab-line', label: 'gitlab'},

  {value: 'ri-gitlab-line', label: 'gitlab'},

  {value: 'ri-google-line', label: 'google'},

  {value: 'ri-google-line', label: 'google'},

  {value: 'ri-google-play-line', label: 'google-play'},

  {value: 'ri-google-play-line', label: 'google-play'},

  {value: 'ri-honor-of-kings-line', label: 'honor-of-kings'},

  {value: 'ri-honor-of-kings-line', label: 'honor-of-kings'},

  {value: 'ri-ie-line', label: 'ie'},

  {value: 'ri-ie-line', label: 'ie'},

  {value: 'ri-instagram-line', label: 'instagram'},

  {value: 'ri-instagram-line', label: 'instagram'},

  {value: 'ri-invision-line', label: 'invision'},

  {value: 'ri-invision-line', label: 'invision'},

  {value: 'ri-kakao-talk-line', label: 'kakao-talk'},

  {value: 'ri-kakao-talk-line', label: 'kakao-talk'},

  {value: 'ri-line-line', label: 'line'},

  {value: 'ri-line-line', label: 'line'},

  {value: 'ri-linkedin-line', label: 'linkedin'},

  {value: 'ri-linkedin-line', label: 'linkedin'},

  {value: 'ri-linkedin-box-line', label: 'linkedin-box'},

  {value: 'ri-linkedin-box-line', label: 'linkedin-box'},

  {value: 'ri-kick-line', label: 'kick'},

  {value: 'ri-kick-line', label: 'kick'},

  {value: 'ri-microsoft-loop-line', label: 'microsoft-loop'},

  {value: 'ri-microsoft-loop-line', label: 'microsoft-loop'},

  {value: 'ri-mastercard-line', label: 'mastercard'},

  {value: 'ri-mastercard-line', label: 'mastercard'},

  {value: 'ri-mastodon-line', label: 'mastodon'},

  {value: 'ri-mastodon-line', label: 'mastodon'},

  {value: 'ri-medium-line', label: 'medium'},

  {value: 'ri-medium-line', label: 'medium'},

  {value: 'ri-messenger-line', label: 'messenger'},

  {value: 'ri-messenger-line', label: 'messenger'},

  {value: 'ri-meta-line', label: 'meta'},

  {value: 'ri-meta-line', label: 'meta'},

  {value: 'ri-microsoft-line', label: 'microsoft'},

  {value: 'ri-microsoft-line', label: 'microsoft'},

  {value: 'ri-mini-program-line', label: 'mini-program'},

  {value: 'ri-mini-program-line', label: 'mini-program'},

  {value: 'ri-netease-cloud-music-line', label: 'netease-cloud-music'},

  {value: 'ri-netease-cloud-music-line', label: 'netease-cloud-music'},

  {value: 'ri-netflix-line', label: 'netflix'},

  {value: 'ri-netflix-line', label: 'netflix'},

  {value: 'ri-notion-line', label: 'notion'},

  {value: 'ri-notion-line', label: 'notion'},

  {value: 'ri-npmjs-line', label: 'npmjs'},

  {value: 'ri-npmjs-line', label: 'npmjs'},

  {value: 'ri-openai-line', label: 'openai'},

  {value: 'ri-openai-line', label: 'openai'},

  {value: 'ri-openbase-line', label: 'openbase'},

  {value: 'ri-openbase-line', label: 'openbase'},

  {value: 'ri-open-source-line', label: 'open-source'},

  {value: 'ri-open-source-line', label: 'open-source'},

  {value: 'ri-opera-line', label: 'opera'},

  {value: 'ri-opera-line', label: 'opera'},

  {value: 'ri-patreon-line', label: 'patreon'},

  {value: 'ri-patreon-line', label: 'patreon'},

  {value: 'ri-paypal-line', label: 'paypal'},

  {value: 'ri-paypal-line', label: 'paypal'},

  {value: 'ri-pinterest-line', label: 'pinterest'},

  {value: 'ri-pinterest-line', label: 'pinterest'},

  {value: 'ri-pixelfed-line', label: 'pixelfed'},

  {value: 'ri-pixelfed-line', label: 'pixelfed'},

  {value: 'ri-playstation-line', label: 'playstation'},

  {value: 'ri-playstation-line', label: 'playstation'},

  {value: 'ri-product-hunt-line', label: 'product-hunt'},

  {value: 'ri-product-hunt-line', label: 'product-hunt'},

  {value: 'ri-qq-line', label: 'qq'},

  {value: 'ri-qq-line', label: 'qq'},

  {value: 'ri-reactjs-line', label: 'reactjs'},

  {value: 'ri-reactjs-line', label: 'reactjs'},

  {value: 'ri-reddit-line', label: 'reddit'},

  {value: 'ri-reddit-line', label: 'reddit'},

  {value: 'ri-remixicon-line', label: 'remixicon'},

  {value: 'ri-remixicon-line', label: 'remixicon'},

  {value: 'ri-safari-line', label: 'safari'},

  {value: 'ri-safari-line', label: 'safari'},

  {value: 'ri-skype-line', label: 'skype'},

  {value: 'ri-skype-line', label: 'skype'},

  {value: 'ri-slack-line', label: 'slack'},

  {value: 'ri-slack-line', label: 'slack'},

  {value: 'ri-snapchat-line', label: 'snapchat'},

  {value: 'ri-snapchat-line', label: 'snapchat'},

  {value: 'ri-soundcloud-line', label: 'soundcloud'},

  {value: 'ri-soundcloud-line', label: 'soundcloud'},

  {value: 'ri-spectrum-line', label: 'spectrum'},

  {value: 'ri-spectrum-line', label: 'spectrum'},

  {value: 'ri-spotify-line', label: 'spotify'},

  {value: 'ri-spotify-line', label: 'spotify'},

  {value: 'ri-stack-overflow-line', label: 'stack-overflow'},

  {value: 'ri-stack-overflow-line', label: 'stack-overflow'},

  {value: 'ri-stackshare-line', label: 'stackshare'},

  {value: 'ri-stackshare-line', label: 'stackshare'},

  {value: 'ri-steam-line', label: 'steam'},

  {value: 'ri-steam-line', label: 'steam'},

  {value: 'ri-supabase-line', label: 'supabase'},

  {value: 'ri-supabase-line', label: 'supabase'},

  {value: 'ri-switch-line', label: 'switch'},

  {value: 'ri-switch-line', label: 'switch'},

  {value: 'ri-taobao-line', label: 'taobao'},

  {value: 'ri-taobao-line', label: 'taobao'},

  {value: 'ri-telegram-line', label: 'telegram'},

  {value: 'ri-telegram-line', label: 'telegram'},

  {value: 'ri-threads-line', label: 'threads'},

  {value: 'ri-threads-line', label: 'threads'},

  {value: 'ri-tiktok-line', label: 'tiktok'},

  {value: 'ri-tiktok-line', label: 'tiktok'},

  {value: 'ri-trello-line', label: 'trello'},

  {value: 'ri-trello-line', label: 'trello'},

  {value: 'ri-tumblr-line', label: 'tumblr'},

  {value: 'ri-tumblr-line', label: 'tumblr'},

  {value: 'ri-twitch-line', label: 'twitch'},

  {value: 'ri-twitch-line', label: 'twitch'},

  {value: 'ri-twitter-line', label: 'twitter'},

  {value: 'ri-twitter-line', label: 'twitter'},

  {value: 'ri-twitter-x-line', label: 'twitter-x'},

  {value: 'ri-twitter-x-line', label: 'twitter-x'},

  {value: 'ri-ubuntu-line', label: 'ubuntu'},

  {value: 'ri-ubuntu-line', label: 'ubuntu'},

  {value: 'ri-unsplash-line', label: 'unsplash'},

  {value: 'ri-unsplash-line', label: 'unsplash'},

  {value: 'ri-vimeo-line', label: 'vimeo'},

  {value: 'ri-vimeo-line', label: 'vimeo'},

  {value: 'ri-visa-line', label: 'visa'},

  {value: 'ri-visa-line', label: 'visa'},

  {value: 'ri-vuejs-line', label: 'vuejs'},

  {value: 'ri-vuejs-line', label: 'vuejs'},

  {value: 'ri-wechat-line', label: 'wechat'},

  {value: 'ri-wechat-line', label: 'wechat'},

  {value: 'ri-wechat-2-line', label: 'wechat-2'},

  {value: 'ri-wechat-2-line', label: 'wechat-2'},

  {value: 'ri-wechat-channels-line', label: 'wechat-channels'},

  {value: 'ri-wechat-channels-line', label: 'wechat-channels'},

  {value: 'ri-wechat-pay-line', label: 'wechat-pay'},

  {value: 'ri-wechat-pay-line', label: 'wechat-pay'},

  {value: 'ri-weibo-line', label: 'weibo'},

  {value: 'ri-weibo-line', label: 'weibo'},

  {value: 'ri-whatsapp-line', label: 'whatsapp'},

  {value: 'ri-whatsapp-line', label: 'whatsapp'},

  {value: 'ri-windows-line', label: 'windows'},

  {value: 'ri-windows-line', label: 'windows'},

  {value: 'ri-wordpress-line', label: 'wordpress'},

  {value: 'ri-wordpress-line', label: 'wordpress'},

  {value: 'ri-xbox-line', label: 'xbox'},

  {value: 'ri-xbox-line', label: 'xbox'},

  {value: 'ri-xing-line', label: 'xing'},

  {value: 'ri-xing-line', label: 'xing'},

  {value: 'ri-youtube-line', label: 'youtube'},

  {value: 'ri-youtube-line', label: 'youtube'},

  {value: 'ri-yuque-line', label: 'yuque'},

  {value: 'ri-yuque-line', label: 'yuque'},

  {value: 'ri-zcool-line', label: 'zcool'},

  {value: 'ri-zcool-line', label: 'zcool'},

  {value: 'ri-zhihu-line', label: 'zhihu'},

  {value: 'ri-zhihu-line', label: 'zhihu'},

  {value: 'ri-Logos228-line', label: 'Logos228'},

  {value: 'ri-map-pin-line', label: 'map-pin'},

  {value: 'ri-map-pin-line', label: 'map-pin'},

  {value: 'ri-map-pin-2-line', label: 'map-pin-2'},

  {value: 'ri-map-pin-2-line', label: 'map-pin-2'},

  {value: 'ri-map-pin-3-line', label: 'map-pin-3'},

  {value: 'ri-map-pin-3-line', label: 'map-pin-3'},

  {value: 'ri-map-pin-4-line', label: 'map-pin-4'},

  {value: 'ri-map-pin-4-line', label: 'map-pin-4'},

  {value: 'ri-map-pin-5-line', label: 'map-pin-5'},

  {value: 'ri-map-pin-5-line', label: 'map-pin-5'},

  {value: 'ri-map-pin-add-line', label: 'map-pin-add'},

  {value: 'ri-map-pin-add-line', label: 'map-pin-add'},

  {value: 'ri-map-pin-range-line', label: 'map-pin-range'},

  {value: 'ri-map-pin-range-line', label: 'map-pin-range'},

  {value: 'ri-map-pin-time-line', label: 'map-pin-time'},

  {value: 'ri-map-pin-time-line', label: 'map-pin-time'},

  {value: 'ri-map-pin-user-line', label: 'map-pin-user'},

  {value: 'ri-map-pin-user-line', label: 'map-pin-user'},

  {value: 'ri-pin-distance-line', label: 'pin-distance'},

  {value: 'ri-pin-distance-line', label: 'pin-distance'},

  {value: 'ri-pushpin-line', label: 'pushpin'},

  {value: 'ri-pushpin-line', label: 'pushpin'},

  {value: 'ri-unpin-line', label: 'unpin'},

  {value: 'ri-unpin-line', label: 'unpin'},

  {value: 'ri-pushpin-2-line', label: 'pushpin-2'},

  {value: 'ri-pushpin-2-line', label: 'pushpin-2'},

  {value: 'ri-compass-line', label: 'compass'},

  {value: 'ri-compass-line', label: 'compass'},

  {value: 'ri-compass-2-line', label: 'compass-2'},

  {value: 'ri-compass-2-line', label: 'compass-2'},

  {value: 'ri-compass-3-line', label: 'compass-3'},

  {value: 'ri-compass-3-line', label: 'compass-3'},

  {value: 'ri-compass-4-line', label: 'compass-4'},

  {value: 'ri-compass-4-line', label: 'compass-4'},

  {value: 'ri-compass-discover-line', label: 'compass-discover'},

  {value: 'ri-compass-discover-line', label: 'compass-discover'},

  {value: 'ri-anchor-line', label: 'anchor'},

  {value: 'ri-anchor-line', label: 'anchor'},

  {value: 'ri-china-railway-line', label: 'china-railway'},

  {value: 'ri-china-railway-line', label: 'china-railway'},

  {value: 'ri-space-ship-line', label: 'space-ship'},

  {value: 'ri-space-ship-line', label: 'space-ship'},

  {value: 'ri-rocket-line', label: 'rocket'},

  {value: 'ri-rocket-line', label: 'rocket'},

  {value: 'ri-rocket-2-line', label: 'rocket-2'},

  {value: 'ri-rocket-2-line', label: 'rocket-2'},

  {value: 'ri-map-line', label: 'map'},

  {value: 'ri-map-line', label: 'map'},

  {value: 'ri-map-2-line', label: 'map-2'},

  {value: 'ri-map-2-line', label: 'map-2'},

  {value: 'ri-treasure-map-line', label: 'treasure-map'},

  {value: 'ri-treasure-map-line', label: 'treasure-map'},

  {value: 'ri-road-map-line', label: 'road-map'},

  {value: 'ri-road-map-line', label: 'road-map'},

  {value: 'ri-earth-line', label: 'earth'},

  {value: 'ri-earth-line', label: 'earth'},

  {value: 'ri-globe-line', label: 'globe'},

  {value: 'ri-globe-line', label: 'globe'},

  {value: 'ri-planet-line', label: 'planet'},

  {value: 'ri-planet-line', label: 'planet'},

  {value: 'ri-parking-line', label: 'parking'},

  {value: 'ri-parking-line', label: 'parking'},

  {value: 'ri-parking-box-line', label: 'parking-box'},

  {value: 'ri-parking-box-line', label: 'parking-box'},

  {value: 'ri-route-line', label: 'route'},

  {value: 'ri-route-line', label: 'route'},

  {value: 'ri-guide-line', label: 'guide'},

  {value: 'ri-guide-line', label: 'guide'},

  {value: 'ri-gas-station-line', label: 'gas-station'},

  {value: 'ri-gas-station-line', label: 'gas-station'},

  {value: 'ri-charging-pile-line', label: 'charging-pile'},

  {value: 'ri-charging-pile-line', label: 'charging-pile'},

  {value: 'ri-charging-pile-2-line', label: 'charging-pile-2'},

  {value: 'ri-charging-pile-2-line', label: 'charging-pile-2'},

  {value: 'ri-car-line', label: 'car'},

  {value: 'ri-car-line', label: 'car'},

  {value: 'ri-car-washing-line', label: 'car-washing'},

  {value: 'ri-car-washing-line', label: 'car-washing'},

  {value: 'ri-roadster-line', label: 'roadster'},

  {value: 'ri-roadster-line', label: 'roadster'},

  {value: 'ri-taxi-line', label: 'taxi'},

  {value: 'ri-taxi-line', label: 'taxi'},

  {value: 'ri-taxi-wifi-line', label: 'taxi-wifi'},

  {value: 'ri-taxi-wifi-line', label: 'taxi-wifi'},

  {value: 'ri-police-car-line', label: 'police-car'},

  {value: 'ri-police-car-line', label: 'police-car'},

  {value: 'ri-bus-line', label: 'bus'},

  {value: 'ri-bus-line', label: 'bus'},

  {value: 'ri-bus-2-line', label: 'bus-2'},

  {value: 'ri-bus-2-line', label: 'bus-2'},

  {value: 'ri-bus-wifi-line', label: 'bus-wifi'},

  {value: 'ri-bus-wifi-line', label: 'bus-wifi'},

  {value: 'ri-truck-line', label: 'truck'},

  {value: 'ri-truck-line', label: 'truck'},

  {value: 'ri-train-line', label: 'train'},

  {value: 'ri-train-line', label: 'train'},

  {value: 'ri-train-wifi-line', label: 'train-wifi'},

  {value: 'ri-train-wifi-line', label: 'train-wifi'},

  {value: 'ri-subway-line', label: 'subway'},

  {value: 'ri-subway-line', label: 'subway'},

  {value: 'ri-subway-wifi-line', label: 'subway-wifi'},

  {value: 'ri-subway-wifi-line', label: 'subway-wifi'},

  {value: 'ri-flight-takeoff-line', label: 'flight-takeoff'},

  {value: 'ri-flight-takeoff-line', label: 'flight-takeoff'},

  {value: 'ri-flight-land-line', label: 'flight-land'},

  {value: 'ri-flight-land-line', label: 'flight-land'},

  {value: 'ri-plane-line', label: 'plane'},

  {value: 'ri-plane-line', label: 'plane'},

  {value: 'ri-sailboat-line', label: 'sailboat'},

  {value: 'ri-sailboat-line', label: 'sailboat'},

  {value: 'ri-ship-line', label: 'ship'},

  {value: 'ri-ship-line', label: 'ship'},

  {value: 'ri-ship-2-line', label: 'ship-2'},

  {value: 'ri-ship-2-line', label: 'ship-2'},

  {value: 'ri-bike-line', label: 'bike'},

  {value: 'ri-bike-line', label: 'bike'},

  {value: 'ri-e-bike-line', label: 'e-bike'},

  {value: 'ri-e-bike-line', label: 'e-bike'},

  {value: 'ri-e-bike-2-line', label: 'e-bike-2'},

  {value: 'ri-e-bike-2-line', label: 'e-bike-2'},

  {value: 'ri-takeaway-line', label: 'takeaway'},

  {value: 'ri-takeaway-line', label: 'takeaway'},

  {value: 'ri-motorbike-line', label: 'motorbike'},

  {value: 'ri-motorbike-line', label: 'motorbike'},

  {value: 'ri-caravan-line', label: 'caravan'},

  {value: 'ri-caravan-line', label: 'caravan'},

  {value: 'ri-walk-line', label: 'walk'},

  {value: 'ri-walk-line', label: 'walk'},

  {value: 'ri-run-line', label: 'run'},

  {value: 'ri-run-line', label: 'run'},

  {value: 'ri-riding-line', label: 'riding'},

  {value: 'ri-riding-line', label: 'riding'},

  {value: 'ri-barricade-line', label: 'barricade'},

  {value: 'ri-barricade-line', label: 'barricade'},

  {value: 'ri-footprint-line', label: 'footprint'},

  {value: 'ri-footprint-line', label: 'footprint'},

  {value: 'ri-traffic-light-line', label: 'traffic-light'},

  {value: 'ri-traffic-light-line', label: 'traffic-light'},

  {value: 'ri-signal-tower-line', label: 'signal-tower'},

  {value: 'ri-signal-tower-line', label: 'signal-tower'},

  {value: 'ri-restaurant-line', label: 'restaurant'},

  {value: 'ri-restaurant-line', label: 'restaurant'},

  {value: 'ri-restaurant-2-line', label: 'restaurant-2'},

  {value: 'ri-restaurant-2-line', label: 'restaurant-2'},

  {value: 'ri-cup-line', label: 'cup'},

  {value: 'ri-cup-line', label: 'cup'},

  {value: 'ri-goblet-line', label: 'goblet'},

  {value: 'ri-goblet-line', label: 'goblet'},

  {value: 'ri-beer-line', label: 'beer'},

  {value: 'ri-beer-line', label: 'beer'},

  {value: 'ri-hotel-bed-line', label: 'hotel-bed'},

  {value: 'ri-hotel-bed-line', label: 'hotel-bed'},

  {value: 'ri-navigation-line', label: 'navigation'},

  {value: 'ri-navigation-line', label: 'navigation'},

  {value: 'ri-oil-line', label: 'oil'},

  {value: 'ri-oil-line', label: 'oil'},

  {value: 'ri-direction-line', label: 'direction'},

  {value: 'ri-direction-line', label: 'direction'},

  {value: 'ri-steering-line', label: 'steering'},

  {value: 'ri-steering-line', label: 'steering'},

  {value: 'ri-steering-2-line', label: 'steering-2'},

  {value: 'ri-steering-2-line', label: 'steering-2'},

  {value: 'ri-lifebuoy-line', label: 'lifebuoy'},

  {value: 'ri-lifebuoy-line', label: 'lifebuoy'},

  {value: 'ri-passport-line', label: 'passport'},

  {value: 'ri-passport-line', label: 'passport'},

  {value: 'ri-suitcase-line', label: 'suitcase'},

  {value: 'ri-suitcase-line', label: 'suitcase'},

  {value: 'ri-suitcase-2-line', label: 'suitcase-2'},

  {value: 'ri-suitcase-2-line', label: 'suitcase-2'},

  {value: 'ri-suitcase-3-line', label: 'suitcase-3'},

  {value: 'ri-suitcase-3-line', label: 'suitcase-3'},

  {value: 'ri-luggage-deposit-line', label: 'luggage-deposit'},

  {value: 'ri-luggage-deposit-line', label: 'luggage-deposit'},

  {value: 'ri-luggage-cart-line', label: 'luggage-cart'},

  {value: 'ri-luggage-cart-line', label: 'luggage-cart'},

  {value: 'ri-Map176-line', label: 'Map176'},

  {value: 'ri-image-line', label: 'image'},

  {value: 'ri-image-line', label: 'image'},

  {value: 'ri-image-2-line', label: 'image-2'},

  {value: 'ri-image-2-line', label: 'image-2'},

  {value: 'ri-image-add-line', label: 'image-add'},

  {value: 'ri-image-add-line', label: 'image-add'},

  {value: 'ri-image-edit-line', label: 'image-edit'},

  {value: 'ri-image-edit-line', label: 'image-edit'},

  {value: 'ri-landscape-line', label: 'landscape'},

  {value: 'ri-landscape-line', label: 'landscape'},

  {value: 'ri-gallery-line', label: 'gallery'},

  {value: 'ri-gallery-line', label: 'gallery'},

  {value: 'ri-gallery-upload-line', label: 'gallery-upload'},

  {value: 'ri-gallery-upload-line', label: 'gallery-upload'},

  {value: 'ri-video-line', label: 'video'},

  {value: 'ri-video-line', label: 'video'},

  {value: 'ri-movie-line', label: 'movie'},

  {value: 'ri-movie-line', label: 'movie'},

  {value: 'ri-movie-2-line', label: 'movie-2'},

  {value: 'ri-movie-2-line', label: 'movie-2'},

  {value: 'ri-film-line', label: 'film'},

  {value: 'ri-film-line', label: 'film'},

  {value: 'ri-clapperboard-line', label: 'clapperboard'},

  {value: 'ri-clapperboard-line', label: 'clapperboard'},

  {value: 'ri-vidicon-line', label: 'vidicon'},

  {value: 'ri-vidicon-line', label: 'vidicon'},

  {value: 'ri-vidicon-2-line', label: 'vidicon-2'},

  {value: 'ri-vidicon-2-line', label: 'vidicon-2'},

  {value: 'ri-live-line', label: 'live'},

  {value: 'ri-live-line', label: 'live'},

  {value: 'ri-video-add-line', label: 'video-add'},

  {value: 'ri-video-add-line', label: 'video-add'},

  {value: 'ri-video-upload-line', label: 'video-upload'},

  {value: 'ri-video-upload-line', label: 'video-upload'},

  {value: 'ri-video-download-line', label: 'video-download'},

  {value: 'ri-video-download-line', label: 'video-download'},

  {value: 'ri-dv-line', label: 'dv'},

  {value: 'ri-dv-line', label: 'dv'},

  {value: 'ri-camera-line', label: 'camera'},

  {value: 'ri-camera-line', label: 'camera'},

  {value: 'ri-camera-off-line', label: 'camera-off'},

  {value: 'ri-camera-off-line', label: 'camera-off'},

  {value: 'ri-camera-2-line', label: 'camera-2'},

  {value: 'ri-camera-2-line', label: 'camera-2'},

  {value: 'ri-camera-3-line', label: 'camera-3'},

  {value: 'ri-camera-3-line', label: 'camera-3'},

  {value: 'ri-camera-lens-line', label: 'camera-lens'},

  {value: 'ri-camera-lens-line', label: 'camera-lens'},

  {value: 'ri-camera-switch-line', label: 'camera-switch'},

  {value: 'ri-camera-switch-line', label: 'camera-switch'},

  {value: 'ri-polaroid-line', label: 'polaroid'},

  {value: 'ri-polaroid-line', label: 'polaroid'},

  {value: 'ri-polaroid-2-line', label: 'polaroid-2'},

  {value: 'ri-polaroid-2-line', label: 'polaroid-2'},

  {value: 'ri-phone-camera-line', label: 'phone-camera'},

  {value: 'ri-phone-camera-line', label: 'phone-camera'},

  {value: 'ri-webcam-line', label: 'webcam'},

  {value: 'ri-webcam-line', label: 'webcam'},

  {value: 'ri-mv-line', label: 'mv'},

  {value: 'ri-mv-line', label: 'mv'},

  {value: 'ri-music-line', label: 'music'},

  {value: 'ri-music-line', label: 'music'},

  {value: 'ri-music-2-line', label: 'music-2'},

  {value: 'ri-music-2-line', label: 'music-2'},

  {value: 'ri-disc-line', label: 'disc'},

  {value: 'ri-disc-line', label: 'disc'},

  {value: 'ri-album-line', label: 'album'},

  {value: 'ri-album-line', label: 'album'},

  {value: 'ri-dvd-line', label: 'dvd'},

  {value: 'ri-dvd-line', label: 'dvd'},

  {value: 'ri-headphone-line', label: 'headphone'},

  {value: 'ri-headphone-line', label: 'headphone'},

  {value: 'ri-radio-line', label: 'radio'},

  {value: 'ri-radio-line', label: 'radio'},

  {value: 'ri-radio-2-line', label: 'radio-2'},

  {value: 'ri-radio-2-line', label: 'radio-2'},

  {value: 'ri-tape-line', label: 'tape'},

  {value: 'ri-tape-line', label: 'tape'},

  {value: 'ri-mic-line', label: 'mic'},

  {value: 'ri-mic-line', label: 'mic'},

  {value: 'ri-mic-2-line', label: 'mic-2'},

  {value: 'ri-mic-2-line', label: 'mic-2'},

  {value: 'ri-mic-off-line', label: 'mic-off'},

  {value: 'ri-mic-off-line', label: 'mic-off'},

  {value: 'ri-volume-down-line', label: 'volume-down'},

  {value: 'ri-volume-down-line', label: 'volume-down'},

  {value: 'ri-volume-mute-line', label: 'volume-mute'},

  {value: 'ri-volume-mute-line', label: 'volume-mute'},

  {value: 'ri-volume-up-line', label: 'volume-up'},

  {value: 'ri-volume-up-line', label: 'volume-up'},

  {value: 'ri-volume-vibrate-line', label: 'volume-vibrate'},

  {value: 'ri-volume-vibrate-line', label: 'volume-vibrate'},

  {value: 'ri-volume-off-vibrate-line', label: 'volume-off-vibrate'},

  {value: 'ri-volume-off-vibrate-line', label: 'volume-off-vibrate'},

  {value: 'ri-speaker-line', label: 'speaker'},

  {value: 'ri-speaker-line', label: 'speaker'},

  {value: 'ri-speaker-2-line', label: 'speaker-2'},

  {value: 'ri-speaker-2-line', label: 'speaker-2'},

  {value: 'ri-speaker-3-line', label: 'speaker-3'},

  {value: 'ri-speaker-3-line', label: 'speaker-3'},

  {value: 'ri-surround-sound-line', label: 'surround-sound'},

  {value: 'ri-surround-sound-line', label: 'surround-sound'},

  {value: 'ri-broadcast-line', label: 'broadcast'},

  {value: 'ri-broadcast-line', label: 'broadcast'},

  {value: 'ri-notification-line', label: 'notification'},

  {value: 'ri-notification-line', label: 'notification'},

  {value: 'ri-notification-2-line', label: 'notification-2'},

  {value: 'ri-notification-2-line', label: 'notification-2'},

  {value: 'ri-notification-3-line', label: 'notification-3'},

  {value: 'ri-notification-3-line', label: 'notification-3'},

  {value: 'ri-notification-4-line', label: 'notification-4'},

  {value: 'ri-notification-4-line', label: 'notification-4'},

  {value: 'ri-notification-off-line', label: 'notification-off'},

  {value: 'ri-notification-off-line', label: 'notification-off'},

  {value: 'ri-play-circle-line', label: 'play-circle'},

  {value: 'ri-play-circle-line', label: 'play-circle'},

  {value: 'ri-pause-circle-line', label: 'pause-circle'},

  {value: 'ri-pause-circle-line', label: 'pause-circle'},

  {value: 'ri-record-circle-line', label: 'record-circle'},

  {value: 'ri-record-circle-line', label: 'record-circle'},

  {value: 'ri-stop-circle-line', label: 'stop-circle'},

  {value: 'ri-stop-circle-line', label: 'stop-circle'},

  {value: 'ri-eject-line', label: 'eject'},

  {value: 'ri-eject-line', label: 'eject'},

  {value: 'ri-play-line', label: 'play'},

  {value: 'ri-play-line', label: 'play'},

  {value: 'ri-pause-line', label: 'pause'},

  {value: 'ri-pause-line', label: 'pause'},

  {value: 'ri-stop-line', label: 'stop'},

  {value: 'ri-stop-line', label: 'stop'},

  {value: 'ri-rewind-line', label: 'rewind'},

  {value: 'ri-rewind-line', label: 'rewind'},

  {value: 'ri-speed-line', label: 'speed'},

  {value: 'ri-speed-line', label: 'speed'},

  {value: 'ri-skip-back-line', label: 'skip-back'},

  {value: 'ri-skip-back-line', label: 'skip-back'},

  {value: 'ri-skip-forward-line', label: 'skip-forward'},

  {value: 'ri-skip-forward-line', label: 'skip-forward'},

  {value: 'ri-play-mini-line', label: 'play-mini'},

  {value: 'ri-play-mini-line', label: 'play-mini'},

  {value: 'ri-pause-mini-line', label: 'pause-mini'},

  {value: 'ri-pause-mini-line', label: 'pause-mini'},

  {value: 'ri-stop-mini-line', label: 'stop-mini'},

  {value: 'ri-stop-mini-line', label: 'stop-mini'},

  {value: 'ri-rewind-mini-line', label: 'rewind-mini'},

  {value: 'ri-rewind-mini-line', label: 'rewind-mini'},

  {value: 'ri-speed-mini-line', label: 'speed-mini'},

  {value: 'ri-speed-mini-line', label: 'speed-mini'},

  {value: 'ri-skip-back-mini-line', label: 'skip-back-mini'},

  {value: 'ri-skip-back-mini-line', label: 'skip-back-mini'},

  {value: 'ri-skip-forward-mini-line', label: 'skip-forward-mini'},

  {value: 'ri-skip-forward-mini-line', label: 'skip-forward-mini'},

  {value: 'ri-replay-5-line', label: 'replay-5'},

  {value: 'ri-replay-5-line', label: 'replay-5'},

  {value: 'ri-forward-5-line', label: 'forward-5'},

  {value: 'ri-forward-5-line', label: 'forward-5'},

  {value: 'ri-replay-10-line', label: 'replay-10'},

  {value: 'ri-replay-10-line', label: 'replay-10'},

  {value: 'ri-forward-10-line', label: 'forward-10'},

  {value: 'ri-forward-10-line', label: 'forward-10'},

  {value: 'ri-replay-15-line', label: 'replay-15'},

  {value: 'ri-replay-15-line', label: 'replay-15'},

  {value: 'ri-forward-15-line', label: 'forward-15'},

  {value: 'ri-forward-15-line', label: 'forward-15'},

  {value: 'ri-replay-30-line', label: 'replay-30'},

  {value: 'ri-replay-30-line', label: 'replay-30'},

  {value: 'ri-forward-30-line', label: 'forward-30'},

  {value: 'ri-forward-30-line', label: 'forward-30'},

  {value: 'ri-memories-line', label: 'memories'},

  {value: 'ri-memories-line', label: 'memories'},

  {value: 'ri-repeat-line', label: 'repeat'},

  {value: 'ri-repeat-line', label: 'repeat'},

  {value: 'ri-repeat-2-line', label: 'repeat-2'},

  {value: 'ri-repeat-2-line', label: 'repeat-2'},

  {value: 'ri-repeat-one-line', label: 'repeat-one'},

  {value: 'ri-repeat-one-line', label: 'repeat-one'},

  {value: 'ri-order-play-line', label: 'order-play'},

  {value: 'ri-order-play-line', label: 'order-play'},

  {value: 'ri-shuffle-line', label: 'shuffle'},

  {value: 'ri-shuffle-line', label: 'shuffle'},

  {value: 'ri-play-list-line', label: 'play-list'},

  {value: 'ri-play-list-line', label: 'play-list'},

  {value: 'ri-play-list-2-line', label: 'play-list-2'},

  {value: 'ri-play-list-2-line', label: 'play-list-2'},

  {value: 'ri-play-list-add-line', label: 'play-list-add'},

  {value: 'ri-play-list-add-line', label: 'play-list-add'},

  {value: 'ri-fullscreen-line', label: 'fullscreen'},

  {value: 'ri-fullscreen-line', label: 'fullscreen'},

  {value: 'ri-fullscreen-exit-line', label: 'fullscreen-exit'},

  {value: 'ri-fullscreen-exit-line', label: 'fullscreen-exit'},

  {value: 'ri-equalizer-line', label: 'equalizer'},

  {value: 'ri-equalizer-line', label: 'equalizer'},

  {value: 'ri-sound-module-line', label: 'sound-module'},

  {value: 'ri-sound-module-line', label: 'sound-module'},

  {value: 'ri-rhythm-line', label: 'rhythm'},

  {value: 'ri-rhythm-line', label: 'rhythm'},

  {value: 'ri-voiceprint-line', label: 'voiceprint'},

  {value: 'ri-voiceprint-line', label: 'voiceprint'},

  {value: 'ri-speed-up-line', label: 'speed-up'},

  {value: 'ri-speed-up-line', label: 'speed-up'},

  {value: 'ri-slow-down-line', label: 'slow-down'},

  {value: 'ri-slow-down-line', label: 'slow-down'},

  {value: 'ri-hq-line', label: 'hq'},

  {value: 'ri-hq-line', label: 'hq'},

  {value: 'ri-hd-line', label: 'hd'},

  {value: 'ri-hd-line', label: 'hd'},

  {value: 'ri-4k-line', label: '4k'},

  {value: 'ri-4k-line', label: '4k'},

  {value: 'ri-closed-captioning-line', label: 'closed-captioning'},

  {value: 'ri-closed-captioning-line', label: 'closed-captioning'},

  {value: 'ri-aspect-ratio-line', label: 'aspect-ratio'},

  {value: 'ri-aspect-ratio-line', label: 'aspect-ratio'},

  {value: 'ri-picture-in-picture-line', label: 'picture-in-picture'},

  {value: 'ri-picture-in-picture-line', label: 'picture-in-picture'},

  {value: 'ri-picture-in-picture-2-line', label: 'picture-in-picture-2'},

  {value: 'ri-picture-in-picture-2-line', label: 'picture-in-picture-2'},

  {value: 'ri-picture-in-picture-exit-line', label: 'picture-in-picture-exit'},

  {value: 'ri-picture-in-picture-exit-line', label: 'picture-in-picture-exit'},

  {value: 'ri-Media218-line', label: 'Media218'},

  {value: 'ri-apps-line', label: 'apps'},

  {value: 'ri-apps-line', label: 'apps'},

  {value: 'ri-apps-2-line', label: 'apps-2'},

  {value: 'ri-apps-2-line', label: 'apps-2'},

  {value: 'ri-function-line', label: 'function'},

  {value: 'ri-function-line', label: 'function'},

  {value: 'ri-dashboard-line', label: 'dashboard'},

  {value: 'ri-dashboard-line', label: 'dashboard'},

  {value: 'ri-menu-line', label: 'menu'},

  {value: 'ri-menu-line', label: 'menu'},

  {value: 'ri-menu-2-line', label: 'menu-2'},

  {value: 'ri-menu-2-line', label: 'menu-2'},

  {value: 'ri-menu-3-line', label: 'menu-3'},

  {value: 'ri-menu-3-line', label: 'menu-3'},

  {value: 'ri-menu-4-line', label: 'menu-4'},

  {value: 'ri-menu-4-line', label: 'menu-4'},

  {value: 'ri-menu-5-line', label: 'menu-5'},

  {value: 'ri-menu-5-line', label: 'menu-5'},

  {value: 'ri-menu-add-line', label: 'menu-add'},

  {value: 'ri-menu-add-line', label: 'menu-add'},

  {value: 'ri-menu-fold-line', label: 'menu-fold'},

  {value: 'ri-menu-fold-line', label: 'menu-fold'},

  {value: 'ri-menu-unfold-line', label: 'menu-unfold'},

  {value: 'ri-menu-unfold-line', label: 'menu-unfold'},

  {value: 'ri-menu-search-line', label: 'menu-search'},

  {value: 'ri-menu-search-line', label: 'menu-search'},

  {value: 'ri-more-line', label: 'more'},

  {value: 'ri-more-line', label: 'more'},

  {value: 'ri-more-2-line', label: 'more-2'},

  {value: 'ri-more-2-line', label: 'more-2'},

  {value: 'ri-star-line', label: 'star'},

  {value: 'ri-star-line', label: 'star'},

  {value: 'ri-star-s-line', label: 'star-s'},

  {value: 'ri-star-s-line', label: 'star-s'},

  {value: 'ri-star-half-line', label: 'star-half'},

  {value: 'ri-star-half-line', label: 'star-half'},

  {value: 'ri-star-half-s-line', label: 'star-half-s'},

  {value: 'ri-star-half-s-line', label: 'star-half-s'},

  {value: 'ri-settings-line', label: 'settings'},

  {value: 'ri-settings-line', label: 'settings'},

  {value: 'ri-settings-2-line', label: 'settings-2'},

  {value: 'ri-settings-2-line', label: 'settings-2'},

  {value: 'ri-settings-3-line', label: 'settings-3'},

  {value: 'ri-settings-3-line', label: 'settings-3'},

  {value: 'ri-settings-4-line', label: 'settings-4'},

  {value: 'ri-settings-4-line', label: 'settings-4'},

  {value: 'ri-settings-5-line', label: 'settings-5'},

  {value: 'ri-settings-5-line', label: 'settings-5'},

  {value: 'ri-settings-6-line', label: 'settings-6'},

  {value: 'ri-settings-6-line', label: 'settings-6'},

  {value: 'ri-list-settings-line', label: 'list-settings'},

  {value: 'ri-list-settings-line', label: 'list-settings'},

  {value: 'ri-forbid-line', label: 'forbid'},

  {value: 'ri-forbid-line', label: 'forbid'},

  {value: 'ri-forbid-2-line', label: 'forbid-2'},

  {value: 'ri-forbid-2-line', label: 'forbid-2'},

  {value: 'ri-prohibited-line', label: 'prohibited'},

  {value: 'ri-prohibited-line', label: 'prohibited'},

  {value: 'ri-information-line', label: 'information'},

  {value: 'ri-information-line', label: 'information'},

  {value: 'ri-error-warning-line', label: 'error-warning'},

  {value: 'ri-error-warning-line', label: 'error-warning'},

  {value: 'ri-question-line', label: 'question'},

  {value: 'ri-question-line', label: 'question'},

  {value: 'ri-alert-line', label: 'alert'},

  {value: 'ri-alert-line', label: 'alert'},

  {value: 'ri-spam-line', label: 'spam'},

  {value: 'ri-spam-line', label: 'spam'},

  {value: 'ri-spam-2-line', label: 'spam-2'},

  {value: 'ri-spam-2-line', label: 'spam-2'},

  {value: 'ri-spam-3-line', label: 'spam-3'},

  {value: 'ri-spam-3-line', label: 'spam-3'},

  {value: 'ri-checkbox-blank-line', label: 'checkbox-blank'},

  {value: 'ri-checkbox-blank-line', label: 'checkbox-blank'},

  {value: 'ri-checkbox-line', label: 'checkbox'},

  {value: 'ri-checkbox-line', label: 'checkbox'},

  {value: 'ri-checkbox-indeterminate-line', label: 'checkbox-indeterminate'},

  {value: 'ri-checkbox-indeterminate-line', label: 'checkbox-indeterminate'},

  {value: 'ri-add-box-line', label: 'add-box'},

  {value: 'ri-add-box-line', label: 'add-box'},

  {value: 'ri-checkbox-blank-circle-line', label: 'checkbox-blank-circle'},

  {value: 'ri-checkbox-blank-circle-line', label: 'checkbox-blank-circle'},

  {value: 'ri-checkbox-circle-line', label: 'checkbox-circle'},

  {value: 'ri-checkbox-circle-line', label: 'checkbox-circle'},

  {value: 'ri-indeterminate-circle-line', label: 'indeterminate-circle'},

  {value: 'ri-indeterminate-circle-line', label: 'indeterminate-circle'},

  {value: 'ri-add-circle-line', label: 'add-circle'},

  {value: 'ri-add-circle-line', label: 'add-circle'},

  {value: 'ri-close-circle-line', label: 'close-circle'},

  {value: 'ri-close-circle-line', label: 'close-circle'},

  {value: 'ri-radio-button-line', label: 'radio-button'},

  {value: 'ri-radio-button-line', label: 'radio-button'},

  {value: 'ri-checkbox-multiple-blank-line', label: 'checkbox-multiple-blank'},

  {value: 'ri-checkbox-multiple-blank-line', label: 'checkbox-multiple-blank'},

  {value: 'ri-checkbox-multiple-line', label: 'checkbox-multiple'},

  {value: 'ri-checkbox-multiple-line', label: 'checkbox-multiple'},

  {value: 'ri-check-line', label: 'check'},

  {value: 'ri-check-line', label: 'check'},

  {value: 'ri-check-double-line', label: 'check-double'},

  {value: 'ri-check-double-line', label: 'check-double'},

  {value: 'ri-close-line', label: 'close'},

  {value: 'ri-close-line', label: 'close'},

  {value: 'ri-add-line', label: 'add'},

  {value: 'ri-add-line', label: 'add'},

  {value: 'ri-subtract-line', label: 'subtract'},

  {value: 'ri-subtract-line', label: 'subtract'},

  {value: 'ri-divide-line', label: 'divide'},

  {value: 'ri-divide-line', label: 'divide'},

  {value: 'ri-equal-line', label: 'equal'},

  {value: 'ri-equal-line', label: 'equal'},

  {value: 'ri-download-line', label: 'download'},

  {value: 'ri-download-line', label: 'download'},

  {value: 'ri-upload-line', label: 'upload'},

  {value: 'ri-upload-line', label: 'upload'},

  {value: 'ri-download-2-line', label: 'download-2'},

  {value: 'ri-download-2-line', label: 'download-2'},

  {value: 'ri-upload-2-line', label: 'upload-2'},

  {value: 'ri-upload-2-line', label: 'upload-2'},

  {value: 'ri-download-cloud-line', label: 'download-cloud'},

  {value: 'ri-download-cloud-line', label: 'download-cloud'},

  {value: 'ri-download-cloud-2-line', label: 'download-cloud-2'},

  {value: 'ri-download-cloud-2-line', label: 'download-cloud-2'},

  {value: 'ri-upload-cloud-line', label: 'upload-cloud'},

  {value: 'ri-upload-cloud-line', label: 'upload-cloud'},

  {value: 'ri-upload-cloud-2-line', label: 'upload-cloud-2'},

  {value: 'ri-upload-cloud-2-line', label: 'upload-cloud-2'},

  {value: 'ri-login-box-line', label: 'login-box'},

  {value: 'ri-login-box-line', label: 'login-box'},

  {value: 'ri-logout-box-line', label: 'logout-box'},

  {value: 'ri-logout-box-line', label: 'logout-box'},

  {value: 'ri-logout-box-r-line', label: 'logout-box-r'},

  {value: 'ri-logout-box-r-line', label: 'logout-box-r'},

  {value: 'ri-login-circle-line', label: 'login-circle'},

  {value: 'ri-login-circle-line', label: 'login-circle'},

  {value: 'ri-logout-circle-line', label: 'logout-circle'},

  {value: 'ri-logout-circle-line', label: 'logout-circle'},

  {value: 'ri-logout-circle-r-line', label: 'logout-circle-r'},

  {value: 'ri-logout-circle-r-line', label: 'logout-circle-r'},

  {value: 'ri-refresh-line', label: 'refresh'},

  {value: 'ri-refresh-line', label: 'refresh'},

  {value: 'ri-shield-line', label: 'shield'},

  {value: 'ri-shield-line', label: 'shield'},

  {value: 'ri-shield-cross-line', label: 'shield-cross'},

  {value: 'ri-shield-cross-line', label: 'shield-cross'},

  {value: 'ri-shield-flash-line', label: 'shield-flash'},

  {value: 'ri-shield-flash-line', label: 'shield-flash'},

  {value: 'ri-shield-star-line', label: 'shield-star'},

  {value: 'ri-shield-star-line', label: 'shield-star'},

  {value: 'ri-shield-user-line', label: 'shield-user'},

  {value: 'ri-shield-user-line', label: 'shield-user'},

  {value: 'ri-shield-keyhole-line', label: 'shield-keyhole'},

  {value: 'ri-shield-keyhole-line', label: 'shield-keyhole'},

  {value: 'ri-shield-check-line', label: 'shield-check'},

  {value: 'ri-shield-check-line', label: 'shield-check'},

  {value: 'ri-delete-back-line', label: 'delete-back'},

  {value: 'ri-delete-back-line', label: 'delete-back'},

  {value: 'ri-delete-back-2-line', label: 'delete-back-2'},

  {value: 'ri-delete-back-2-line', label: 'delete-back-2'},

  {value: 'ri-delete-bin-line', label: 'delete-bin'},

  {value: 'ri-delete-bin-line', label: 'delete-bin'},

  {value: 'ri-delete-bin-2-line', label: 'delete-bin-2'},

  {value: 'ri-delete-bin-2-line', label: 'delete-bin-2'},

  {value: 'ri-delete-bin-3-line', label: 'delete-bin-3'},

  {value: 'ri-delete-bin-3-line', label: 'delete-bin-3'},

  {value: 'ri-delete-bin-4-line', label: 'delete-bin-4'},

  {value: 'ri-delete-bin-4-line', label: 'delete-bin-4'},

  {value: 'ri-delete-bin-5-line', label: 'delete-bin-5'},

  {value: 'ri-delete-bin-5-line', label: 'delete-bin-5'},

  {value: 'ri-delete-bin-6-line', label: 'delete-bin-6'},

  {value: 'ri-delete-bin-6-line', label: 'delete-bin-6'},

  {value: 'ri-delete-bin-7-line', label: 'delete-bin-7'},

  {value: 'ri-delete-bin-7-line', label: 'delete-bin-7'},

  {value: 'ri-lock-line', label: 'lock'},

  {value: 'ri-lock-line', label: 'lock'},

  {value: 'ri-lock-2-line', label: 'lock-2'},

  {value: 'ri-lock-2-line', label: 'lock-2'},

  {value: 'ri-lock-password-line', label: 'lock-password'},

  {value: 'ri-lock-password-line', label: 'lock-password'},

  {value: 'ri-lock-unlock-line', label: 'lock-unlock'},

  {value: 'ri-lock-unlock-line', label: 'lock-unlock'},

  {value: 'ri-eye-line', label: 'eye'},

  {value: 'ri-eye-line', label: 'eye'},

  {value: 'ri-eye-off-line', label: 'eye-off'},

  {value: 'ri-eye-off-line', label: 'eye-off'},

  {value: 'ri-eye-2-line', label: 'eye-2'},

  {value: 'ri-eye-2-line', label: 'eye-2'},

  {value: 'ri-eye-close-line', label: 'eye-close'},

  {value: 'ri-eye-close-line', label: 'eye-close'},

  {value: 'ri-search-line', label: 'search'},

  {value: 'ri-search-line', label: 'search'},

  {value: 'ri-search-2-line', label: 'search-2'},

  {value: 'ri-search-2-line', label: 'search-2'},

  {value: 'ri-search-eye-line', label: 'search-eye'},

  {value: 'ri-search-eye-line', label: 'search-eye'},

  {value: 'ri-zoom-in-line', label: 'zoom-in'},

  {value: 'ri-zoom-in-line', label: 'zoom-in'},

  {value: 'ri-zoom-out-line', label: 'zoom-out'},

  {value: 'ri-zoom-out-line', label: 'zoom-out'},

  {value: 'ri-find-replace-line', label: 'find-replace'},

  {value: 'ri-find-replace-line', label: 'find-replace'},

  {value: 'ri-share-line', label: 'share'},

  {value: 'ri-share-line', label: 'share'},

  {value: 'ri-share-box-line', label: 'share-box'},

  {value: 'ri-share-box-line', label: 'share-box'},

  {value: 'ri-share-circle-line', label: 'share-circle'},

  {value: 'ri-share-circle-line', label: 'share-circle'},

  {value: 'ri-share-forward-line', label: 'share-forward'},

  {value: 'ri-share-forward-line', label: 'share-forward'},

  {value: 'ri-share-forward-2-line', label: 'share-forward-2'},

  {value: 'ri-share-forward-2-line', label: 'share-forward-2'},

  {value: 'ri-share-forward-box-line', label: 'share-forward-box'},

  {value: 'ri-share-forward-box-line', label: 'share-forward-box'},

  {value: 'ri-side-bar-line', label: 'side-bar'},

  {value: 'ri-side-bar-line', label: 'side-bar'},

  {value: 'ri-time-line', label: 'time'},

  {value: 'ri-time-line', label: 'time'},

  {value: 'ri-timer-line', label: 'timer'},

  {value: 'ri-timer-line', label: 'timer'},

  {value: 'ri-timer-2-line', label: 'timer-2'},

  {value: 'ri-timer-2-line', label: 'timer-2'},

  {value: 'ri-timer-flash-line', label: 'timer-flash'},

  {value: 'ri-timer-flash-line', label: 'timer-flash'},

  {value: 'ri-alarm-line', label: 'alarm'},

  {value: 'ri-alarm-line', label: 'alarm'},

  {value: 'ri-history-line', label: 'history'},

  {value: 'ri-history-line', label: 'history'},

  {value: 'ri-hourglass-2-line', label: 'hourglass-2'},

  {value: 'ri-hourglass-2-line', label: 'hourglass-2'},

  {value: 'ri-hourglass-line', label: 'hourglass'},

  {value: 'ri-hourglass-line', label: 'hourglass'},

  {value: 'ri-thumb-up-line', label: 'thumb-up'},

  {value: 'ri-thumb-up-line', label: 'thumb-up'},

  {value: 'ri-thumb-down-line', label: 'thumb-down'},

  {value: 'ri-thumb-down-line', label: 'thumb-down'},

  {value: 'ri-alarm-warning-line', label: 'alarm-warning'},

  {value: 'ri-alarm-warning-line', label: 'alarm-warning'},

  {value: 'ri-notification-badge-line', label: 'notification-badge'},

  {value: 'ri-notification-badge-line', label: 'notification-badge'},

  {value: 'ri-toggle-line', label: 'toggle'},

  {value: 'ri-toggle-line', label: 'toggle'},

  {value: 'ri-filter-line', label: 'filter'},

  {value: 'ri-filter-line', label: 'filter'},

  {value: 'ri-filter-2-line', label: 'filter-2'},

  {value: 'ri-filter-2-line', label: 'filter-2'},

  {value: 'ri-filter-3-line', label: 'filter-3'},

  {value: 'ri-filter-3-line', label: 'filter-3'},

  {value: 'ri-filter-off-line', label: 'filter-off'},

  {value: 'ri-filter-off-line', label: 'filter-off'},

  {value: 'ri-loader-line', label: 'loader'},

  {value: 'ri-loader-line', label: 'loader'},

  {value: 'ri-loader-2-line', label: 'loader-2'},

  {value: 'ri-loader-2-line', label: 'loader-2'},

  {value: 'ri-loader-3-line', label: 'loader-3'},

  {value: 'ri-loader-3-line', label: 'loader-3'},

  {value: 'ri-loader-4-line', label: 'loader-4'},

  {value: 'ri-loader-4-line', label: 'loader-4'},

  {value: 'ri-loader-5-line', label: 'loader-5'},

  {value: 'ri-loader-5-line', label: 'loader-5'},

  {value: 'ri-external-link-line', label: 'external-link'},

  {value: 'ri-external-link-line', label: 'external-link'},

  {value: 'ri-loop-left-line', label: 'loop-left'},

  {value: 'ri-loop-left-line', label: 'loop-left'},

  {value: 'ri-loop-right-line', label: 'loop-right'},

  {value: 'ri-loop-right-line', label: 'loop-right'},

  {value: 'ri-System264-line', label: 'System264'},

  {value: 'ri-user-line', label: 'user'},

  {value: 'ri-user-line', label: 'user'},

  {value: 'ri-user-2-line', label: 'user-2'},

  {value: 'ri-user-2-line', label: 'user-2'},

  {value: 'ri-user-3-line', label: 'user-3'},

  {value: 'ri-user-3-line', label: 'user-3'},

  {value: 'ri-user-4-line', label: 'user-4'},

  {value: 'ri-user-4-line', label: 'user-4'},

  {value: 'ri-user-5-line', label: 'user-5'},

  {value: 'ri-user-5-line', label: 'user-5'},

  {value: 'ri-user-6-line', label: 'user-6'},

  {value: 'ri-user-6-line', label: 'user-6'},

  {value: 'ri-user-smile-line', label: 'user-smile'},

  {value: 'ri-user-smile-line', label: 'user-smile'},

  {value: 'ri-account-box-line', label: 'account-box'},

  {value: 'ri-account-box-line', label: 'account-box'},

  {value: 'ri-account-circle-line', label: 'account-circle'},

  {value: 'ri-account-circle-line', label: 'account-circle'},

  {value: 'ri-account-pin-box-line', label: 'account-pin-box'},

  {value: 'ri-account-pin-box-line', label: 'account-pin-box'},

  {value: 'ri-account-pin-circle-line', label: 'account-pin-circle'},

  {value: 'ri-account-pin-circle-line', label: 'account-pin-circle'},

  {value: 'ri-user-add-line', label: 'user-add'},

  {value: 'ri-user-add-line', label: 'user-add'},

  {value: 'ri-user-follow-line', label: 'user-follow'},

  {value: 'ri-user-follow-line', label: 'user-follow'},

  {value: 'ri-user-unfollow-line', label: 'user-unfollow'},

  {value: 'ri-user-unfollow-line', label: 'user-unfollow'},

  {value: 'ri-user-shared-line', label: 'user-shared'},

  {value: 'ri-user-shared-line', label: 'user-shared'},

  {value: 'ri-user-shared-2-line', label: 'user-shared-2'},

  {value: 'ri-user-shared-2-line', label: 'user-shared-2'},

  {value: 'ri-user-received-line', label: 'user-received'},

  {value: 'ri-user-received-line', label: 'user-received'},

  {value: 'ri-user-received-2-line', label: 'user-received-2'},

  {value: 'ri-user-received-2-line', label: 'user-received-2'},

  {value: 'ri-user-location-line', label: 'user-location'},

  {value: 'ri-user-location-line', label: 'user-location'},

  {value: 'ri-user-search-line', label: 'user-search'},

  {value: 'ri-user-search-line', label: 'user-search'},

  {value: 'ri-user-settings-line', label: 'user-settings'},

  {value: 'ri-user-settings-line', label: 'user-settings'},

  {value: 'ri-user-star-line', label: 'user-star'},

  {value: 'ri-user-star-line', label: 'user-star'},

  {value: 'ri-user-heart-line', label: 'user-heart'},

  {value: 'ri-user-heart-line', label: 'user-heart'},

  {value: 'ri-admin-line', label: 'admin'},

  {value: 'ri-admin-line', label: 'admin'},

  {value: 'ri-user-forbid-line', label: 'user-forbid'},

  {value: 'ri-user-forbid-line', label: 'user-forbid'},

  {value: 'ri-contacts-line', label: 'contacts'},

  {value: 'ri-contacts-line', label: 'contacts'},

  {value: 'ri-group-line', label: 'group'},

  {value: 'ri-group-line', label: 'group'},

  {value: 'ri-group-2-line', label: 'group-2'},

  {value: 'ri-group-2-line', label: 'group-2'},

  {value: 'ri-team-line', label: 'team'},

  {value: 'ri-team-line', label: 'team'},

  {value: 'ri-user-voice-line', label: 'user-voice'},

  {value: 'ri-user-voice-line', label: 'user-voice'},

  {value: 'ri-emotion-line', label: 'emotion'},

  {value: 'ri-emotion-line', label: 'emotion'},

  {value: 'ri-emotion-2-line', label: 'emotion-2'},

  {value: 'ri-emotion-2-line', label: 'emotion-2'},

  {value: 'ri-emotion-happy-line', label: 'emotion-happy'},

  {value: 'ri-emotion-happy-line', label: 'emotion-happy'},

  {value: 'ri-emotion-normal-line', label: 'emotion-normal'},

  {value: 'ri-emotion-normal-line', label: 'emotion-normal'},

  {value: 'ri-emotion-unhappy-line', label: 'emotion-unhappy'},

  {value: 'ri-emotion-unhappy-line', label: 'emotion-unhappy'},

  {value: 'ri-emotion-laugh-line', label: 'emotion-laugh'},

  {value: 'ri-emotion-laugh-line', label: 'emotion-laugh'},

  {value: 'ri-emotion-sad-line', label: 'emotion-sad'},

  {value: 'ri-emotion-sad-line', label: 'emotion-sad'},

  {value: 'ri-skull-line', label: 'skull'},

  {value: 'ri-skull-line', label: 'skull'},

  {value: 'ri-skull-2-line', label: 'skull-2'},

  {value: 'ri-skull-2-line', label: 'skull-2'},

  {value: 'ri-men-line', label: 'men'},

  {value: 'ri-men-line', label: 'men'},

  {value: 'ri-women-line', label: 'women'},

  {value: 'ri-women-line', label: 'women'},

  {value: 'ri-travesti-line', label: 'travesti'},

  {value: 'ri-travesti-line', label: 'travesti'},

  {value: 'ri-genderless-line', label: 'genderless'},

  {value: 'ri-genderless-line', label: 'genderless'},

  {value: 'ri-open-arm-line', label: 'open-arm'},

  {value: 'ri-open-arm-line', label: 'open-arm'},

  {value: 'ri-body-scan-line', label: 'body-scan'},

  {value: 'ri-body-scan-line', label: 'body-scan'},

  {value: 'ri-parent-line', label: 'parent'},

  {value: 'ri-parent-line', label: 'parent'},

  {value: 'ri-robot-line', label: 'robot'},

  {value: 'ri-robot-line', label: 'robot'},

  {value: 'ri-robot-2-line', label: 'robot-2'},

  {value: 'ri-robot-2-line', label: 'robot-2'},

  {value: 'ri-aliens-line', label: 'aliens'},

  {value: 'ri-aliens-line', label: 'aliens'},

  {value: 'ri-bear-smile-line', label: 'bear-smile'},

  {value: 'ri-bear-smile-line', label: 'bear-smile'},

  {value: 'ri-mickey-line', label: 'mickey'},

  {value: 'ri-mickey-line', label: 'mickey'},

  {value: 'ri-criminal-line', label: 'criminal'},

  {value: 'ri-criminal-line', label: 'criminal'},

  {value: 'ri-ghost-line', label: 'ghost'},

  {value: 'ri-ghost-line', label: 'ghost'},

  {value: 'ri-ghost-2-line', label: 'ghost-2'},

  {value: 'ri-ghost-2-line', label: 'ghost-2'},

  {value: 'ri-ghost-smile-line', label: 'ghost-smile'},

  {value: 'ri-ghost-smile-line', label: 'ghost-smile'},

  {value: 'ri-star-smile-line', label: 'star-smile'},

  {value: 'ri-star-smile-line', label: 'star-smile'},

  {value: 'ri-spy-line', label: 'spy'},

  {value: 'ri-spy-line', label: 'spy'},

  {value: 'ri-User & Faces114-line', label: 'User & Faces114'},

  {value: 'ri-sun-line', label: 'sun'},

  {value: 'ri-sun-line', label: 'sun'},

  {value: 'ri-moon-line', label: 'moon'},

  {value: 'ri-moon-line', label: 'moon'},

  {value: 'ri-flashlight-line', label: 'flashlight'},

  {value: 'ri-flashlight-line', label: 'flashlight'},

  {value: 'ri-cloudy-line', label: 'cloudy'},

  {value: 'ri-cloudy-line', label: 'cloudy'},

  {value: 'ri-cloudy-2-line', label: 'cloudy-2'},

  {value: 'ri-cloudy-2-line', label: 'cloudy-2'},

  {value: 'ri-mist-line', label: 'mist'},

  {value: 'ri-mist-line', label: 'mist'},

  {value: 'ri-foggy-line', label: 'foggy'},

  {value: 'ri-foggy-line', label: 'foggy'},

  {value: 'ri-cloud-windy-line', label: 'cloud-windy'},

  {value: 'ri-cloud-windy-line', label: 'cloud-windy'},

  {value: 'ri-windy-line', label: 'windy'},

  {value: 'ri-windy-line', label: 'windy'},

  {value: 'ri-rainy-line', label: 'rainy'},

  {value: 'ri-rainy-line', label: 'rainy'},

  {value: 'ri-drizzle-line', label: 'drizzle'},

  {value: 'ri-drizzle-line', label: 'drizzle'},

  {value: 'ri-showers-line', label: 'showers'},

  {value: 'ri-showers-line', label: 'showers'},

  {value: 'ri-heavy-showers-line', label: 'heavy-showers'},

  {value: 'ri-heavy-showers-line', label: 'heavy-showers'},

  {value: 'ri-thunderstorms-line', label: 'thunderstorms'},

  {value: 'ri-thunderstorms-line', label: 'thunderstorms'},

  {value: 'ri-hail-line', label: 'hail'},

  {value: 'ri-hail-line', label: 'hail'},

  {value: 'ri-snowy-line', label: 'snowy'},

  {value: 'ri-snowy-line', label: 'snowy'},

  {value: 'ri-sun-cloudy-line', label: 'sun-cloudy'},

  {value: 'ri-sun-cloudy-line', label: 'sun-cloudy'},

  {value: 'ri-moon-cloudy-line', label: 'moon-cloudy'},

  {value: 'ri-moon-cloudy-line', label: 'moon-cloudy'},

  {value: 'ri-tornado-line', label: 'tornado'},

  {value: 'ri-tornado-line', label: 'tornado'},

  {value: 'ri-typhoon-line', label: 'typhoon'},

  {value: 'ri-typhoon-line', label: 'typhoon'},

  {value: 'ri-haze-line', label: 'haze'},

  {value: 'ri-haze-line', label: 'haze'},

  {value: 'ri-haze-2-line', label: 'haze-2'},

  {value: 'ri-haze-2-line', label: 'haze-2'},

  {value: 'ri-sun-foggy-line', label: 'sun-foggy'},

  {value: 'ri-sun-foggy-line', label: 'sun-foggy'},

  {value: 'ri-moon-foggy-line', label: 'moon-foggy'},

  {value: 'ri-moon-foggy-line', label: 'moon-foggy'},

  {value: 'ri-moon-clear-line', label: 'moon-clear'},

  {value: 'ri-moon-clear-line', label: 'moon-clear'},

  {value: 'ri-temp-hot-line', label: 'temp-hot'},

  {value: 'ri-temp-hot-line', label: 'temp-hot'},

  {value: 'ri-temp-cold-line', label: 'temp-cold'},

  {value: 'ri-temp-cold-line', label: 'temp-cold'},

  {value: 'ri-celsius-line', label: 'celsius'},

  {value: 'ri-celsius-line', label: 'celsius'},

  {value: 'ri-fahrenheit-line', label: 'fahrenheit'},

  {value: 'ri-fahrenheit-line', label: 'fahrenheit'},

  {value: 'ri-water-percent-line', label: 'water-percent'},

  {value: 'ri-water-percent-line', label: 'water-percent'},

  {value: 'ri-fire-line', label: 'fire'},

  {value: 'ri-fire-line', label: 'fire'},

  {value: 'ri-blaze-line', label: 'blaze'},

  {value: 'ri-blaze-line', label: 'blaze'},

  {value: 'ri-earthquake-line', label: 'earthquake'},

  {value: 'ri-earthquake-line', label: 'earthquake'},

  {value: 'ri-flood-line', label: 'flood'},

  {value: 'ri-flood-line', label: 'flood'},

  {value: 'ri-meteor-line', label: 'meteor'},

  {value: 'ri-meteor-line', label: 'meteor'},

  {value: 'ri-rainbow-line', label: 'rainbow'},

  {value: 'ri-rainbow-line', label: 'rainbow'},

  {value: 'ri-shining-line', label: 'shining'},

  {value: 'ri-shining-line', label: 'shining'},

  {value: 'ri-shining-2-line', label: 'shining-2'},

  {value: 'ri-shining-2-line', label: 'shining-2'},

  {value: 'ri-sparkling-line', label: 'sparkling'},

  {value: 'ri-sparkling-line', label: 'sparkling'},

  {value: 'ri-sparkling-2-line', label: 'sparkling-2'},

  {value: 'ri-sparkling-2-line', label: 'sparkling-2'},

  {value: 'ri-Weather80-line', label: 'Weather80'},

  {value: 'ri-basketball-line', label: 'basketball'},

  {value: 'ri-basketball-line', label: 'basketball'},

  {value: 'ri-bell-line', label: 'bell'},

  {value: 'ri-bell-line', label: 'bell'},

  {value: 'ri-billiards-line', label: 'billiards'},

  {value: 'ri-billiards-line', label: 'billiards'},

  {value: 'ri-boxing-line', label: 'boxing'},

  {value: 'ri-boxing-line', label: 'boxing'},

  {value: 'ri-cake-line', label: 'cake'},

  {value: 'ri-cake-line', label: 'cake'},

  {value: 'ri-cake-2-line', label: 'cake-2'},

  {value: 'ri-cake-2-line', label: 'cake-2'},

  {value: 'ri-cake-3-line', label: 'cake-3'},

  {value: 'ri-cake-3-line', label: 'cake-3'},

  {value: 'ri-door-lock-line', label: 'door-lock'},

  {value: 'ri-door-lock-line', label: 'door-lock'},

  {value: 'ri-door-lock-box-line', label: 'door-lock-box'},

  {value: 'ri-door-lock-box-line', label: 'door-lock-box'},

  {value: 'ri-football-line', label: 'football'},

  {value: 'ri-football-line', label: 'football'},

  {value: 'ri-game-line', label: 'game'},

  {value: 'ri-game-line', label: 'game'},

  {value: 'ri-handbag-line', label: 'handbag'},

  {value: 'ri-handbag-line', label: 'handbag'},

  {value: 'ri-key-line', label: 'key'},

  {value: 'ri-key-line', label: 'key'},

  {value: 'ri-key-2-line', label: 'key-2'},

  {value: 'ri-key-2-line', label: 'key-2'},

  {value: 'ri-knife-line', label: 'knife'},

  {value: 'ri-knife-line', label: 'knife'},

  {value: 'ri-knife-blood-line', label: 'knife-blood'},

  {value: 'ri-knife-blood-line', label: 'knife-blood'},

  {value: 'ri-lightbulb-line', label: 'lightbulb'},

  {value: 'ri-lightbulb-line', label: 'lightbulb'},

  {value: 'ri-lightbulb-flash-line', label: 'lightbulb-flash'},

  {value: 'ri-lightbulb-flash-line', label: 'lightbulb-flash'},

  {value: 'ri-outlet-line', label: 'outlet'},

  {value: 'ri-outlet-line', label: 'outlet'},

  {value: 'ri-outlet-2-line', label: 'outlet-2'},

  {value: 'ri-outlet-2-line', label: 'outlet-2'},

  {value: 'ri-plug-line', label: 'plug'},

  {value: 'ri-plug-line', label: 'plug'},

  {value: 'ri-plug-2-line', label: 'plug-2'},

  {value: 'ri-plug-2-line', label: 'plug-2'},

  {value: 'ri-ping-pong-line', label: 'ping-pong'},

  {value: 'ri-ping-pong-line', label: 'ping-pong'},

  {value: 'ri-reserved-line', label: 'reserved'},

  {value: 'ri-reserved-line', label: 'reserved'},

  {value: 'ri-shirt-line', label: 'shirt'},

  {value: 'ri-shirt-line', label: 'shirt'},

  {value: 'ri-sword-line', label: 'sword'},

  {value: 'ri-sword-line', label: 'sword'},

  {value: 'ri-t-shirt-line', label: 't-shirt'},

  {value: 'ri-t-shirt-line', label: 't-shirt'},

  {value: 'ri-t-shirt-2-line', label: 't-shirt-2'},

  {value: 'ri-t-shirt-2-line', label: 't-shirt-2'},

  {value: 'ri-t-shirt-air-line', label: 't-shirt-air'},

  {value: 'ri-t-shirt-air-line', label: 't-shirt-air'},

  {value: 'ri-umbrella-line', label: 'umbrella'},

  {value: 'ri-umbrella-line', label: 'umbrella'},

  {value: 'ri-character-recognition-line', label: 'character-recognition'},

  {value: 'ri-character-recognition-line', label: 'character-recognition'},

  {value: 'ri-voice-recognition-line', label: 'voice-recognition'},

  {value: 'ri-voice-recognition-line', label: 'voice-recognition'},

  {value: 'ri-leaf-line', label: 'leaf'},

  {value: 'ri-leaf-line', label: 'leaf'},

  {value: 'ri-plant-line', label: 'plant'},

  {value: 'ri-plant-line', label: 'plant'},

  {value: 'ri-seedling-line', label: 'seedling'},

  {value: 'ri-seedling-line', label: 'seedling'},

  {value: 'ri-tree-line', label: 'tree'},

  {value: 'ri-tree-line', label: 'tree'},

  {value: 'ri-recycle-line', label: 'recycle'},

  {value: 'ri-recycle-line', label: 'recycle'},

  {value: 'ri-scales-2-line', label: 'scales-2'},

  {value: 'ri-scales-2-line', label: 'scales-2'},

  {value: 'ri-scales-line', label: 'scales'},

  {value: 'ri-scales-line', label: 'scales'},

  {value: 'ri-scales-3-line', label: 'scales-3'},

  {value: 'ri-scales-3-line', label: 'scales-3'},

  {value: 'ri-fridge-line', label: 'fridge'},

  {value: 'ri-fridge-line', label: 'fridge'},

  {value: 'ri-wheelchair-line', label: 'wheelchair'},

  {value: 'ri-wheelchair-line', label: 'wheelchair'},

  {value: 'ri-cactus-line', label: 'cactus'},

  {value: 'ri-cactus-line', label: 'cactus'},

  {value: 'ri-door-line', label: 'door'},

  {value: 'ri-door-line', label: 'door'},

  {value: 'ri-door-open-line', label: 'door-open'},

  {value: 'ri-door-open-line', label: 'door-open'},

  {value: 'ri-door-closed-line', label: 'door-closed'},

  {value: 'ri-door-closed-line', label: 'door-closed'},

  {value: 'ri-graduation-cap-line', label: 'graduation-cap'},

  {value: 'ri-graduation-cap-line', label: 'graduation-cap'},

  {value: 'ri-box-1-line', label: 'box-1'},

  {value: 'ri-box-1-line', label: 'box-1'},

  {value: 'ri-box-2-line', label: 'box-2'},

  {value: 'ri-box-2-line', label: 'box-2'},

  {value: 'ri-box-3-line', label: 'box-3'},

  {value: 'ri-box-3-line', label: 'box-3'},

  {value: 'ri-candle-line', label: 'candle'},

  {value: 'ri-candle-line', label: 'candle'},

  {value: 'ri-cross-line', label: 'cross'},

  {value: 'ri-cross-line', label: 'cross'},

  {value: 'ri-infinity-line', label: 'infinity'},

  {value: 'ri-infinity-line', label: 'infinity'}
];


