<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="{{'MENUITEMS.CALENDAR.TEXT' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <!--    <div class="col-3">-->
    <!--      <div class="card h-100">-->
    <!--        <div class="card-body">-->


    <!--          <div id="external-events">-->
    <!--            <br>-->
    <!--            <p class="text-muted">Drag and drop your event or click in the calendar</p>-->
    <!--            <div class="external-event fc-event bg-success" data-class="bg-success">-->
    <!--              <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>New Event-->
    <!--              Planning-->
    <!--            </div>-->
    <!--            <div class="external-event fc-event bg-info" data-class="bg-info">-->
    <!--              <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>Meeting-->
    <!--            </div>-->
    <!--            <div class="external-event fc-event bg-warning" data-class="bg-warning">-->
    <!--              <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>Generating-->
    <!--              Reports-->
    <!--            </div>-->
    <!--            <div class="external-event fc-event bg-danger" data-class="bg-danger">-->
    <!--              <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i>Create-->
    <!--              New theme-->
    <!--            </div>-->
    <!--          </div>-->

    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="app-calendar">
            <!-- calendar -->
            <full-calendar #calendar defaultView="dayGridMonth" [header]="{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }" [plugins]="calendarPlugins" [eventLimit]="true" [editable]="false"
                           themeSystem='bootstrap' [weekends]="calendarWeekends" [bootstrapFontAwesome]="false"
                           deepChangeDetection="true" [events]="calendarEvents"
                           (dateClick)="openModal(content, $event)" [minTime]="curentTime"
                           (eventClick)="openDetailsModal(dtContent, $event)">
            </full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Event Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Ajouter Nouvel Evénement</h5>
    <button type="button" class="close" aria-hidden="true" (click)="closeEventModal()">×</button>
  </div>
  <div class="modal-body p-3">
    <form [formGroup]="eventForm" (ngSubmit)="saveEvent()">
      <div class="row form-row col-12">
        <div class="form-group col-12 ">
          <label class="control-label">Evenement :</label>
          <span class="text-danger ml-2">*</span>
          <div class="field">
            <p-dropdown [options]="listEvents" styleClass="ui-fluid col-lg-12"
                        [ngClass]="{ 'is-invalid': submitted && deF.codeEvent.errors}"
                        optionLabel="evtDescLoc" placeholder="Choisissez un Evenement"
                        (onChange)="onSelectEvent($event)"></p-dropdown>

            <div *ngIf="submitted && deF.codeEvent.errors" class="invalid-feedback">
              <div *ngIf="deF.codeEvent.errors.required">Le code d'événement est obligatoire</div>
            </div>
          </div>
        </div>

        <div class="col-12" *ngIf="eventFileFlag">
          <div class="form-group">
            <div class="field">
              <app-custom-file-upload
                [uploadedFiles]="uploadedFiles"
                (fileUploaded)="onFileUploaded($event)"
                [ngClass]="{ 'is-invalid': deF.eventFiles.errors}"
                (validateUpload)="onValidateUpload(uploadedFiles)">
              </app-custom-file-upload>
              <div *ngIf="submitted && deF.eventFiles.errors" class="invalid-feedback">
                <div *ngIf="deF.eventFiles.errors.required">piéces jointes obligatoires</div>
              </div>
            </div>
          </div>
        </div>


        <div class="form-group col-12 mt-2"><label class="control-label">Date Fin : </label>
          <span class="text-danger ml-2 mr-2">*</span>
          <p-calendar [baseZIndex]="10001" appendTo="body" id="EndDate" required
                      [showIcon]="true" styleClass="ui-fluid col-lg-12"
                      dateFormat="dd/mm/yy" formControlName="dateFin"
                      name="epeEndDate" [minDate]='deF.dateDebut.value' inputId="icon"
                      [showTime]="true" [inputStyle]="{'width':'350px'}"
                      [ngClass]="{'is-invalid': submitted  && deF.dateFin.errors }">

          </p-calendar>
          <div *ngIf="submitted && deF.dateFin.errors" class="invalid-feedback">
            <div *ngIf="deF.dateFin.errors.required">Date fin est obligatoire</div>
          </div>
        </div>
      </div>

      <div class="text-right pt-4">
        <button pButton pRipple label="Annuler" (click)="closeEventModal()" icon="pi pi-check"
                class="p-button-text"></button>
        <button pButton pRipple label="Enregistrer"
                icon="pi pi-check" class="p-button-text"></button>
      </div>
    </form>
  </div>
</ng-template>

<!-- détails event modal -->
<ng-template #dtContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Détails de l'évènement:</h5>
    <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
  </div>
  <form #eventForm="ngForm">
    <div class="modal-body p-3">
      <div class="col">
        <div class="card-evt background-white box-shadow-card-evt pb-2 pl-4 pl-4">
          <div class="p-card-body pl-2 pr-2 background-white pb-2">
            <div class="date-evt">
              <div class="date-evt-left"><span>{{ rhEmployeEvt.epeDate | date:'dd' }}</span></div>
              <div class="date-evt-right">
                <span class="font-size-20">{{ rhEmployeEvt.epeDate | date:'MMM' }}</span>
                <span class="font-size-16 font-weight-bolder">2023</span>
              </div>
            </div>

            <h1>  {{rhEmployeEvt.stEvents.evtDescLoc}} </h1>
            <div class="d-flex align-items-center">
              <span class="font-size-18 mr-1">Organiser par : </span> <span
              class="badge font-size-18 badge-soft-success "> {{rhEmployeEvt.epeCreerPar}} </span>
            </div>
          </div>
          <div class="card-footer border-top background-white">
            <div class="d-flex align-items-center  justify-content-between pl-1 pr-1">
              <div class="badge font-size-12 badge-soft-dark"> {{ rhEmployeEvt.epeDate | date:'MMM d, y, h:mm:ss a' }} --
                {{ rhEmployeEvt.epeEndDate | date:'MMM d, y, h:mm:ss a' }}</div>
              <i class="fa fa-map-marker-alt font-size-18"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>

</ng-template>

