<div class="container-fluid ">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="{{'ABS_REQUEST.TEXT' |translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <p-toast></p-toast>
      <div class="card">
        <div class="card-body">

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-12">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                  <ng-template pTemplate="right">
                    <button pButton pRipple label="{{'ABS_REQUEST.LIST.NEW_REQUEST' |translate}}" icon="pi pi-plus"
                            class="p-button-success succes-color p-button-add p-mr-2"
                            (click)="showAddNewDemande()"></button>
                  </ng-template>
                  <ng-template pTemplate="left">
                    <div class="d-flex ">
                      <button type="button" pButton pRipple icon="pi pi-file-excel"
                              (click)="applicationService.exportExcel(demandeAbcenes)"
                              class="p-button-success p-button-outlined mr-2" pTooltip="XLS"
                              tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="pi pi-file-pdf"
                              (click)="applicationService.exportPdf(exportColumns,demandeAbcenes,document)"
                              class="p-button-danger p-button-outlined mr-2" pTooltip="PDF"
                              tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                </p-toolbar>
                <div class="card">
                  <app-loader *ngIf="blockedDocument"></app-loader>
                  <p-table [columns]="cols" [value]="demandeAbcenes" [responsive]="true" #absT
                           selectionMode="single" [(selection)]="selectedDemandeAbs" dataKey="rhDemandeAbsPK"
                           [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                           [paginator]="true" [rows]="4"
                           [totalRecords]="totalRecords"
                           [showCurrentPageReport]="true"
                           [tableStyle]="{ 'min-width': '100%' }"
                           currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                           [rowsPerPageOptions]="[5,10, 15, 50]">
                    <ng-template pTemplate="caption">

                      <div class="row">
                        <div class="p-toolbar-group-left col-lg-5 col-sm-12">
                          <div class="app-search d-lg-block" style="padding: 0 !important">
                            <div class="position-relative">
                              <input type="text" class="form-control" pInputText size="35"
                                     placeholder="{{'ABS_REQUEST.LIST.SEARCH' |translate}}"
                                     (input)="absT.filterGlobal($event.target.value, 'contains')"
                                     style="width:auto;text-align: left !important">
                              <span class="ri-search-line"></span>
                            </div>

                          </div>
                        </div>
                        <div class="p-toolbar-group-right col-lg-7 col-sm-12 mt-sm-2">
                          <div class="row form-row col-12">
                            <div class="form-group col-lg-6 col-sm-12 ">
                              <label class="control-label">{{'ABS_REQUEST.LIST.EXERCICE' |translate}}</label>
                              <div class="field">
                                <p-dropdown [options]="exercicesList"
                                            styleClass="ui-fluid col-lg-12"
                                            placeholder="{{'ABS_REQUEST.LIST.CHOISE_EXERCICE' |translate}}"
                                            optionLabel="label"
                                            [filter]="true" filterBy="label"
                                            (onChange)="onSelectExercice($event)">
                                </p-dropdown>
                              </div>
                            </div>

                            <div class="form-group col-lg-6 col-sm-12 ">
                              <label class="control-label">{{'ABS_REQUEST.LIST.STATUS' |translate}}</label>
                              <div class="field">
                                <p-dropdown [options]="statusList"
                                            styleClass="ui-fluid col-lg-12"
                                            placeholder="{{'ABS_REQUEST.LIST.CHOISE_STATUS' |translate}}"
                                            [filter]="true" filterBy="label"
                                            (onChange)="onSelectStatus($event)">
                                  <ng-template let-item pTemplate="selectedItem">
                                    <div class="badge font-size-12 align-content-center"
                                         ngClass="{{item.value === 0 ? 'badge-soft-info' : item.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{item.label}}</div>
                                  </ng-template>
                                  <ng-template let-st pTemplate="item">
                                    <div class="badge font-size-12 align-content-center"
                                         ngClass="{{st.value === 0 ? 'badge-soft-info' : st.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{st.label}}</div>
                                  </ng-template>
                                </p-dropdown>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns ; " [ngStyle]="{'width': col.width}">
                          {{col.header | translate}}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                      <tr>
                        <td *ngFor="let col of columns">
                        <span
                          *ngIf="col.field==='dabType'">{{applicationService.filterListDlvByAbrv(listAbsTypes, rowData[col.field]) }}</span>
                          <span ngClass="{{col.field ==='dabEmpNum' ? 'font-weight-bold' :''}}"
                                *ngIf="col.field!='dabDemandeDate' && col.field!='rhEmployes' && col.field!='dabDecision' && col.field!='actions' && col.field!='dabType'">  {{rowData[col.field]}} </span>
                          <span *ngIf="col.field ==='rhEmployes'">  {{rowData[col.field].empNomLoc }} </span>
                          <span
                            *ngIf="col.field ==='dabDemandeDate'">  {{rowData[col.field]| date:'longDate'}} </span>

                          <div class="badge font-size-12 " *ngIf="col.field ==='dabDecision'"
                               ngClass="{{rowData[col.field] === 'En cours' ? 'badge-soft-info' : rowData[col.field] === 'Validé' ? 'badge-soft-success' :'badge-soft-danger'}}">{{rowData[col.field]}}</div>
                          <!--ACTIONS TOOLS BARRE-->
                          <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">
                            <button pButton pRipple type="button" icon="pi pi-search"
                                    class="p-button-rounded p-button-text p-button-info  mr-2"
                                    pTooltip="Afficher details"
                                    (click)="showDetails(rowData)"></button>

                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                    class="p-button-rounded p-button-text p-button-warning  mr-2"
                                    pTooltip="modifier"
                                    (click)="showEditDemande(rowData , rowIndex)"
                                    [disabled]="rowData.dabStatus==1 || rowData.dabStatus==2"></button>

                            <!--                                  [disabled]="rowData.dabStatus==1 || rowData.dabStatus==2"-->

                            <button pButton pRipple type="button" icon="pi pi-check"
                                    [disabled]="rowData.dabStatus==1 || rowData.dabStatus==2"
                                    *ngIf="currentUser.roles.includes('ROLE_RH')"
                                    class="p-button-rounded p-button-text p-button-success mr-2"
                                    pTooltip="Valider"
                                    (click)="showValidateDemande(rowData,rowIndex)"></button>

                            <button pButton pRipple type="button" icon="pi pi-folder"
                                    *ngIf="currentUser.roles.includes('ROLE_RH')"
                                    class="p-button-rounded p-button-text p-button-success"
                                    pTooltip="Status"
                                    (click)="showStatusDemande(rowData)"></button>

                            <button pButton pRipple type="button" icon="pi pi-file-export"
                                    *ngIf="currentUser.roles.includes('ROLE_USER')"
                                    class="p-button-rounded p-button-text p-button-success"
                                    pTooltip="téléchargement de document"
                                    [disabled]="rowData.dabStatus==0"
                                    (click)="donloadDemandeAbs(rowData)"></button>


                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="columns.length">
                          No records found
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="p-d-flex p-ai-center p-jc-between">
                        Au total il y a {{demandeAbcenes ? data.length : 0 }} demandes.
                      </div>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>


            <!--            ADD+UPDATE DIALOG-->
            <form [formGroup]="demandeAbsForm" (ngSubmit)="saveDemandeAbs()">
              <p-dialog #pdBack [maximizable]="false "
                        [baseZIndex]="10000"
                        [(visible)]="demandeAbsDlg" [style]="{width: '90%'}"
                        header="{{'ABS_REQUEST.LIST.NEW_REQUEST' |translate}}" [modal]="true"
                        styleClass="p-fluid" id="demandeAbsDlg" [draggable]="false">

                <app-loader *ngIf="blockedDocument"></app-loader>
                <ng-template pTemplate="content"  class="pb-2">
                  <p-card>
                    <div class="row col-12 col-sm-12 form-row">
                      <div class="p-field col-12 col-lg-4">
                        <label>{{'ABS_REQUEST.FIELDS.EMPLOYEE' |translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field" *ngIf="currentUser.roles.includes('ROLE_RH') && !editMode">
                          <p-dropdown [options]="listEmployes"
                                      class="ui-fluid"
                                      [ngClass]="{ 'is-invalid': submitted && daF.employeeNum.errors && daF.employeeType.errors }"
                                      optionLabel="empNomLoc"
                                      placeholder="{{'ABS_REQUEST.LIST.CHOISE_EMPLOYEE' |translate}}"
                                      (onChange)="onSelectEmploye($event)" [disabled]="editMode"></p-dropdown>
                          <div *ngIf="submitted && daF.employeeNum.errors && daF.employeeType.errors"
                               class="invalid-feedback">
                            <div *ngIf="daF.employeeNum.errors.required && daF.employeeType.errors.required">
                              {{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}
                            </div>
                          </div>
                        </div>
                        <div class="field" *ngIf="!currentUser.roles.includes('ROLE_RH') || editMode">
                          <input id="employeeUs" type="text" pInputText [value]="selectedEmployee.empNomLoc" readonly
                                 name="employeeUs" class="form-control"/>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-4">
                        <label for="dabType">{{'ABS_REQUEST.FIELDS.ABSENCE_TYPE' |translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-dropdown id="dabType" [options]="listAbsTypes"
                                      [ngClass]="{ 'is-invalid': submitted && daF.absenceType.errors }"
                                      (onChange)="onSelectAbsType($event)"
                                      optionLabel="lvdDescLoc"
                                      placeholder="{{'ABS_REQUEST.LIST.CHOISE_ABS_TYPE' |translate}}">
                          </p-dropdown>
                          <div *ngIf="submitted && daF.absenceType.errors" class="invalid-feedback">
                            <div
                              *ngIf="daF.absenceType.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row col-12 col-sm-12 form-row mt-3">
                      <div class="p-field col-lg-12 col-sm-12">
                        <label for="dabDemandeDate">{{'ABS_REQUEST.FIELDS.FILES' |translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <app-custom-file-upload
                            [uploadedFiles]="uploadedFiles"
                            (fileUploaded)="onFileUploaded($event)"
                            (validateUpload)="onValidateUpload(uploadedFiles)"
                          ></app-custom-file-upload>
                        </div>
                      </div>
                    </div>

                    <div class="row col-12 col-sm-12 form-row mt-3">
                      <div class="p-field col-lg-4 col-sm-12">
                        <label for="dabDemandeDate">{{'ABS_REQUEST.FIELDS.ABSENCE_REQUEST_DATE' |translate}}"</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-calendar [baseZIndex]="10001" appendTo="body" id="dabDemandeDate" [showIcon]="true"
                                      (onSelect)="onDemandeDateChange($event)" dateFormat="dd/mm/yy"
                                      [showTime]="true" [showSeconds]="true" formControlName="demandeDate"
                                      [ngClass]="{ 'is-invalid': submitted && daF.demandeDate.errors }"
                                      [disabled]="daF.employeeType.value ==null && daF.employeeNum.value ==null"
                                      [minDate]='curentTime'
                                      [maxDate]="applicationService.addDaysToDate(curentTime,5)"
                                      name="dabDemandeDate" inputId="icon"></p-calendar>
                          <div *ngIf="submitted && daF.demandeDate.errors" class="invalid-feedback">
                            <div
                              *ngIf="daF.demandeDate.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>


                      <div class="p-field col-lg-4 col-sm-12">
                        <label for="dabDebutDate">{{'ABS_REQUEST.FIELDS.START_DATE' |translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-calendar [baseZIndex]="10001" appendTo="body" id="dabDebutDate"
                                      [disabled]="daF.demandeDate.value ==null" dateFormat="dd/mm/yy"
                                      [showTime]="true" [showSeconds]="true" [showIcon]="true"
                                      formControlName="dateDebut" inputId="icon"
                                      name="dabDebutDate" [minDate]="applicationService.toDate(daF.demandeDate.value)"
                                      [ngClass]="{ 'is-invalid': submitted && daF.dateDebut.errors }"></p-calendar>
                          <div *ngIf="submitted && daF.dateDebut.errors" class="invalid-feedback">
                            <div
                              *ngIf="daF.dateDebut.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-lg-4 col-sm-12">
                        <label for="dabFinDate">{{'ABS_REQUEST.FIELDS.END_DATE' |translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-calendar [baseZIndex]="10001" appendTo="body" id="dabFinDate"
                                      [disabled]="daF.dateDebut.value ==null" dateFormat="dd/mm/yy"
                                      [showTime]="true" [showSeconds]="true" [showIcon]="true"
                                      formControlName="dateFin" inputId="icon"
                                      name="dabDebutDate" (onSelect)="onSelectDateFin()"
                                      [minDate]="applicationService.addHoursToDate(daF.dateDebut.value,2)"
                                      [ngClass]="{ 'is-invalid': submitted && daF.dateFin.errors }"></p-calendar>
                          <div *ngIf="submitted && daF.dateFin.errors" class="invalid-feedback">
                            <div
                              *ngIf="daF.dateFin.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row mt-3 ">
                      <div class="p-field col-lg-4 col-sm-12">
                        <label for="dabContactTelephone">{{'ABS_REQUEST.FIELDS.CONTACT_PHONE' |translate}} </label>
                        <div class="field">
                          <p-inputMask mask="9 999-99-99-99" id="dabContactTelephone"
                                       name="dabContactTelephone" formControlName="phoneContact"
                                       placeholder="0999-999-999"></p-inputMask>
                        </div>
                      </div>

                      <div class="p-field col-lg-8 col-sm-12">
                        <label for="dabAddressLoc">{{'ABS_REQUEST.FIELDS.CONTACT_ADDRESS' |translate}} </label>
                        <div class="field">
                          <input id="dabAddressLoc" type="text" pInputText formControlName="addressContact"
                                 name="dabAddressLoc" class="form-control"/>
                        </div>
                      </div>
                    </div>
                  </p-card>
                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' | translate}}" icon="pi pi-times" class="p-button-text"
                          (click)="hideDialog()"></button>
                  <button pButton pRipple label="{{'EVENT.FIELD.SAVE' | translate}}" icon="pi pi-check" class="p-button-text"
                          type="submit"></button>
                </ng-template>

              </p-dialog>
            </form>

            <!--            VALIDATION DIALOG-->
            <form [formGroup]="validateDemandeAbsFrom"
                  (ngSubmit)="validateDemandeAbs(validateDemandeAbsFrom,validationStatus)">
              <p-dialog #pdBack [maximizable]="false"
                        [baseZIndex]="10000"
                        [(visible)]="validationDlg"
                        [style]="{width: '60%'}"
                        header="validation demande d'absence." [modal]="true"
                        styleClass="p-fluid" id="validerdemandeAbsDlg">
                <app-loader *ngIf="blockedDocument"></app-loader>
                <ng-template pTemplate="content">
                  <p-fieldset
                    legend="{{selectedDemandeAbs.rhEmployes.empNomLoc}} -  {{(applicationService.filterListDlvByAbrv(listAbsTypes,selectedDemandeAbs.dabType)).split(':')[1]}}"
                    class="pb-2">
                    <div class="row col-12 mt-2">
                      <div class="p-field col-lg-12 col-sm-12">
                        <label> Décision : </label>
                        <div class="field">
                          <p-toggleButton formControlName="status" onLabel="Confirmer" offLabel="Rejeter"
                                          onIcon="pi pi-check" [(ngModel)]="validationState"
                                          offIcon="pi pi-times" [style]="{'width': '10em'}"></p-toggleButton>
                        </div>
                      </div>

                      <div class="p-field col-lg-12 col-sm-12 mt-3">
                        <label for="remarques">Remarques : </label>
                        <div class="field">
                    <textarea id="remarques" formControlName="remarques" pInputTextarea
                              name="remarques" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && daF.remarques.errors }"> </textarea>
                          <div *ngIf="submitted && daF.remarques.errors" class="invalid-feedback">
                            <div
                              *ngIf="daF.remarques.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-fieldset>
                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' | translate}}" icon="pi pi-times" class="p-button-text"
                          (click)="hideValidateDemande()"></button>
                  <button pButton pRipple label="{{'EVENT.FIELD.SAVE' | translate}}" icon="pi pi-check" type="submit"
                          class="p-button-text"></button>
                </ng-template>

              </p-dialog>
            </form>

            <!--DETAILLES DIALOG-->
            <p-dialog #pdBack [maximizable]="false"
                      [baseZIndex]="10000"
                      [(visible)]="demandeAbsDtDlg"
                      header="{{'ABS_REQUEST.LIST.REQUEST_DETAILS' | translate}}"
                      [modal]="true" [draggable]="false"
                      styleClass="p-fluid" id="demandeAbsDtDlg">
              <p-message *ngIf="authService.currentUser().roles.includes(ERole.ROLE_USER)"
                         severity="{{selectedDemandeAbs.dabDecision === 'En cours' ? 'info' : selectedDemandeAbs.dabDecision === 'Validé' ? 'success' :'error'}}"
                         text="{{selectedDemandeAbs.dabDecision === 'En cours' ? 'Votre demande de congé est ' +selectedDemandeAbs.dabDecision : 'Votre demande de congé a été ' +selectedDemandeAbs.dabDecision }}"></p-message>
              <p-card
                header="{{selectedEmployee.rhEmployesPK.empNum}} : {{selectedEmployee.empNomLoc}}"
                *ngIf="demandeAbsDtDlg"
                subheader="{{applicationService.filterListDlvByAbrv(listAbsTypes,selectedDemandeAbs.dabType)}}"
                styleClass="p-card-shadow">
                <div class="col-12 row mt-4 ">
                  <div class="p-field col-lg-4 col-sm-12">
                    <label for="dabContactTelephone">{{'ABS_REQUEST.FIELDS.ABSENCE_DATE' |translate}} </label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeAbs.dabDebutDate | date:'fullDate'}}"
                             class="form-control"/>
                    </div>
                  </div>
                  <div class="p-field col-lg-4 col-sm-12">
                    <label for="dabContactTelephone">{{'ABS_REQUEST.FIELDS.RETURN_DATE' |translate}} </label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeAbs.dabFinDate | date:'fullDate'}}"
                             class="form-control"/>
                    </div>
                  </div>
                  <div class="p-field col-lg-2 col-sm-12">
                    <label for="dabContactTelephone">{{'ABS_REQUEST.FIELDS.DAYS' |translate}} </label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeAbs.dabNbrDays}}  jours"
                             class="form-control font-weight-bolder"/>
                    </div>

                  </div>

                  <div class="p-field col-lg-2 col-sm-12">
                    <label for="dabContactTelephone"> {{'ABS_REQUEST.FIELDS.HOURS' |translate}} </label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeAbs.dabHeurs}}  heurs"
                             class="form-control font-weight-bolder "/>
                    </div>

                  </div>
                </div>

                <div class="row col-12 mt-3">
                  <div class="p-field col-lg-12 col-sm-12">
                    <label for="dabRemarksLoc">{{'ABS_REQUEST.FIELDS.DECISION_REMARKS' |translate}} </label>
                    <div class="field">
                    <textarea pInputTextarea readonly value="{{selectedDemandeAbs.dabRemarksLoc}}"
                              name="dabRemarksLoc" id="dabRemarksLoc" class="form-control"> </textarea>
                    </div>
                  </div>
                </div>
              </p-card>

            </p-dialog>

            <!-- STATUS DIALOG-->
            <p-dialog #pdBack [maximizable]="false"
                      [baseZIndex]="10000"
                      [(visible)]="statusDlg" [style]="{width: '60%'}"
                      header="{{'ABS_REQUEST.LIST.REQUEST_STATUS' | translate}}"
                      [modal]="true" [draggable]="false"
                      styleClass="p-fluid" id="statusDlg">
              <p-card
                header="{{selectedEmployee.rhEmployesPK.empNum}} : {{selectedEmployee.empNomLoc}}"
                *ngIf="statusDlg"
                subheader="{{applicationService.filterListDlvByAbrv(listAbsTypes,selectedDemandeAbs.dabType)}}"
                styleClass="p-card-shadow">
                <p-table [value]="selectedDemandeAbs.rhDemandeAbsStatusList" responsiveLayout="scroll"
                         [responsive]="true">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>{{'ABS_REQUEST.HEADERS.CREATE_BY' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.CREATE_DATE' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.STATUS' |translate}}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-status>
                    <tr>
                      <td>{{status.dasCreatedPar}}</td>
                      <td>{{status.dasCreationDate | date:'longDate'}}</td>
                      <td>
                        <div class="badge font-size-12"
                             ngClass="{{status.dasStatus == 0 ? 'badge-soft-info' : status.dasStatus === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{status.dasInformation}}</div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-card>
            </p-dialog>

            <p-confirmDialog [style]="{width: '450px'}" [baseZIndex]="999999"></p-confirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
