import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';
// @ts-ignore
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DiLovDetails} from '../models/adm/DiLovDetails';


// tslint:disable-next-line:prefer-const
import FileSaver from 'file-saver';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {PAGES_URLS} from '../../pages/pages.urls';
import {DatePipe} from '@angular/common';
import {RhDocuments} from '../models/rh-ec/RhDocuments';
import {MessageService} from 'primeng/api';


@Injectable()
export class ApplicationService {
  listDlv: DiLovDetails[];
  currentTime: Date;
  lang: BehaviorSubject<string> = new BehaviorSubject<string>('fr');

  constructor(private httpClient: HttpClient, private translate: TranslateService, private router: Router, private datePipe: DatePipe, private messageService: MessageService) {
    this.translate.addLangs(['fr', 'en', 'ar']);
    this.currentTime = new Date();
  }


  public compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  public formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day > 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  public filterListDlvByAbrv(list: DiLovDetails[], id: string): string {
    list = list.filter(dlv => dlv.lvdAbrvLoc === id);
    return list.length > 0 ? list[0].lvdDescLoc : null;
  }

  public findDlvtByAbrv(list: DiLovDetails[], id: string): DiLovDetails {
    list = list.filter(dlv => dlv.lvdAbrvLoc === id);
    return list.length > 0 ? list[0] : new DiLovDetails();
  }

  public filterListDlvById(list: DiLovDetails[], id: string): string {
    list = list.filter(dlv => dlv.lvdId === id);
    return list.length > 0 ? list[0].lvdDescLoc : null;
  }

  public findDlvtById(list: DiLovDetails[], id: string): DiLovDetails {
    list = list.filter(dlv => dlv.lvdId === id);
    return list.length > 0 ? list[0] : new DiLovDetails();
  }


  exportPdf(exportColumns: any[], list: any[], document: string) {
    // @ts-ignore
    import('jspdf').then(jsPDF => {
      // @ts-ignore
      import('jspdf-autotable').then(x => {
        const doc = new jsPDF.default(0, 0);
        doc.setFontSize(17).setFont(undefined, 'bold');
        const splitTitle = doc.splitTextToSize(document, 180);
        doc.text(10, 20, splitTitle);
        doc.setFontSize(16);
        doc.autoTable(exportColumns, list, {
          startY: 25,
          margin: {horizontal: 7},
          styles: {columnWidth: 'wrap'},
          columnStyles: {text: {columnWidth: 'auto'}}
        });
        doc.save(document + '.pdf');
      });
    });
  }

  exportExcel(data: any[]) {
    // import('xlsx').then(xlsx => {
    //   const worksheet = xlsx.utils.json_to_sheet(data);
    //   const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    //   const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
    //   this.saveAsExcelFile(excelBuffer, 'Demande d\'absence');
    // });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  youtube_parser(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  goToLink(link: string) {
    window.location.href = link;
  }

  goToDetails(path: string, arg: any) {
    this.router.navigate([path, arg]);
  }

  public jasonParser(data: any) {
    return JSON.parse(JSON.stringify(data));
  }


  /**
   * Show successfull Save Dialog
   */
  showSuccefulSaveDialog(t: any, i: any) {
    Swal.fire({
      position: 'center',
      icon: i,
      title: t,
      showConfirmButton: false,
      timer: 2000
    });
  }

  showSuccessMessage() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success Message',
      detail: 'Opération effectuée avec succès'
    });
    // this.showConfDialog = false;
  }

  public showErrorMessage(message: string) {
    this.messageService.add({severity: 'error', summary: 'Message d\'erreur', detail: message});
  }

  /**
   * Object to jason object
   */

  convertToJasonObject(object: any) {
    return JSON.stringify(object);
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }


  getNumberOfDays(startDate: any, endDate: any): number | null {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return null; // Invalid date format
    }
    const timeDifference = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  }

  addDaysToDate(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addHoursToDate(date: Date, hours: number): Date {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

  toDate(stringDate: string): Date {
    return new Date(stringDate);
  }

  convertDateString(dateString: string): Date {
    const date = new Date(dateString);
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    return formattedDate ? new Date(formattedDate) : null;
  }

  areListsEqual<T>(list1: T[], list2: T[]): boolean {
    // Check if either list is undefined or null
    if (!list1 || !list2) {
      return false;
    }
    // Check if both lists have the same length
    if (list1.length !== list2.length) {
      return false;
    }
    // Compare each element of the lists
    console.log(list1.every((element, index) => element === list2[index]));
    return list1.every((element, index) => element === list2[index]);
  }

  strongPassword(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.value;
    if (password && !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(password)) {
      return {strongPassword: true};
    }
    return null;
  }

  calculateYears(date: any): number {
    return ((new Date().getFullYear()) - new Date(date).getFullYear());
  }

  calculateMounths(dateNaissance: any): number {
    return ((new Date().getFullYear()) - new Date(dateNaissance).getFullYear());
  }

  convertToRoleName(inputString: string, prefix: string = 'ROLE_'): string {
    const strippedString = inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const roleName = strippedString.toUpperCase().replace(/\s+/g, '_');
    if (!roleName.startsWith(prefix)) {
      return prefix + roleName;
    }
    return roleName;
  }

}
