<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="{{'EVENT.TEXT' |translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row" *ngIf="authenticationService.currentUserRoles().includes('ROLE_RH')">
    <div class="col-lg-12" *ngIf="!loading">
      <div class="card">
        <div class="card-body">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="right">
              <button pButton pRipple label="{{'EVENT.TEXT' |translate}}" icon="pi pi-plus"
                      class="p-button-success succes-color p-button-add p-mr-2"
                      (click)="showAddNewEvent()"></button>
            </ng-template>
          </p-toolbar>

          <p-table [columns]="cols" [value]="events" [responsive]="true" #evT
                   selectionMode="single" [(selection)]="selectedEvent" dataKey="stEventsPK"
                   [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                   [paginator]="true" [rows]="4"
                   [totalRecords]="totalRecords"
                   [showCurrentPageReport]="true"
                   [tableStyle]="{ 'min-width': '50rem' }"
                   currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                   [rowsPerPageOptions]="[5,10, 15, 50]">
            <ng-template pTemplate="caption">

              <div class="row">
                <div class="p-toolbar-group-left col-5">
                  <div class="app-search d-none d-lg-block" style="padding: 0 !important">
                    <div class="position-relative">
                      <input type="text" class="form-control" pInputText size="50"
                             placeholder="{{'ABS_REQUEST.LIST.SEARCH' |translate}}"
                             (input)="evT.filterGlobal($event.target.value, 'contains')"
                             style="width:auto;text-align: left !important">
                      <span class="ri-search-line"></span>
                    </div>

                  </div>
                </div>
                <!--                <div class="p-toolbar-group-right col-7">-->
                <!--                  <div class="row form-row col-12">-->
                <!--                    <div class="form-group col-6 ">-->
                <!--                      <label class="control-label">Exercice :</label>-->
                <!--                      <div class="field">-->
                <!--                        <p-dropdown [options]="exercicesList"-->
                <!--                                    styleClass="ui-fluid col-lg-12"-->
                <!--                                    placeholder="Choisissez un exercice"-->
                <!--                                    optionLabel="label"-->
                <!--                                    [filter]="true" filterBy="label"-->
                <!--                                    (onChange)="onSelectExercice($event)">-->
                <!--                        </p-dropdown>-->
                <!--                      </div>-->
                <!--                    </div>-->

                <!--                    <div class="form-group col-6 ">-->
                <!--                      <label class="control-label">Status :</label>-->
                <!--                      <div class="field">-->
                <!--                        <p-dropdown [options]="statusList"-->
                <!--                                    styleClass="ui-fluid col-lg-12"-->
                <!--                                    placeholder="Choisissez un status"-->
                <!--                                    [filter]="true" filterBy="label"-->
                <!--                                    (onChange)="onSelectStatus($event)">-->
                <!--                          <ng-template let-item pTemplate="selectedItem">-->
                <!--                            <div class="badge font-size-12 align-content-center"-->
                <!--                                 ngClass="{{item.value === 0 ? 'badge-soft-info' : item.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{item.label}}</div>-->
                <!--                          </ng-template>-->
                <!--                          <ng-template let-st pTemplate="item">-->
                <!--                            <div class="badge font-size-12 align-content-center"-->
                <!--                                 ngClass="{{st.value === 0 ? 'badge-soft-info' : st.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{st.label}}</div>-->
                <!--                          </ng-template>-->
                <!--                        </p-dropdown>-->

                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns ; " [ngStyle]="{'width': col.width}">
                  {{col.header | translate}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
              <tr>
                <td *ngFor="let col of columns">
                  <span ngClass="{{col.field ==='stEventsPK' ? 'font-weight-bold' :''}}"
                        *ngIf="col.field!='actions' && col.field!='evtCategorie'">  {{rowData[col.field].evtCode}} </span>

                  <span
                    *ngIf="col.field==='evtDescLoc'">{{rowData[col.field] }}</span>
                  <div class="badge font-size-12 " *ngIf="col.field ==='evtCategorie'"
                       ngClass="{{rowData[col.field] == 1 ? 'badge-soft-warning' : rowData[col.field] == 2 ? 'badge-soft-secondary' :'badge-soft-secondary'}}">
                    {{applicationService.filterListDlvById(listCategoriesEvent, rowData[col.field]) }}
                  </div>
                  <!--ACTIONS TOOLS BARRE-->
                  <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">
                    <button pButton pRipple type="button" icon="pi pi-search"
                            class="p-button-rounded p-button-text p-button-info  mr-2"
                            pTooltip="Afficher details"
                            (click)="showDetailsEvent(rowData)"></button>

                    <button pButton pRipple type="button" icon="pi pi-pencil"
                            class="p-button-rounded p-button-text p-button-warning  mr-2"
                            pTooltip="modifier" id="one"
                            (click)="showEditEvent(rowData , rowIndex)"></button>

                    <!--                                  [disabled]="rowData.dabStatus==1 || rowData.dabStatus==2"-->

                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length">
                  No records found
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                Au total il y a {{events ? data.length : 0 }} demandes.
              </div>
            </ng-template>
          </p-table>

          <!--ADD+UPDATE DIALOG-->
          <form [formGroup]="seteupEventForm" (ngSubmit)="saveEvent()">
            <p-dialog #pdBack
                      id="modal-container"
                      [baseZIndex]="10000"
                      [(visible)]="evtDlg"
                      [style]="{width: '80%'}"
                      header="{{showAdd ?('EVENT.TEXT' |translate) :
                      showEdits ? ('LEAVE_REQUEST.LIST.REQUEST_DETAILS' |translate) :
                      ('LEAVE_REQUEST.LIST.REQUEST_DETAILS' |translate)}}"
                      [modal]="true" [draggable]="false" [resizable]="false"
                      styleClass="p-fluid" id="evtDlg">
              <app-loader *ngIf="blockedDocument"></app-loader>
              <ng-template pTemplate="content">
                <p-card>
                  <div class="form-group">
                    <div class="row col-sm-12 form-row mb-2 ">
                      <div class="p-field col-12 col-lg-2">
                        <label class="control-label" for="codeEvent">{{'EVENT.FIELD.EVENT' |translate}}</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">
                          <input id="codeEvent" type="text" pInputText formControlName="codeEvent"
                                 name="codeEvent" class="form-control" readonly/>
                          <div *ngIf="submitted && seF.codeEvent.errors" class="invalid-feedback">
                            <div *ngIf="seF.codeEvent.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row col-sm-12 form-row mb-2 ">

                      <div class="p-field col-12 col-lg-4">
                        <label class="control-label" for="codeEvent">{{'EVENT.FIELD.CATEGORIE' |translate}}</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field" *ngIf="showAdd || showEdits">
                          <p-dropdown id="categoryEvent" [options]="listCategoriesEvent"
                                      [ngClass]="{ 'is-invalid': submitted && seF.categoryEvent.errors }"
                                      (onChange)="onSelectCategorie($event)"
                                      optionLabel="lvdDescLoc"
                                      styleClass="col-lg-12"
                                      [readonly]="showDetails"
                                      placeholder="Choisissez une catégoriee">
                          </p-dropdown>
                          <div *ngIf="submitted && seF.categoryEvent.errors" class="invalid-feedback">
                            <div *ngIf="seF.categoryEvent.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                        <div class="field" *ngIf="showDetails">
                          <input id="categoryEventDt" type="text" pInputText
                                 value="{{applicationService.filterListDlvById(listCategoriesEvent ,seF.categoryEvent.value )}}"
                                 name="categoryEventDt" class="form-control" [readonly]="showDetails"/>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-2">
                        <label class="control-label" for="icon">{{'EVENT.FIELD.ICON' |translate}}</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field" *ngIf="showEdits || showAdd">
                          <p-dropdown id="icon" [options]="iconsList"
                                      [ngClass]="{ 'is-invalid': submitted && seF.icon.errors }"
                                      (onChange)="onSelectIcon($event)"
                                      [filter]="true" filterBy="value"
                                      [readonly]="showDetails"
                                      styleClass="col-lg-12">
                            <ng-template let-item pTemplate="selectedItem">
                              <i class="fa {{item.value }}"></i>
                            </ng-template>
                            <ng-template let-icon pTemplate="item">
                              <div class="ui-helper-clearfix d-flex align-items-center justofy-content-between"
                                   style="position: relative;height:25px;">
                                <i style="font-size: 20px" class="{{icon.value}}"></i>
                              </div>
                            </ng-template>
                          </p-dropdown>
                          <div *ngIf="submitted && seF.icon.errors" class="invalid-feedback">
                            <div *ngIf="seF.icon.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>

                        <div class="field" *ngIf="showDetails">
                          <i class="{{seF.icon.value}}" style="font-size: 25px; font-weight: bold"></i>
                        </div>
                      </div>
                      <div class="p-field col-12 col-lg-2">
                        <label class="control-label" for="color">{{'EVENT.FIELD.COULEUR' |translate}} :</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">
                          <input id="color" type="color" pInputText formControlName="color"
                                 [ngClass]="{ 'is-invalid': submitted && seF.color.errors }"
                                 name="color" class="form-control" [readonly]="showDetails"/>
                        </div>
                        <div *ngIf="submitted && seF.color.errors" class="invalid-feedback">
                          <div *ngIf="seF.color.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>

                    </div>

                    <div class="row col-sm-12 form-row mb-2">
                      <div class="p-field col-12 col-lg-4">
                        <label class="control-label" for="nomLoc">{{'EVENT.FIELD.NOM_LOC' |translate}}:</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">

                          <input id="nomLoc" type="text" pInputText formControlName="nomLoc"
                                 name="nomLoc" [ngClass]="{ 'is-invalid': submitted && seF.nomLoc.errors }"
                                 class="form-control" [readonly]="showDetails"/>
                          <div *ngIf="submitted && seF.nomLoc.errors" class="invalid-feedback">
                            <div *ngIf="seF.nomLoc.errors.required">{{'EVENT.FIELD.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="p-field col-12 col-lg-4">
                        <label class="control-label" for="nomFrn">{{'EVENT.FIELD.NOM_FRN' |translate}} </label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">
                          <input id="nomFrn" type="text" pInputText formControlName="nomFrn"
                                 name="nomFrn" class="form-control" [readonly]="showDetails"/>
                        </div>
                      </div>
                      <div class="p-field col-12 col-lg-2">
                        <label class="control-label" for="abrLoc">{{'EVENT.FIELD.ABRV_LOC' |translate}} </label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">
                          <input id="abrLoc" type="text" pInputText formControlName="abrLoc"
                                 name="abrLoc" class="form-control" [readonly]="showDetails"/>
                        </div>
                      </div>
                      <div class="p-field col-12 col-lg-2">
                        <label class="control-label" for="abrFrn">{{'EVENT.FIELD.ABRV_FRN' |translate}}</label>
                        <span class="text-danger ml-2 mr-2">*</span>
                        <div class="field">
                          <input id="abrFrn" type="text" pInputText formControlName="abrFrn"
                                 name="abrFrn" class="form-control" [readonly]="showDetails"/>
                        </div>
                      </div>

                    </div>

                    <div class="row col-sm-12 form-row mb-2 ">
                      <div class="p-field col-12 col-lg-2">
                        <label>{{'EVENT.FIELD.ACTIVE_FLAG' |translate}}</label>
                        <p-toggleButton styleClass="form-control" formControlName="activeFlag" onIcon="pi pi-check"
                                        onLabel="{{'EVENT.FIELD.ACTIVE' |translate}}" [disabled]="showDetails"
                                        offIcon="pi pi-times" offLabel="{{'EVENT.FIELD.INACTIVE' |translate}}"></p-toggleButton>
                      </div>

                      <div class="p-field col-12 col-lg-2">
                        <label>File flag :</label>
                        <p-toggleButton styleClass="form-control" formControlName="fileFlag" onIcon="pi pi-file"
                                        onLabel="{{'EVENT.FIELD.ACTIVE' |translate}}" [disabled]="showDetails"
                                        offIcon="pi pi-times" offLabel="{{'EVENT.FIELD.INACTIVE' |translate}}"></p-toggleButton>
                      </div>

                    </div>
                  </div>

                </p-card>
              </ng-template>
              <ng-template pTemplate="footer">
                <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' |translate}}" icon="pi pi-times" class="p-button-text"
                        (click)="hideDialog()"></button>

                <button pButton pRipple label="{{'EVENT.FIELD.SAVE' |translate}}" icon="pi pi-check"
                        class="p-button-text  float-right" *ngIf="showEdits || showAdd"
                        type="submit"></button>

              </ng-template>
            </p-dialog>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
