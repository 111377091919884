import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {RhEmployes, RhEmployesPK} from '../../models/rh-ec/RhEmployes';
import {User} from '../../models/auth.models';
import {AuthenticationService} from '../auth.service';
import {EmployeeData} from '../../models/payloads/responces/EmployeeData';
import * as empty from 'firebase/empty-import';


@Injectable({
  providedIn: 'root'
})
export class EmployesService {

  selectedEmployData = new EmployeeData();
  currentUser: User = new User();

  constructor(private http: HttpClient, private authService: AuthenticationService) {
  }

  findAllEmployes(): Observable<any> {
    return this.http.get<any>(PAGES_URLS.FIND_ALL_EMPLOYES_ABS);
  }

  findAllEmployesByresponsables(empNum: string): Observable<any> {
    const params = new HttpParams()
      .set('empNum', empNum);
    return this.http.get<any>(PAGES_URLS.FIND_ALL_EMPLOYES_BY_RESP, {params});
  }

  findEmploye(empNum: string, empType: number): Observable<EmployeeData> {
    const params = new HttpParams()
      .set('empNum', empNum).set('empType', empType.toString());
    return this.http.get<any>(PAGES_URLS.FIND_EMPLOYES, {
      params
    });
  }

  _getCurrentEmploy(): EmployeeData {
    this.currentUser = this.authService.currentUser();
    this.findEmploye(this.currentUser.empNum, 1).subscribe(data => {
      this.selectedEmployData.rhEmployes = new RhEmployes();
      this.selectedEmployData.rhEmployes.rhEmployesPK = new RhEmployesPK();
      this.selectedEmployData.rhEmployes = data.rhEmployes;
      this.selectedEmployData.employeePost = data.employeePost;
      this.selectedEmployData.profilePicture = data.profilePicture;
      this.selectedEmployData.emploExperiencesList = data.emploExperiencesList;
    });
    return this.selectedEmployData;
  }

  findMaxEmpNum(): any {
    return this.http.get<any>(PAGES_URLS.MAX_EMPLOYE_NUM);
  }

}
