import { Component, OnInit } from '@angular/core';
import {User} from '../../core/models/auth.models';
import {ApplicationService} from '../../core/services/application.service';
import {FormBuilder} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {DemandeCongesService} from '../../core/services/rh-ec/demandeCongesService';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-roles',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  currentUser: User;

  breadCrumbItems: Array<{}>;
  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, public employesService: EmployesService, public dilovService: DilovService, private messageService: MessageService, private confirmationService: ConfirmationService, public authService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser();
    this.breadCrumbItems = [{label: 'Dashbord'}, {label: 'Roles', active: true}];
  }

}
