import {DiLovMaitres} from './DiLovMaitres';

export class DiLovDetails {
  lvdGrpId: number;
  lvdCmpId: number;
  lvdSysId: number;
  lvdLvmId: number;
  lvdId: string;
  lvdDescLoc: string;
  lvdDescFrn: string;
  lvdAbrvLoc: string;
  lvdAbrvFrn: string;
  lvdActiveFlag: number;
  lvdInactiveDate: Date;
  lvdInactivePour: string;
  lvdCreerPar: string;
  lvdCreationDate: Date;
  lvdModifierPar: string;
  lvdModificationDate: Date;
  lvdLvdId: number;
  diLovMaitres: DiLovMaitres;
}


