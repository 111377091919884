import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';
import {LanguageService} from '../../core/services/language.service';
import {TranslateModule} from '@ngx-translate/core';

import {TopbarComponent} from './topbar/topbar.component';
import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CustomFileUploadComponent} from './custom-file-upload/custom-file-upload.component';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {WizardComponent} from './wizard/wizard.component';
import {ArchwizardModule} from 'angular-archwizard';
import {UiModule} from '../../shared/ui/ui.module';
import {ButtonModule} from 'primeng/button';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [TopbarComponent, FooterComponent, SidebarComponent, CustomFileUploadComponent , PageNotFoundComponent , WizardComponent ],
    imports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        ClickOutsideModule,
        RouterModule,
        TableModule,
        PanelModule,
        ArchwizardModule,
        UiModule,
        ButtonModule
    ],
  exports: [TopbarComponent, FooterComponent, SidebarComponent, CustomFileUploadComponent , PageNotFoundComponent , WizardComponent],
  providers: [LanguageService]
})
export class SharedModule {
}
