import {RhEmployes} from '../../rh-ec/RhEmployes';
import {RhExperiences} from '../../rh-ec/RhExperiences';
import {RhFormations} from '../../rh-ec/RhFormations';

export class EmployeeData {

  rhEmployes: RhEmployes;
  employeePost: string;
  profilePicture: any;
  emploExperiencesList: RhExperiences[];
  formationsList: RhFormations[];

}
