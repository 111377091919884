import {ChangeDetectorRef, Component, OnInit, Renderer2} from '@angular/core';
import {User} from '../../core/models/auth.models';
import {ApplicationService} from '../../core/services/application.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {RhUsersApp} from '../../core/models/security/RhUsersApp';
import {UsersService} from '../../core/services/rh-ec/usersService';
import {UtilConstants} from '../../core/helpers/util-constants';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {HttpParams} from '@angular/common/http';
import {File} from '../../core/models/transient/File';
import {RhRoles} from '../../core/models/security/RhRoles';
import {UsRolesService} from '../../core/services/rh-ec/usRolesService';
import {RhUsersAppRoles, RhUsersAppRolesPK} from '../../core/models/security/RhUsersAppRoles';
import {PathName} from '../../core/helpers/path-name';

@Component({
  selector: 'app-setup-events',
  templateUrl: './users-app.component.html',
  styleUrls: ['./users-app.component.scss']
})
export class UsersAppComponent implements OnInit {

  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, public cookiesService: CookieService,
              public employesService: EmployesService, public dilovService: DilovService, private messageService: MessageService,
              private usersService: UsersService, private confirmationService: ConfirmationService, private cdr: ChangeDetectorRef,
              public authService: AuthenticationService, public rolesService: UsRolesService, private router: Router) {
  }

  cookieValue;

  document: string;

  currentUser: User;
  currentEmpNum: string;
  editMode = false;

  breadCrumbItems: Array<{}>;


  // Form Controll attributs
  userAppForm: FormGroup;
  editUserAppForm: FormGroup;
  submitted = false;
  error = '';


  // data Attributes
  // Data
  data: RhUsersApp[];
  users: RhUsersApp[];
  totalRecords: number;
  selectedUser = new RhUsersApp();
  rowIndex: any;

  // Dialog Values
  usersAppDtDlg = false;
  usersAppDlg = false;

  // Lazy attributes
  cols: any[];
  exportColumns: any[];
  loading = true;

  // items list attributs
  listCivilites: DiLovDetails[] = [];
  listGenres: DiLovDetails[] = [];
  listTitres: DiLovDetails[] = [];

  // profile attricuts
  maxSize = 5000000; // 5mb
  maxSizeErr = false;
  readonly utilConstant = UtilConstants;
  newProfile: File = new File();
  newProfileStream: string = this.utilConstant.EMPTY_PROFILE_BASE64;

  // role attributes
  roles: RhRoles[] = [];
  userRoles: RhRoles[] = [];
  listuserRoles: RhUsersAppRoles[] = [];
  selectedRole: string[] = [];
  editRolesDlg = false;
  maxIdUsersRoles = 0;


  blockedDocument = false;


  public readonly UtilConstants = UtilConstants;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser();
    this.breadCrumbItems = [{label: 'Dashbord'}, {label: 'Users App', active: true}];
    this._fetchData();
    this.getListCivilte();
    this.getlistGenres();
    this.getlistTitres();
    this.getListRoles();
    // this.userRoles = this.authService.currentUser().roles;

    /**
     * Template cols
     */
    this.cols = [
      {
        field: 'usrImage',
        header: '#',
        width: '7%'
      },
      {
        field: 'username',
        header: 'USER_APP.HEADERS.USERNAME',
        width: '18%'
      },
      {
        field: 'usrFirstName',
        header: 'USER_APP.HEADERS.FIRST_NAME',
        width: '12%'
      },
      {
        field: 'usrLastName',
        header: 'USER_APP.HEADERS.LAST_NAME',
        width: '12%'
      },
      {
        field: 'usrDdn',
        header: 'USER_APP.HEADERS.DATE_OF_BIRTH',
        width: '15%'
      },
      {
        field: 'usrMobile',
        header: 'USER_APP.HEADERS.MOBILE',
        width: '10%'
      },
      {
        field: 'rhUsersAppRolesList',
        header: 'USER_APP.HEADERS.ROLE',
        width: '10%'
      },
      {
        field: 'usrActiveFlag',
        header: 'USER_APP.HEADERS.STATUS',
        width: '10%'
      },
      {
        field: 'actions',
        header: 'USER_APP.HEADERS.ACTIONS',
        width: '8%'
      }
    ];

    /**
     * init UsersApp  form groupe
     */
    this.userAppForm = this.formBuilder.group({
      username: ['', Validators.required],
      usrEmail: ['', [Validators.required, Validators.email]],
      usrCivilite: ['', Validators.required],
      usrTitre: ['', Validators.required],
      usrSexe: ['', Validators.required],
      usrFirstName: ['', Validators.required],
      usrLastName: ['', Validators.required],
      usrActiveFlag: [null],
      usrImage: [''],
      usrDdn: [null, Validators.required],
      usrAsrCode: ['', Validators.required],
      usrAdresse: [''],
      usrMobile: ['', [Validators.pattern(UtilConstants.ALGERIAN_PHONE_NUMBER_PATTERN)]],
    });

  }

  // convenience getter for easy access to form fields
  get uaF() {
    return this.userAppForm.controls;
  }

  resetForm(form: FormGroup) {
    form.reset();
  }

  _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.usersService.findAllUsers().subscribe(
        data => {
          this.data = data.data[0];
          this.users = data.data[0];
          this.totalRecords = this.users.length;
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/account/login']);
          }
        },
      );
    }, 1000);

  }

  getListRoles() {
    this.rolesService.findAllRoles().subscribe(data => {
      this.roles = data.data[0];
      console.log(this.roles);
    });
  }

  getListCivilte() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '31');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listCivilites = data.diLovDetailsList;
      }, error => {
        if (error === '403') {
          this.router.navigate(['/']);
        }
      }
    );
  }

  getlistGenres() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '1');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listGenres = data.diLovDetailsList;
      }, error => {
        if (error === '403') {
          this.router.navigate(['/']);
        }
      }
    );
  }

  getlistTitres() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '11');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listTitres = data.diLovDetailsList;
      }, error => {
        if (error === '403') {
          this.router.navigate(['/']);
        }
      }
    );
  }

  onSelectCivilite(event) {
    this.userAppForm.patchValue({
      usrCivilite: (event.value as DiLovDetails).lvdId,
    });
  }

  onSelectGenre(event) {
    this.userAppForm.patchValue({
      usrSexe: (event.value as DiLovDetails).lvdId,
    });
  }

  onSelectTitre(event) {
    this.userAppForm.patchValue({
      usrTitre: (event.value as DiLovDetails).lvdId,
    });
  }

  findMaxEmpNum() {
    this.employesService.findMaxEmpNum().subscribe(data => {
      this.currentEmpNum = data.data[0];
      console.log(this.currentEmpNum);
      this.userAppForm.patchValue({
        usrAsrCode: this.currentEmpNum
      });
    }, error => {
    });
  }

  /**
   *  new User App
   */
  showAddNewUser() {
    this.findMaxEmpNum();
    this.usersAppDlg = true;
    this.resetForm(this.userAppForm);

  }

  hideDialog() {
    this.usersAppDlg = false;
  }

  saveUser() {
    // stop here if form is invalid
    this.userAppForm.patchValue({
      username: this.userAppForm.value.usrEmail,
    });
    this.submitted = true;
    this.cdr.detectChanges();
    if (this.userAppForm.invalid) {
      return;
    } else {
      this.blockedDocument = true;
      if (this.editMode) {
        this.editEntity();
      } else {
        this.addEntity();
      }
    }

  }

  /**
   * entity methods
   */
  addEntity() {

    this.usersService.saveUser(this.userAppForm).subscribe(data => {
      if (data.data != null) {
        this.selectedUser = (data.data[0] as RhUsersApp);
        this.users.push(this.selectedUser);
        this.users = [...this.users];
        this.usersAppDlg = false;
        this.blockedDocument = false;
        this.resetForm(this.userAppForm);
        this.applicationService.showSuccefulSaveDialog('Utilisateur Ajouté Avec Succée.', 'success');
      }
    }, error => {
      console.log(error);
    });
  }


  editEntity() {

    this.usersService.editUser(this.userAppForm).subscribe(data => {
      this.selectedUser = (data.data[0] as RhUsersApp);
      this.users.splice(this.rowIndex, 1);
      this.users.splice(this.rowIndex, 0, this.selectedUser);
      this.users = [...this.users];
      this.usersAppDlg = false;
      this.blockedDocument = false;
      this.resetForm(this.userAppForm);
      this.applicationService.showSuccefulSaveDialog('L\'utilistaeur  a été modifier avec succéee', 'success');
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: error.value, life: 3000});
    });
  }

  /**
   * details demande
   */
  showDetails(rowData: RhUsersApp) {
    this.selectedUser = rowData;
    console.log(this.selectedUser);
    this.usersAppDtDlg = true;
  }

  /**
   *edit User App
   */
  showEditUser(rowdata: RhUsersApp, rowIndex: any) {
    this.rowIndex = rowIndex;
    this.selectedUser = new RhUsersApp();
    this.selectedUser = rowdata;
    this.editMode = true;

    /**
     * init edit form groupe
     */

    this.userAppForm.patchValue({
      usrEmail: this.selectedUser.usrEmail,
      usrCivilite: this.selectedUser.usrCivilite,
      usrTitre: this.selectedUser.usrTitre,
      usrSexe: this.selectedUser.usrSexe,
      usrFirstName: this.selectedUser.usrFirstName,
      usrLastName: this.selectedUser.usrLastName,
      usrActiveFlag: this.selectedUser.usrActiveFlag,
      usrImage: this.selectedUser.usrImage,
      usrDdn: new Date(this.selectedUser.usrDdn),
      usrAsrCode: this.selectedUser.usrAsrCode,
      usrAdresse: this.selectedUser.usrAdresse,
      usrMobile: this.selectedUser.usrMobile,
    });
    this.newProfileStream = 'data:image/png;base64,' + this.selectedUser.usrImage;
    this.usersAppDlg = true;

  }


  /**
   * delete demande
   */
  deleteSelectedUser(rowdata: RhUsersApp) {
    this.confirmationService.confirm({
      message: 'Voulez-vous vraiment supprimer l\' utilisateur sélectionné?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.usersService.deletUser(rowdata.usrId).subscribe(data => {
          this.users = this.users.filter(val => !this.users.includes(val));
          this.users = null;
          this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        });
      }
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    const fileSize = event.target.files[0].size;
    if (fileSize > this.maxSize) {
      this.maxSizeErr = true;
    } else {
      this.maxSizeErr = false;
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        this.newProfile.data = reader.result.toString().split(',')[1];
        this.newProfile.size = fileSize;
        this.newProfile.name = file.name;
        this.newProfileStream = 'data:image/png;base64,' + this.newProfile.data;
        this.userAppForm.patchValue({
          usrImage: btoa(this.newProfile.data)
        });
      };
    }
  }

  showEditRoles(rowdata: RhUsersApp) {
    this.selectedRole = [];
    this.selectedUser = rowdata;
    this.selectedUser.rhUsersAppRolesList.map(rl => {
      this.selectedRole.push(rl.rhRoles.rlaCode);
    });
    console.log(this.selectedUser);
    this.listuserRoles = this.selectedUser.rhUsersAppRolesList;
    this.findMaxIdUserRoles();
    this.editRolesDlg = true;

  }

  hideEditRoles() {
    this.editRolesDlg = false;
  }

  onAffectRole(event, role: RhRoles) {
    if (event.checked) {
      const r = new RhUsersAppRoles();
      const pk = new RhUsersAppRolesPK();
      pk.uurRlaCode = role.rlaCode;
      pk.uurUsrId = this.selectedUser.usrId;
      r.rhUsersAppRolesPK = pk;
      r.rhRoles = role;
      r.uurGrpId = 1;
      r.uurUsrCode = this.maxIdUsersRoles.toString();
      this.maxIdUsersRoles++;
      this.selectedUser.rhUsersAppRolesList.push(r);
    } else {
      this.listuserRoles = this.selectedUser.rhUsersAppRolesList;
      this.selectedUser.rhUsersAppRolesList = this.listuserRoles.filter(r => r.rhRoles.rlaCode !== role.rlaCode);
    }
  }

  findMaxIdUserRoles() {
    this.rolesService.findMaxId().subscribe(data => {
      this.maxIdUsersRoles = (Number(data[0].data));
    });
  }

  updateUserRoles() {
    this.addUserAppRole(this.selectedRole);
    this.usersService.editUserRoles(this.selectedUser.rhUsersAppRolesList).subscribe(data => {
      this.applicationService.showSuccefulSaveDialog('Role affecté Avec Succée.', 'success');
      this.editRolesDlg = false;
    }, error => {
      console.log(error);
    });
  }

  addUserAppRole(roles: string[]) {
    const r = new RhUsersAppRoles();
    const pk = new RhUsersAppRolesPK();
    roles.map(rl => {
      pk.uurRlaCode = rl;
      pk.uurUsrId = this.selectedUser.usrId;
      r.rhUsersAppRolesPK = pk;
      r.uurGrpId = 1;
      r.uurUsrCode = this.maxIdUsersRoles.toString();
      this.maxIdUsersRoles++;
      this.selectedUser.rhUsersAppRolesList.push(r);
    });
  }


  protected readonly AuthenticationService = AuthenticationService;
}
