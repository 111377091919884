import {Component, OnInit, Renderer2} from '@angular/core';
import {EmployesService} from './core/services/rh-ec/employesService';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from './core/services/language.service';
import {TextDirectionController} from './core/text-direction-controller';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'B-link RH';
  public directionController = new TextDirectionController();

  constructor(public cookiesService: CookieService, public languageService: LanguageService, private render: Renderer2) {
  }

  ngOnInit(): void {
    if (this.cookiesService.get('lang') === '') {
      this.cookiesService.set('lang', 'fr');
      this.languageService.setLanguage('fr');
      this.directionController.checkDirection(this.cookiesService.get('lang'));
    }

    // this.render.setAttribute(
    //   document.body, 'dir', this.directionController.textDirection
    // );
  }

}
