import {Component, Input, OnInit} from '@angular/core';
import {ApplicationService} from '../../core/services/application.service';
import {FormBuilder} from '@angular/forms';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {error} from 'protractor';
import {UtilConstants} from '../../core/helpers/util-constants';
import {HttpParams} from '@angular/common/http';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {EmployeeData} from '../../core/models/payloads/responces/EmployeeData';

@Component({
  selector: 'app-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss']
})
export class UsersDetailsComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  loading = true;

  listGenders: DiLovDetails[] = [];
  listContractsTypes: DiLovDetails[] = [];

  empNum: string;
  employeeData: EmployeeData = new EmployeeData();
  employee: RhEmployes = new RhEmployes();
  employeePoste: string;
  employeeProfilePicStream: any;

  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, public employesService: EmployesService,
              public dilovService: DilovService, private messageService: MessageService, private confirmationService: ConfirmationService,
              public authService: AuthenticationService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{label: 'USERS.TEXT'}, {label: 'USERS.LIST.USER_DETAILS', active: true}];
    this._getListGendersType();
    this._getListContractType();
    this.route.params.subscribe(
      (params: Params) => {
        this.empNum = params.empNum;
        this.employesService.findEmploye(this.empNum, 1).subscribe(data => {
          this.employeeData = data;
          this.employee = this.employeeData.rhEmployes;
          this.employeeData.employeePost !== null ? this.employeePoste = this.employeeData.employeePost : this.employeePoste = 'Employée';
          // tslint:disable-next-line:max-line-length
          this.employeeData.profilePicture !== null ? this.employeeProfilePicStream = 'data:image/png;base64,' + this.employeeData.profilePicture : this.employeeProfilePicStream = UtilConstants.EMPTY_PROFILE_BASE64;
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/']);
          }
        });
      }
    );
  }

  _getListGendersType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '1');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listGenders = [];
        this.listGenders = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

  _getListContractType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '6');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listContractsTypes = [];
        this.listContractsTypes = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

}
