import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {StEvents} from '../../core/models/rh-ec/StEvents';
import {EventsService} from '../../core/services/rh-ec/eventsService';
import {RhEmployeEvts} from '../../core/models/rh-ec/RhEmployeEvts';
import {ApplicationService} from '../../core/services/application.service';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {User} from '../../core/models/auth.models';
import {File} from '../../core/models/transient/File';
import {CookieService} from 'ngx-cookie-service';
import {CalandarEvent} from './calendar.model';


// Event Data
export interface Event {
  name: string;
  value: string;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

/**
 * Calendar Component
 */
export class CalendarComponent implements OnInit {


  // tslint:disable-next-line:max-line-length
  constructor(private modalService: NgbModal, private messageService: MessageService, private formBuilder: FormBuilder,
              private eventsService: EventsService, public authenticationService: AuthenticationService,
              public applicationService: ApplicationService, public employesService: EmployesService,
              private confirmationService: ConfirmationService, private cookiesService: CookieService) {
  }

  // convenience getter for easy access to form fields
  get deF() {
    return this.eventForm.controls;
  }

  cookieValue;

  curentTime = new Date();
  currentUser: User;
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition value
  eventForm: FormGroup;
  submitted: boolean;

  // Form category data
  category: Event[];
  listEvents: StEvents[] = [];


  rhEmployeEvt = new RhEmployeEvts();
  cols: any[];
  // Edit event

  // Delete event

  eventDtDlg = false;
  validationDlg = false;
  validationStatus: number;
  rowIndex: number;
  validationState = true;

  statusDlg = false;

  calendarWeekends: any;
  // show events
  calendarEvents: CalandarEvent[] = [];

  rhEmployeeEvents: RhEmployeEvts[] = [];

  /*upload file properties */
  uploadedFiles: File[] = [];
  eventFileFlag = false;

  loading = true;

  // calendar plugin
  calendarPlugins = [dayGridPlugin, bootstrapPlugin, timeGrigPlugin, interactionPlugin, listPlugin];


  ngOnInit() {
    this.cookieValue = this.cookiesService.get('lang');
    this.currentUser = this.authenticationService.currentUser();
    this.breadCrumbItems = [{label: 'Alliance Rh'}, {label: 'MENUITEMS.CALENDAR.TEXT', active: true}];
    this._getEventsList();
    this.employesService._getCurrentEmploy();
    this._fetchData();

    /**
     * init the form
     */

    this.eventForm = this.formBuilder.group({
      employeeType: [null, Validators.required],
      employeeNum: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      codeEvent: [null, Validators.required],
      dateDebut: [null, Validators.required],
      dateFin: [null, Validators.required],
      eventFiles: [[], this.eventFileFlag ? Validators.required : Validators.nullValidator]
    });

    /**
     * Template cols
     */
    this.cols = [
      {
        field: 'rhEmployes',
        header: this.cookieValue === 'fr' ? 'Employes' : this.cookieValue === 'en' ? 'Employees' : 'الموظفين',
        width: '18%'
      },
      {
        field: 'stEvents', header: this.cookieValue === 'fr' ? 'Evenemnet ' : this.cookieValue === 'en' ? 'Event' : 'الحدث',
        width: '18%'
      },
      {
        field: 'epeStartDate',
        header: this.cookieValue === 'fr' ? 'Date Demande' : this.cookieValue === 'en' ? 'Request Date' : 'تاريخ الطلب',
        width: '18%'
      },
      {
        field: 'epeStatus', header: this.cookieValue === 'fr' ? 'Statut' : this.cookieValue === 'en' ? 'Status' : 'الحالة',
        width: '18%'
      },
      {
        field: 'actions', header: this.cookieValue === 'fr' ? 'Actions' : this.cookieValue === 'en' ? 'Actions' : 'الإجراءات',
        width: '18%'
      }
    ];
  }


  /**
   * select event listener
   */
  onSelectEvent(event) {

    this.eventForm.patchValue({
      codeEvent: (event.value as StEvents).stEventsPK.evtCode,
    });
    this.eventFileFlag = (event.value as StEvents).evtFileFlag === 1 ? true : false;
    console.log(this.eventFileFlag);
  }

  /**
   * Open Event Modal
   * @param content modal content
   * @param event calendar event
   */
  openModal(content: any, event: any) {
    this.modalService.open(content);
    this.uploadedFiles = [];
    this.eventForm.patchValue({
      employeeType: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empType,
      employeeNum: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empNum,
      dateDebut: event.date,
    });

  }

  openDetailsModal(content: any, event: any) {
    this.rhEmployeEvt = event.event.extendedProps.rhEmployeEvent;
    this.modalService.open(content);
  }


  /**
   * Delete the event from calendar
   */
  deleteEventData() {
    this.eventsService.delteEvent(this.rhEmployeEvt.rhEmployeEvtsPK).subscribe(data => {
      this.modalService.dismissAll();
    });
  }

  /**
   * Model Data save and show the event in calendar
   */
  saveEvent() {
    this.submitted = true;
    console.log(this.eventForm.invalid);
    console.log(this.eventForm.value);
    if (this.eventForm.invalid) {
      return;
    } else {
      this.eventsService.saveRhEmployeeEvent(this.eventForm).subscribe(data => {
        this.rhEmployeEvt = (data.data[0] as RhEmployeEvts);
        this.rhEmployeeEvents.push(this.rhEmployeEvt);
        this.rhEmployeeEvents = [...this.rhEmployeeEvents];
        this.calendarEvents = [];
        this.genrateEventFromRhemployeeEvenetsList(this.rhEmployeeEvents);
        this.modalService.dismissAll();
        this.applicationService.showSuccefulSaveDialog('L\'événement a été enregistré', 'success');
        this.modalService.dismissAll();
      }, error => {
        console.log(error);
      });
    }
  }

  /**
   * Open Delete Confirmation Modal
   */
  confirm() {
    Swal.fire({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas revenir en arrière!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui, supprimer!'
    }).then(result => {
      if (result.value) {
        this.deleteEventData();
        Swal.fire('Supprimé!', 'L\'événement a été supprimé.', 'success');
      }
    });
  }

  private _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.eventsService.findAllRhEmployeeEvents().subscribe(data => {
        this.genrateEventFromRhemployeeEvenetsList(data);
      }, error => {
        console.log(error);
      });
    }, 1000);

  }

  private _getEventsList() {
    this.loading = true;
    setTimeout(() => {
      this.eventsService.findAllStEvents().subscribe(data => {
          this.listEvents = data;
          this.listEvents = this.listEvents.filter(evt =>
            evt.evtCategorie === '2');
          this.loading = false;
        },
        error => {
          console.log(error);
        });
    }, 1000);

  }

  closeEventModal() {
    this.rhEmployeEvt = new RhEmployeEvts();
    this.eventFileFlag = false;
    this.modalService.dismissAll();
  }


  genrateEventFromRhemployeeEvenetsList(list: RhEmployeEvts[]) {

    list.map(e => {
      if (e.rhEmployes.rhEmployesPK.empNum === this.currentUser.empNum) {
        this.calendarEvents = this.calendarEvents.concat({
          id: this.calendarEvents.length + 1,
          title: e.stEvents.evtDescLoc,
          className: e.stEvents.evtCategorie,
          start: new Date(e.epeStartDate),
          end: new Date(e.epeEndDate),
          color: e.stEvents.evtColor,
          rhEmployeEvent: e
        });
      }
    });
  }

  onDragStart(event: any) {
    event.dragElement.style.opacity = '0.7'; // Example: Reduce opacity when dragging
  }

  onDragEnd(event: any) {
    event.dragElement.style.opacity = '1'; // Example: Restore opacity when the drag ends
  }


  /**
   * Admin methodes
   */

  /**
   * details demande
   */
  showDetails(rowData: RhEmployeEvts) {
    this.rhEmployeEvt = rowData;
    this.eventDtDlg = true;
  }

  hideDetails() {
    this.eventDtDlg = true;
  }

  /**
   * validate Event
   */
  showValidateDemande(rowDta: RhEmployeEvts, rowIndex: number) {
    this.rowIndex = rowIndex;
    this.rhEmployeEvt = rowDta;
    this.validationState = true;
    this.validationDlg = true;
  }

  hideValidateDemande() {
    this.validationDlg = false;
  }


  validateDemandEvent(rowdata: RhEmployeEvts) {
    this.rhEmployeEvt = rowdata;
    // this.rhEmployeEvt.epeDate = new Date(this.rhEmployeEvt.epeDate);
    // this.rhEmployeEvt.epeStartDate = new Date(this.rhEmployeEvt.epeStartDate);
    // this.rhEmployeEvt.epeEndDate = new Date(this.rhEmployeEvt.epeEndDate);
    this.confirmationService.confirm({
      message: 'Voulez-vous vraiment Valider le Demande sélectionné?',
      header: 'Confirmation',
      icon: 'pi pi-check',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.validationState ? this.rhEmployeEvt.epeStatus = 1 : this.rhEmployeEvt.epeStatus = 2;
        this.validationDlg = false;
        this.editEntity();
      }
    });
  }


  editEntity() {
    this.eventsService.validateEvent(this.rhEmployeEvt).subscribe(data => {
      this.rhEmployeEvt = (data.data[0] as RhEmployeEvts);
      this.rhEmployeeEvents.splice(this.rowIndex, 1);
      this.rhEmployeeEvents.splice(this.rowIndex, 0, this.rhEmployeEvt);
      this.rhEmployeeEvents = [...this.rhEmployeeEvents];
      switch (this.validationStatus) {
        case 1: {
          // tslint:disable-next-line:max-line-length
          this.applicationService.showSuccefulSaveDialog('La demande  a été validé avec succéee', 'success');
          break;
        }
        case 2: {
          // tslint:disable-next-line:max-line-length
          this.applicationService.showSuccefulSaveDialog('La demande  a été rejeté avec succéee', 'success');
          break;
        }
        default: {
          this.applicationService.showSuccefulSaveDialog('La demande  a été modifier avec succéee', 'success');
          break;
        }
      }
    });
  }

  /**
   * status demande
   */
  showStatusDemande(rowDta: RhEmployeEvts) {
    this.rhEmployeEvt = new RhEmployeEvts();
    this.rhEmployeEvt = rowDta;
    this.statusDlg = true;
  }

  hideStatusDemande() {
    this.statusDlg = false;
  }


  /*upload file methods*/
  onFileUploaded(event) {
    console.log(event);
  }

  onValidateUpload(files: any[]) {
    this.eventForm.patchValue({
      eventFiles: files
    });
    console.log(this.eventForm.value);

  }

}


