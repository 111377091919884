import {RhEmployeEvtFiles} from './RhEmployeEvtFiles';
import {StEvents} from './StEvents';
import {RhEmployes} from './RhEmployes';
import {RhEmployeEvtsStatus} from './RhEmployeEvtsStatus';

export class RhEmployeEvts {
  rhEmployeEvtsPK: RhEmployeEvtsPK;
  epeDate: Date;
  epeStartDate: Date;
  epeEndDate: Date;
  epeActiveFlag: number;
  epeStatus: number;
  epeCreerPar: string;
  epeCreationDate: Date;
  epeModifierPar: string;
  epeModificationDate: Date;
  rhEmployeEvtFilesList: RhEmployeEvtFiles[];
  rhEmployeEvtsStatusList: RhEmployeEvtsStatus[];
  stEvents: StEvents;
  rhEmployes: RhEmployes;
}

export class RhEmployeEvtsPK {
  epeGrpId: number;
  epeCmpId: number;
  epeEmpType: number;
  epeEmpNum: string;
  epeEvtCode: string;
  epeSerial: number;
}
