import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {DiLovDetails} from '../../models/adm/DiLovDetails';
import {DiLovMaitres} from '../../models/adm/DiLovMaitres';

@Injectable({
  providedIn: 'root'
})
export class DilovService {

  constructor(private http: HttpClient) {
  }


  findListDLvd(params: HttpParams): Observable<DiLovMaitres> {

    return this.http.get<DiLovMaitres>(PAGES_URLS.FIND_LVD, {
      params
    });
  }
}
