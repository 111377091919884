import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe, DecimalPipe} from '@angular/common';
import {RhDemandeAbs, RhDemandeAbsPK} from '../../core/models/rh-ec/RhDemandeAbs';
import {DemandeAbsService} from '../../core/services/rh-ec/demandeAbsService';
import {Router} from '@angular/router';
import {Table} from 'primeng/table';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {RhEmployes, RhEmployesPK} from '../../core/models/rh-ec/RhEmployes';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {AuthenticationService} from '../../core/services/auth.service';
import {HttpParams} from '@angular/common/http';
import {DilovService} from '../../core/services/adm/dilov.service';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../core/services/application.service';
import {User} from '../../core/models/auth.models';
import Swal from 'sweetalert2';
import {CookieService} from 'ngx-cookie-service';
import {ERole} from '../../core/enums/Erole';
import {Exercice, Status} from './demande-abs.model';
import {statusList} from './demande-abs-data';
import {$e} from 'codelyzer/angular/styles/chars';
import {File} from '../../core/models/transient/File';
import {RhDemandeConges} from '../../core/models/rh-ec/RhDemandeConges';
import {ReportService} from '../../core/services/adm/report.service';
import {saveAs} from 'file-saver';
import {throwError} from 'rxjs';
import {PAGES_URLS} from '../pages.urls';


@Component({
  selector: 'app-demande-abs',
  templateUrl: './demande-abs.component.html',
  styleUrls: ['./demande-abs.component.scss'],
  providers: [DecimalPipe],

})
export class DemandeAbsComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, private pipe: DecimalPipe,
              public demandeAbsService: DemandeAbsService, public employesService: EmployesService,
              public dilovService: DilovService, private messageService: MessageService, public cookiesService: CookieService,
              private confirmationService: ConfirmationService, public authService: AuthenticationService,
              private router: Router, private cdr: ChangeDetectorRef, private reportService: ReportService) {
  }

  // convenience getter for easy access to form fields
  get daF() {
    return this.demandeAbsForm.controls;
  }

  // Current User
  currentUser: User;

  cookieValue;
  listEmployes: RhEmployes[];
  selectedEmployee = new RhEmployes();

  // breadcrumb items
  breadCrumbItems: Array<{}>;

  // Data
  data: RhDemandeAbs[];
  demandeAbcenes: RhDemandeAbs[];
  totalRecords: number;
  selectedDemandeAbs: RhDemandeAbs;
  rowIndex: any;

  // Dialogs attributes
  demandeAbsDlg = false;
  demandeAbsDtDlg = false;
  validationDlg = false;
  validationHeder: string;
  validationStatus: number;
  validationState = true;

  statusDlg = false;


  listAbsTypes: DiLovDetails[];
  curentTime = new Date();
  editMode = false;

  // Lazy attributes
  cols: any[];
  exportColumns: any[];
  loading = true;

  document: string;

  // Form Controll attributs
  demandeAbsForm: FormGroup;
  validateDemandeAbsFrom: FormGroup;
  printDemandeAbs: FormGroup;
  submitted = false;
  error = '';

  // bloque Ui
  blockedDocument = false;

  statusList = statusList;
  exercicesList: Exercice[] = [];
  selectedEcxercice: Exercice = {
    label: null,
    value: null
  };

  // upload file properties
  uploadedFiles: File[] = [];

  @ViewChild('absT') table: Table;


  public readonly ERole = ERole;


  protected readonly status = status;
  protected readonly $e = $e;


  ngOnInit(): void {
    this.selectedEcxercice = {
      label: new Date().getFullYear().toString(),
      value: new Date().getFullYear()
    };
    this.exercicesList.push(this.selectedEcxercice);

    this.cookieValue = this.cookiesService.get('lang');
    this.currentUser = this.authService.currentUser();
    this.document = 'List de toute des demande dabcence';
    this.selectedDemandeAbs = new RhDemandeAbs();
    this.selectedDemandeAbs.rhDemandeAbsPK = new RhDemandeAbsPK();
    this.breadCrumbItems = [{label: 'Dashbord'}, {label: 'Abscence Requests', active: true}];
    /**
     * get list of employees just to the admin
     */
    if (this.currentUser.roles.includes('ROLE_RH')) {
      this._getEmployes();
    } else {
      this.employesService._getCurrentEmploy();
      this.selectedEmployee = this.employesService.selectedEmployData.rhEmployes;

    }
    this._fetchData();
    this._getListAbsType();

    /**
     * Template cols
     */
    this.cols = [
      // {field: 'img', header: '#'},
      {
        field: 'rhEmployes',
        header: 'ABS_REQUEST.HEADERS.EMPLOYEE',
        width: '18%',


      },
      {
        field: 'dabType',
        header: 'ABS_REQUEST.HEADERS.ABS_TYPE',
        width: '25%',

      },
      {
        field: 'dabDemandeDate',
        header: 'ABS_REQUEST.HEADERS.ABS_REQUEST_DATE',
        width: '12%',

      },
      {
        field: 'dabRemarksLoc',
        header: 'ABS_REQUEST.HEADERS.REMARKS',
        width: '20%',

      },
      {
        field: 'dabDecision',
        header: 'ABS_REQUEST.HEADERS.STATUS',
        width: '10%',

      },
      {
        field: 'actions',
        header: 'ABS_REQUEST.HEADERS.ACTIONS',
        width: '15%',

      }
    ];

    /**
     * Files export cols
     */
    this.exportColumns = [
      {field: 'rhEmployes', header: 'Employes'},
      {field: 'dabType', header: 'Type absence'},
      {field: 'dabDemandeDate', header: 'Date Demande'},
      {field: 'dabDecision', header: 'Status'},
    ];

    /**
     * init demande abscence  form groupe
     */
    this.demandeAbsForm = this.formBuilder.group({
      employeeType: [null, Validators.required],
      employeeNum: [null, Validators.required],
      demandeDate: [null, Validators.required],
      dateDebut: [null, Validators.required],
      dateFin: [null, Validators.required],
      absenceType: ['', Validators.required],
      exercice: ['', this.editMode ? Validators.required : Validators.nullValidator],
      serial: ['', this.editMode ? Validators.required : Validators.nullValidator],
      addressContact: [''],
      phoneContact: [''],
      remarques: ['', this.validationDlg ? Validators.required : Validators.nullValidator],
      eventFiles: [null]
    });

    /**
     * init validate demannde Abscence form groupe
     */

    this.validateDemandeAbsFrom = this.formBuilder.group({
      employeeType: ['', Validators.required],
      employeeNum: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      exercice: ['', Validators.required],
      serial: ['', Validators.required],
      status: [''],
      remarks: ['']
    });

    /**
     * init print "titre congé" form group
     */

    this.printDemandeAbs = this.formBuilder.group({
      employeeType: ['', Validators.required],
      employeeNum: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      exercice: ['', Validators.required],
      serial: ['', Validators.required],
    });

  }

  resetForm(form: FormGroup) {
    form.reset();
  }

  onSelectExercice(event) {
    this.demandeAbcenes = this.data;
    this.selectedEcxercice = event.value as Exercice;
    this.filterByExercice(this.selectedEcxercice);
  }

  onSelectStatus(event) {
    this.demandeAbcenes = this.data;
    if (this.selectedEcxercice.value !== null) {
      this.filterByExercice(this.selectedEcxercice);
    }
    this.filterByStatus(event.value);

  }

  filterByExercice(e: Exercice) {
    this.demandeAbcenes = this.demandeAbcenes.filter(abs => abs.rhDemandeAbsPK.dabExercice === e.value);
  }

  filterByStatus(s: number) {
    this.demandeAbcenes = this.demandeAbcenes.filter(abs => abs.dabStatus === s);
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.demandeAbsService.findAllDemandes(this.currentUser).subscribe(
        data => {
          this.data = data;
          this.demandeAbcenes = data;
          this.totalRecords = this.demandeAbcenes.length;
          this.exercicesList = [...new Set(data.map(e => e.rhDemandeAbsPK.dabExercice))]
            .map(value => ({label: value.toString(), value}));
          if (this.exercicesList.length === 0) {
            this.exercicesList.push(this.selectedEcxercice);
          }
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/account/login']);
          }
        },
      );
    }, 1000);

  }

  _getListAbsType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '36');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listAbsTypes = [];
        this.listAbsTypes = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

  _getEmployes() {
    this.employesService.findAllEmployes().subscribe(data => {
        this.listEmployes = (data as RhEmployes[]);
        // tslint:disable-next-line:no-shadowed-variable
      }, error => {
        if (error.status === '403') {
          this.router.navigate(['/']);
        }
      },
    );
  }

  public reinitCols() {
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  /**
   * lazy load data
   *
   */
  loadDemandeAbscences(event: LazyLoadEvent) {
    this.loading = true;
    setTimeout(() => {
      if (this.data) {
        this.demandeAbcenes = this.data.slice(event.first, (event.first + event.rows));
        this.totalRecords = this.demandeAbcenes.length;
        this.loading = false;
      }
    }, 1000);
  }

  onSelectAbsType(event) {
    this.demandeAbsForm.patchValue({
      absenceType: (event.value as DiLovDetails).lvdAbrvLoc,
    });
  }


  /*  Data Methods */

  /**
   *  new Demande
   */
  showAddNewDemande() {
    this.demandeAbsForm.reset();
    // tslint:disable-next-line:max-line-length
    if (this.currentUser.roles.includes('ROLE_RH')) {
      this.selectedEmployee = new RhEmployes();
    } else {
      this.employesService._getCurrentEmploy();
      this.selectedEmployee = this.employesService.selectedEmployData.rhEmployes;
      this.demandeAbsForm.patchValue({
        employeeType: this.selectedEmployee.rhEmployesPK.empType,
        employeeNum: this.selectedEmployee.rhEmployesPK.empNum,
      });
    }
    this.selectedDemandeAbs = new RhDemandeAbs();
    this.submitted = false;
    this.demandeAbsDlg = true;
    this.editMode = false;
  }

  hideDialog() {
    this.demandeAbsDlg = false;
  }

  onSelectEmploye(event) {
    this.demandeAbsForm.patchValue({
      employeeType: (event.value as RhEmployes).rhEmployesPK.empType,
      employeeNum: (event.value as RhEmployes).rhEmployesPK.empNum,
    });
  }

  onDemandeDateChange(event) {
    this.demandeAbsForm.patchValue({
      dateDebut: null,
      dateFin: null,
    });
  }

  onSelectDateFin() {
// set the valid date pattern
    this.demandeAbsForm.patchValue({
      demandeDate: this.applicationService.convertDateString(this.daF.demandeDate.value),
      dateDebut: this.applicationService.convertDateString(this.daF.dateDebut.value),
      dateFin: this.applicationService.convertDateString(this.daF.dateFin.value),
    });
  }

  /**
   * details demande
   */
  showDetails(rowData: RhDemandeAbs) {
    this.selectedDemandeAbs = rowData;
    this.selectedEmployee = this.selectedDemandeAbs.rhEmployes;
    this.demandeAbsDtDlg = true;
  }

  hideDetails() {
    this.demandeAbsDtDlg = true;
  }


  /**
   *edit demande
   */
  showEditDemande(rowdata: RhDemandeAbs, rowIndex: any) {
    this.rowIndex = rowIndex;
    console.log('this.rowIndex' + this.rowIndex);
    this.selectedDemandeAbs = new RhDemandeAbs();
    this.selectedDemandeAbs = rowdata;
    this.selectedEmployee = this.selectedDemandeAbs.rhEmployes;
    this.editMode = true;
    /**
     * init edit form groupe
     */
    this.demandeAbsForm.patchValue({
      employeeType: this.selectedDemandeAbs.rhEmployes.rhEmployesPK.empType,
      employeeNum: this.selectedDemandeAbs.rhEmployes.rhEmployesPK.empNum,
      demandeDate: new Date(this.selectedDemandeAbs.dabDemandeDate),
      dateDebut: new Date(this.selectedDemandeAbs.dabDebutDate),
      dateFin: new Date(this.selectedDemandeAbs.dabFinDate),
      absenceType: this.selectedDemandeAbs.dabType,
      exercice: this.selectedDemandeAbs.rhDemandeAbsPK.dabExercice,
      serial: this.selectedDemandeAbs.rhDemandeAbsPK.dabSerial,
      addressContact: this.selectedDemandeAbs.dabAddressLoc,
      phoneContact: this.selectedDemandeAbs.dabContactTelephone,
      remarques: this.selectedDemandeAbs.dabRemarksLoc
    });
    this.demandeAbsDlg = true;

  }


  /**
   * delete demande
   */
  deleteSelectedDemandeAbs(rowdata: RhDemandeAbs) {
    this.confirmationService.confirm({
      message: 'Voulez-vous vraiment supprimer le demande sélectionné?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.demandeAbsService.deleteDemandeAbs(rowdata.rhDemandeAbsPK).subscribe(data => {
          this.demandeAbcenes = this.demandeAbcenes.filter(val => !this.demandeAbcenes.includes(val));
          this.selectedDemandeAbs = null;
          this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        });
      }
    });
  }


  /**
   * validate demande
   */
  showValidateDemande(rowDta: RhDemandeAbs, rowIndex: any) {
    this.rowIndex = rowIndex;
    this.selectedDemandeAbs = new RhDemandeAbs();
    this.selectedDemandeAbs = rowDta;
    // this.validationStatus = 1;
    this.selectedEmployee = this.selectedDemandeAbs.rhEmployes;

//  Patch values to the form controll
    this.validateDemandeAbsFrom.patchValue({
      employeeType: this.selectedDemandeAbs.rhEmployes.rhEmployesPK.empType,
      employeeNum: this.selectedDemandeAbs.rhEmployes.rhEmployesPK.empNum,
      exercice: this.selectedDemandeAbs.rhDemandeAbsPK.dabExercice,
      serial: this.selectedDemandeAbs.rhDemandeAbsPK.dabSerial,
      status: this.validationStatus,
    });

    this.validationDlg = true;
  }

  hideValidateDemande() {
    this.validationDlg = false;
  }


  validateDemandeAbs(form: FormGroup, status: number) {
    this.validateDemandeAbsFrom.patchValue({
      status: this.validationState ? 1 : 2,
    });
    this.blockedDocument = true;
    this.vlidateEntity();

  }


  /**
   * status demande
   */
  showStatusDemande(rowDta: RhDemandeAbs) {
    this.selectedDemandeAbs = new RhDemandeAbs();
    this.selectedDemandeAbs = rowDta;
    this.selectedEmployee = this.selectedDemandeAbs.rhEmployes;
    this.statusDlg = true;
  }

  hideStatusDemande() {
    this.statusDlg = false;
  }

  saveDemandeAbs() {
    // stop here if form is invalid

    this.submitted = true;
    this.cdr.detectChanges();
    if (this.demandeAbsForm.invalid) {
      return;
    } else {
      this.blockedDocument = true;
      if (!this.currentUser.roles.includes('ROLE_RH')) {
        this.demandeAbsForm.patchValue({
          employeeType: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empType,
          employeeNum: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empNum,
        });
      }

      if (this.editMode) {
        this.editEntity();
      } else {
        this.addEntity();
      }
    }

  }


  /**
   * entity methods
   */
  addEntity() {

    this.demandeAbsService.saveDemandeAbs(this.demandeAbsForm).subscribe(data => {
      if (data.data != null) {
        this.selectedDemandeAbs = (data.data[0] as RhDemandeAbs);
        this.demandeAbcenes.push(this.selectedDemandeAbs);
        this.demandeAbcenes = [...this.demandeAbcenes];
        this.demandeAbsDlg = false;
        this.resetForm(this.demandeAbsForm);
        this.blockedDocument = false;
        this.applicationService.showSuccefulSaveDialog('Demande Ajouté Avec Succée.', 'success');
      }
    }, error => {
      console.log(error);
    });
  }


  editEntity() {

    this.demandeAbsService.editDemandeAbs(this.demandeAbsForm).subscribe(data => {
      this.selectedDemandeAbs = (data.data[0] as RhDemandeAbs);
      this.demandeAbcenes.splice(this.rowIndex, 1);
      this.demandeAbcenes.splice(this.rowIndex, 0, this.selectedDemandeAbs);
      this.demandeAbcenes = [...this.demandeAbcenes];
      this.demandeAbsDlg = false;
      this.resetForm(this.demandeAbsForm);
      this.blockedDocument = false;
      this.applicationService.showSuccefulSaveDialog('La demande  a été modifier avec succéee', 'success');
    }, error => {
      console.log('eroor===>' + error);
    });
  }

  vlidateEntity() {
    this.demandeAbsService.validateDemandeAbs(this.validateDemandeAbsFrom).subscribe(data => {
        this.selectedDemandeAbs = (data.data[0] as RhDemandeAbs);
        this.demandeAbcenes.splice(this.rowIndex, 1);
        this.demandeAbcenes.splice(this.rowIndex, 0, this.selectedDemandeAbs);
        this.demandeAbcenes = [...this.demandeAbcenes];
        switch (this.validationStatus) {
          case 1: {
            // tslint:disable-next-line:max-line-length
            this.applicationService.showSuccefulSaveDialog('La demande  a été ' + (this.selectedDemandeAbs.dabDecision).toLowerCase() + ' avec succéee', 'success');
            break;
          }
          case 2: {
            // tslint:disable-next-line:max-line-length
            this.applicationService.showSuccefulSaveDialog('La demande  a été ' + (this.selectedDemandeAbs.dabDecision).toLowerCase() + ' avec succéee', 'success');
            break;
          }
          default: {
            this.applicationService.showSuccefulSaveDialog('La demande  a été modifier avec succéee', 'success');
            break;
          }
        }
        this.validationDlg = false;
        this.blockedDocument = false;
      }
      , error => {
        console.log('eroor===>' + error);
      });
  }


  /*upload file methods*/
  onFileUploaded(event) {
  }


  onValidateUpload(files: any[]) {
    this.demandeAbsForm.patchValue({
      eventFiles: files
    });

  }

  public donloadDemandeAbs(rowDta: RhDemandeAbs): any {
    this.blockedDocument = true;
    this.printDemandeAbs.patchValue({
      employeeType: rowDta.rhDemandeAbsPK.dabEmpType,
      employeeNum: rowDta.rhDemandeAbsPK.dabEmpNum,
      exercice: rowDta.rhDemandeAbsPK.dabExercice,
      serial: rowDta.rhDemandeAbsPK.dabSerial,
    });
    const mediaType = 'application/pdf';
    this.reportService.printDocument(this.printDemandeAbs, PAGES_URLS.PRINT_DEMANDE_ABS_REPORTING).subscribe(
      (response) => {
        const blob = new Blob([response], {type: mediaType});
        console.log(blob);
        saveAs(blob, 'report.pdf');
        this.blockedDocument = false;
      },
      e => {
        throwError(e);
      }
    );
  }
}
