<p-toast></p-toast>
<app-loader *ngIf="loading"></app-loader>
<div class="container-fluid" *ngIf="!loading">
  <app-pagetitle title="{{'DASHBOARD.TEXT' |translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <!--  dashbord admin-->
  <div class="row" *ngIf="athentificationService.currentUserRoles().includes('ROLE_RH')">
    <div class="col-xl-8">
      <div class="row">
        <div *ngFor="let stat of statAdminData" class="col-md-4">
          <app-stat [title]="stat.title| translate" [value]="stat.value" [icon]="stat.icon"
                    (click)="applicationService.goToLink(stat.link)"></app-stat>
        </div>
      </div>
      <!-- end row -->

      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Ttile</h4>
          <p-chart type="line" [data]="multiAxisData" [options]="multiAxisOptions"></p-chart>
        </div>

        <!--        <div class="card-body border-top text-center">-->
        <!--          <div class="row">-->
        <!--            footer-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="col-xl-4" *ngIf="employeesAnnalytics.length!==0">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{'DASHBOARD.LIST.EMPLOYEE_ANALYTICS' |translate}}</h4>
          <p-chart type="doughnut" [data]="employeesData"></p-chart>
          <div class="row">
            <div class="col-6">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i
                  class="mdi mdi-circle text-primary font-size-10 mr-1"></i> {{this.employeesAnnalytics[0][1]}}
                </p>
                <h5>{{this.employeesAnnalytics[0][2]}} %</h5>
              </div>
            </div>
            <div class="col-6">
              <div class="text-center mt-4">
                <p class="mb-2 text-truncate"><i
                  class="mdi mdi-circle text-success font-size-10 mr-1"></i> {{this.employeesAnnalytics[1][1]}}
                </p>
                <h5>{{this.employeesAnnalytics[1][2]}} %</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Earning Reports</h4>
          <div class="text-center">
            <div class="row">
              <div class="col-sm-6">
                <div>
                  <div class="mb-3">
                    <!--                    <p-chart type="line" [data]="multiAxisData[1]" [options]="multiAxisOptions[1]"></p-chart>-->
                  </div>

                </div>
              </div>

              <div class="col-sm-6">
                <div class="mt-5 mt-sm-0">
                  <div class="mb-3">
                    <!--    <apx-chart dir="ltr" class="apex-charts" [series]="sparklineMonthly.series"
                                   [chart]="sparklineMonthly.chart" [colors]="sparklineMonthly.colors"
                                   [labels]="sparklineMonthly.labels" [dataLabels]="sparklineMonthly.dataLabels"
                                   [plotOptions]="sparklineMonthly.plotOptions">
                        </apx-chart>-->
                  </div>

                  <!--                  <p class="text-muted text-truncate mb-2">Monthly Earnings</p>-->
                  <!--                  <h5 class="mb-0">$11,235</h5>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  dashbord employee-->
  <div class="row" *ngIf="!athentificationService.currentUserRoles().includes(ERole.ROLE_RH) && !loading">
    <div class="col-xl-8">
      <div class="row">
        <div *ngFor="let stat of statEmployeeData" class="col-md-4">
          <app-stat [title]="stat.title | translate" [value]="stat.value" [icon]="stat.icon"></app-stat>
        </div>
      </div>

      <div class="row">
        <div class="col-12" *ngIf="!loading">
          <div class="card" style="box-shadow: none!important;">
            <div class="card-header" style="padding:0 !important ; height: 70px!important;">
              <div class="text-center">
                <img src="{{UtilConstants.EMPTY_PROFILE_BASE64}}" class="profile-picture">
              </div>
            </div>
            <div class="card-body" style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);!important;">
              <i class="fa fa-arrow-left" style="font-size: 20px ; color: var(--primary) ; cursor: pointer"></i>
              <div class="row mt-5">
                <div class="d-flex flex-column col-12 mt-4">
                  <div class="text-center">
                    <h2
                      class="card-subtitle">{{employee.emp1stNameLoc}} {{employee.empLastNameLoc}}</h2>
                    <span class="card-title">{{employeePoste}}</span>
                  </div>
                  <div class="d-flex justify-content-around text-center">
                    <div class="col-lg-4 col-sm-12">
                      <i class="mdi mdi-facebook social-media-icons text-primary mr-3"></i>
                      <i class="mdi mdi-linkedin social-media-icons text-primary mr-3"></i>
                      <i class="mdi mdi-gmail social-media-icons text-primary"></i>
                    </div>
                  </div>
                </div>
                <div class="row mt-4 col-12">
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Nom et Prénom :</label>
                    <input class="form-control" value="{{employee.emp1stNameLoc}} {{employee.empLastNameLoc}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Email :</label>
                    <input class="form-control" value="{{employee.empEmail}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Genre :</label>
                    <input class="form-control"
                           value="{{applicationService.filterListDlvById(listGenders,employee.empGender)}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Date De Naissance :</label>
                    <input class="form-control" value="{{employee.empBirthDate | date:'fullDate'}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Lieu de Naissance :</label>
                    <input class="form-control" value="{{employee.empBirthPlace}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Age :</label>
                    <input class="form-control" value="{{applicationService.calculateYears(employee.empBirthDate)}} ans"
                           readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Date début Contrat :</label>
                    <input class="form-control" value="{{employee.empTrainingStartDate |date:'fullDate'}}" readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Date fin Contrat :</label>
                    <input class="form-control"
                           value="{{employee.empTrainingEndDate===null ? '/' :employee.empTrainingEndDate |date:'fullDate'}}"
                           readonly>
                  </div>
                  <div class="col-12 col-lg-4 form-group">
                    <label class="control-label">Type Contrat :</label>
                    <input class="form-control"
                           value="{{applicationService.filterListDlvById(listContractsTypes,employee.empContractType)}}"
                           readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Fil d'activité récente</h4>
          <perfect-scrollbar style="height: 625px;">
            <div *ngIf="rhEmployeeProgramedEvents.length === 0" class="empty-message">
              Aucun évenemnt trouvé.
            </div>
            <ul class="list-unstyled activity-wid" *ngFor="let evt of rhEmployeeProgramedEvents">
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                    <i class="{{evt.stEvents.evtIcon}}"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <h5 class="font-size-13"> {{evt.stEvents.evtDescLoc}}</h5>
                  </div>

                  <div>
                    <p class="text-muted mb-0">{{evt.epeStartDate | date:'fullDate'}} <small class="text-muted">
                      {{evt.epeStartDate | date:'shortTime'}}</small>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
      </div>
    </div>

    <p-dialog [(visible)]="evtDlg"
              [modal]="true" [closable]="true" [resizable]="false" [draggable]="false"
              [baseZIndex]="10000" [style]="{width: '50%'}">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title mb-4">List Des évenement a participer</h4>
          <perfect-scrollbar style="height: 330px;">
            <div *ngIf="rhEmployeeRequestedEvents.length === 0" class="empty-message">
              Aucun évenemnt trouvé.
            </div>
            <p-table [value]="rhEmployeeRequestedEvents">
              <ng-template pTemplate="body" let-evt let-rowIndex rowIndex>
                <tr>
                  <td class="font-weight-bolder"><i class="pi pi-gift mr-2"></i> {{evt.stEvents.evtDescLoc}}</td>
                  <td>Du : {{evt.epeStartDate | date:'fullDate'}}</td>
                  <td>Jusqu'a :{{evt.epeEndDate | date:'fullDate'}}</td>
                  <td>
                    <button pButton type="button" class="p-button-rounded p-button-text p-button-success mr-2"
                            pTooltip="Valider"
                            icon="pi pi-check" (click)="validateDemanEvent(evt , rowIndex , 1)"></button>
                    <button pButton type="button" class="p-button-rounded p-button-text p-button-success mr-2"
                            pTooltip="Rejeté" (click)="validateDemanEvent(evt , rowIndex , 2)"
                            icon="pi pi-times"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </perfect-scrollbar>
        </div>
      </div>
      <!--        end row-->
    </p-dialog>
  </div>
</div>
