import {Serializable} from 'selenium-webdriver';
import {RhEmployes} from './RhEmployes';
import {RhDemandeAbsStatus} from './RhDemandeAbsStatus';

export class RhDemandeAbs {

  dabDemandeDate: Date;
  dabDebutDate: Date;
  dabFinDate: Date;
  dabHeurs: number;
  dabType: string;
  dabAddressLoc: string;
  dabAddressFrn: string;
  dabContactTelephone: string;
  dabRemarksLoc: string;
  dabRemarksFrn: string;
  dabStatus: number;
  dabExpRepriseDate: Date;
  dabReelRepriseDate: Date;
  dabRetardJours: number;
  dabDecision: string;
  dabCreatedPar: string;
  dabModifierPar: string;
  dabCreationDate: Date;
  dabModificationDate: Date;
  dabNbrDays: number;
  rhEmployes: RhEmployes;
  rhDemandeAbsPK: RhDemandeAbsPK;
  rhDemandeAbsStatusList: RhDemandeAbsStatus[];
}

export class RhDemandeAbsPK {
  dabGrpId: number;
  dabCmpId: number;
  dabExercice: number;
  dabEmpType: number;
  dabEmpNum: string;
  dabSerial: number;
}

