import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs, RhDemandeAbsPK} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {AuthenticationService} from '../auth.service';
import {User} from '../../models/auth.models';
import {FormControl, FormGroup} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class DemandeAbsService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
  }


  findAllDemandes(user: User): Observable<RhDemandeAbs[]> {
    // tslint:disable-next-line:max-line-length
    return user.roles.includes('ROLE_RH') ? this.http.get<RhDemandeAbs[]>(PAGES_URLS.FIND_ALL_DEMANDES_ABS_BY_RESP) : this.http.get<RhDemandeAbs[]>(PAGES_URLS.FIND_ALL_DEMANDES_ABS);
  }

  public deleteDemandeAbs(pk: RhDemandeAbsPK) {
    return this.http.delete<RhDemandeAbsPK>(PAGES_URLS.DELETE_DEMANDES_ABS + pk);
  }

  public validateDemandeAbs(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.VALIDATE_DEMANDES_ABS, formData.value);
  }
  public editDemandeAbs(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.PUT_DEMANDES_ABS, formData.value);
  }

  public saveDemandeAbs(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.SAVE_DEMANDE_ABS
      , formData.value);

  }

}
