import {Component, EventEmitter, Input, Output} from '@angular/core';
import {File} from '../../../core/models/transient/File';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-custom-file-upload',
  templateUrl: './custom-file-upload.component.html',
  styleUrls: ['./custom-file-upload.component.scss']
})
export class CustomFileUploadComponent {
  @Input() uploadedFiles: any[];

  rendListFiles = false;
  disableUploadButtons = false;
  value = 0;


  @Output() fileUploaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() validateUpload: EventEmitter<any[]> = new EventEmitter<any[]>();


  onFileUpload(event: any) {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const f = event.target.files[i];
      const tempFile = new File();
      tempFile.name = f.name;
      tempFile.size = f.size;
      tempFile.type = f.type;
      reader.readAsDataURL(f);
      reader.onloadend = (e) => {
        tempFile.data = reader.result.toString().split(',')[1];
        this.uploadedFiles.push(tempFile);
      };
    }

    this.rendListFiles = true;
  }

  onCancelUpload() {
    this.uploadedFiles = [];
    this.rendListFiles = false;
    this.disableUploadButtons = true;
    this.value = 0;
  }

  onRemoveFile(rowIndex: number) {
    this.uploadedFiles.splice(rowIndex, 1);
  }

  onValidateUpload() {
    if (this.value < 100) {
      this.disableUploadButtons = true;
      this.value += 100;
    }
    this.validateUpload.emit(this.uploadedFiles);

  }

}
