<app-loader *ngIf="loading"></app-loader>
<p-toast position="bottom-right"></p-toast>
<div class="container-fluid">
  <app-pagetitle title="{{'USERS.TEXT' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <perfect-scrollbar style="height: 550px;">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <div class="app-search d-none d-lg-block" style="padding: 0 !important">
          <div class="position-relative">
            <input type="text" pInputText [(ngModel)]="searchTerm" (input)="applyFilter()" class="form-control"
                   placeholder="{{'HEADER.SEARCH' | translate}}">
            <span class="ri-search-line"></span>
          </div>

        </div>
      </div>
<!--      <div class="p-toolbar-group-right">-->
<!--        <p-button icon="pi pi-calendar" styleClass="p-button-success mr-2"></p-button>-->
<!--        <p-button icon="pi pi-times" styleClass="p-button-danger"></p-button>-->
<!--      </div>-->
    </p-toolbar>

    <div class="row mt-3">
      <div *ngIf="showEmptyMsg" class="empty-message ml-3 ">
        Aucun évenemnt trouvé.
      </div>
      <div class="col-xl-3 col-sm-6" *ngFor="let emp of listEmployesLoaded ">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
<!--              emp.rhDocumentsList[0] !==null ?'data:image/png;base64,' + emp.rhDocumentsList[0] :-->
              <img
                src="{{utilConstant.EMPTY_PROFILE_BASE64}}"
                alt class="avatar-sm mt-2 mb-4 "
                style="border-radius: 50%;border: solid 2px rgba(149, 6, 74, 0.62);"/>
              <div class="media-body">
                <h5 class="text-truncate">
                  <a href="javascript: void(0);" class="text-dark">{{emp.empNomLoc}}</a>
                </h5>
                <p class="text-muted">
                  <i class="mdi mdi-mail mr-1"></i>
                  {{emp.empEmail}}
                </p>
              </div>
            </div>
            <hr class="my-4"/>
            <div class="row text-center">
              <div class="col-6">
                <p class="text-muted mb-2">Age</p>
                <h5>{{applicationService.calculateYears(emp.empBirthDate)}} ans</h5>
              </div>
              <div class="col-6">
                <p class="text-muted mb-2">Experience</p>
                <h5>{{applicationService.calculateYears(emp.empTrainingStartDate)}} ans</h5>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-12">
                <button pButton pRipple label="Voir Détails" icon="pi pi-check" class="p-button-text"
                        (click)="applicationService.goToDetails('employees',emp.rhEmployesPK.empNum)"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showLoadMoreButton">
      <div class="col-xl-12">
        <div class="text-center my-3">
          <a class="text-primary" (click)="loadMore()" style="cursor: pointer!important;">
            <i class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i> Load more
          </a>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>


