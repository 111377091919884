<app-loader *ngIf="loading"></app-loader>
<p-toast position="bottom-right"></p-toast>
<div class="container-fluid">
  <app-pagetitle title="{{'PROFILE.TEXT' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row" *ngIf="!loading">
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="userContainer">
            <div class="userShow">
              <div class="userShowTop">
                <img class="userShowImg" style="border-radius: 50%;border: solid 2px rgba(149, 6, 74, 0.62);"
                     src="{{newProfile===null ? utilConstant.EMPTY_PROFILE_BASE64 : newProfileStream}}" alt="">
                <div class="userShowTitle">
                  <span class="userShowUsername">{{selectedEmploy.empNomLoc}}</span>
                  <span class="userShowRole">{{selectedEmployPoste}}</span>
                </div>
              </div>
              <div class="userShowBottom">

                <span class="userShowDetailTitle">{{'PROFILE.LIST.DETAILS' | translate}}</span>
                <div class="userShowInfos">
                  <i class="fa fa-calendar userShowIcon"></i>
                  <span class="userShowInfosTitle">{{selectedEmploy.empBirthDate | date:'shortDate'}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="fa fa-map-pin userShowIcon"></i>
                  <span
                    class="userShowInfosTitle">{{selectedEmploy.empBirthPlace != null ? selectedEmploy.empBirthPlace.toLowerCase() : null}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="userShowIcon" [ngClass]="selectedEmploy.empGender === '1' ? 'fa fa-mars':'fa fa-venus'"></i>
                  <span
                    class="userShowInfosTitle">{{applicationService.filterListDlvById(listGenders, selectedEmploy.empGender)}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="fa fa-tint  userShowIcon"></i>
                  <span
                    class="userShowInfosTitle">{{applicationService.filterListDlvById(listBloodTypes, selectedEmploy.empBloodType)}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="fa fa-id-card userShowIcon"></i>
                  <span class="userShowInfosTitle">{{selectedEmploy.empNationalNum}}</span>
                </div>


                <span class="userShowDetailTitle">{{'PROFILE.LIST.CONTACT' | translate}}</span>
                <div class="userShowInfos">
                  <i class="fa fa-phone userShowIcon"></i>
                  <span class="userShowInfosTitle">{{selectedEmploy.empMobileNo}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="fa fa-envelope userShowIcon"></i>
                  <span class="userShowInfosTitle">{{currentUser.username}}</span>
                </div>

                <div class="userShowInfos">
                  <i class="fa fa-map-marked userShowIcon"></i>
                  <span class="userShowInfosTitle">{{selectedEmploy.empAdresseLoc}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8">
      <div class="card">
        <div class="card-body">
          <div>
            <p-tabView>
              <p-tabPanel header="1">
                <ng-template pTemplate="header">
                  <i class="pi pi-user mr-1 ml-1"></i>
                  <span>{{'PROFILE.LIST.ABOUT_ME' | translate}}</span>
                </ng-template>
                <section class="mb-4">
                  <div class="row d-flex align-items-center justify-content-between">
                    <h3>{{'PROFILE.LIST.EXPERIENCE' | translate}}</h3>
                    <i class="fa fa-pencil-alt" style="cursor: pointer !important"></i>
                  </div>
                  <perfect-scrollbar style="height: 100px;background-color: #f1f5f7 !important;">
                    <p-table [value]="employeeData.emploExperiencesList">
                      <ng-template pTemplate="body" let-exp let-rowIndex rowIndex>
                        <tr style="background-color: transparent!important;">
                          <td>
                            <div class="card-form">
                              <div class="card-form-header">
                                <h2 class="card-form-title">{{exp.expWorkingForLoc}}</h2>
                                <h3 class="card-form-subtitle">{{ exp.expStartDate | date:'fullDate' }} -
                                  {{exp.expEndDate !== null ? (exp.expEndDate | date:'fullDate') : 'today' }}</h3>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </perfect-scrollbar>
                </section>
                <section class="mb-2">
                  <div class="row d-flex align-items-center justify-content-between">
                    <h3>{{'PROFILE.LIST.EDUCATION' | translate}}</h3>
                    <i class="fa fa-pencil-alt" style="cursor: pointer !important"></i>
                  </div>
                  <perfect-scrollbar style="height: 120px;background-color: #f1f5f7 !important;">
                    <p-table [value]="employeeData.formationsList">
                      <ng-template pTemplate="body" let-form let-rowIndex rowIndex>
                        <tr style="background-color: transparent!important;">
                          <td>
                            <div class="card-form">
                              <div class="card-form-header">
                                <h2 class="card-form-title">{{form.institution}}</h2>
                                <h3 class="card-form-subtitle">{{ form.startDate | date:'fullDate' }}
                                  - {{ form.endDate | date:'fullDate' }}</h3>
                              </div>
                              <div class="card-form-content">
                                {{form.degree}}
                              </div>
                            </div>

                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </perfect-scrollbar>
                </section>

                <section class="mb-2">
                  <div class="row d-flex align-items-center justify-content-between ">
                    <h3>{{'PROFILE.LIST.SKILLS' | translate}}</h3>
                    <i class="fa fa-pencil-alt" style="cursor: pointer !important"></i>
                  </div>
                  <perfect-scrollbar style="height: 100px;background-color: #f1f5f7 !important;">
                    <p-table [value]="employeeData.emploExperiencesList">
                      <ng-template pTemplate="body" let-comp let-rowIndex rowIndex>
                        <tr style="background-color: transparent!important;">
                          <td>
                            <div class="card-form">
                              <div class="card-form-header">
                                <h2 class="card-form-title">{{comp.name}}</h2>
                                <div class="card-form-content">
                                  <p-rating [(ngModel)]="comp.level" readonly="true" stars="5"
                                            [cancel]="false"></p-rating>
                                </div>
                              </div>
                            </div>
                        </tr>
                      </ng-template>
                    </p-table>
                  </perfect-scrollbar>
                </section>
                <button pButton pRipple label="{{'PROFILE.LIST.UPLOAD_CV' | translate}}" type="submit"
                        icon="pi pi-check"
                        class="p-button-text updateUserBtn float-right"></button>
              </p-tabPanel>
              <p-tabPanel header="2">
                <ng-template pTemplate="header">
                  <i class="pi pi-cog mr-1 ml-1"></i>
                  <span>{{'PROFILE.LIST.SETTING' | translate}}</span>
                </ng-template>
                <div class="userUpdateLeft">
                  <span class="userUpdateTitle">Modifier</span>
                  <form class="form-horizontal" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                    <div class="row justify-content-between">
                      <div class="userUpdateLeft col-xl-8">

                        <div class="userUpdateItem">
                          <label>{{'PROFILE.LIST.NOM' |translate}}</label>
                          <input type="text" formControlName="nom"
                                 [ngClass]="{ 'is-invalid': submittedProfile && pf.nom.errors }"
                                 class="form-control"/>
                          <div class="invalid-feedback">
                            <div> {{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>

                        <div class="userUpdateItem">
                          <label>{{'PROFILE.LIST.PRENOM' |translate}}</label>
                          <input type="text" formControlName="prenom"
                                 [ngClass]="{ 'is-invalid': submittedProfile && pf.prenom.errors }"
                                 class="form-control"/>
                          <div class="invalid-feedback">
                            <div> {{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>
                        </div>

                        <div class="userUpdateItem">
                          <label>{{'PROFILE.LIST.DATE_DE_NAISSANCE' |translate}}</label>
                          <p-calendar formControlName="dateNaissance"
                                      [ngClass]="{ 'is-invalid': submittedProfile && pf.dateNaissance.errors }"
                                      [showIcon]="true"
                                      [inputStyle]="{'width':'450px', 'border-radius': '0 !important'}">

                          </p-calendar>

                          <div class="invalid-feedback">
                            <div> {{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                          </div>

                        </div>

                        <div class="userUpdateItem">
                          <label>{{'PROFILE.LIST.TELEPHONE' |translate}}</label>
                          <input type="text" placeholder="ex: +213 999 99 99 99"
                                 formControlName="mobile"
                                 class="form-control"
                                 [ngClass]="{ 'is-invalid': submittedProfile && pf.mobile.errors }"/>

                          <div class="invalid-feedback">
                            <div> {{'USER_APP.FIELDS.INVALID_PHONE' |translate}}</div>
                          </div>

                        </div>

                        <div class="userUpdateItem">
                          <label>{{'PROFILE.LIST.ADRESSE' |translate}}</label>
                          <input type="text" placeholder="Algeria" formControlName="address"
                                 class="form-control"/>
                        </div>
                      </div>

                      <div class="userUpdateRight col-xl-4">

                        <div class="userUpdateUpload">
                          <img class="userUpdateImg" (click)="openPhotoProfileModal(profile, $event)"
                               style="border-radius: 50%;border: solid 2px rgba(149, 6, 74, 0.62);"
                               src="{{newProfileStream}}" alt=""/>
                          <label for="file" style="cursor: pointer"> <i class="fa fa-upload"></i></label>
                          <input type="file" id="file" style="display: none"
                                 valid-file required accept=".png" size="100"
                                 (change)="onFileChange($event)">

                        </div>
                        <p ng-show="ebook.maxSizeError" *ngIf="maxSizeErr"
                           style="background-color: #ff9999 ;margin-top: 10px">Max file size exceeded (500 ko)</p>
                        <button pButton pRipple label="{{'PROFILE.LIST.REGISTER' | translate}}" type="submit"
                                icon="pi pi-check"
                                class="p-button-text updateUserBtn"></button>
                      </div>
                    </div>
                  </form>
                </div>
              </p-tabPanel>
              <p-tabPanel header="3">
                <ng-template pTemplate="header">
                  <i class="pi pi-lock mr-1 ml-1"></i>
                  <span>{{'PROFILE.LIST.SECURITY' | translate}}</span>
                </ng-template>
                <span class="userUpdateTitle">{{'PROFILE.LIST.SECURITY' | translate}}</span>
                <form class="form-horizontal" [formGroup]="resetForm" (ngSubmit)="onChangePassword()">
                  <div class="userUpdateItem">
                    <label for="oldPassword">Ancien mot de passe</label>
                    <input formControlName="oldPassword" id="oldPassword"
                           [ngClass]="{ 'is-invalid': submittedReset && f.oldPassword.errors }"
                           type="password" placeholder="ancien mot de passe" minlength="7"
                           class="form-control"/>
                    <div *ngIf="submittedReset && f.oldPassword.errors?.required" class="invalid-feedback">
                      Ancien mot de passe est obligatoire
                    </div>
                  </div>
                  <div class="userUpdateItem">
                    <label for="newPassword">Nouveau mot de passe</label>
                    <input formControlName="newPassword" id="newPassword" type="password"
                           placeholder="nouveau mot de passe" minlength="6"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': submittedReset && f.newPassword.errors }"/>
                    <div *ngIf="submittedReset && f.newPassword.errors?.required" class="invalid-feedback">
                      Nouveau mot de passe est obligatoire
                    </div>
                    <div *ngIf="submittedReset && f.newPassword.errors?.pattern" class="invalid-feedback">
                      Le mot de passe doit contenir au moins 6 caractères et doit contenir au moins une lettre majuscule
                      , un carractére special
                      et un caractère numérique.
                    </div>
                  </div>
                  <div class="userUpdateItem pb-3">
                    <label>confirmé Nouveau mot de passe</label>
                    <input formControlName="confirmeNewPassword"
                           [ngClass]="{ 'is-invalid': submittedReset && f.confirmeNewPassword.errors}"
                           type="password"
                           placeholder="confirmé nouveau mot de passe"
                           class="form-control"/>
                    <div *ngIf="submittedReset && f.confirmeNewPassword.errors" class="invalid-feedback">
                      <div *ngIf="f.confirmeNewPassword.errors.required">Confirmation mot de passe est obligatoire</div>
                      <div *ngIf="!mustMatche"> pattern</div>
                    </div>
                  </div>
                  <button pButton pRipple label="{{'PROFILE.LIST.REGISTER' | translate}}" icon="pi pi-check"
                          class="p-button-text updateUserBtn" type="submit"></button>
                </form>
              </p-tabPanel>
              <p-tabPanel header="4">
                <ng-template pTemplate="header">
                  <i class="pi pi-calendar mr-1 ml-1"></i>
                  <span>{{'PROFILE.LIST.RELICATS' | translate}}</span>
                </ng-template>
                <p-card header="{{numberOfRelicats}}"
                        styleClass="p-card-shadow">
                  <p>vous avez le droit de prendre un congé sur l'intervalle de votre relicats a partir du mois de
                    juin</p>
                  <ng-template pTemplate="footer">
                    <button pButton pRipple label="{{'PROFILE.LIST.LEAVE_REQUEST' | translate}}"
                            icon="pi pi-calendar"
                            class="p-button-text updateUserBtn"
                            routerLink="/demande-conges"></button>


                  </ng-template>
                </p-card>
              </p-tabPanel>
              <p-tabPanel header="5">
                <ng-template pTemplate="header">
                  <i class="pi pi-file mr-1 ml-1"></i>
                  <span>{{'PROFILE.LIST.FILES' | translate}}</span>
                </ng-template>
                <p>fichiers Espace</p>
              </p-tabPanel>
            </p-tabView>

          </div>
        </div>
      </div>
    </div>

    <!-- Photo de profile  Modal -->
    <ng-template #profile let-modal>
      <div class="modal-header">
        <h5 class="modal-title">votre photo de profile</h5>
        <button type="button" class="close" aria-hidden="true" (click)="closePhotoProfileModal()">×</button>
      </div>
      <div class="modal-body p-3">
        <div class="form-group col-12 ">
          <img class="col-12" src="{{newProfileStream}}" alt=""/>
        </div>
      </div>
    </ng-template>
  </div>
</div>

