import {Component, OnInit, ViewChild} from '@angular/core';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {Router} from '@angular/router';
import {EventsService} from '../../core/services/rh-ec/eventsService';
import {StEvents} from '../../core/models/rh-ec/StEvents';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../core/services/application.service';
import {AuthenticationService} from '../../core/services/auth.service';
import {RhEmployeEvts} from '../../core/models/rh-ec/RhEmployeEvts';
import {LazyLoadEvent} from 'primeng/api';
import {CookieService} from 'ngx-cookie-service';
import {RhEmployeEvtFiles} from '../../core/models/rh-ec/RhEmployeEvtFiles';
import {File} from '../../core/models/transient/File';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-employees-events',
  templateUrl: './employees-events.component.html',
  styleUrls: ['./employees-events.component.scss']
})
export class EmployeesEventsComponent implements OnInit {

  // lang cookie
  cookieValue;

  // bread crumb items
  breadCrumbItems: Array<{}>;


  showDetails = false;
  showEdits = false;
  showAdd = false;


  listEmployes: RhEmployes[] = [];
  selectedEmployee: RhEmployes[] = [];


  listEvents: StEvents[] = [];
  rhEmployeEvt = new RhEmployeEvts();
  cols: any[];
  isLoading = true;

// form control values
  eventForm: FormGroup;
  submitted: boolean;

  // Date added in event
  dataEvents: RhEmployeEvts[] = [];
  totalRecords = 0;
  loading = true;
  rhEmployeeEvents: RhEmployeEvts[] = [];

  eventDtDlg = false;
  statusDlg = false;


  /*upload file properties */
  uploadedFiles: File[] = [];
  eventFileFlag = false;

  // bloque Ui
  blockedDocument = false;

  @ViewChild('dtE') table: Table;

  constructor(public employesService: EmployesService, private router: Router, private eventsService: EventsService,
              private formBuilder: FormBuilder, public applicationService: ApplicationService,
              public authenticationService: AuthenticationService, private cookiesService: CookieService) {
  }

  ngOnInit(): void {
    this.cookieValue = this.cookiesService.get('lang');
    this.breadCrumbItems = [{label: 'Alliance Rh'}, {label: 'Events', active: true}];
    this._getEmployes();
    this._getEventsList();
    this._fetchData();

    /**
     * init the form
     */

    this.eventForm = this.formBuilder.group({
      codeEvent: [null, Validators.required],
      serial: [null, this.showEdits ? Validators.required : Validators.nullValidator],
      employeeList: [[], this.showAdd ? Validators.required : Validators.nullValidator],
      employeeNum: [null, this.showEdits ? Validators.required : Validators.nullValidator],
      dateDebut: [null, Validators.required],
      dateFin: [null, Validators.required],
      eventFiles: [[], this.eventFileFlag ? Validators.required : Validators.nullValidator], // Assuming eventFiles is an array of any type
      activeFlag: [null, Validators.nullValidator],
    });


    /**
     * Template cols
     */
    this.cols = [
      {
        field: 'rhEmployes',
        header: 'EVENT.HEADERS.EMPLOYEE',
        width: '18%'
      },
      {
        field: 'stEvents',
        header: 'EVENT.HEADERS.EVENT',
        width: '18%'
      },
      {
        field: 'epeStartDate',
        header: 'EVENT.HEADERS.START_DATE',
        width: '18%'
      },
      {
        field: 'epeStatus',
        header: 'EVENT.HEADERS.STATUS',
        width: '18%'
      },
      {
        field: 'actions',
        header: 'EVENT.HEADERS.ACTIONS',
        width: '18%'
      }
    ];
  }

  _fetchData() {

    setTimeout(() => {
      this.eventsService.findAllRhEmployeeEvents().subscribe(data => {
        this.dataEvents = data;
        this.dataEvents = this.dataEvents.filter(evt => evt.stEvents.evtCategorie === '1');
        this.loading = false;
      }, error => {
        console.log(error);
      });
    }, 1000);
  }

  loadEmployeeEvents(event: LazyLoadEvent) {
    setTimeout(() => {
      if (this.dataEvents) {
        this.rhEmployeeEvents = this.dataEvents.slice(event.first, (event.first + event.rows));
        this.totalRecords = this.rhEmployeeEvents.length;
      }
      this.loading = false;
    }, 1000);
  }

  get deF() {
    return this.eventForm.controls;
  }

  resetForm(form: FormGroup) {
    form.reset();
  }

  private _getEventsList() {
    setTimeout(() => {
      this.eventsService.findAllStEvents().subscribe(data => {
          this.listEvents = data;
          this.listEvents = this.listEvents.filter(evt =>
            evt.evtCategorie === '1');
        },
        error => {
          console.log(error);
        });
    }, 1000);
  }

  _getEmployes() {
    this.loading = true;
    setTimeout(() => {
      this.employesService.findAllEmployes().subscribe((data: RhEmployes[]) => {
          this.listEmployes = data;
          this.isLoading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/']);
          }
        },
      );
    }, 1000);

  }


  /**
   *  new Demande
   */

  showAddNewEvent() {
    this.resetForm(this.eventForm);
    this.showDetails = false;
    this.showEdits = false;
    this.showAdd = true;
    this.showAdd = true;
    this.eventDtDlg = true;
  }

  showDetailsEvent(rowdata: RhEmployeEvts) {
    this.rhEmployeEvt = rowdata;
    this.eventDtDlg = true;
    this.patchFormValues(this.eventForm, rowdata);
    this.getFilesList(this.rhEmployeEvt.rhEmployeEvtFilesList);
    this.showDetails = true;
    this.showEdits = false;
    this.showAdd = false;

  }

  showEditEvent(rowdata: RhEmployeEvts, rowIndex: any) {
    this.rhEmployeEvt = rowdata;
    this.eventDtDlg = true;
    this.showEdits = true;
    this.showDetails = false;
    this.showAdd = false;
    this.patchFormValues(this.eventForm, rowdata);

  }

  patchFormValues(form: FormGroup, rowdata: RhEmployeEvts) {
    form.patchValue({
      codeEvent: rowdata.stEvents.stEventsPK.evtCode,
      serial: rowdata.rhEmployeEvtsPK.epeSerial,
      employeeList: [],
      employeeNum: this.showEdits ? rowdata.rhEmployes.rhEmployesPK.empNum : null,
      dateDebut: new Date(rowdata.epeDate),
      dateFin: new Date(rowdata.epeEndDate),
      eventFiles: rowdata.rhEmployeEvtFilesList.length > 0 ? rowdata.rhEmployeEvtFilesList : [],
      activeFlag: rowdata.epeActiveFlag,
    });
  }

  getFilesList(files: RhEmployeEvtFiles[]) {
    files.map(f => {
      let file = new File();
      file.name = f.evfNom;
      file.data = f.evfFile;
      file.size = null;
      file.type = f.evfType;
      this.uploadedFiles.push(file);
    });
  }

  hideDialog() {
    this.eventDtDlg = false;
    this.resetForm(this.eventForm);
    this.showEdits = false;
    this.showDetails = false;
    this.showAdd = false;

  }

  /**
   * select event listener
   */
  onSelectEvent(event) {
    this.eventForm.patchValue({
      codeEvent: (event.value as StEvents).stEventsPK.evtCode,
    });
    this.eventFileFlag = (event.value as StEvents).evtFileFlag === 1 ? true : false;
  }

  saveEmployeeEvent() {
    this.submitted = true;
    if (this.eventForm.invalid) {
      console.log(this.eventForm.invalid);
      console.log(this.eventForm);
      return;
    } else {
      this.blockedDocument = true;
      this.addEntity();
    }
  }

  addEntity() {
    this.eventsService.saveRhEmployeeEventByRh(this.eventForm).subscribe(data => {
      if (data != null) {
        const list = (data.data[0]);
        console.log(list);
        this.dataEvents.push(...list);
        this.eventDtDlg = false;
        this.resetForm(this.eventForm);
        this.blockedDocument = false;
        this.applicationService.showSuccefulSaveDialog('Evenement Ajouté Avec Succée.', 'success');
        this.submitted = false;
      }
    }, error => {
      console.log(error);
    });
  }


  /**
   * status demande
   */
  showStatusEmpEvent(rowDta: RhEmployeEvts) {
    this.rhEmployeEvt = new RhEmployeEvts();
    this.rhEmployeEvt = rowDta;
    this.statusDlg = true;
  }

  hideStatusEmpEvent() {
    this.statusDlg = false;
  }


  /*upload file methods*/
  onFileUploaded(event) {
    console.log(event);
  }

  onValidateUpload(files: any[]) {
    this.eventForm.patchValue({
      eventFiles: files
    });
    console.log(this.eventForm.value);

  }
}
