import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
            const currentUserRoles = this.authenticationService.currentUserRoles();
            if (currentUserRoles.includes('ROLE_RH' , 'ROLE_ADMIN')) {
                // you have the role
                return true;
        }
      // you don't have the role
            this.router.navigate(['/pages-404']);
            return false;
    }
}
