import {Component, OnInit, ViewChild} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {RhDemandeConges, RhDemandeCongesPK} from '../../core/models/rh-ec/RhDemandeConges';
import {Router} from '@angular/router';
import {Table} from 'primeng/table';
import {ConfirmationService, LazyLoadEvent, MessageService, SortEvent} from 'primeng/api';
import {RhEmployes, RhEmployesPK} from '../../core/models/rh-ec/RhEmployes';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {AuthenticationService} from '../../core/services/auth.service';
import {HttpParams} from '@angular/common/http';
import {DilovService} from '../../core/services/adm/dilov.service';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../core/services/application.service';
import {DemandeCongesService} from '../../core/services/rh-ec/demandeCongesService';
import {User} from '../../core/models/auth.models';
import {RhDemandeAbs} from '../../core/models/rh-ec/RhDemandeAbs';
import {VEmpCng} from '../../core/models/vues/VEmpCng';
import {CookieService} from 'ngx-cookie-service';
import {statusList} from './demande-cg-data';
import {UtilConstants} from '../../core/helpers/util-constants';
import {File} from '../../core/models/transient/File';
import {Exercice} from './demande-cg.model';
import {ReportService} from '../../core/services/adm/report.service';
import {saveAs} from 'file-saver';
import {throwError} from 'rxjs';
import {PAGES_URLS} from '../pages.urls';


@Component({
  selector: 'app-demande-cg',
  templateUrl: './demande-cg.component.html',
  styleUrls: ['./demande-cg.component.scss'],
  providers: [DecimalPipe],

})
export class DemandeCgComponent implements OnInit {

// Current User
  currentUser: User;

  cookieValue;
  listEmployes: RhEmployes[];
  selectedEmployee = new RhEmployes();


  // breadcrumb items
  breadCrumbItems: Array<{}>;

  // Data
  data: RhDemandeConges[];
  demandeConges: RhDemandeConges[];
  totalRecords = 0;
  selectedDemandeConges: RhDemandeConges;
  disableAddNew: boolean;
  vEmpCg = new VEmpCng();
  numberOfDays: number;
  showNmbrOfDaysMsg = false;
  rowIndex: any;
  // Dialogs attributes
  demandeCongesDlg = false;
  demandeCongesDtDlg = false;
  validationDlg = false;
  validationHeder: string;
  validationStatus = 0;
  validationState = true;

  statusDlg = false;

  listCongesTypes: DiLovDetails[];
  curentTime = new Date();
  editMode = false;

  // Lazy attributes
  cols: any[];
  exportColumns: any[];
  loading = true;
  document: string;

  // Form Controll attributs
  demandeCgForm: FormGroup;
  validateDemandeCgFrom: FormGroup;
  titreCongeForm: FormGroup;
  submitted = false;
  error = '';

  statusList = statusList;
  exercicesList: Exercice[] = [];
  selectedEcxercice: Exercice = {
    label: null,
    value: null
  };


  // bloque Ui
  blockedDocument = false;

  // upload file properties
  uploadedFiles: File[] = [];

  @ViewChild('cgT') table: Table;

  // tslint:disable-next-line:max-line-length
  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder,
              private pipe: DecimalPipe, public demandeCongesService: DemandeCongesService,
              public employesService: EmployesService, public dilovService: DilovService, public cookiesService: CookieService,
              private messageService: MessageService, private confirmationService: ConfirmationService,
              public authService: AuthenticationService, private reportService: ReportService,
              private router: Router) {
  }


  ngOnInit(): void {
    this.selectedEcxercice = {
      label: new Date().getFullYear().toString(),
      value: new Date().getFullYear()
    };
    this.cookieValue = this.cookiesService.get('lang');
    this.selectedEmployee.rhEmployesPK = new RhEmployesPK();
    this.currentUser = this.authService.currentUser();
    this.document = 'List de toute des demande Congés';
    this.selectedDemandeConges = new RhDemandeConges();
    this.selectedDemandeConges.rhDemandeCongesPK = new RhDemandeCongesPK();
    this.disableAddNew = false;
    this.breadCrumbItems = [{label: 'Dashbord'}, {label: 'Leave Requests', active: true}];
    /**
     * get list of employees just to the admin
     */
    if (this.currentUser.roles.includes('ROLE_RH')) {
      this._getEmployes();
    } else {
      this.employesService._getCurrentEmploy();
      this.selectedEmployee = this.employesService.selectedEmployData.rhEmployes;
    }
    this._fetchData();
    this._getListcgType();

    /**
     * Template cols
     */
    this.cols = [
      // {field: 'img', header: '#'},
      {
        field: 'rhEmployes',
        header: 'LEAVE_REQUEST.HEADERS.EMPLOYEE',
        width: '18%',


      },
      {
        field: 'dcgType',
        header: 'LEAVE_REQUEST.HEADERS.CONGEE_TYPE',
        width: '25%',

      },
      {
        field: 'dcgDemandeDate',
        header: 'LEAVE_REQUEST.HEADERS.CONGEE_REQUEST_DATE',
        width: '12%',

      },
      {
        field: 'dcgRemarksLoc',
        header: 'LEAVE_REQUEST.HEADERS.REMARKS',
        width: '20%',

      },
      {
        field: 'dcgDecision',
        header: 'LEAVE_REQUEST.HEADERS.STATUS',
        width: '10%',

      },
      {
        field: 'actions',
        header: 'LEAVE_REQUEST.HEADERS.ACTIONS',
        width: '15%',

      }
    ];


    /**
     * Files export cols
     */
    this.exportColumns = [
      {field: 'rhEmployes', header: 'Employes'},
      {field: 'dcgType', header: 'Type cgcence'},
      {field: 'dcgDemandeDate', header: 'Date Demande'},
      {field: 'dcgDecision', header: 'Status'},
    ];

    /**
     * init demande congé  form groupe
     */
    this.demandeCgForm = this.formBuilder.group({
      employeeType: [null, Validators.required],
      employeeNum: [null, Validators.required],
      demandeDate: [null, Validators.required],
      dateDebut: [null, Validators.required],
      dateFin: [null, Validators.required],
      congeType: ['', Validators.required],
      exercice: [null, this.editMode ? Validators.required : Validators.nullValidator],
      serial: [null, this.editMode ? Validators.required : Validators.nullValidator],
      addressContact: [''],
      phoneContact: [''],
      remarques: ['', this.editMode ? Validators.required : Validators.nullValidator],
      eventFiles: [null]
    });

    /**
     * init validate demannde congé form groupe
     */

    this.validateDemandeCgFrom = this.formBuilder.group({
      employeeType: ['', Validators.required],
      employeeNum: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      exercice: ['', Validators.required],
      serial: ['', Validators.required],
      status: [''],
      remarks: ['']
    });

    /**
     * init print "titre congé" form group
     */

    this.titreCongeForm = this.formBuilder.group({
      employeeType: ['', Validators.required],
      employeeNum: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      exercice: ['', Validators.required],
      serial: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get dcG() {
    return this.demandeCgForm.controls;
  }

  resetForm(form: FormGroup) {
    form.reset();
  }

  onSelectExercice(event) {
    this.demandeConges = this.data;
    this.selectedEcxercice = event.value as Exercice;
    this.filterByExercice(this.selectedEcxercice);
  }

  onSelectStatus(event) {
    this.demandeConges = this.data;
    if (this.selectedEcxercice.value !== null) {
      this.filterByExercice(this.selectedEcxercice);
    }
    this.filterByStatus(event.value);

  }

  filterByExercice(e: Exercice) {
    this.demandeConges = this.demandeConges.filter(abs => abs.rhDemandeCongesPK.dcgExercice === e.value);
  }

  filterByStatus(s: number) {
    this.demandeConges = this.demandeConges.filter(abs => abs.dcgStatus === s);
  }

  isDemandeCgFormInvalid(): boolean {
    return this.demandeCgForm.invalid;
  }


  columnFilter(event: any, field) {
    console.log(event.target.value);
    this.demandeConges.filter(event.target.value, 'contains');
  }


  /**
   * fetches the table value
   */
  _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.demandeCongesService.findAllDemandes(this.currentUser).subscribe(
        data => {
          this.data = data;
          this.demandeConges = data;
          this.totalRecords = this.demandeConges.length;
          this.exercicesList = [...new Set(data.map(e => e.rhDemandeCongesPK.dcgExercice))]
            .map(value => ({label: value.toString(), value}));
          if (this.exercicesList.length === 0) {
            this.exercicesList.push(this.selectedEcxercice);
          }
          ;
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '401') {
            this.router.navigate(['/']);
          }
        },
      );
    }, 1000);
  }

  _getVEmployeCongee(numEmp: string) {
    this.demandeCongesService.findVempCg(numEmp).subscribe(
      data => {
        this.vEmpCg = data;
        this.numberOfDays = this.vEmpCg.nbrReliquat;
        console.log(this.numberOfDays);
        // tslint:disable-next-line:no-shadowed-variable
      }, error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      },
    );
  }

  _getListcgType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '16');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listCongesTypes = [];
        this.listCongesTypes = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

  _getEmployes() {
    this.employesService.findAllEmployes().subscribe(data => {
        this.listEmployes = (data as RhEmployes[]);
        console.log(this.listEmployes);
        // tslint:disable-next-line:no-shadowed-variable
      }, error => {
        if (error.status === '403') {
          this.router.navigate(['/']);
        }
      },
    );
  }


  public reinitCols() {
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }


  /*  Data Methods */

  /**
   *  new Demande
   */

  showAddNewDemande() {

    this.demandeCgForm.reset();
    // tslint:disable-next-line:max-line-length
    if (this.currentUser.roles.includes('ROLE_RH')) {
      this.selectedEmployee = new RhEmployes();
    } else {
      this.selectedEmployee = this.employesService.selectedEmployData.rhEmployes;
      this.demandeCgForm.patchValue({
        employeeType: this.selectedEmployee.rhEmployesPK.empType,
        employeeNum: this.selectedEmployee.rhEmployesPK.empNum,
      });
      this._getVEmployeCongee(this.selectedEmployee.rhEmployesPK.empNum);
    }
    if (this.currentUser.roles.includes('ROLE_USER')) {
      if (this.numberOfDays > 2.5) {
        this.initNewDemandeCongee();
      } else {
        this.applicationService.showErrorMessage(UtilConstants.VOUS_NAVEZ_PAS_LE_DROIT);
      }
    } else {
      this.initNewDemandeCongee();
    }
  }

  private initNewDemandeCongee() {
    this.selectedDemandeConges = new RhDemandeConges();
    this.submitted = false;
    this.demandeCongesDlg = true;
    this.editMode = false;
  }

  hideDialog() {
    this.demandeCongesDlg = false;
    this.showNmbrOfDaysMsg = false;
    this.numberOfDays = 0;
  }


  onSelectRhEmploye(event) {
    this.demandeCgForm.patchValue({
      employeeType: (event.value as RhEmployes).rhEmployesPK.empType,
      employeeNum: (event.value as RhEmployes).rhEmployesPK.empNum,
    });
    this._getVEmployeCongee((event.value as RhEmployes).rhEmployesPK.empNum);
  }

  onSelectCongesType(event) {
    this.demandeCgForm.patchValue({
      congeType: (event.value as DiLovDetails).lvdId,
    });
    console.log(this.dcG.congeType.value);
  }

  onDemandeDateChange(event) {
    this.demandeCgForm.patchValue({
      dateDebut: null,
      dateFin: null,
    });
  }

  onSelectDateFin() {
// set the valid date pattern
    this.demandeCgForm.patchValue({
      demandeDate: this.applicationService.convertDateString(this.dcG.demandeDate.value),
      dateDebut: this.applicationService.convertDateString(this.dcG.dateDebut.value),
      dateFin: this.applicationService.convertDateString(this.dcG.dateFin.value),
    });
  }


  /**
   * details demande
   */
  showDetails(rowData: RhDemandeConges) {
    this.selectedDemandeConges = rowData;
    this.selectedEmployee = this.selectedDemandeConges.rhEmployes;
    this.demandeCongesDtDlg = true;
  }

  hideDetails() {
    this.demandeCongesDtDlg = true;
  }

  /**
   * edit demande
   */
  showEditDemande(rowdata: RhDemandeConges, rowIndex: any) {
    this.rowIndex = rowIndex;
    console.log('this.rowIndex' + this.rowIndex);
    this.selectedDemandeConges = new RhDemandeConges();
    this.selectedDemandeConges = rowdata;
    this.selectedEmployee = this.selectedDemandeConges.rhEmployes;
    this.editMode = true;
    this._getVEmployeCongee(this.selectedDemandeConges.rhDemandeCongesPK.dcgEmpNum);

    /**
     * init edit form groupe
     */
    this.demandeCgForm.patchValue({
      employeeType: this.selectedDemandeConges.rhEmployes.rhEmployesPK.empType,
      employeeNum: this.selectedDemandeConges.rhEmployes.rhEmployesPK.empNum,
      demandeDate: new Date(this.selectedDemandeConges.dcgDemandeDate),
      dateDebut: new Date(this.selectedDemandeConges.dcgDebutDate),
      dateFin: new Date(this.selectedDemandeConges.dcgFinDate),
      congeType: this.selectedDemandeConges.dcgType,
      exercice: this.selectedDemandeConges.rhDemandeCongesPK.dcgExercice,
      serial: this.selectedDemandeConges.rhDemandeCongesPK.dcgSerial,
      addressContact: this.selectedDemandeConges.dcgAddressLoc,
      phoneContact: this.selectedDemandeConges.dcgContactTelephone,
      remarques: this.selectedDemandeConges.dcgRemarksLoc
    });
    this.editMode = true;
    this.demandeCongesDlg = true;

  }

  /**
   * delete demande
   */
  deleteselectedDemandeConges(rowdata: RhDemandeConges) {
    this.confirmationService.confirm({
      message: 'Voulez-vous vraiment supprimer le demande sélectionné?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.demandeCongesService.deleteDemandeConges(rowdata.rhDemandeCongesPK).subscribe(data => {
          this.demandeConges = this.demandeConges.filter(val => !this.demandeConges.includes(val));
          this.selectedDemandeConges = null;
          this.messageService.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        });
      }
    });
  }


  /**
   * validate demande
   */
  showValidateDemande(rowDta: RhDemandeConges, rowIndex: any) {
    this.rowIndex = rowIndex;
    this.selectedDemandeConges = new RhDemandeConges();
    this.selectedDemandeConges = rowDta;
    this.selectedEmployee = this.selectedDemandeConges.rhEmployes;

    //  Patch values to the form controll
    this.validateDemandeCgFrom.patchValue({
      employeeType: this.selectedDemandeConges.rhEmployes.rhEmployesPK.empType,
      employeeNum: this.selectedDemandeConges.rhEmployes.rhEmployesPK.empNum,
      exercice: this.selectedDemandeConges.rhDemandeCongesPK.dcgExercice,
      serial: this.selectedDemandeConges.rhDemandeCongesPK.dcgSerial,
      status: this.validationStatus,
    });

    this.validationDlg = true;
  }

  hideValidateDemande() {
    this.validationDlg = true;
  }

  validateDemandeConges(form: FormGroup, status: number) {
    this.validateDemandeCgFrom.patchValue({
      status: this.validationState ? 1 : 2,
    });
    this.vlidateEntity();

  }


  showStatusDemande(rowDta: RhDemandeConges) {
    this.selectedDemandeConges = rowDta;
    this.selectedEmployee = this.selectedDemandeConges.rhEmployes;
    this.statusDlg = true;
  }

  hideStatusDemande() {
    this.statusDlg = false;
  }


  saveDemandeConges() {
    // stop here if form is invalid
    this.submitted = true;
    if (this.demandeCgForm.invalid) {
      console.log(this.demandeCgForm.invalid);
      console.log(this.demandeCgForm.value);
      return;
    } else {
      this.blockedDocument = true;
      if (!this.currentUser.roles.includes('ROLE_RH')) {
        this.demandeCgForm.patchValue({
          employeeType: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empType,
          employeeNum: this.employesService.selectedEmployData.rhEmployes.rhEmployesPK.empNum,
        });
      }
      if (this.editMode) {
        this.editEntity();
      } else {
        this.addEntity();
      }
    }
  }

  addEntity() {
    // this.selectedDemandeAbs.dabDecision = 'En cours';
    this.demandeCongesService.saveDemandeConges(this.demandeCgForm).subscribe(data => {
      if (data.data != null) {
        this.selectedDemandeConges = (data.data[0] as RhDemandeConges);
        this.demandeConges.push(this.selectedDemandeConges);
        this.demandeConges = [...this.demandeConges];
        this.demandeCongesDlg = false;
        this.blockedDocument = false;
        this.resetForm(this.demandeCgForm);
        this.applicationService.showSuccefulSaveDialog('Demande Ajouté Avec Succée.', 'success');
      }
    }, error => {
      console.log(error);
    });
  }

  editEntity() {
    this.demandeCongesService.editDemandeConges(this.demandeCgForm).subscribe(data => {
      this.selectedDemandeConges = (data.data[0] as RhDemandeConges);
      this.demandeConges.splice(this.rowIndex, 1);
      this.demandeConges.splice(this.rowIndex, 0, this.selectedDemandeConges);
      this.demandeConges = [...this.demandeConges];
      this.demandeCongesDlg = false;
      this.blockedDocument = false;
      this.resetForm(this.demandeCgForm);
      this.applicationService.showSuccefulSaveDialog('La demande  a été modifier avec succéee', 'success');
    }, error => {
      console.log('eroor===>' + error);
    });
  }

  vlidateEntity() {
    this.blockedDocument = true;
    this.demandeCongesService.validateDemandeConges(this.validateDemandeCgFrom).subscribe(data => {
      this.selectedDemandeConges = (data.data[0] as RhDemandeConges);
      this.demandeConges.splice(this.rowIndex, 1);
      this.demandeConges.splice(this.rowIndex, 0, this.selectedDemandeConges);
      this.demandeConges = [...this.demandeConges];
      switch (this.validationStatus) {
        case 1: {
          // tslint:disable-next-line:max-line-length
          this.applicationService.showSuccefulSaveDialog('La demande  a été ' + (this.selectedDemandeConges.dcgDecision).toLowerCase() + ' avec succéee', 'success');
          break;
        }
        case 2: {
          // tslint:disable-next-line:max-line-length
          this.applicationService.showSuccefulSaveDialog('La demande  a été ' + (this.selectedDemandeConges.dcgDecision).toLowerCase() + ' avec succéee', 'success');
          break;
        }
        default: {
          this.applicationService.showSuccefulSaveDialog('La demande  a été modifier avec succéee', 'success');
          break;
        }
      }
      this.validationDlg = false;
      this.blockedDocument = false;
    }, error => {
      console.log('eroor===>' + error);
    });
  }

  /*upload file methods*/
  onFileUploaded(event) {
  }

  onValidateUpload(files: any[]) {
    console.log(files);
    this.demandeCgForm.patchValue({
      eventFiles: files
    });

  }


  public donloadTitreConge(rowDta: RhDemandeConges): any {
    this.blockedDocument = true;
    this.titreCongeForm.patchValue({
      employeeType: rowDta.rhDemandeCongesPK.dcgEmpType,
      employeeNum: rowDta.rhDemandeCongesPK.dcgEmpNum,
      exercice: rowDta.rhDemandeCongesPK.dcgExercice,
      serial: rowDta.rhDemandeCongesPK.dcgSerial,
    });
    const mediaType = 'application/pdf';
    this.reportService.printDocument(this.titreCongeForm, PAGES_URLS.PRINT_DEMANDE_CONGE_REPORTING).subscribe(
      (response) => {
        const blob = new Blob([response], {type: mediaType});
        console.log(blob);
        saveAs(blob, 'report.pdf');
        this.blockedDocument = false;
      },
      e => {
        throwError(e);
        console.log(e);
      }
    );
  }


}
