import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {DashboardComponent} from './dashboard/dashboard.component';
import {CalendarComponent} from './calendar/calendar.component';
import {DemandeAbsComponent} from './demande-abs/demande-abs.component';
import {DemandeCgComponent} from './demande-cg/demande-cg.component';
import {ProfileComponent} from './profile/profile.component';
import {RolesComponent} from './roles/roles.component';
import {ReportsComponent} from './reports/reports.component';
import {EmployeesEventsComponent} from './employees-events/employees-events.component';
import {UsersComponent} from './users/users.component';
import {UsersDetailsComponent} from './users-details/users-details.component';
import {RoleGuard} from '../core/guards/role.guard';
import {PageNotFoundComponent} from '../layouts/shared/page-not-found/page-not-found.component';
import {UsersAppComponent} from './users-app/users-app.component';
import {SetupEventsComponent} from './setup-events/setup-events.component';


const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'calendar', component: CalendarComponent},
  {path: 'demande-abscence', component: DemandeAbsComponent},
  {path: 'demande-conges', component: DemandeCgComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'roles', component: RolesComponent , canActivate: [RoleGuard]},
  {path: 'reports', component: ReportsComponent},
  {path: 'employees-events', component: EmployeesEventsComponent},
  {path: 'employees', component: UsersComponent , canActivate: [RoleGuard]},
  {path: 'employees/:empNum', component: UsersDetailsComponent , canActivate: [RoleGuard]},
  {path: 'users-app', component: UsersAppComponent , canActivate: [RoleGuard]},
  {path: 'setup-events', component: SetupEventsComponent , canActivate: [RoleGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}
