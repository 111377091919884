
  <p-panel header="joindre des fichiers" class="mt-2">
    <div class="row align-items-center justify-content-between">
      <div class="ml-3">
        <input type="file" id="fileUploadInput" multiple accept="image/png, image/gif, image/jpeg"
               class="file-input col-4"
               (change)="onFileUpload($event)"/>
        <label for="fileUploadInput" class="file-label">
          <i class="pi pi-plus"></i><span class="ml-1" [class.disabled]="!disableUploadButtons">choisir</span>
        </label>
        <button pButton type="button" [disabled]="uploadedFiles.length===0" class="mr-1 col-4" label="Télécharger"
                icon="pi pi-upload" (click)="onValidateUpload()"></button>
        <button pButton type="button" (click)="onCancelUpload()" [disabled]="uploadedFiles.length===0"
                class="mr-1 col-4"
                label="Annuler"
                icon="pi pi-times">
        </button>
      </div>
    </div>
    <div class="progress-bar-container mt-1">
      <div class="progress-bar" [style.width.%]="value">
      </div>
    </div>
    <div class="mt-3">
      <p-table [value]="uploadedFiles" *ngIf="rendListFiles">
        <ng-template pTemplate="body" let-file let-rowIndex rowIndex>
          <tr class="d-flex align-items-center justify-content-between">
            <td><img src="data:image/png;base64,{{file.data}}" height="30px" width="30px"/></td>
            <td>{{file.name}}</td>
            <td>{{file.size}} KB</td>
            <td>
              <button pButton type="button" [disabled]="disableUploadButtons"
                      (click)="onRemoveFile(rowIndex)" class="mr-1"
                      icon="pi pi-times"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>


