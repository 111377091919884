<div id="layout-wrapper">
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
  </app-topbar>
  <app-sidebar></app-sidebar>
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div [ngClass]="{'main-content-rtl':cookiesService.get('lang')=='ar',
       'main-content': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

</div>
<!-- END layout-wrapper -->
