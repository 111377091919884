<div class="container-fluid">
  <app-pagetitle title="{{'ROLE.TEXT' |translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <p-toast></p-toast>
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-12">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                  <ng-template pTemplate="right">
                    <button pButton pRipple label="Nouveau Role" icon="pi pi-plus"
                            class="p-button-success succes-color p-button-add p-mr-2"
                            (click)="showAddNewRole()"
                            [disabled]="!authService.currentUserRoles().includes(UtilConstants.DEVLOPPER_ROLE)"></button>
                  </ng-template>
                </p-toolbar>
                <p-table [columns]="cols" [value]="roles" [responsive]="true" #absT
                         selectionMode="single" [(selection)]="selectedRole" dataKey="rlaCode"
                         [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                         [paginator]="true" [rows]="4"
                         [totalRecords]="totalRecords"
                         [showCurrentPageReport]="true"
                         [tableStyle]="{ 'min-width': '50rem' }"
                         currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                         [rowsPerPageOptions]="[5,10, 15, 50]">
                  <ng-template pTemplate="caption">

                    <div class="p-toolbar-group-left">
                      <div class="app-search d-none d-lg-block" style="padding: 0 !important">
                        <div class="position-relative">
                          <input type="text" class="form-control" pInputText size="50"
                                 placeholder="{{'ABS_REQUEST.LIST.SEARCH' |translate}}"
                                 (input)="absT.filterGlobal($event.target.value, 'contains')"
                                 style="width:auto;text-align: left !important">
                          <span class="ri-search-line"></span>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns ; " [ngStyle]="{'width': col.width}">
                        {{col.header | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                      <td *ngFor="let col of columns">
                        <span *ngIf="col.field !='actions'">  {{rowData[col.field] }} </span>
                        <!--ACTIONS TOOLS BARRE-->
                        <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">

                          <button pButton pRipple type="button" icon="pi pi-pencil"
                                  class="p-button-rounded p-button-text p-button-warning  mr-2"
                                  pTooltip="modifier"
                                  *ngIf="authService.currentUserRoles().includes(UtilConstants.DEVLOPPER_ROLE)"
                                  (click)="showEditRole(rowData , rowIndex)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                      <td [attr.colspan]="columns.length">
                        No records found
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                      Au total il y a {{roles ? data.length : 0 }} roles.
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </div>


            <!--            ADD+UPDATE DIALOG-->
            <form [formGroup]="rolesForm" (ngSubmit)="saveRole()">
              <p-dialog [maximizable]="true"
                        [baseZIndex]="10000" [draggable]="false"
                        [(visible)]="rolesDlg" [style]="{width: '60%'}"
                        header="Nouveau Role" [modal]="true"
                        styleClass="p-fluid" id="rolesDlg">

                <ng-template pTemplate="content">

                  <div class="form-group">
                    <div class="row col-12 col-sm-12 form-row mb-2">

                      <div class="p-field col-12 col-lg-6">
                        <label for="rlaLabel">Roles label :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <input id="rlaLabel" type="text" pInputText (keyup)="onKeyLabel($event)"
                                 name="rlaLabel" class="form-control" formControlName="rlaLabel"
                                 [ngClass]="{ 'is-invalid': submitted && urF.rlaLabel.errors }"/>

                          <div *ngIf="submitted && urF.rlaLabel.errors" class="invalid-feedback">
                            <div *ngIf="urF.rlaLabel.errors.required">Libel est obligatoire</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-6">
                        <label for="rlaCode">Roles Code :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <input id="rlaCode" type="text" pInputText readonly
                                 name="rlaCode" class="form-control" formControlName="rlaCode"
                                 [ngClass]="{ 'is-invalid': submitted && urF.rlaCode.errors }"/>

                          <div *ngIf="submitted && urF.rlaCode.errors" class="invalid-feedback">
                            <div *ngIf="urF.rlaCode.errors.required">Code est obligatoire</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
                          (click)="hideDialog()"></button>
                  <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text"
                          type="submit"></button>
                </ng-template>

              </p-dialog>
            </form>

            <p-confirmDialog [style]="{width: '450px'}" [baseZIndex]="999999"></p-confirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
