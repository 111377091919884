import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {User} from '../models/auth.models';
import {FormGroup} from '@angular/forms';
import {PAGES_URLS} from '../../pages/pages.urls';
import {RhEmployes} from '../models/rh-ec/RhEmployes';
import {EmployesService} from './rh-ec/employesService';
import {ApplicationService} from './application.service';
import {AuthenticationService} from './auth.service';

@Injectable({providedIn: 'root'})
export class UserProfileService {

  // tslint:disable-next-line:max-line-length
  constructor(private http: HttpClient, private employesService: EmployesService, private applicationService: ApplicationService, private authService: AuthenticationService) {
  }

  getAll() {
    return this.http.get<User[]>(`/api/login`);
  }

  register(user: User) {
    return this.http.post(`/users/register`, user);
  }

  changePassword(changePwdForm: FormGroup) {
    const data = {
      oldPassword: changePwdForm.controls.oldPassword.value,
      newPassword: changePwdForm.controls.newPassword.value,
      confirmeNewPassword: changePwdForm.controls.confirmeNewPassword.value,
    };
    return this.http.put<any>(PAGES_URLS.CHANGEPASSWORD, data);
  }

  updateUser(updateForm: FormGroup) {
    return this.http.put<any>(PAGES_URLS.UPDATE_EMPLOYEE, updateForm.value);
  }

}
