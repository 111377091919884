<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="Users App" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <p-toast></p-toast>
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-12">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                  <ng-template pTemplate="right">
                    <button pButton pRipple label="{{'USER_APP.LIST.NEW_USER' |translate}}" icon="pi pi-plus"
                            class="p-button-success succes-color p-button-add p-mr-2"
                            (click)="showAddNewUser()"></button>
                  </ng-template>
                  <ng-template pTemplate="left">
                    <div class="d-flex ">
                      <button type="button" pButton pRipple icon="pi pi-file-excel"
                              (click)="applicationService.exportExcel(users)"
                              class="p-button-success p-button-outlined mr-2" pTooltip="XLS"
                              tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="pi pi-file-pdf"
                              (click)="applicationService.exportPdf(exportColumns,users,document)"
                              class="p-button-danger p-button-outlined mr-2" pTooltip="PDF"
                              tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                </p-toolbar>
                <p-table [columns]="cols" [value]="users" [responsive]="true" #absT
                         selectionMode="single" [(selection)]="selectedUser" dataKey="usrId"
                         [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                         [paginator]="true" [rows]="4"
                         [totalRecords]="totalRecords"
                         [showCurrentPageReport]="true"
                         [tableStyle]="{ 'min-width': '50rem' }"
                         currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                         [rowsPerPageOptions]="[5,10, 15, 50]">
                  <ng-template pTemplate="caption">

                    <div class="p-toolbar-group-left">
                      <div class="app-search d-none d-lg-block" style="padding: 0 !important">
                        <div class="position-relative">
                          <input type="text" class="form-control" pInputText size="50"
                                 placeholder="{{'ABS_REQUEST.LIST.SEARCH' |translate}}"
                                 (input)="absT.filterGlobal($event.target.value, 'contains')"
                                 style="width:auto;text-align: left !important">
                          <span class="ri-search-line"></span>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns ; " [ngStyle]="{'width': col.width}">
                        {{col.header | translate}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                      <td *ngFor="let col of columns">
                        <img *ngIf="col.field ==='usrImage'" class="small-profil-pic"
                             src="{{ rowData[col.field]===null ? UtilConstants.EMPTY_PROFILE_BASE64:'data:image/png;base64,'+rowData[col.field]}}">
                        <span
                          *ngIf="col.field !='actions' && col.field !='usrActiveFlag' && col.field !='usrDdn' && col.field !='rhUsersAppRolesList' && col.field !='usrImage'"
                          ngClass="{{col.field ==='username' ? 'font-weight-bold' :''}}">{{rowData[col.field]}}</span>
                        <span
                          *ngIf="col.field ==='usrDdn'">  {{rowData[col.field] == null ? '/' : rowData[col.field]| date:'longDate'}} </span>

                        <div class="badge font-size-12 " *ngIf="col.field ==='usrActiveFlag'"
                             ngClass="{{rowData[col.field] === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{rowData[col.field] === 1 ? 'Actif' : 'Innactif'}}</div>

                        <div *ngIf="col.field === 'rhUsersAppRolesList'">
                          <!-- (click)="showEditRoles(rowData)" -->
                          <ng-container *ngFor="let role of rowData[col.field]; let last = last">
                            <div class="badge font-size-12" ngClass="badge-soft-info"
                                 pTooltip="modifier role">
                              {{ role.rhRoles.rlaLabel }}
                            </div>
                            <ng-container *ngIf="!last"> ,</ng-container>
                          </ng-container>
                        </div>
                        <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">
                          <button pButton pRipple type="button" icon="pi pi-search"
                                  class="p-button-rounded p-button-text p-button-info  mr-2"
                                  pTooltip="Details"
                                  (click)="showDetails(rowData)"></button>

                          <button pButton pRipple type="button" icon="pi pi-pencil"
                                  class="p-button-rounded p-button-text p-button-warning  mr-2"
                                  pTooltip="modifier"
                                  (click)="showEditUser(rowData , rowIndex)"></button>

                          <!--                                  [disabled]="rowData.dabStatus==1 || rowData.dabStatus==2"-->

                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                      <td [attr.colspan]="columns.length">
                        No records found
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                      Au total il y a {{users ? data.length : 0 }} demandes.
                    </div>
                  </ng-template>
                </p-table>
              </div>
            </div>


            <!--            ADD+UPDATE DIALOG-->
            <form [formGroup]="userAppForm" (ngSubmit)="saveUser()">
              <p-dialog #pdBack [maximizable]="false"
                        [baseZIndex]="10000" [draggable]="false"
                        [(visible)]="usersAppDlg" [style]="{width: '80%'}"
                        header="{{ !editMode ? ('USER_APP.LIST.NEW_USER' |translate) : ('USER_APP.LIST.USER_DETAILS' |translate) }}"
                        [modal]="true"
                        styleClass="p-fluid" id="usersAppDlg">
                <app-loader *ngIf="blockedDocument"></app-loader>
                <ng-template pTemplate="content">
                  <div class="form-group">
                    <div class="row col-12 col-sm-12 form-row mb-2">
                      <div class="p-field col-12 col-lg-3">
                        <label for="usrFirstName">{{'USER_APP.FIELDS.LAST_NAME' | translate}}:</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <input id="usrFirstName" type="text" pInputText
                                 name="usrFirstName" class="form-control" formControlName="usrFirstName"
                                 [ngClass]="{ 'is-invalid': submitted && uaF.usrFirstName.errors }"/>

                          <div *ngIf="submitted && uaF.usrFirstName.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrFirstName.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-3">
                        <label for="usrFirstName">{{'USER_APP.FIELDS.FIRST_NAME' | translate}}:</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <input id="usrLastName" type="text" pInputText
                                 name="usrLastName" class="form-control" formControlName="usrLastName"
                                 [ngClass]="{ 'is-invalid': submitted && uaF.usrLastName.errors }"/>

                          <div *ngIf="submitted && uaF.usrLastName.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrLastName.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-lg-3 col-sm-12">
                        <label for="usrDdn">{{'USER_APP.FIELDS.DATE_OF_BIRTH' | translate}}</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-calendar [baseZIndex]="10001" appendTo="body" id="usrDdn"
                                      [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                                      yearRange="1900:2030"
                                      formControlName="usrDdn" inputId="icon"
                                      name="usrDdn"
                                      [ngClass]="{ 'is-invalid': submitted && uaF.usrDdn.errors }"></p-calendar>
                          <div *ngIf="submitted && uaF.usrDdn.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrDdn.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row col-12 col-sm-12 form-row mb-2">
                      <div class="p-field col-12 col-lg-3">
                        <label for="usrFirstName">{{'USER_APP.FIELDS.CIVILITY' | translate}} :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-dropdown [options]="listCivilites"
                                      [ngClass]="{ 'is-invalid': submitted && uaF.usrCivilite.errors }"
                                      optionLabel="lvdDescLoc"
                                      placeholder="{{'USER_APP.FIELDS.CHOOSE_CIVILITY' | translate}}"
                                      [filter]="true" filterBy="lvdDescLoc"
                                      class="ui-fluid"
                                      (onChange)="onSelectCivilite($event)"></p-dropdown>

                          <div *ngIf="submitted && uaF.usrCivilite.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrCivilite.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-3">
                        <label for="usrSexe">{{'USER_APP.FIELDS.GENDER' | translate}} :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-dropdown [options]="listGenres" id="usrSexe"
                                      [ngClass]="{ 'is-invalid': submitted && uaF.usrSexe.errors }"
                                      optionLabel="lvdDescLoc"
                                      placeholder="{{'USER_APP.FIELDS.CHOOSE_GENDER' | translate}}"
                                      [filter]="true" filterBy="lvdDescLoc"
                                      class="ui-fluid"
                                      (onChange)="onSelectGenre($event)"></p-dropdown>

                          <div *ngIf="submitted && uaF.usrSexe.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrSexe.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-3">
                        <label for="usrTitre">{{'USER_APP.FIELDS.TITLE' | translate}} :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <p-dropdown [options]="listTitres" id="usrTitre"
                                      [ngClass]="{ 'is-invalid': submitted && uaF.usrTitre.errors }"
                                      [filter]="true" filterBy="lvdDescLoc"
                                      class="ui-fluid"
                                      optionLabel="lvdDescLoc"
                                      placeholder="{{'USER_APP.FIELDS.CHOOSE_TITLE' | translate}}"
                                      (onChange)="onSelectTitre($event)"></p-dropdown>

                          <div *ngIf="submitted && uaF.usrTitre.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrTitre.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row col-12 col-sm-12 form-row mb-2">
                      <div class="p-field col-12 col-lg-12">
                        <label for="usrAdresse">{{'USER_APP.FIELDS.ADDRESS' | translate}} :</label>

                        <div class="field">
            <textarea id="usrAdresse" type="text" rows="3"
                      name="usrAdresse" class="form-control" formControlName="usrAdresse"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row col-12 col-sm-12 form-row -2">
                      <div class="p-field col-12 col-lg-3  mb-2">
                        <label for="usrEmail">{{'USER_APP.FIELDS.EMAIL' | translate}} :</label>
                        <span class="text-danger ml-2">*</span>
                        <div class="field">
                          <input id="usrEmail" type="email" pInputText
                                 name="usrEmail" class="form-control" formControlName="usrEmail"
                                 [ngClass]="{ 'is-invalid': submitted && uaF.usrEmail.errors }"
                                 [readOnly]="editMode"/>

                          <div *ngIf="submitted && uaF.usrEmail.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrEmail.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-3 mb-2">
                        <label for="usrMobile">{{'USER_APP.FIELDS.PHONE' | translate}} :</label>

                        <div class="field">
                          <input id="usrMobile" type="text" pInputText
                                 [ngClass]="{ 'is-invalid': submitted && uaF.usrMobile.errors }"
                                 name="usrMobile" class="form-control" formControlName="usrMobile"/>
                          <div *ngIf="submitted && uaF.usrMobile.errors" class="invalid-feedback">
                            <div
                              *ngIf="uaF.usrMobile.errors.pattern">{{'USER_APP.FIELDS.INVALID_PHONE' | translate}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="p-field col-12 col-lg-2 mb-2">
                        <label for="usrActiveFlag">{{'USER_APP.FIELDS.ACTIVE_FLAG' | translate}}</label>
                        <p-toggleButton styleClass="form-control" formControlName="usrActiveFlag" id="usrActiveFlag"
                                        onIcon="pi pi-check"
                                        onLabel="{{'USER_APP.FIELDS.ACTIVE' | translate}}"
                                        offIcon="pi pi-times"
                                        offLabel="{{'USER_APP.FIELDS.INACTIVE' | translate}}"></p-toggleButton>
                      </div>

                      <div class="p-field col-12 col-lg-4 mb-2">
                        <div class="field">
                          <img class="userUpdateImg"
                               style="border-radius: 50%;border: solid 2px rgba(149, 6, 74, 0.62);"
                               src="{{newProfileStream}}" alt=""/>
                          <label for="file" style="cursor: pointer"> <i class="fa fa-upload"></i></label>
                          <input type="file" id="file" style="display: none" (change)="onFileChange($event)"
                                 valid-file required accept=".png" size="100">
                        </div>
                        <p ng-show="ebook.maxSizeError" *ngIf="maxSizeErr"
                           style="background-color: #ff9999 ;margin-top: 10px">{{'USER_APP.MAX_FILE_SIZE_EXCEEDED' | translate}}</p>
                      </div>
                    </div>
                  </div>

                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
                          (click)="hideDialog()"></button>
                  <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text"
                          type="submit"></button>
                </ng-template>

              </p-dialog>
            </form>

            <!--DETAILLES DIALOG-->
            <p-dialog #pdBack [maximizable]="false" [draggable]="false"
                      [baseZIndex]="10000" [style]="{width: '60%' }"
                      [(visible)]="usersAppDtDlg"
                      header="Détails de l'utilisateur " [modal]="true"
                      styleClass="p-fluid" id="userAppDtDlg">
              <p-message
                severity="{{selectedUser.usrActiveFlag === 1 ? 'success' :'error'}}"
                text="{{selectedUser.usrActiveFlag === 1 ? 'Actif ' : 'Inactif'}} "></p-message>
              <div class="row mt-2">
                <div class="col-12 " *ngIf="!loading">
                  <div class="card" style="box-shadow: none!important;">
                    <div class="card-header"
                         style="padding:0 !important ; height: 70px!important;background-color: #ffffff !important">
                      <div class="text-center">
                        <img src="{{UtilConstants.EMPTY_PROFILE_BASE64}}" class="profile-picture">
                      </div>
                    </div>
                    <div class="card-body" style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);!important;">
                      <div class="row mt-5">
                        <div class="d-flex flex-column col-12 mt-4">
                          <div class="text-center">
                            <h2 class="card-subtitle">{{selectedUser.usrFirstName}} {{selectedUser.usrLastName}}</h2>
                            <span></span>
                            <!--                              class="card-title">{{selectedUser.rhUsersAppRolesList.length > 0 ? selectedUser.rhUsersAppRolesList[0].rhRoles.rlaLabel : null }}</span>-->
                          </div>
                          <div class="d-flex justify-content-around text-center">
                            <div class="col-lg-4 col-sm-12">
                              <i class="mdi mdi-facebook social-media-icons text-primary mr-3"></i>
                              <i class="mdi mdi-linkedin social-media-icons text-primary mr-3"></i>
                              <i class="mdi mdi-gmail social-media-icons text-primary"></i>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4 col-12">
                          <div class="col-12 col-lg-4 form-group">
                            <label class="control-label">{{'USER_APP.FIELDS.FIRST_NAME' | translate}}
                              -{{'USER_APP.FIELDS.LAST_NAME' | translate}}</label>
                            <input class="form-control"
                                   value="{{selectedUser.usrFirstName}} {{selectedUser.usrLastName}}" readonly>
                          </div>
                          <div class="col-12 col-lg-4 form-group">
                            <label class="control-label">{{'USER_APP.FIELDS.EMAIL' | translate}}</label>
                            <input class="form-control" value="{{selectedUser.usrEmail}}" readonly>
                          </div>
                          <div class="col-12 col-lg-4 form-group">
                            <label class="control-label"> {{'MENUITEMS.CALENDAR.LIST.EMPLOYEE' | translate}}</label>
                            <input class="form-control"
                                   value="{{selectedUser.usrAsrCode}}" readonly>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </p-dialog>

            <!--            Edit Roles Dialog-->

            <p-dialog [(visible)]="editRolesDlg" [baseZIndex]="10000" [style]="{width: '25%'}"
                      header="Affectation des Privilèges" [modal]="true"
                      styleClass="p-fluid" id="editRolestDlg">
              <app-loader *ngIf="blockedDocument"></app-loader>
              <div class="col-12">
                <div *ngFor="let role of roles" class="d-flex justify-content-between align-items-center">
                  <label id="switch2" class="p-checkbox-label font-size-15">{{ role.rlaLabel }}</label>
                  <p-inputSwitch [ngModel]="selectedRole.includes(role.rlaCode)"
                                 (onChange)="onAffectRole($event,role)"></p-inputSwitch>
                </div>

              </div>
              <p-footer>
                <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
                        (click)="hideEditRoles()"></button>
                <button pButton pRipple label="Enregistrer" (click)="updateUserRoles()" icon="pi pi-check"
                        class="p-button-text"
                        type="submit"></button>
              </p-footer>
            </p-dialog>


            <p-confirmDialog [style]="{width: '450px'}" [baseZIndex]="999999"></p-confirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
