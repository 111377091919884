import {Component, HostListener, OnInit} from '@angular/core';
import {ApplicationService} from '../../core/services/application.service';
import {FormBuilder} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DemandeCongesService} from '../../core/services/rh-ec/demandeCongesService';
import {AuthenticationService} from '../../core/services/auth.service';
import {Router} from '@angular/router';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {User} from '../../core/models/auth.models';
import {UtilConstants} from '../../core/helpers/util-constants';
import {EmployeeData} from '../../core/models/payloads/responces/EmployeeData';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
// breadcrumb items
  breadCrumbItems: Array<{}>;
  currentUser: User = new User();

  listEmployes: RhEmployes[];
  listEmployesLoaded: RhEmployes[];
  loading = true;
  readonly utilConstant = UtilConstants;
  showLoadMoreButton = false;
  showEmptyMsg = true;
  loadCardsEndIndex = 12;

  searchTerm;
  employeeProfilePicStream: any;

  // tslint:disable-next-line:max-line-length
  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, public employesService: EmployesService,
              public dilovService: DilovService, private messageService: MessageService, private confirmationService: ConfirmationService,
              public authService: AuthenticationService, private router: Router) {
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{label: 'USERS.TEXT'}, {label: 'USERS.LIST.LIST_USERS', active: true}];
    this.currentUser = this.authService.currentUser();
    this._getEmployes();
  }

  _getEmployes() {
    this.employesService.findAllEmployesByresponsables(this.currentUser.empNum).subscribe(data => {
        this.listEmployes = (data as RhEmployes[]);
        this.listEmployesLoaded = this.listEmployes;
        this.listEmployesLoaded = this.listEmployesLoaded.slice(0, this.loadCardsEndIndex);
        if (this.listEmployesLoaded.length > 0) {
          this.showLoadMoreButton = true;
          this.showEmptyMsg = false;
        }
        this.loading = false;
        // tslint:disable-next-line:no-shadowed-variable
      }, error => {
        if (error.status === '403') {
          this.router.navigate(['/']);
        }
      },
    );
  }

  loadMore(): void {
    this.loadCardsEndIndex = this.loadCardsEndIndex + 12;
    this.listEmployesLoaded = [];
    this.listEmployesLoaded = this.listEmployes.slice(0, this.loadCardsEndIndex);
    if (this.loadCardsEndIndex === this.listEmployes.length) {
      this.showLoadMoreButton = false;
    }
    console.log(this.listEmployesLoaded.length);
  }

  applyFilter() {
    this.showLoadMoreButton = true;
    if (this.searchTerm !== '') {
      this.listEmployesLoaded = this.listEmployes;
      this.listEmployesLoaded = this.listEmployesLoaded.slice(0, this.loadCardsEndIndex);
      this.listEmployesLoaded = this.listEmployesLoaded.filter(emp => emp.empNomLoc.toLowerCase().includes(this.searchTerm.toLowerCase()));
      if (this.listEmployesLoaded.length < 12) {
        this.showLoadMoreButton = false;
      }
    } else {
      this.listEmployesLoaded = this.listEmployes;
      this.listEmployesLoaded = this.listEmployesLoaded.slice(0, this.loadCardsEndIndex);
      this.listEmployesLoaded = this.listEmployesLoaded.filter(emp => emp.empNomLoc.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

}
