import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {latLng, tileLayer} from 'leaflet';

import {ChartType, Stat} from './dashboard.model';

import {revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly} from './data';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PAGES_URLS} from '../pages.urls';
import {AuthenticationService} from '../../core/services/auth.service';
import {RhEmployeEvts} from '../../core/models/rh-ec/RhEmployeEvts';
import {EventsService} from '../../core/services/rh-ec/eventsService';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {UtilConstants} from '../../core/helpers/util-constants';
import {EmployeeData} from '../../core/models/payloads/responces/EmployeeData';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {ApplicationService} from '../../core/services/application.service';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ERole} from '../../core/enums/Erole';
import {MessageService} from 'primeng/api';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(public applicationService: ApplicationService, public formBuilder: FormBuilder, private httpClient: HttpClient,
              public athentificationService: AuthenticationService, private eventsService: EventsService,
              private messageService: MessageService, private cookiesService: CookieService,
              private router: Router, public employesService: EmployesService, public dilovService: DilovService) {
    this.cookieValue = this.cookiesService.get('lang');
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  // language cookie value
  cookieValue;

  statAdminData: Stat[];
  statEmployeeData: Stat[];


  rhEmployeeRequestedEvents: RhEmployeEvts[] = [];
  rhEmployeeProgramedEvents: RhEmployeEvts[] = [];
  rhEmployeEvt: RhEmployeEvts = new RhEmployeEvts();
  loading = true;

  totalRecords: number;
  multiAxisData: any;
  multiAxisOptions: any;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  revenueChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;

  // New charts data
  employeesData: any;
  employeesAnnalytics: any[] = [];

  formData: FormGroup;

  // Employee Details
  employeeData: EmployeeData = new EmployeeData();
  employee: RhEmployes = new RhEmployes();
  employeePoste: string;
  employeeProfilePicStream: any;
  listGenders: DiLovDetails[] = [];
  listContractsTypes: DiLovDetails[] = [];

  evtDlg = false;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  public readonly UtilConstants = UtilConstants;
  public readonly ERole = ERole;

  /*

   */


  ngOnInit(): void {
    this._getListGendersType();
    this._getListContractType();
    this.breadCrumbItems = [{label: 'Alliance Rh'}, {label: 'Dashboard', active: true}];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this._fetchData();
    this.multiAxisData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'Dataset 1',
        fill: false,
        borderColor: '#42A5F5',
        yAxisID: 'y-axis-1',
        data: [65, 59, 80, 81, 56, 55, 10]
      }, {
        label: 'Dataset 2',
        fill: false,
        borderColor: '#00bb7e',
        yAxisID: 'y-axis-2',
        data: [28, 48, 40, 19, 86, 27, 90]
      }]
    };

    this.multiAxisOptions = {
      responsive: true,
      hoverMode: 'index',
      stacked: false,
      scales: {
        yAxes: [{
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        }, {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnChartArea: false
          }
        }]
      }
    };
  }


  private _fetchData() {
    this.loading = true;
    this.athentificationService.currentUserRoles().includes('ROLE_RH') ? this.getDashbordAdmin() : this.getDashbordEmployee();
  }

  getDashbordAdmin() {
    this.loading = true;
    setTimeout(() => {
      this.revenueChart = revenueChart;
      this.salesAnalytics = salesAnalytics;
      this.sparklineEarning = sparklineEarning;
      this.sparklineMonthly = sparklineMonthly;
      this.httpClient.get(PAGES_URLS.DASHBOARD_ADMIN).subscribe(data => {
          // @ts-ignore
          this.statAdminData = [
            {
              icon: 'ri-calendar-check-fill',
              title: 'RH_CARD.LEAVE_ABSENCE',
              // @ts-ignore
              value: data.data[0].countDemandesAbs,
              link: '#/demande-abscence'
            }, {
              icon: 'ri-calendar-event-line',
              title: 'RH_CARD.LEAVE_REQUEST',
              // @ts-ignore
              value: data.data[0].countDemandesCgs,
              link: '#/demande-conges'
            }, {
              icon: 'ri-briefcase-4-line',
              title: 'RH_CARD.EMPLOYEES',
              // @ts-ignore
              value: data.data[0].countEmployees,
              link: '#/'
            }
          ];

          // @ts-ignore
          this.employeesAnnalytics = data.data[0].employeesAnnalytics;
          this.employeesData = {

            datasets: [
              {
                data: [this.employeesAnnalytics[0][0], this.employeesAnnalytics[1][0]],
                backgroundColor: [
                  '#950047',
                  '#1cbb8c'
                ],
                hoverBackgroundColor: [
                  '#950047',
                  '#1cbb8c'
                ]
              }]
          };
          this.loading = false;
        },
        error => {
          if (error.value === '403') {

          }
        });
    }, 2000);
  }

  getDashbordEmployee() {
    this.loading = true;
    setTimeout(() => {
      this.httpClient.get(PAGES_URLS.DASHBOARD_EMPLOYEE).subscribe(data => {
          /*
          Employee cards data
           */
          this.statEmployeeData = [
            {
              icon: 'ri-bank-card-2-line',
              // @ts-ignore
              title: 'EMPLOYEE_CARD.EMPLOYEE_NUMBER',
              // @ts-ignore
              value: data.data[0].congeInfos.numEmp,
              link: '#/'
            },
            {
              icon: 'ri-calendar-check-fill',
              title: 'EMPLOYEE_CARD.NUMBER_OF_DAYS',
              // @ts-ignore
              value: data.data[0].congeInfos.nbrJours,
              link: '#/'
            }, {
              icon: 'ri-calendar-event-line',
              title: 'EMPLOYEE_CARD.NUMBER_OF_LEFTOVERS',
              // @ts-ignore
              value: data.data[0].congeInfos.nbrReliquat,
              link: '#/'
            }
          ];
        },
        error => {
          if (error === '403') {

          }
        });


      if (!this.athentificationService.currentUserRoles().includes('ROLE_RH')) {
        this.eventsService.findAllRhEmployeeEvents().subscribe(data => {
            this.rhEmployeeRequestedEvents = data;
            this.rhEmployeeProgramedEvents = data;
            // REQUEST EVENTS
            this.rhEmployeeRequestedEvents = this.rhEmployeeRequestedEvents.filter(evt => evt.stEvents.evtCategorie === '1'
              && evt.rhEmployeEvtsPK.epeEmpNum === this.athentificationService.currentUser().empNum && evt.epeStatus === 0);
            // VLIDATE EVENTS
            this.rhEmployeeProgramedEvents = this.rhEmployeeProgramedEvents.filter(evt => evt.stEvents.evtCategorie === '1'
              && evt.rhEmployeEvtsPK.epeEmpNum === this.athentificationService.currentUser().empNum && evt.epeStatus === 1);
            this.evtDlg = this.rhEmployeeRequestedEvents.length !== 0;
          }, error => {
            if (error.status === '403') {
              this.router.navigate(['/account/login']);
            }
          },
        );
      }


      this.employesService.findEmploye(this.athentificationService.currentUser().empNum, 1).subscribe(data => {
          this.employeeData = data;
          this.employee = this.employeeData.rhEmployes;
          this.employeeData.employeePost !== null ? this.employeePoste = this.employeeData.employeePost : this.employeePoste = 'Employée';
          // tslint:disable-next-line:max-line-length
          this.employeeData.profilePicture !== null ? this.employeeProfilePicStream = 'data:image/png;base64,' + this.employeeData.profilePicture : this.employeeProfilePicStream = UtilConstants.EMPTY_PROFILE_BASE64;
          this.loading = false;
        }, error => {
          if (error.status === '403') {
            this.router.navigate(['/account/login']);
          }
        },
      );
    }, 2000);
  }


  validateDemanEvent(rowdata: RhEmployeEvts, rowIndex: number, status: number) {
    this.rhEmployeEvt = rowdata;
    this.rhEmployeEvt.epeStatus = status;
    this.editEntity(rowIndex, status);
  }


  editEntity(rowIndex: number, status: number) {
    this.eventsService.validateEvent(this.rhEmployeEvt).subscribe(data => {
      this.rhEmployeEvt = (data.data[0] as RhEmployeEvts);
      this.rhEmployeeRequestedEvents.splice(rowIndex, 1);
      this.rhEmployeeRequestedEvents = [...this.rhEmployeeRequestedEvents];
      if (this.rhEmployeEvt.epeStatus === 1) {
        this.rhEmployeeProgramedEvents.push(this.rhEmployeEvt);
        this.rhEmployeeProgramedEvents = [...this.rhEmployeeProgramedEvents];
      }
      this.messageService.add({
        severity: status === 1 ? 'success' : 'error',
        summary: status === 1 ? 'Validée' : 'Rejetée',
        detail: status === 1 ? 'La demande est validée avec sucée.' : 'La demande est rejetée avec sucée.',
        life: 3000
      });
    });
  }

  _getListGendersType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '1');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listGenders = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

  _getListContractType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '6');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listContractsTypes = [];
        this.listContractsTypes = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

}
