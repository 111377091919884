import {CookieService} from 'ngx-cookie-service';
import {Inject, Injectable} from '@angular/core';

export class TextDirectionController {
  public textDirection: string;

  constructor() {
  }

  public checkDirection(lang: string): void {
    if (lang === 'ar') {
      this.textDirection = 'rtl';
      document.body.style.textAlign = 'right';
    } else {
      this.textDirection = 'ltr';
      document.body.style.textAlign = 'left';
    }
  }


}
