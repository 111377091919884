<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <app-pagetitle title="Demande Congés" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <p-toast [baseZIndex]="10000"></p-toast>
      <div class="card">
        <div class="card-body">

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-12">
              <div class="card">
                <p-toolbar styleClass="p-mb-4">
                  <ng-template pTemplate="right">
                    <button pButton pRipple label="{{'LEAVE_REQUEST.LIST.NEW_REQUEST' | translate}}" icon="pi pi-plus"
                            class="succes-color p-button-success p-button-add p-mr-2"
                            (click)="showAddNewDemande()" [disabled]="disableAddNew"></button>
                  </ng-template>
                  <ng-template pTemplate="left">
                    <div class="d-flex ">
                      <button type="button" pButton pRipple icon="pi pi-file-excel"
                              (click)="applicationService.exportExcel(demandeConges)"
                              class="p-button-success p-button-outlined mr-2" pTooltip="XLS"
                              tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="pi pi-file-pdf"
                              (click)="applicationService.exportPdf(exportColumns,demandeConges,document)"
                              class="p-button-danger p-button-outlined mr-2" pTooltip="PDF"
                              tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                </p-toolbar>
                <div class="card">
                  <app-loader *ngIf="blockedDocument"></app-loader>
                  <p-table [columns]="cols" [value]="demandeConges" [responsive]="true" #cgT
                           selectionMode="single" [(selection)]="selectedDemandeConges" dataKey="rhDemandeCongesPK"
                           [loading]="loading" loadingIcon="pi pi-spin pi-spinner"
                           [paginator]="true" [rows]="4"
                           [totalRecords]="totalRecords"
                           [showCurrentPageReport]="true"
                           [tableStyle]="{ 'min-width': '100%' }"
                           currentPageReportTemplate="Affichage du  {first} au  {last} des  {totalRecords} entrées"
                           [rowsPerPageOptions]="[5,10, 15, 50]">
                    <ng-template pTemplate="caption">
                      <div class="row">
                        <div class="p-toolbar-group-left col-lg-5 col-sm-12">
                          <div class="app-search  d-lg-block" style="padding: 0 !important">
                            <div class="position-relative">
                              <input type="text" class="form-control" pInputText size="35"
                                     placeholder="{{'LEAVE_REQUEST.LIST.SEARCH' | translate}}"
                                     (input)="cgT.filterGlobal($event.target.value, 'contains')"
                                     style="width:auto;text-align: left !important">
                              <span class="ri-search-line"></span>
                            </div>

                          </div>
                        </div>
                        <div class="p-toolbar-group-right col-lg-7 col-sm-12">
                          <div class="row form-row col-12">
                            <div class="form-group col-lg-6 col-sm-12 ">
                              <label class="control-label">{{'LEAVE_REQUEST.LIST.EXERCICE' |translate}}</label>
                              <div class="field">
                                <p-dropdown [options]="exercicesList"
                                            styleClass="ui-fluid col-lg-12"
                                            placeholder="{{'ABS_REQUEST.LIST.CHOISE_EXERCICE' |translate}}"
                                            optionLabel="label"
                                            [filter]="true" filterBy="label"
                                            (onChange)="onSelectExercice($event)">
                                </p-dropdown>
                              </div>
                            </div>

                            <div class="form-group col-lg-6 col-sm-12 ">
                              <label class="control-label">{{'LEAVE_REQUEST.LIST.STATUS' |translate}}</label>
                              <div class="field">
                                <p-dropdown [options]="statusList"
                                            styleClass="ui-fluid col-lg-12"
                                            placeholder="{{'ABS_REQUEST.LIST.CHOISE_STATUS' |translate}}"
                                            [filter]="true" filterBy="label"
                                            (onChange)="onSelectStatus($event)">
                                  <ng-template let-item pTemplate="selectedItem">
                                    <div class="badge font-size-12 align-content-center"
                                         ngClass="{{item.value === 0 ? 'badge-soft-info' : item.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{item.label}}</div>
                                  </ng-template>
                                  <ng-template let-st pTemplate="item">
                                    <div class="badge font-size-12 align-content-center"
                                         ngClass="{{st.value === 0 ? 'badge-soft-info' : st.value === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{st.label}}</div>
                                  </ng-template>
                                </p-dropdown>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                    <ng-template pTemplate="header">

                      <tr>
                        <th *ngFor="let col of cols">
                          {{ col.header | translate }}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                      <tr>
                        <td *ngFor="let col of columns">
                          <img src="/assets/images/users/avatar.png" width="32" style="vertical-align: middle"
                               *ngIf="col.field ==='img'"/>
                          <span
                            *ngIf="col.field==='dcgType'">{{applicationService.filterListDlvById(listCongesTypes, rowData[col.field]) }}</span>
                          <span ngClass="{{col.field ==='dcgEmpNum' ? 'font-weight-bold' :''}}"
                                *ngIf="col.field!='dcgDemandeDate' && col.field!='rhEmployes' && col.field!='dcgDecision' && col.field!='actions' && col.field!='dcgType'">  {{rowData[col.field]}} </span>
                          <span *ngIf="col.field ==='rhEmployes'">  {{rowData[col.field].empNomLoc }} </span>
                          <span *ngIf="col.field ==='dcgDemandeDate'">  {{rowData[col.field]| date:'longDate'}} </span>
                          <div class="badge font-size-12 " *ngIf="col.field ==='dcgDecision'"
                               ngClass="{{rowData[col.field] === 'En cours' ? 'badge-soft-info' : rowData[col.field] === 'Validé' ? 'badge-soft-success' :'badge-soft-danger'}}">{{rowData[col.field]}}</div>

                          <div *ngIf="col.field ==='actions'" class="p-toolbar-group-left ">
                            <button pButton pRipple type="button" icon="pi pi-search"
                                    class="p-button-rounded p-button-text p-button-info  mr-2"
                                    pTooltip="Afficher details"
                                    (click)="showDetails(rowData)"></button>

                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                    class="p-button-rounded p-button-text p-button-warning  mr-2"
                                    pTooltip="modifier"
                                    (click)="showEditDemande(rowData , rowIndex)"
                                    [disabled]="rowData.dcgStatus==1 || rowData.dcgStatus==2"></button>

                            <button pButton pRipple type="button" icon="pi pi-check mr-2"
                                    [disabled]="rowData.dcgStatus==1 || rowData.dcgStatus==2"
                                    *ngIf="authService.currentUser().roles.includes('ROLE_RH')"
                                    class="p-button-rounded p-button-text p-button-success"
                                    pTooltip="Valider"
                                    (click)="showValidateDemande(rowData , rowIndex)"></button>

                            <button pButton pRipple type="button" icon="pi pi-folder"
                                    *ngIf="currentUser.roles.includes('ROLE_RH')"
                                    class="p-button-rounded p-button-text p-button-success"
                                    pTooltip="Status"
                                    (click)="showStatusDemande(rowData)"></button>

                            <button pButton pRipple type="button" icon="pi pi-file-export"
                                    *ngIf="currentUser.roles.includes('ROLE_USER')"
                                    class="p-button-rounded p-button-text p-button-success"
                                    pTooltip="téléchargement de document"
                                    [disabled]="rowData.dcgStatus==0"
                                    (click)="donloadTitreConge(rowData)"></button>

                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td [attr.colspan]="columns.length">
                          No records found
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="p-d-flex p-ai-center p-jc-between">
                        Au total il y a {{demandeConges ? demandeConges.length : 0 }} demandes.
                      </div>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

            <!--            ADD+UPDATE DIALOG-->
            <form [formGroup]="demandeCgForm" (ngSubmit)="saveDemandeConges()">
              <p-dialog #pdBack [maximizable]="false"
                        [draggable]="false"
                        [baseZIndex]="10000"
                        [(visible)]="demandeCongesDlg"
                        [style]="{width:'90%'}"
                        header="{{'LEAVE_REQUEST.LIST.NEW_REQUEST' | translate}}" [modal]="true"
                        styleClass="p-fluid" id="demandeAbsDlg">
                <app-loader *ngIf="blockedDocument"></app-loader>
                <ng-template pTemplate="content">
                  <div class="row col-12 col-sm-12 form-row">
                    <div class="p-field col-12 col-lg-4">
                      <label>{{'LEAVE_REQUEST.FIELDS.EMPLOYEE' | translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field" *ngIf="currentUser.roles.includes('ROLE_RH') && !editMode">
                        <p-dropdown [options]="listEmployes"
                                    [ngClass]="{ 'is-invalid': submitted && dcG.employeeNum.errors && dcG.employeeType.errors }"
                                    optionLabel="empNomLoc"
                                    placeholder="{{'LEAVE_REQUEST.LIST.CHOISE_EMPLOYEE' |translate}}"
                                    styleClass="ui-fluid"
                                    [filter]="true" filterBy="empNomLoc"
                                    (onChange)="onSelectRhEmploye($event)" [disabled]="editMode"></p-dropdown>
                        <div *ngIf="submitted && dcG.employeeNum.errors && dcG.employeeType.errors"
                             class="invalid-feedback">
                          <div *ngIf="dcG.employeeNum.errors.required && dcG.employeeType.errors.required">
                            {{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}
                          </div>
                        </div>
                      </div>
                      <div class="field" *ngIf="!currentUser.roles.includes('ROLE_RH') || editMode">
                        <input id="employeeUs" type="text" pInputText [value]="selectedEmployee.empNomLoc" readonly
                               name="employeeUs" class="form-control ui-fluid"/>
                      </div>
                    </div>

                    <div class="p-field col-12 col-lg-4">
                      <label for="dcgType">{{'LEAVE_REQUEST.FIELDS.CONGEE_REQUEST_DATE' | translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field">
                        <p-dropdown id="dcgType" [options]="listCongesTypes"
                                    (onChange)="onSelectCongesType($event)"
                                    optionLabel="lvdDescLoc"
                                    class="ui-fluid"
                                    placeholder="{{'LEAVE_REQUEST.LIST.CHOISE_CONGEE_TYPE' |translate}}"
                                    [ngClass]="{ 'is-invalid': submitted && dcG.congeType.errors }"></p-dropdown>
                        <div *ngIf="submitted && dcG.congeType.errors" class="invalid-feedback">
                          <div
                            *ngIf="dcG.congeType.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>
                    </div>

                    <div class="p-field col-lg-1 col-sm-12">
                      <label for="nbrDays">{{'LEAVE_REQUEST.FIELDS.DAYS' | translate}}</label>
                      <div class="field">
                        <input id="nbrDays" type="text" pInputText readonly
                               [value]="(dcG.dateFin.value!==null)?(applicationService.getNumberOfDays(dcG.dateDebut.value,dcG.dateFin.value)):0"
                               name="nbrDays" class="form-control font-weight-bolder"/>
                      </div>
                    </div>
                    <div class="p-field col-lg-1 col-sm-12">
                      <label for="nbrDaysRest">{{'LEAVE_REQUEST.FIELDS.REST' | translate}} </label>
                      <div class="field">
                        <input id="nbrDaysRest" type="text" pInputText readonly
                               [value]="(dcG.dateFin.value!==null && dcG.dateDebut.value!==null)?(numberOfDays-(applicationService.getNumberOfDays(dcG.dateDebut.value,dcG.dateFin.value))):0"
                               name="nbrDays" class="form-control font-weight-bolder"/>
                      </div>
                    </div>
                    <div class="p-field col-lg-2 col-sm-12" *ngIf="dcG.employeeNum.value!==null">
                      <label for="numberOfDays">Nombre de Relicats :</label>
                      <div class="field">
                        <div id="numberOfDays" class="badge  font-size-20 badge-soft-warning">{{numberOfDays}}  jours
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row col-12 col-sm-12 form-row mt-3">
                    <div class="p-field col-lg-12 col-sm-12">
                      <label> {{'ABS_REQUEST.FIELDS.FILES' |translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field">
                        <app-custom-file-upload
                          [uploadedFiles]="uploadedFiles"
                          (fileUploaded)="onFileUploaded($event)"
                          (validateUpload)="onValidateUpload(uploadedFiles)"
                        ></app-custom-file-upload>
                      </div>
                    </div>
                  </div>


                  <div class="row col-12 col-sm-12 form-row mt-3">
                    <div class="p-field col-lg-4 col-sm-12">
                      <label for="dcgDemandeDate">{{'LEAVE_REQUEST.FIELDS.CONGEE_REQUEST_DATE' | translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field">
                        <p-calendar [baseZIndex]="10001" appendTo="body" id="dcgDemandeDate" [showIcon]="true"
                                    (onSelect)="onDemandeDateChange($event)" dateFormat="dd/mm/yy"
                                    [showTime]="true" [showSeconds]="true" formControlName="demandeDate"
                                    [ngClass]="{ 'is-invalid': submitted && dcG.demandeDate.errors }"
                                    [disabled]="(dcG.employeeType.value ==null && dcG.employeeNum.value ==null) || numberOfDays<3"
                                    name="dcgDemandeDate" [minDate]='curentTime'
                                    [maxDate]="applicationService.addDaysToDate(curentTime,5)"
                                    inputId="icon"></p-calendar>
                        <div *ngIf="submitted && dcG.demandeDate.errors" class="invalid-feedback">
                          <div
                            *ngIf="dcG.demandeDate.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>
                    </div>


                    <div class="p-field col-lg-4 col-sm-12">
                      <label for="dcgDebutDate">{{'LEAVE_REQUEST.FIELDS.START_DATE' | translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field">
                        <p-calendar [baseZIndex]="10001" appendTo="body" id="dcgDebutDate"
                                    [disabled]="dcG.demandeDate.value ==null" dateFormat="dd/mm/yy"
                                    [showTime]="true" [showSeconds]="true" [showIcon]="true"
                                    formControlName="dateDebut" inputId="icon"
                                    name="dcgDebutDate" [minDate]="dcG.demandeDate.value"
                                    [ngClass]="{ 'is-invalid': submitted && dcG.dateDebut.errors }"></p-calendar>
                        <div *ngIf="submitted && dcG.dateDebut.errors" class="invalid-feedback">
                          <div
                            *ngIf="dcG.dateDebut.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>
                    </div>

                    <div class="p-field col-lg-4 col-sm-12">
                      <label for="dcgFinDate">{{'LEAVE_REQUEST.FIELDS.END_DATE' | translate}}</label>
                      <span class="text-danger ml-2">*</span>
                      <div class="field">
                        <p-calendar [baseZIndex]="10001" appendTo="body" id="dcgFinDate"
                                    [disabled]="dcG.dateDebut.value ==null" dateFormat="dd/mm/yy"
                                    [showTime]="true" [showSeconds]="true" [showIcon]="true"
                                    formControlName="dateFin" inputId="icon"
                                    name="dcgDebutDate"
                                    [minDate]="applicationService.addDaysToDate(dcG.dateDebut.value,2)"
                                    [maxDate]="applicationService.addDaysToDate(dcG.dateDebut.value,numberOfDays)"
                                    [ngClass]="{ 'is-invalid': submitted && dcG.dateFin.errors }"></p-calendar>
                        <div *ngIf="submitted && dcG.dateFin.errors" class="invalid-feedback">
                          <div *ngIf="dcG.dateFin.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 row mt-3 ">
                    <div class="p-field col-lg-4 col-sm-12">
                      <label for="dcgContactTelephone">{{'LEAVE_REQUEST.FIELDS.CONTACT_PHONE' | translate}} </label>
                      <div class="field">
                        <p-inputMask mask="9 999-99-99-99" id="dcgContactTelephone"
                                     name="dcgContactTelephone" formControlName="phoneContact"
                                     placeholder="0999-999-999"></p-inputMask>
                      </div>
                    </div>

                    <div class="p-field col-lg-8 col-sm-12">
                      <label for="dcgAddressLoc">{{'LEAVE_REQUEST.FIELDS.CONTACT_ADDRESS' | translate}} </label>
                      <div class="field">
                        <input id="dcgAddressLoc" type="text" pInputText formControlName="addressContact"
                               name="dcgAddressLoc" class="form-control"/>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' | translate}}" icon="pi pi-times"
                          class="p-button-text"
                          (click)="hideDialog()"></button>
                  <button pButton pRipple label="{{'EVENT.FIELD.SAVE' | translate}}" icon="pi pi-check"
                          class="p-button-text"
                          type="submit" [disabled]="numberOfDays<3"></button>
                </ng-template>

              </p-dialog>
            </form>


            <!--            VALIDATION DIALOG-->
            <form [formGroup]="validateDemandeCgFrom"
                  (ngSubmit)="validateDemandeConges(validateDemandeCgFrom,validationStatus)">
              <p-dialog #pdBack [maximizable]="true"
                        [baseZIndex]="10000"
                        [(visible)]="validationDlg"
                        header=" validation demande congé." [modal]="true"
                        styleClass="p-fluid" id="validerdemandeAbsDlg">
                <app-loader *ngIf="blockedDocument"></app-loader>
                <ng-template pTemplate="content">
                  <p-fieldset
                    legend="{{selectedDemandeConges.rhEmployes.empNomLoc}} - {{applicationService.filterListDlvById(listCongesTypes,selectedDemandeConges.dcgType)}}"
                    class="pb-2">
                    <div class="row col-12">
                      <div class="p-field col-lg-12 col-sm-12">
                        <label> Décision : </label>
                        <div class="field">
                          <p-toggleButton formControlName="status" onLabel="Confirmer" offLabel="Rejeter"
                                          onIcon="pi pi-check" [(ngModel)]="validationState"
                                          offIcon="pi pi-times" [style]="{'width': '10em'}"></p-toggleButton>
                        </div>
                      </div>

                      <div class="p-field col-lg-12 col-sm-12 mt-3">
                        <label for="dcgVRemarksLoc">Remarques : </label>
                        <div class="field">
                         <textarea id="dcgVRemarksLoc" formControlName="remarques" pInputTextarea
                                   name="dcgVRemarksLoc" class="form-control"
                                   [ngClass]="{ 'is-invalid': submitted && dcG.remarques.errors }"> </textarea>
                        </div>
                        <div *ngIf="submitted && dcG.remarques.errors" class="invalid-feedback">
                          <div
                            *ngIf="dcG.remarques.errors.required">{{'USER_APP.FIELDS.REQUIRED_FIELD' |translate}}</div>
                        </div>
                      </div>
                    </div>
                  </p-fieldset>
                </ng-template>

                <ng-template pTemplate="footer">
                  <button pButton pRipple label="{{'EVENT.FIELD.CANCEL' | translate}}" icon="pi pi-times"
                          class="p-button-text"
                          (click)="hideValidateDemande()"></button>
                  <button pButton pRipple label="{{'EVENT.FIELD.SAVE' | translate}}" icon="pi pi-check" type="submit"
                          class="p-button-text"></button>
                </ng-template>

              </p-dialog>
            </form>

            <!--            DETAILLES DIALOG-->
            <p-dialog #pdBack [maximizable]="true"
                      [baseZIndex]="10000"
                      [(visible)]="demandeCongesDtDlg"
                      [draggable]="false"
                      header="{{'LEAVE_REQUEST.LIST.REQUEST_DETAILS' | translate}}" [modal]="true"
                      styleClass="p-fluid" id="demandeAbsDtDlg">
              <p-message *ngIf="authService.currentUser().roles.includes('ROLE_USER')"
                         severity="{{selectedDemandeConges.dcgDecision === 'En cours' ? 'info' : selectedDemandeConges.dcgDecision === 'Validé' ? 'success' :'error'}}"
                         text="{{selectedDemandeConges.dcgDecision === 'En cours' ? 'Votre demande de congé est ' +selectedDemandeConges.dcgDecision : 'Votre demande de congé a été ' +selectedDemandeConges.dcgDecision }}"></p-message>
              <p-card
                header="{{selectedEmployee.rhEmployesPK.empNum}} : {{selectedEmployee.empNomLoc}}"
                *ngIf="demandeCongesDtDlg"
                subheader="{{applicationService.filterListDlvById(listCongesTypes,selectedDemandeConges.dcgType)}}"
                styleClass="p-card-shadow">
                <div class="col-12 row mt-3 ">
                  <div class="p-field col-lg-4 col-sm-12">
                    <label for="dcgContactTelephone">{{'LEAVE_REQUEST.FIELDS.ABSENCE_DATE' | translate}} </label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeConges.dcgDebutDate | date:'fullDate'}}"
                             class="form-control"/>
                    </div>
                  </div>
                  <div class="p-field col-lg-4 col-sm-12">
                    <label for="dcgContactTelephone">{{'LEAVE_REQUEST.FIELDS.RETURN_DATE' | translate}}</label>
                    <div class="field">
                      <input type="text" readonly value="{{selectedDemandeConges.dcgFinDate | date:'fullDate'}}"
                             class="form-control"/>
                    </div>
                  </div>
                  <div class="p-field col-lg-4 col-sm-12">
                    <label for="dcgJours">{{'LEAVE_REQUEST.FIELDS.DAYS' | translate}} </label>
                    <div class="field">
                      <input name="dcgJours" id="dcgJours" type="text" readonly
                             value="{{selectedDemandeConges.dcgJours}} jours"
                             class="form-control font-weight-bolder "/>
                    </div>
                  </div>
                </div>

                <div class="row col-12 mt-3">
                  <div class="p-field col-lg-12 col-sm-12">
                    <label for="dcgRemarksLoc">{{'LEAVE_REQUEST.FIELDS.DECISION_REMARKS' | translate}} </label>
                    <div class="field">
                    <textarea pInputTextarea readonly value="{{selectedDemandeConges.dcgRemarksLoc}}"
                              id="dcgRemarksLoc" name="dcgRemarksLoc" class="form-control"> </textarea>
                    </div>
                  </div>
                </div>
              </p-card>

            </p-dialog>

            <!-- STATUS DIALOG-->
            <p-dialog #pdBack [maximizable]="false"
                      [baseZIndex]="10000"
                      [(visible)]="statusDlg"
                      header="{{'LEAVE_REQUEST.LIST.REQUEST_STATUS' |translate}}"
                      [modal]="true" [draggable]="false"
                      styleClass="p-fluid" id="statusDlg">
              <p-card
                header="{{selectedEmployee.rhEmployesPK.empNum}} : {{selectedEmployee.empNomLoc}}"
                *ngIf="statusDlg"
                subheader="{{applicationService.filterListDlvById(listCongesTypes,selectedDemandeConges.dcgType)}}"
                styleClass="p-card-shadow">
                <p-table [value]="selectedDemandeConges.rhDemandeCongStatusList" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>{{'ABS_REQUEST.HEADERS.CREATE_BY' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.CREATE_DATE' |translate}}</th>
                      <th>{{'ABS_REQUEST.HEADERS.STATUS' |translate}}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-status>
                    <tr>
                      <td>{{status.dcsCreatedPar}}</td>
                      <td>{{status.dcsCreationDate | date:'longDate'}}</td>
                      <td>
                        <div class="badge font-size-12"
                             ngClass="{{status.dcsStatus === 0 ? 'badge-soft-info' : status.dcsStatus === 1  ? 'badge-soft-success' :'badge-soft-danger'}}">{{status.dcsInformation}}</div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-card>
            </p-dialog>

            <p-confirmDialog [style]="{width: '450px'}" [baseZIndex]="90000"></p-confirmDialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
