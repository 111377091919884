import {BACKEND_API} from '../core/helpers/api.url';


export const PAGES_URLS = {

  // AUTH
  LOGIN: BACKEND_API + 'api/auth/signin',
  LOGOUT: BACKEND_API + 'api/auth/signout',
  CHANGEPASSWORD: BACKEND_API + 'api/user/changePassword',

  // USERS
  USERS: BACKEND_API + 'api/user',
  UPDATE_USER_ROLES: BACKEND_API + 'api/user/update-user-roles',

  // ROLES
  ROLES: BACKEND_API + 'api/roles',
  FIND_MAX_USERS_ROLES: BACKEND_API + 'api/roles/find-max-id',

  // DASHBOARD
  DASHBOARD_ADMIN: BACKEND_API + 'api/dashboard/admin',
  DASHBOARD_EMPLOYEE: BACKEND_API + 'api/dashboard/employee',

  // DEMANDE ABSC
  FIND_ALL_DEMANDES_ABS: BACKEND_API + 'api/demandeAbs',
  FIND_ALL_DEMANDES_ABS_BY_RESP: BACKEND_API + 'api/demandeAbs/demandesAbsByResp',
  SAVE_DEMANDE_ABS: BACKEND_API + 'api/demandeAbs',
  DELETE_DEMANDES_ABS: BACKEND_API + 'api/demandeAbs/',
  PUT_DEMANDES_ABS: BACKEND_API + 'api/demandeAbs/',
  VALIDATE_DEMANDES_ABS: BACKEND_API + 'api/demandeAbs/validateDemandeAbs',

  //  EMPLOYES
  FIND_ALL_EMPLOYES_ABS: BACKEND_API + 'api/employes',
  FIND_ALL_EMPLOYES_BY_RESP: BACKEND_API + 'api/employes/findAllEmployeesData',
  FIND_EMPLOYES: BACKEND_API + 'api/employes/getEmployee',
  UPDATE_EMPLOYEE: BACKEND_API + 'api/employes',
  MAX_EMPLOYE_NUM: BACKEND_API + 'api/employes/find-max-empNum',

  // DEMANDE CONGE
  FIND_ALL_DEMANDES_CG: BACKEND_API + 'api/demandeConges',
  FIND_ALL_DEMANDES_CG_BY_RESP: BACKEND_API + 'api/demandeConges/demandesCgByResp',
  SAVE_DEMANDE_CG: BACKEND_API + 'api/demandeConges',
  DELETE_DEMANDES_CG: BACKEND_API + 'api/demandeConges/',
  PUT_DEMANDES_CG: BACKEND_API + 'api/demandeConges/',
  VALIDATE_DEMANDES_CG: BACKEND_API + 'api/demandeConges/validateDemandeConge',
  FIND_NUMBER_JOURS: BACKEND_API + 'api/demandeConges/getNumbrJours',

  //  EVENTS
  EVENTS: BACKEND_API + 'api/events/',
  MAX_CODE_EVENT: BACKEND_API + 'api/events/max-event-code',

  //  EMPLOYEE EVENTS
  EMPLOYEE_EVENTS: BACKEND_API + 'api/rhEmployeeEvents/',
  DELETE_EMPLOYEE_EVENTS: 'api/rhEmployeeEvents/deleteEvent',
  SAVE_EVENT_BY_RH: BACKEND_API + 'api/rhEmployeeEvents/saveEventByRh',
  SAVE_EMPLOYEE_EVENTS_FILES: BACKEND_API + 'api/rhEmployeeEvents/saveRhEmployeeEvtsFiles',
  FIND_MAX_ID_EMPLOYEE_EVENTS: BACKEND_API + 'api/rhEmployeeEvents/findMaxId',

  // DILOV
  FIND_LVD: BACKEND_API + 'api/lvd',

  // REPORTING
  REPORTING: BACKEND_API + 'api/report',
  PRINT_DEMANDE_CONGE_REPORTING: BACKEND_API + 'api/report/demande-conge',
  PRINT_DEMANDE_ABS_REPORTING: BACKEND_API + 'api/report/demande-abs',


};
