import {Component, OnInit, Inject, Output, EventEmitter, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../../core/services/auth.service';
import {AuthfakeauthenticationService} from '../../../core/services/authfake.service';
import {LanguageService} from '../../../core/services/language.service';
import {environment} from '../../../../environments/environment';
import {User} from '../../../core/models/auth.models';
import {ApplicationService} from '../../../core/services/application.service';
import {UtilConstants} from '../../../core/helpers/util-constants';
import {EmployesService} from '../../../core/services/rh-ec/employesService';
import {File} from '../../../core/models/transient/File';
import {TextDirectionController} from '../../../core/text-direction-controller';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  countryFlag;
  valueset: string;

  currentUser: User;
  profilePic: any;
  readonly utilConstant = UtilConstants;

  public directionController = new TextDirectionController();

  listLang = [
    {text: 'English', lang: 'en', flag: 'assets/images/flags/en.png'},
    {text: 'Français', lang: 'fr', flag: 'assets/images/flags/fr.png'},
    {text: 'العربية', lang: 'ar', flag: 'assets/images/flags/ar.png'},
  ];

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
              public languageService: LanguageService, public cookiesService: CookieService,
              public applicationService: ApplicationService, private employesService: EmployesService, public render: Renderer2) {
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    if (this.cookiesService.get('lang') === '') {
      this.cookiesService.set('lang', 'fr');
    }
    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    this.countryFlag = val.map(element => element.flag);
    this.setLanguage(this.countryName, this.countryFlag, this.cookieValue);
    // CURRENT USER
    this.currentUser = this.authService.currentUser();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, flag: string, lang: string) {
    this.countryName = text;
    this.countryFlag = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this.directionController.checkDirection(this.cookiesService.get('lang'));
    this.render.setAttribute(
      document.body, 'dir', this.directionController.textDirection,
    );
  }


  /**
   * Logout the user
   */
  logout() {
    this.authService.logout().subscribe(data => {
        sessionStorage.removeItem('authUser');
        this.router.navigate(['/account/login']);
      },
      error => {
        console.log(error);
      });
  }

  // loadStylesheet(url: string): void {
  //   const link = this.render.createElement('link');
  //   link.rel = 'stylesheet';
  //   link.href = url;
  //   this.render.appendChild(this.document.head, link);
  // }
}
