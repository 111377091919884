import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'ri-dashboard-line',
    badge: {
      variant: 'success',
      text: 'MENUITEMS.DASHBOARDS.BADGE',
    },
    link: '/',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 10,
    label: 'HEADER.LOGIN.PROFILE',
    icon: 'ri-user-line',
    link: '/profile',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 3,
    label: 'MENUITEMS.CALENDAR.TEXT',
    icon: 'ri-calendar-2-line',
    link: '/calendar',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 11,
    label: 'MENUITEMS.EVENTS.TEXT',
    icon: 'ri-store-2-line',
    link: '/employees-events',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_DEVLOPPER']
  },
  {
    id: 4,
    label: 'MENUITEMS.DEMANCEABS.TEXT',
    icon: 'ri-user-unfollow-line',
    link: '/demande-abscence',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 5,
    label: 'MENUITEMS.DEMANCECG.TEXT',
    icon: 'ri-flight-takeoff-line',
    link: '/demande-conges',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 6,
    label: 'MENUITEMS.USERS.TEXT',
    icon: 'ri-group-line',
    link: '/employees',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_DEVLOPPER']
  },
  {
    id: 7,
    label: 'MENUITEMS.SETTINGS.TEXT',
    isTitle: true,
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },
  {
    id: 8,
    label: 'MENUITEMS.USERS_APP.TEXT',
    icon: 'ri-user-settings-line',
    link: '/users-app',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_DEVLOPPER']
  },
  {
    id: 9,
    label: 'MENUITEMS.ROLES.TEXT',
    icon: 'ri-dashboard-line',
    link: '/roles',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_DEVLOPPER']
  },
  {
    id: 12,
    label: 'MENUITEMS.SETEUP_EVENT.TEXT',
    icon: 'ri-settings-4-line',
    link: '/setup-events',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_DEVLOPPER']
  },
  {
    id: 10,
    label: 'MENUITEMS.REPORTS.TEXT',
    icon: 'ri-calendar-2-line',
    link: '/reports',
    roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_DEVLOPPER']
  },

];
