import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {UiModule} from '../shared/ui/ui.module';
import {WidgetModule} from '../shared/widget/widget.module';

import {PagesRoutingModule} from './pages-routing.module';

import {
  NgbAccordionModule, NgbAlertModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule,
  NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {DndModule} from 'ngx-drag-drop';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

import {DashboardComponent} from './dashboard/dashboard.component';
import {CalendarComponent} from './calendar/calendar.component';
import {Ng5SliderModule} from 'ng5-slider';
import {ArchwizardModule} from 'angular-archwizard';
import {TableModule} from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {DemandeAbsComponent} from './demande-abs/demande-abs.component';
import {ToolbarModule} from 'primeng/toolbar';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputMaskModule} from 'primeng/inputmask';
import {FullCalendarModule} from '@fullcalendar/angular';
import {CardModule} from 'primeng/card';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TooltipModule} from 'primeng/tooltip';
import {DemandeCgComponent} from './demande-cg/demande-cg.component';
import {SidebarModule} from 'primeng/sidebar';
import {ProfileComponent} from './profile/profile.component';
import {TabViewModule} from 'primeng/tabview';
import {RolesComponent} from './roles/roles.component';
import {ReportsComponent} from './reports/reports.component';
import {FileUploadModule} from 'primeng/fileupload';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {RippleModule} from 'primeng/ripple';
import {FieldsetModule} from 'primeng/fieldset';
import {SharedModule} from '../layouts/shared/shared.module';
import {PanelModule} from 'primeng/panel';
import {ChartModule} from 'primeng/chart';
import {BlockUIModule} from 'primeng/blockui';
import {MessageModule} from 'primeng/message';
import {EmployeesEventsComponent} from './employees-events/employees-events.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {DataViewModule} from 'primeng/dataview';
import {LayoutsModule} from '../layouts/layouts.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ChipsModule} from 'primeng/chips';
import {RatingModule} from 'primeng/rating';
import {TranslateModule} from '@ngx-translate/core';
import {UsersComponent} from './users/users.component';
import {UsersDetailsComponent} from './users-details/users-details.component';
import {UsersAppComponent} from './users-app/users-app.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SetupEventsComponent} from './setup-events/setup-events.component';
import {DragDropModule} from 'primeng/dragdrop';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, CalendarComponent, DemandeAbsComponent, DemandeCgComponent, ProfileComponent, RolesComponent, ReportsComponent,
    EmployeesEventsComponent, UsersComponent, UsersDetailsComponent, UsersAppComponent, SetupEventsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    DndModule,
    LeafletModule,
    WidgetModule,
    NgbPaginationModule,
    ArchwizardModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTypeaheadModule,
    NgbCollapseModule,
    Ng5SliderModule,
    NgbModalModule,
    TableModule,
    ProgressBarModule,
    DropdownModule,
    CalendarModule,
    ToolbarModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    InputMaskModule,
    FullCalendarModule,
    CardModule,
    SplitButtonModule,
    TooltipModule,
    SidebarModule,
    TabViewModule,
    NgbAlertModule,
    FileUploadModule,
    ToggleButtonModule,
    RippleModule,
    FieldsetModule,
    SharedModule,
    PanelModule,
    ChartModule,
    BlockUIModule,
    MessageModule,
    MultiSelectModule,
    DataViewModule,
    LayoutsModule,
    ProgressSpinnerModule,
    ChipsModule,
    RatingModule,
    TranslateModule,
    InputSwitchModule,
    RadioButtonModule,
    DragDropModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule {
}
