import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeConges, RhDemandeCongesPK} from '../../models/rh-ec/RhDemandeConges';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {RhDemandeAbs} from '../../models/rh-ec/RhDemandeAbs';
import {User} from '../../models/auth.models';
import {FormGroup} from '@angular/forms';
import {VEmpCng} from '../../models/vues/VEmpCng';


@Injectable({
  providedIn: 'root'
})
export class DemandeCongesService {
  constructor(private http: HttpClient) {
  }


  findAllDemandes(user: User): Observable<RhDemandeConges[]> {
    return user.roles.includes('ROLE_RH') ? this.http.get<RhDemandeConges[]>(PAGES_URLS.FIND_ALL_DEMANDES_CG_BY_RESP) : this.http.get<RhDemandeConges[]>(PAGES_URLS.FIND_ALL_DEMANDES_CG);
  }

  findVempCg(numEmp: string): Observable<VEmpCng> {
    const params = new HttpParams()
      .set('numEmp', numEmp);
    return this.http.get<VEmpCng>(PAGES_URLS.FIND_NUMBER_JOURS, {
      params
    });
  }

  public deleteDemandeConges(pk: RhDemandeCongesPK) {
    return this.http.delete<RhDemandeCongesPK>(PAGES_URLS.DELETE_DEMANDES_CG + pk);
  }

  public validateDemandeConges(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.VALIDATE_DEMANDES_CG, formData.value);
  }

  public editDemandeConges(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.PUT_DEMANDES_CG, formData.value);
  }

  public saveDemandeConges(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.SAVE_DEMANDE_CG
      , formData.value);

  }

}
