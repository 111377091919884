<header id="page-topbar">
  <div [ngClass]="{'navbar-header-rtl':cookiesService.get('lang')=='ar',
       'navbar-header': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="50">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="ri-search-line"></span>
        </div>
      </form>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
             aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <div class="d-flex align-items-center justify-content-between">
            <span class="ml-2 mr-2">{{countryName}}</span><span><img src="{{countryFlag}}" width="20px"></span>
          </div>
        </button>
        <div [ngClass]="{'dropdown-menu-left':cookiesService.get('lang')=='ar',
       'dropdown-menu-right': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}"
             class="dropdown-menu " ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
             (click)="setLanguage(item.text,item.flag, item.lang)" [ngClass]="{'active': cookieValue === item.lang}">
            <span
              class="align-middle font-weight-bold mr-3">{{item.text}}</span>
            <span><img src="{{item.flag}}" width="20px">
              </span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ml-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
                id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          <i class="ri-notification-3-line"></i>
          <span class="noti-dot"></span>
        </button>
        <div [ngClass]="{'dropdown-menu-left':cookiesService.get('lang')=='ar',
       'dropdown-menu-right': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}"
             class="dropdown-menu dropdown-menu-lg  p-0" ngbDropdownMenu
             aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <!--            <a href="" class="text-reset notification-item">-->
            <!--              <div class="media">-->
            <!--                <div class="avatar-xs mr-3">-->
            <!--                  <span class="avatar-title bg-primary rounded-circle font-size-16">-->
            <!--                    <i class="ri-shopping-cart-line"></i>-->
            <!--                  </span>-->
            <!--                </div>-->
            <!--                <div class="media-body">-->
            <!--                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>-->
            <!--                  <div class="font-size-12 text-muted">-->
            <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>-->
            <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>-->
            <!--                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </a>-->
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle mr-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" *ngIf="profilePic"
               src="{{profilePic !==null ?'data:image/png;base64,'+profilePic:utilConstant.EMPTY_PROFILE_BASE64}}"
               alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">{{currentUser.firstName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div [ngClass]="{'dropdown-menu-left':cookiesService.get('lang')=='ar',
       'dropdown-menu-right': cookiesService.get('lang')=='fr' || cookiesService.get('lang')=='en'}"
             class="dropdown-menu" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);" routerLink="profile"><i
            class="ri-user-line align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
            class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>


    </div>
  </div>
</header>
