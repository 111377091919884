import {CfVilles} from '../adm/CfVilles';
import {RhRoles} from './RhRoles';
import {RhUsersAppRoles} from './RhUsersAppRoles';

export class RhUsersApp {
  usrId: number;
  username: string;
  usrEmail: string;
  usrCivilite: string;
  usrTitre: string;
  usrSexe: string;
  usrFirstName: string;
  usrLastName: string;
  usrIpAdresse: string;
  usrActiveFlag: number;
  usrAdminFlag: number;
  usrImage: any;
  usrCreationDate: Date;
  usrDateConnection: Date;
  usrDdn: Date;
  usrAsrCode: string;
  usrAdresse: string;
  usrMobile: string;
  usrToken: string;
  commune: CfVilles;
  rhUsersAppRolesList: RhUsersAppRoles[] = [];
}
