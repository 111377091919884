import {Component, OnInit} from '@angular/core';
import {ApplicationService} from '../../core/services/application.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {DemandeAbsService} from '../../core/services/rh-ec/demandeAbsService';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {DilovService} from '../../core/services/adm/dilov.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService} from '../../core/services/auth.service';
import {Router} from '@angular/router';
import {User} from '../../core/models/auth.models';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {environment} from '../../../environments/environment';
import {UserProfileService} from '../../core/services/user.service';
import {UtilConstants} from '../../core/helpers/util-constants';
import {DemandeCongesService} from '../../core/services/rh-ec/demandeCongesService';
import {HttpParams} from '@angular/common/http';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {EmployeeData} from '../../core/models/payloads/responces/EmployeeData';
import {File} from '../../core/models/transient/File';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [DecimalPipe]
})
export class ProfileComponent implements OnInit {
// breadcrumb items
  breadCrumbItems: Array<{}>;
  currentUser: User;
  selectedEmploy: RhEmployes;
  selectedEmployPoste: string;

  employeeData: EmployeeData = new EmployeeData();
  resetForm: FormGroup;
  submittedReset = false;
  profileForm: FormGroup;
  submittedProfile = false;
  mustMatche = false;
  error = '';
  success = '';
  numberOfDays: number;
  numberOfRelicats: number;
  listGenders: DiLovDetails[] = [];
  listBloodTypes: DiLovDetails[] = [];

  loading = true;

  maxSize = 500000; // 500 ko
  maxSizeErr = false;
  readonly utilConstant = UtilConstants;
  newProfile: File = new File();
  newProfileStream: string;

  // tslint:disable-next-line:max-line-length
  constructor(public applicationService: ApplicationService, private formBuilder: FormBuilder, private pipe: DecimalPipe, public employesService: EmployesService,
              public dilovService: DilovService, private messageService: MessageService,
              public userService: UserProfileService, private confirmationService: ConfirmationService,
              public demandeCongesService: DemandeCongesService, private modalService: NgbModal,
              public authService: AuthenticationService, private router: Router) {

  }

  ngOnInit(): void {
    this.selectedEmploy = new RhEmployes();
    this.breadCrumbItems = [{label: 'PROFILE.TEXT'}, {label: 'PROFILE.LIST.ABOUT_ME', active: true}];
    this.currentUser = this.authService.currentUser();
    this._getCurrentEmploy();
    this._getListGendersType();
    this._getListBloodsType();

    /*reset password */
    this.resetForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(UtilConstants.STRONG_PASSWORD_PATTERN)]],
      confirmeNewPassword: ['', [Validators.required, Validators.pattern(UtilConstants.STRONG_PASSWORD_PATTERN)]]
    });
    /*update profile */
    this.profileForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      dateNaissance: ['', Validators.required],
      mobile: ['', [Validators.pattern(UtilConstants.ALGERIAN_PHONE_NUMBER_PATTERN)]],
      address: [],
      profile: [null, this.newProfileStream ? Validators.required : Validators.nullValidator],
    });
  }

  _getListGendersType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '1');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listGenders = [];
        this.listGenders = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }

  initProfileFormValues(rhEmploye: RhEmployes) {
    this.profileForm.patchValue({
      profile: '',
      nom: rhEmploye.emp1stNameLoc,
      prenom: rhEmploye.empLastNameLoc,
      dateNaissance: new Date(rhEmploye.empBirthDate),
      mobile: rhEmploye.empMobileNo,
      address: rhEmploye.empAdresseLoc,
    });
  }

  _getListBloodsType() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '3');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listBloodTypes = [];
        this.listBloodTypes = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }


  _getVEmployeCongee(numEmp: string) {
    this.demandeCongesService.findVempCg(numEmp).subscribe(
      data => {
        this.numberOfRelicats = data.nbrReliquat !== null ? data.nbrReliquat : 0;
        this.numberOfDays = data.nbrJours !== null ? data.nbrJours : 0;
        // tslint:disable-next-line:no-shadowed-variable
      }, error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      },
    );
  }

  get f() {
    return this.resetForm.controls;
  }

  get pf() {
    return this.profileForm.controls;
  }


  _getCurrentEmploy() {

    setTimeout(() => {
      this.employesService.findEmploye(this.currentUser.empNum, 1).subscribe(data => {
        this.employeeData = data;
        this.selectedEmploy = this.employeeData.rhEmployes;
        this.selectedEmploy.empBirthDate = new Date(this.selectedEmploy.empBirthDate);
        this.selectedEmployPoste = this.employeeData.employeePost ?? 'Employée';
        this.newProfile.data = this.employeeData.profilePicture;
        // tslint:disable-next-line:max-line-length
        this.newProfile.data !== null ? this.newProfileStream = 'data:image/png;base64,' + this.newProfile.data : this.newProfileStream = this.utilConstant.EMPTY_PROFILE_BASE64;
        this._getVEmployeCongee(this.selectedEmploy.rhEmployesPK.empNum);
        this.initProfileFormValues(this.selectedEmploy);
        this.loading = false;
      });
    }, 1000);
  }

  onChangePassword() {
    this.success = '';
    this.submittedReset = true;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    } else {
      this.userService.changePassword(this.resetForm).subscribe(data => {
        if (data === null) {
          this.messageService.add({
            severity: 'error',
            summary: 'failed',
            detail: 'Votre ancien mot de passe est erroné',
            life: 3000
          });
          this.submittedReset = false;
        } else {
          this.messageService.add({
            severity: 'success',
            summary: 'Successful',
            detail: 'Votre mot de passe a était modifier avec sucée.',
            life: 3000
          });
          this.resetForm.reset();
          this.submittedReset = false;
        }
      }, error => {
        console.log(error);
      });
    }
  }

  onSubmit() {
    this.success = '';
    this.submittedProfile = true;

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    this.userService.updateUser(this.profileForm).subscribe(data => {
      this.applicationService.showSuccefulSaveDialog('Votre profile a était modifier avec sucée.', 'success');
      this.submittedProfile = false;
    }, error => {
      console.log(error);
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    const fileSize = event.target.files[0].size;
    if (fileSize > this.maxSize) {
      this.maxSizeErr = true;
    } else {
      this.maxSizeErr = false;
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        this.newProfile.data = reader.result.toString().split(',')[1];
        this.newProfile.size = fileSize;
        this.newProfile.name = file.name;
        this.newProfileStream = 'data:image/png;base64,' + this.newProfile.data;
        this.profileForm.patchValue({
          profile: this.newProfile
        });
      };
    }
  }

  passwordMatch(control: AbstractControl): boolean {
    const newPassword = this.resetForm.controls.newPassword?.value;
    const confirmeNewPassword = this.resetForm.controls.confirmeNewPassword?.value;
    if (newPassword && confirmeNewPassword && newPassword !== confirmeNewPassword) {
      return true;
    }
    return false;
  }

  getPasswordStrengthMessage(password: string): string {
    if (password.length < 6) {
      return 'Faible';
    } else if (password.length < 10) {
      return 'Moyen';
    } else {
      return 'Fort';
    }
  }

  openPhotoProfileModal(content: any, event: any) {
    this.modalService.open(content);
  }

  closePhotoProfileModal() {
    this.modalService.dismissAll();
  }


}
