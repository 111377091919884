import {RhUsersApp} from './RhUsersApp';
import {RhUsersAppRoles} from './RhUsersAppRoles';

export class RhRoles {
  rlaCode: string;
  rlaLabel: string;
  rlaCreationDate: Date;
  rlaDateConnection: Date;
  rhUsersAppRolesList: RhUsersAppRoles[];
}

export class UsRolesDTO {
  usRoles: RhUsersAppRoles[];
}


