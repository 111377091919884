import {RhEmployes} from './RhEmployes';
import {RhDemandeCongStatus} from './RhDemandeCongStatus';

export class RhDemandeConges {
  dcgGrpId: number;
  dcgCmpId: number;
  dcgExercice: number;
  dcgEmpType: number;
  dcgEmpNum: string;
  dcgSerial: number;
  dcgDemandeDate: Date;
  dcgDebutDate: Date;
  dcgFinDate: Date;
  dcgJours: number;
  dcgType: string;
  dcgAddressLoc: string;
  dcgAddressFrn: string;
  dcgContactTelephone: string;
  dcgRemarksLoc: string;
  dcgRemarksFrn: string;
  dcgStatus: number;
  dcgExpRepriseDate: Date;
  dcgReelRepriseDate: Date;
  dcgRetardJours: number;
  dcgDecision: string;
  dcgCreatedPar: string;
  dcgModifierPar: string;
  dcgCreationDate: Date;
  dcgModificationDate: Date;
  rhEmployes: RhEmployes;
  dcgNbrDays: number;
  rhDemandeCongesPK: RhDemandeCongesPK;
  rhDemandeCongStatusList: RhDemandeCongStatus[];
}

export class RhDemandeCongesPK {
  dcgGrpId: number;
  dcgCmpId: number;
  dcgExercice: number;
  dcgEmpType: number;
  dcgEmpNum: string;
  dcgSerial: number;
}
