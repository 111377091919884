import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RhDemandeAbs, RhDemandeAbsPK} from '../../models/rh-ec/RhDemandeAbs';
import {PAGES_URLS} from '../../../pages/pages.urls';
import {RhEmployes, RhEmployesPK} from '../../models/rh-ec/RhEmployes';
import {User} from '../../models/auth.models';
import {AuthenticationService} from '../auth.service';
import {EmployeeData} from '../../models/payloads/responces/EmployeeData';
import {FormGroup} from '@angular/forms';
import {RhUsersApp} from '../../models/security/RhUsersApp';
import {RhRoles, UsRolesDTO} from '../../models/security/RhRoles';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  selectedEmployData = new EmployeeData();
  currentUser: User = new User();

  constructor(private http: HttpClient, private authService: AuthenticationService) {
  }

  findAllUsers(): Observable<any> {
    return this.http.get<any>(PAGES_URLS.USERS);
  }

  public editUser(formData: FormGroup) {
    return this.http.put<any>(PAGES_URLS.USERS, formData.value);
  }

  public editUserRoles(roles: any) {
    const request = new UsRolesDTO();
    request.usRoles = roles;
    return this.http.put<any>(PAGES_URLS.UPDATE_USER_ROLES, request);
  }


  public deletUser(id: number) {
    return this.http.delete<RhUsersApp>(PAGES_URLS.USERS + id);
  }

  public saveUser(formData: FormGroup) {
    return this.http.post<any>(PAGES_URLS.USERS
      , formData.value);

  }


}
