<div class="container-fluid">
  <app-pagetitle title="Reports" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <p-toast></p-toast>
      <div class="card">
        <div class="card-body">
          <p>reports works!</p>
        </div>
      </div>
    </div>
  </div>
</div>



