import {Component, OnInit} from '@angular/core';
import {RhEmployes} from '../../core/models/rh-ec/RhEmployes';
import {DemandeAbsService} from '../../core/services/rh-ec/demandeAbsService';
import {EmployesService} from '../../core/services/rh-ec/employesService';
import {Router} from '@angular/router';
import {EventsService} from '../../core/services/rh-ec/eventsService';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationService} from '../../core/services/application.service';
import {AuthenticationService} from '../../core/services/auth.service';
import {HttpParams} from '@angular/common/http';
import {DilovService} from '../../core/services/adm/dilov.service';
import {DiLovDetails} from '../../core/models/adm/DiLovDetails';
import {fontAwsomeIconsList, remixIconsList} from './setup-events-data';
import {Icon} from './setup-events.model';
import {RhDemandeConges} from '../../core/models/rh-ec/RhDemandeConges';
import {StEvents} from '../../core/models/rh-ec/StEvents';
import {statusList} from '../demande-abs/demande-abs-data';
import {CookieService} from 'ngx-cookie-service';
import {RhDemandeAbs} from '../../core/models/rh-ec/RhDemandeAbs';
import {customDialogAnimations, modalBackgroundAnimations} from '../animations';

@Component({
  selector: 'app-setup-events',
  templateUrl: './setup-events.component.html',
  styleUrls: ['./setup-events.component.scss'],
  animations: []
})
export class SetupEventsComponent implements OnInit {
  cookieValue;

  showDetails = false;
  showEdits = false;
  showAdd = false;

  // bread crumb items
  breadCrumbItems: Array<{}>;
  listEmployes: RhEmployes[] = [];
  isLoading = true;


  // Data
  data: StEvents[];
  events: StEvents[];
  selectedEvent: StEvents;
  totalRecords = 0;

  // Lazy attributes
  cols: any[];
  loading = true;

  // Form submition value
  seteupEventForm: FormGroup;
  submitted: boolean;

  // Dialogs attributes
  evtDlg = false;


  listCategoriesEvent: DiLovDetails[] = [];
  iconsList: Icon[] = remixIconsList;

  // bloque Ui
  blockedDocument = false;


  constructor(public employesService: EmployesService, private router: Router, private eventsService: EventsService,
              private formBuilder: FormBuilder, public applicationService: ApplicationService, public cookiesService: CookieService,
              public authenticationService: AuthenticationService, public dilovService: DilovService) {
  }

  ngOnInit(): void {
    this.cookieValue = this.cookiesService.get('lang');
    this.breadCrumbItems = [{label: 'Alliance Rh'}, {label: 'Setup Events', active: true}];
    this._fetchData();
    this._getListEventCategories();
    this.iconsList = this.iconsList.filter(i => i.label.length > 0);

    /**
     * Template cols
     */
    this.cols = [
      // {field: 'img', header: '#'},
      {
        field: 'stEventsPK',
        header: 'EVENT.HEADERS.CODE_EVENT',
        width: '10%'
      },
      {
        field: 'evtDescLoc',
        header: 'EVENT.HEADERS.DESCRIPTION',
        width: '25%'
      },
      {
        field: 'evtCategorie',
        header: 'EVENT.HEADERS.CATEGORIE',
        width: '25%'
      },

      {
        field: 'actions',
        header: 'EVENT.HEADERS.ACTIONS',
        width: '15%'
      }
    ];


    /**
     * init the form
     */

    this.seteupEventForm = this.formBuilder.group({
      codeEvent: [null, Validators.required],
      categoryEvent: [null, Validators.required],
      nomLoc: [null, Validators.required],
      nomFrn: [null, Validators.nullValidator],
      abrLoc: [null, Validators.nullValidator],
      abrFrn: [null, Validators.nullValidator],
      icon: [null, Validators.required],
      color: ['#1cbb8c', Validators.required],
      fileFlag: [false, Validators.required],
      activeFlag: [true, Validators.required],
    });


  }

  get seF() {
    return this.seteupEventForm.controls;
  }

  resetForm(form: FormGroup) {
    form.reset();
  }


  /**
   * fetches the table value
   */
  _fetchData() {
    this.loading = true;
    setTimeout(() => {
      this.eventsService.findAllStEvents().subscribe(
        data => {
          this.data = data;
          this.events = data;
          this.totalRecords = this.events.length;
          this.loading = false;
          // tslint:disable-next-line:no-shadowed-variable
        }, error => {
          if (error.status === '401') {
            this.router.navigate(['/']);
          }
        },
      );
    }, 1000);
  }

  findMaxEventCode() {
    this.eventsService.findMaxEventCode().subscribe(data => {
        this.seteupEventForm.patchValue({
          codeEvent: data.data[0],
        });
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }


  _getListEventCategories() {
    const params = new HttpParams()
      .set('lvmSysId', '4').set('lvmId', '38');
    this.dilovService.findListDLvd(params).subscribe(data => {
        this.listCategoriesEvent = data.diLovDetailsList;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        if (error.status === '401') {
          this.router.navigate(['/']);
        }
      });
  }


  /**
   * select event listener
   */

  onSelectCategorie(event) {
    this.seteupEventForm.patchValue({
      categoryEvent: (event.value as DiLovDetails).lvdId
    });
  }

  onSelectIcon(event) {
    this.seteupEventForm.patchValue({
      icon: event.value
    });
  }


  saveEvent() {
    this.submitted = true;
    if (this.seteupEventForm.invalid) {
      return;
    } else {
      console.log(this.seteupEventForm.value);
      this.blockedDocument = true;
      this.addEntity();
    }
  }


  showAddNewEvent() {
    this.findMaxEventCode();
    this.showDetails = false;
    this.showEdits = false;
    this.showAdd = true;
    this.evtDlg = true;
  }

  showDetailsEvent(rowdata: StEvents) {
    this.evtDlg = true;
    this.patchFormValues(this.seteupEventForm, rowdata);
    this.showDetails = true;
    this.showEdits = false;
    this.showAdd = false;

  }

  showEditEvent(rowdata: StEvents, rowIndex: any) {
    this.evtDlg = true;
    this.patchFormValues(this.seteupEventForm, rowdata);
    this.showEdits = true;
    this.showDetails = false;
    this.showAdd = false;
  }

  hideDialog() {
    this.evtDlg = false;
    this.resetForm(this.seteupEventForm);
    this.showEdits = false;
    this.showDetails = false;
    this.showAdd = false;

  }

  patchFormValues(form: FormGroup, rowdata: StEvents) {
    form.patchValue({
      codeEvent: rowdata.stEventsPK.evtCode,
      categoryEvent: rowdata.evtCategorie,
      nomLoc: rowdata.evtDescLoc,
      nomFrn: rowdata.evtDescFrn,
      abrLoc: rowdata.evtAbrvLoc,
      abrFrn: rowdata.evtDescFrn,
      icon: rowdata.evtIcon,
      color: rowdata.evtColor,
      fileFlag: rowdata.evtFileFlag === 1 ? true : false,
      activeFlag: rowdata.evtActiveFlag === 1 ? true : false,
    });
  }


  addEntity() {
    this.eventsService.saveEvent(this.seteupEventForm).subscribe(data => {
      if (data != null) {
        this.selectedEvent = (data.data[0] as StEvents);
        this.events.push(this.selectedEvent);
        this.events = [...this.events];
        this.evtDlg = false;
        this.resetForm(this.seteupEventForm);
        this.blockedDocument = false;
        this.applicationService.showSuccefulSaveDialog('Evenement Ajouté Avec Succée.', 'success');
        this.resetForm(this.seteupEventForm);
        this.submitted = false;
      }
    }, error => {
      console.log(error);
    });
  }

  protected readonly statusList = statusList;
}
